import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function TreatmentDataBtn(props) {
  const { onClick } = props;
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Stack spacing={2} direction="row">
      <Button variant="outlined" onClick={handleClick} sx={{ width: "200px",mr: 2, }}>
        tretmani
      </Button>
    </Stack>
  );
}
