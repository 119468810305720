import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"; //redux

//Button
import EditButton from "../../components/Datatables/EditButton";
import DelButton from "../../components/Datatables/DelButton"
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear"; //icon for delete search
import TextField from "@mui/material/TextField";
//import component 
import DialogAddPrice from "./Dialog/DialogAddPrice"

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
//import { GridColCell } from "@mui/x-data-grid-pro";
import { styled } from '@mui/system';
//import { DataGridPro } from '@mui/x-data-grid-pro';
import { useTheme } from '@mui/material/styles';

import { useMediaQuery } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapCellContent: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: 160,
  },
  
});

  

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: 'rgba(237, 237, 237)',
    },
    '& .clear-icon': {
      backgroundColor: 'lightgray', // Set your desired color for the ClearIcon here
    },
    '& .MuiDataGrid-row': {
      borderBottom: 'none !important',
    },
  
    '& .MuiDataGrid-row': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    }
  }));
export default function TreatmentPrice({ treatmentPriceId  }) {
  const theme = useTheme();

  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);
  const { id } = useParams(); //id from url
  const isDesktop = useMediaQuery('(min-width:800px)');
  const BASE_URL = 'https://api.adaktarbooking.com';

  const basicRole = useSelector((state) => state.menu.basicRole); 

  const classes = useStyles();
  
    
  const token = localStorage.getItem('accessToken'); // get the token from local storage
  const tenant = 'root'; // Define your tenant
  //console.log(token,"tokenis")
  //function for deleting row whan click on deletebutton
  function handleDelete(id) {
    // check if id is valid
    if (!id) {
      console.error('Invalid id');
      return;
    }
    
    axios
      .delete(`${BASE_URL}/api/v1/treatmentprice/${id}`, {
        headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const updatedRows = rows.filter((row) => row.id !== id);
          setRows(updatedRows);
          setFilteredRows(updatedRows);
        } else {
          console.error('Failed to delete treatment price');
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
      });
  }
  

  //columns in table
  const columns = [//    { field: "id", headerName: "ID", flex: 1, hide:true },

    { field: "name", headerName: "Naziv",       width: isDesktop ? undefined : 100, flex: isDesktop ? 1.5 : undefined,
    renderCell: (params) => (
      
      <div className={classes.wrapCellContent}>
        {params.value}
      </div>
    ), },
    { 
      field: "tsPrice", 
      headerName: "Cena vazi od",       
      width: isDesktop ? undefined : 200, 
      flex: isDesktop ? 1.5 : undefined,  
      renderCell: (params) => {
        let date = new Date(params.value);
        let options = { 
          day: '2-digit', 
          month: '2-digit', 
          year: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit', 
          timeZone: 'Europe/Berlin' // Use appropriate time zone for your use case.
        };
        let formattedDateAndTime = date.toLocaleString('en-GB', options);
        return (
          <div className={classes.wrapCellContent}>
            {formattedDateAndTime}
          </div>
        );
      },
    },
    
    
    { field: "price", headerName: "Cena",       width: isDesktop ? undefined : 100, flex: isDesktop ? 1.5 : undefined,
  },
    { field: "discount", headerName: "Popust(%)",       width: isDesktop ? undefined : 100, flex: isDesktop ? 1.5 : undefined,
  },
    {
      field: "action",
      headerName: "Akcije",
      width: isDesktop ? undefined : 100, flex: isDesktop ? 1.5 : undefined,

      align: "right", // Aligns the column header to the right
      headerAlign: "right", // Aligns the column values to the right
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "right" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <NavLink to={`/treatment/preview/${params.row.id}`}>
                <EditButton />
              </NavLink> */}
               {!basicRole && (
              <DelButton onClick={() => handleDelete(params.row.id)} />
              )}
            </Box>
          </div>
          );
        },
      },
  ];
  


//API TAKE DATA FROM DB TREATMENTS PRICE 
useEffect(() => {
  // console.log(id,'iddd')
  //povuci podatke iz api  tretman    i iz njega uzim cenu 
  axios.get(`${BASE_URL}/api/v1/treatmentprice/${treatmentPriceId}`, {

    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${token}`,
    },
  })
  .then((response) => {
    if (response.status === 200) {
     setRows([response.data]);
     setFilteredRows([response.data]);
     
    } else {
      console.error('Failed to fetch treatment price');
    }
  })
  .catch((error) => {
    if (error.response) {
      console.log(error.response.data);
      switch(error.response.status) {
          case 400:
              console.error("Error 400: Bad Request");
              break;
          case 402:
              console.error("Error 402: Payment Required");
              break;
          default:
              console.error(`Unknown error occurred: ${error.response.status}`);
              break;
      }
    } else if (error.request) {
      console.log('The request was made but no response was received', error.request);
    } else {
      console.log('Error', error.message);
    }
  });
}, [token, id]);



  //function for search
  useEffect(() => {
    if (search === "") {
      setFilteredRows(rows);
    } else {
      const filteredData = rows.filter((row) =>
        row.title.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredRows(filteredData);
    }
  }, [search, rows]);

  function DeleteSearch() {
    setSearch("");
  }
  const [open, setOpen] = useState(false);

 
  const handleButtonClick = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div style={{ height: 600, width: "100%" }}>
    <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "0px",paddingRight:"20px",paddingTop:"10px" }}>
      {/* <div 
        onClick={handleButtonClick}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'blue',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            cursor: 'pointer',
            minWidth: '150px',
            textAlign: 'center',
            userSelect: 'none',
            '@media (max-width: 600px)': {
                width: '100%',
                margin: '10px 0',
            }
        }}
      >
        Dodaj cenu tretmana
      </div> */}
      {/* <DialogAddPrice open={open} handleClose={handleClose}  /> */}
    </Box>

    <Box sx={{ display: "flex", justifyContent: "center",marginBottom: "70px",marginTop:"20px" }}>
  <FormControl sx={{ marginX: "5px", width: "50%" }}>
    <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
    <Input
      id="standard-basic"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
    {search !== "" && (
        <IconButton aria-label="delete" onClick={DeleteSearch}>
        <ClearIcon />
      </IconButton>

    
   
    )}
  </FormControl>
</Box>


<StyledDataGrid
  sx={{
    border: "none",
     "& .MuiDataGrid-row": {
          borderBottom: "none",
        },
    borderRadius: "8px",
    marginTop: "16px",
    backgroundColor: "white",
    paddingX: "40px",
  }}
  rows={filteredRows}
  columns={columns}
  pageSize={5}
  rowsPerPageOptions={[5]}
/>



  </div>
  );
}
