import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Divider, Typography } from '@mui/material';
import { Box } from "@mui/material";
import DataPickerPrice from './DataPickerPrice';
import TextField from '@mui/material/TextField';
import EuroIcon from '@mui/icons-material/Euro';
import InputAdornment from '@mui/material/InputAdornment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {  useParams } from "react-router-dom";
import axios from "axios"; 
import dayjs from 'dayjs';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';

export default function DialogAddPrice({ treatmentPriceId, onConfirm, ...props }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { id } = useParams(); //id from url
  const [treatmentPriceData, setTreatmentPriceData] = useState(null);

  const BASE_URL = 'https://api.adaktarbooking.com';


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  

  //state  
  const [name, setName] = useState("");
  const [tsPrice, setTsPrice] = useState(null);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountValidAfter, setDiscountValidAfter] = useState(0);
  const [color, setColor] = useState("");  // You need to manage this state if it's necessary
  const [treatmentId, setTreatmentId] = useState(id);
  
  const resetForm = () => {


    setName("");
    setTsPrice(null);
    setPrice(0);
    setDiscount(0);
    setDiscountValidAfter(0);
    setTreatmentId();
  };
  
//TAKE PRACE FOR TREATMENT FROM API START
useEffect(() => {
  if (treatmentPriceId) {
    fetchTreatmentPriceData(treatmentPriceId);
  }
}, [treatmentPriceId]);

const fetchTreatmentPriceData = async (id) => {
  try {
    const token = localStorage.getItem('accessToken');
    const tenant = 'root';

    const response = await axios.get(`${BASE_URL}/api/v1/treatmentprice/${id}`, {
      headers: {
        "accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant,
      },
    });

    if (response.status === 200) {
      setTreatmentPriceData(response.data);
      const dateObject = dayjs(response.data.tsPrice);

       setName(response.data.name);
        setTsPrice(dateObject);
        setPrice(response.data.price);
        setDiscount(response.data.discount);
        setDiscountValidAfter(response.data.discountValidAfter);
        setTreatmentId(response.data.treatmentId);
    } else {
      console.log("Failed to fetch TreatmentPriceData");
    }
  } catch (error) {
    console.error(error);
  }
};


//TAKE PRACE FOR TREATMENT FROM API END
  const handleConfirm = () => {
    handleClose();
    //handleSubmit();
    // Here, we're calling the function passed in through the onConfirm prop.
    // You can replace `treatmentprice` with any data you want to send back to the parent.
    const treatmentprice = {
      id:treatmentPriceId,
      name,
      tsPrice: tsPrice.toISOString(), // Convert Moment.js object to ISO string
      price: Number(price),
      discount: Number(discount),
      discountValidAfter: Number(discountValidAfter),
      color:"", 
    };
    onConfirm(treatmentprice);
  };


  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Dodaj cenu tretmana
      </Button>
     
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs" 
        fullWidth 
      >
        <DialogTitle id="responsive-dialog-title">
          {"Cena tretmana:"}  {/* uzeti ime od id tretmana   */}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
          <Box>
            <Typography>Cena važi od</Typography>           
            <DataPickerPrice tsPrice={tsPrice} setTsPrice={setTsPrice} />
          </Box>
          <Box sx={{pt:2}}>
          <Typography sx={{ paddingBottom: "13px" }}>Ime tretmana</Typography>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <OutlinedInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              size="small"
              id="outlined-basic"
              placeholder="Unesi Naziv"
              style={{ height: "40px" }}
            />
          </FormControl>          <Box sx={{pt:2}}>

<Typography sx={{ paddingBottom: "13px" }}>Cena</Typography>
  <FormControl variant="outlined" style={{ width: "100%" }}>
    <OutlinedInput
      value={price}
      onChange={(e) => setPrice(e.target.value)}
      size="small"
      id="price"
      style={{ height: "40px" }}
      startAdornment={
        <InputAdornment position="start">
          <EuroIcon fontSize="small" />
        </InputAdornment>
      }
      inputProps={{
        type: "number",
        placeholder: "Cena",
      }}
    />
  </FormControl>

</Box>          
          </Box >
          <Box sx={{pt:2}}>
          <Typography sx={{ paddingBottom: "13px" }}>Popust</Typography>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel htmlFor="discount">Popust</InputLabel>
              <OutlinedInput
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                size="small"
                id="discount"
                style={{ height: "40px" }}
                startAdornment={
                  <InputAdornment position="start">
                    <EmojiEventsIcon fontSize="small" />
                  </InputAdornment>
                }
                inputProps={{
                  type: "number",
                }}
              />
            </FormControl>          
          </Box >
          <Box sx={{pt:2}}>
          <Typography sx={{ paddingBottom: "13px" }}>Popust važi nakon</Typography>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <OutlinedInput
              value={discountValidAfter}
              onChange={(e) => setDiscountValidAfter(e.target.value)}
              size="small"
              id="discountValidAfter"
              style={{ height: "40px" }}
              startAdornment={
                <InputAdornment position="start">
                  <BookmarkBorderIcon fontSize="small" />
                </InputAdornment>
              }
              inputProps={{
                type: "number",
                placeholder: "Popust važi nakon",
              }}
            />
          </FormControl>
          </Box >
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button autoFocus onClick={handleConfirm}>
            Potvrdi
          </Button>
          <Button onClick={handleClose} autoFocus>
            Otkaži
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


