import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { NavLink, useParams } from "react-router-dom";
//Button
import EditButton from "../EditButton";
import DelButton from "../DelButton";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear"; //icon for delete search
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from "react-redux"; //redux

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { format } from "date-fns";



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "rgba(237, 237, 237)",
  },
  "& .clear-icon": {
    backgroundColor: "lightgray", // Set your desired color for the ClearIcon here
  },
  "& .MuiDataGrid-row": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  
}));

const useStyles = makeStyles({
  wrapCellContent: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: 160,
  },
  
});
export default function DataTableC() {
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);
  const token = localStorage.getItem('accessToken'); // get the token from local storage
  const isDesktop = useMediaQuery('(min-width:1200px)');
  const classes = useStyles();
  const navigate = useNavigate();
  const basicRole = useSelector((state) => state.menu.basicRole); 
  const BASE_URL = 'https://api.adaktarbooking.com';

  //REDUX PART START
  const dispatch = useDispatch();

  const allClients = useSelector(state => state.menu.clients);  // access the data from the state

  // REDUX END 

  //function for deleting row whan click on deletebutton
  function handleDelete(id) {
    axios

      .delete(`${BASE_URL}/api/v1/client/${id}`, {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,

        },
      })
      .then(() => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
        setFilteredRows(updatedRows);
        // Optionally, show a success message to the user here
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // Optionally, show an error message to the user here
        } else if (error.request) {
          // The request was made but no response was received
          // Optionally, show an error message to the user here
        } else {
          // Something happened in setting up the request that triggered an Error
          // Optionally, show an error message to the user here
        }
      });
  }
  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YY')

}
const navigateOnCreateCard = () => {
  navigate("/client/new"); // Replace '/desired-route' with your actual route.
}

  //columns in table
  const columns = [
 //   { field: "id", headerName: "ID", flex: 0, headerAlign: "center", align: "center" },
    { field: "name", headerName: "Ime", width: isDesktop ? undefined : 200, flex: isDesktop ? 1.3 : undefined,headerAlign: "center", align: "center", },
    { field: "surname", headerName: "Prezime",  headerAlign: "center", align: "center", width: isDesktop ? undefined : 200, flex: isDesktop ? 1.5 : undefined , renderCell: (params) => (
      <div className={classes.wrapCellContent}>
        {params.value}
      </div>
    ),},
    { field: "dateOfBirth", headerName: "Datum rodjenja",  headerAlign: "center", align: "center", width: isDesktop ? undefined : 200, flex: isDesktop ? 1.7 : undefined, 
    valueGetter: (params) => {
      const date = params.value;
      if(!date){
        return "Invalid Date"
      }
      const formattedStartTime = format(new Date(date), "dd.MM.yyyy");
      return formattedStartTime;
    }},
    { field: "phone", headerName: "Telefon",  headerAlign: "center", align: "center", width: isDesktop ? undefined : 200, flex: isDesktop ? 2 : undefined },
    { field: "address", headerName: "Adresa", headerAlign: "center", align: "center", width: isDesktop ? undefined : 250, flex: isDesktop ? 2 : undefined,  renderCell: (params) => (
      <div className={classes.wrapCellContent}>
        {params.value}
      </div>
    ),},
    { field: "email", headerName: "Email",  headerAlign: "center", align: "center", width: isDesktop ? undefined : 250, flex: isDesktop ? 2 : undefined, renderCell: (params) => (
      
      <div className={classes.wrapCellContent}>
        {params.value}
      </div>
    ),},
    {
      field: "action",
      headerName: "Akcije",
      width: isDesktop ? undefined : 200,
       flex: isDesktop ? 1.5 : undefined,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <Box sx={{ display: "flex" }}>
              <NavLink to={`/client/preview/${params.row.id}`}>
                <EditButton />
              </NavLink>

              {!basicRole && (
        <DelButton onClick={() => handleDelete(params.row.id)} />
      )}
            </Box>
          </div>
        );
      },
    },
  ];

  //function get data and put data in state
  // React.useEffect(() => {

  //   axios.get("${BASE_URL}/api/v1/client/allclients", {

  //     headers: {
  //       "accept": "application/json",
  //       "Authorization": `Bearer ${token}`,
  //     },
  //   }).then((response) => {
  //     setRows(response.data);
  //     setFilteredRows(response.data);
  //   });
  // }, []);

  React.useEffect(() => {
    setRows(allClients);
    setFilteredRows(allClients);
  }, [allClients]);

  //function for search
  useEffect(() => {
    if (search === "") {
      setFilteredRows(rows);
    } else {
      const searchTerms = search.toLowerCase().split(' '); // Split the search string into words
      const filteredData = rows.filter((row) =>
        searchTerms.every(term => 
          row.name.toLowerCase().includes(term) ||
          row.surname.toLowerCase().includes(term) ||
          row.phone.toLowerCase().includes(term) ||
          (row.name.toLowerCase() + ' ' + row.surname.toLowerCase()).includes(term)
        )
      );
      setFilteredRows(filteredData);
    }
  }, [search, rows]);


  function DeleteSearch() {
    setSearch("");
  }

  return (
    <div style={{ height: '50vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div style={{ height: '90%', width: '100%', marginBottom: "50px" }}>
    <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "50px" }}>
        <TextField
          fullWidth
          size="Normal"
          id="standard-basic"
          label="search"
          variant="standard"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: "50%" }} // Set the desired width here

        />

      {search !== "" &&   (

        <IconButton aria-label="delete" onClick={DeleteSearch}>
          <ClearIcon />
        </IconButton>
        )}
            <IconButton onClick={navigateOnCreateCard}  sx={{ ml: 1 }}>
        <AddCircleOutlineIcon sx={{ fontSize: '35px' }} />
    </IconButton>

      </Box>

      <StyledDataGrid
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
              borderBottom: "none",
            },
            borderRadius: "8px",
            marginTop: "16px",
            backgroundColor: "white",
            paddingX: "40px",
            }}
            rows={filteredRows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
      />
    </div>
    </div>
  );
}
