// import * as React from "react";
// import { useState } from "react";
// import { useEffect } from "react";
// import { useCallback } from 'react';

// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import { Divider } from "@material-ui/core";
// import ComboClient from "./combo/ComboforClient";
// import { Box, Typography } from "@mui/material";
// import ComboTreatment from "./combo/ComboTreatments";
// import DataEvent from "./DataEvent";
// import ComboBoxStatus from "./combo/ComboboxStatus";
// import ComboStaff from "./combo/ComboStaff";
// import TimeStart from "./TimeStart";
// import TimeEnd from "./TimeEnd";
// import ComboStatus from "./combo/ComboStatus";
// import TextField from '@mui/material/TextField';
// import Backdrop from '@mui/material/Backdrop';
// import axios from "axios";
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import moment from 'moment';
// import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
// import AddCardIcon from '@mui/icons-material/AddCard';
// import { useSelector, useDispatch } from "react-redux"; //redux
// import { fetchClients, fetchTreatments, fetchPreferences, fetchUsers, fetchStaffTreatments, fetchStaff ,postClientTreatments,fetchClientTreatmentStatus } from '../../../redux/action';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import IconButton from '@mui/material/IconButton';
// import ClientDialog from '../../../pages/client/ClientDialog'
// import { gridColumnGroupsLookupSelector } from "@mui/x-data-grid";
// React related imports
import * as React from "react";
import { useState, useEffect, useCallback } from "react";

// Redux related imports
import { useSelector, useDispatch } from "react-redux";
import { fetchClients, fesecondStateClientTreatment, fetchTreatments, fetchPreferences, fetchUsers, fetchStaffTreatments, fetchStaff, postClientTreatments, fetchClientTreatmentStatus } from '../../../redux/action';

// Third-party libraries
import axios from "axios";
import moment from 'moment';

// MUI core components
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, Divider, Box, Typography, Select, MenuItem, FormControl, InputLabel, OutlinedInput, IconButton } from "@mui/material";

// MUI styles
import { useTheme } from "@mui/material/styles";

// MUI icons
import AddCardIcon from '@mui/icons-material/AddCard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


// Component imports
import ComboClient from "./combo/ComboforClient";
import ComboTreatment from "./combo/ComboTreatments";
import DataEvent from "./DataEvent";
import ComboStaff from "./combo/ComboStaff";
import TimeStart from "./TimeStart";
import TimeEnd from "./TimeEnd";
import ComboStatus from "./combo/ComboStatus";
import ClientDialog from '../../../pages/client/ClientDialog';
import { ToastContainer, toast } from 'react-toastify';
import { useRef } from "react";
import { setDate } from "date-fns";


export default function ResponsiveDialog({ resetDateFromCalendar, isOpen, onClose, onEventSave, handleInsert, isSidebarOpen, timefromCalendar, dateFromCalendar }) {
  // console.log(dateFromCalendar,'datum')
  const dispatch = useDispatch();
  const statusTreatment = useSelector(state => state.menu.clientTreatmentStatus);


  const [open, setOpen] = React.useState(isOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [idStaffAndPriceForSelectedTreatment, setidStaffAndPriceForSelectedTreatment] = useState("");
  const [price, setPrice] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [currency, setCurrency] = useState('eur');
  const [paidAmount, setPaidAmount] = useState(0);
  const [remaining, setRemaining] = useState(price);
  const [preferences, setPreferences] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [depositPaid, setDepositPaid] = useState(0);
  const [statusOptions, setStatusOptions] = useState([]);
  const [treatmentChanged, setTreatmentChanged] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [dateforSenttoStaffComponent, setDateforSenttoStaffComponent] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateFromCalendarS, setDateFromCalendarS] = useState(dateFromCalendar);
  const [parentDate, setParentDate] = useState(null);
  const [idOfInsertedClient, setIdOfInsertedClient] = React.useState(null);
  const [users, setUsers] = useState([]);


  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);




  const [event, setEvent] = useState({
    date: "",
    client: "",
    treatment: "",
    timeStart: "",
    timeEnd: "",
    status: "",
    staff: "",
    paidAmount: paidAmount,
    remaining: remaining,
    price: 0,
  });


  const handleClickOpen = () => {
    setOpen(true);
  };
  //ovde smo promenili funkciju koja je bila samo jedna svala se handleClose  i dodavala se svakom buttonu
  // sad smo napravili dve odvojene i dodelili  svakom buttunu posebnu funkciju i dodelili smo dialogu
  //funkciju da bi se zatvorio  ukoliko bude greske kad se pusuje  vrati na handleClose 
  const handleCloseSave = () => {
    //insertEvent();
    setEvent({
      date: "",
      client: "",
      treatment: "",
      timeStart: "",
      timeEnd: "",
      status: "",
      staff: "",
      paidAmount: 0,
      remaining: 0,
      price: 0,

    });
    setOpen(false);
    onClose && onClose();

  };

  useEffect(() => {
    if (event.timeStart === "") {
      setEndTime("")
    }
  }, [dateFromCalendar, isOpen, event.timeStart]);

  useEffect(() => {
      const date = new Date(dateFromCalendar);
    const formattedDate = `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}`;
    setDateFromCalendarS(formattedDate);
    setEvent(prevEvent => ({ ...prevEvent, price: 0 }));

  }, [isOpen]);



  const handleClickOpenDialogCreateNewClient = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const refetchUsers = async () => {
    const token = localStorage.getItem('accessToken');
    const tenant = 'root';

    const response = await axios.get(`https://api.adaktarbooking.com/api/v1/client/allclients`, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant
      }
    });

    setUsers(response.data);

  };

  //API TAKE PREFERECE FROM DB START
  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    const headers = {
      "accept": "application/json",
      "Authorization": `Bearer ${token}`,
    };


    axios.get('https://api.adaktarbooking.com/api/v1/preferences/allpreferences', { headers })
      .then((response) => {
        if (response.status === 200) {
          setPreferences(response.data); // update state with API response
          if (response.data && response.data[0]) {
            setExchangeRate(response.data[0].exchangeRate); // update exchange rate here
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error('Server responded with an error', error.response.data);
          switch (error.response.status) {
            case 400:
              console.error("Error 400: Bad Request");
              break;
            case 402:
              console.error("Error 402: Payment Required");
              break;
            default:
              console.error(`Unknown error occurred: ${error.response.status}`);
              break;
          }
        } else if (error.request) {
          console.error('No response received', error.request);
        } else {
          console.error('Error', error.message);
        }
      });
  }, []);

  //API TAKE PREFERECE FROM DB END


  const insertEvent = () => {

    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    };
    axios.get('https://api.adaktarbooking.com/api/v1/stafftreatment', config)
      .then((response) => {
        const staffTreatments = response.data;
        const matchedStaffTreatment = staffTreatments.find((staffTreatment) =>
          staffTreatment.treatmentId === event.treatment && staffTreatment.staffId === event.staff
        );

        if (matchedStaffTreatment) {
          event.staffTreatmentId = matchedStaffTreatment.id;
        }


        const formattedDate = moment(event.date, 'MM.DD.YYYY').format('YYYY-MM-DD');
        const formattedStartTime = moment(`${formattedDate}T${event.timeStart}`).format('YYYY-MM-DDTHH:mm:ss');
        const formattedEndTime = moment(`${formattedDate}T${event.timeEnd}`).format('YYYY-MM-DDTHH:mm:ss');
        const oneMonthReminder = moment(formattedStartTime).add(1, 'months').toISOString();
        const threeMonthsReminder = moment(formattedStartTime).add(3, 'months').toISOString();
        const sixMonthsReminder = moment(formattedStartTime).add(6, 'months').toISOString();
        const elevenMonthsReminder = moment(formattedStartTime).add(11, 'months').toISOString();

        const reservationValidDays = preferences[0].reservationValidDays;  // assuming preferences state is already updated
        const formattedBookingTime = moment(formattedStartTime).add(reservationValidDays, 'days').format('YYYY-MM-DDTHH:mm:ss');
        const data = {
          "startTime": formattedStartTime,
          "endTime": formattedEndTime,
          "paid": parseFloat(paidAmount),
          "price": parseFloat(event.price),
          "description": "sss",
          "therapy": "sss",
          "bookingTime": formattedBookingTime,
          "clientId": String(event.client),
          "staffTreatmentId": String(event.staffTreatmentId),
          "clientTreatmentStatusId": String(event.status),
          "controlDays": 0,
          "sentMailRemainderForTreatment": true,
          "sentMailReservationExpired": true,
          "discount": 0.0,
          "sendMailAt1m": true,
          "sendMailAt3m": true,
          "sendMailAt6m": true,
          "sendMailAt12m": true,
          "color": "s",
          "cancilTokenROneMonth": "s",
          "cancilTokenRThreeMonths": "s",
          "cancilTokenRSixMonths": "s",
          "cancilTokenRTwelveMonths": "s",
          "reminderForOneMonth": oneMonthReminder,
          "reminderForThreeMonths": threeMonthsReminder,
          "reminderForSixMonths": sixMonthsReminder,
          "reminderForTwelveMonths": elevenMonthsReminder,
        };

        //  console.log(data, "0asdf00adsf0a0f0088");

        axios.post('https://api.adaktarbooking.com/api/v1/clienttreatment/insert', data, config)
          .then((response) => {
            //  console.log(response, "uspesnosssssjksj");
            //  console.log("USPESNO UNET CLIENT TRETMAN","uspesnosssssjksj");
            if (response.status === 200) {
              toast.success("Uspešno Ste zakazali tretman!");
              //dispatch(fesecondStateClientTreatment()); 

              const dateFromCalendarr = new Date(dateFromCalendar);
              //console.log(dateFromCalendar,'datumpriupdate')
              localStorage.setItem('startTime', dateFromCalendarr.toString());




              // dispatch(fetchClients());
              // dispatch(fetchTreatments());
              // dispatch(fetchStaffTreatments());
              // dispatch(fetchStaff());
              //   dispatch(postClientTreatments());
              // dispatch(fetchUsers());
              // dispatch(fetchClientTreatmentStatus());
              // dispatch(fetchPreferences());
              handleInsert(data);
            } // Call the callback function to update data in the parent component
          })
          .catch((error) => {

            console.error('uspesnosssssjksj', error);
            toast.error("Greška prilikom zakazivanja, molimo Vas pokušajte ponovo!");

          });
      })
      .catch((error) => {
        console.error('There was an error fetching staff treatments!', error);
      });
  };
  //SET PRICE FOR SELECTED TREATMENT AND SET DEPOZIT FOR SELECTED DREATMENT 
  useEffect(() => {
    let paid = depositPaid;  // Use customPaidValue if provided

    let paidDeposite = 0

    if (idStaffAndPriceForSelectedTreatment) {

      if (event.timeStart) {
        const endTime = calculateEndTime(event.timeStart, idStaffAndPriceForSelectedTreatment.duration);
        setEvent(prevEvent => ({ ...prevEvent, timeEnd: endTime }));

        setEndTime(endTime);
      }
      const token = localStorage.getItem('accessToken');
      const config = {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
        }
      };

      axios.get(`https://api.adaktarbooking.com/api/v1/treatmentprice/${idStaffAndPriceForSelectedTreatment.treatmentPriceId}`, config)
        .then((response) => {
          let newPrice = response.data.price;
          //  setPrice(newPrice)
          if ((currency === 'eur' && newPrice > preferences[0].reservationLevelOne) || newPrice > preferences[0].reservationLevelOne * exchangeRate) {
            paidDeposite = currency === 'eur' ? preferences[0].paidReservationLevelTwo : preferences[0].paidReservationLevelTwo * exchangeRate;
            paid = 0;
          } else {
            paidDeposite = currency === 'eur' ? preferences[0].paidReservation : preferences[0].paidReservation * exchangeRate;
            paid = 0
          }
          setDepositPaid(paidDeposite)
          setPaidAmount(paid);
          // convert price to RSD if currency is set to 'rsd'
          if (currency === 'rsd') {
            newPrice = newPrice * exchangeRate; // Assume 1 eur = 120 rsd
          }
          setPrice(newPrice || '');
          setEvent(prevEvent => ({ ...prevEvent, price: newPrice }));
          setEvent(prevEvent => ({ ...prevEvent, remaining: newPrice }));
          setEvent(prevEvent => ({ ...prevEvent, remaining: newPrice }));

        })
        .catch((error) => {
          console.error('There was an error!', error);
        });
    }
  }, [idStaffAndPriceForSelectedTreatment, currency]);

  //SET PRICE FOR SELECTED TREATMENT AND SET DEPOZIT FOR SELECTED DREATMENT 

  useEffect(() => {
    setIsButtonDisabled(areFieldsEmpty());
  }, [event]);

  useEffect(() => {

    if (timefromCalendar) {
      const formattedTimeStart = timefromCalendar || ""; // Set to an empty string if timefromCalendar is falsy
      setEvent((prevEvent) => ({ ...prevEvent, timeStart: formattedTimeStart }));
    }

    if (dateFromCalendar) {
      const date = new Date(dateFromCalendar);
      const formattedDate = `${("0" + (date.getMonth() + 1)).slice(-2)}.${("0" + date.getDate()).slice(-2)}.${date.getFullYear()}`;
      setEvent((prevEvent) => ({ ...prevEvent, date: formattedDate }));
    }

  }, [timefromCalendar, dateFromCalendar]);

  //FOR CURRENCY CONVERT
  useEffect(() => {
    let newPrice = price;
    // convert price to RSD if currency is set to 'rsd'
    if (currency === 'rsd') {
      newPrice = price * exchangeRate; // Assume 1 eur = 120 rsd
    }
    // convert price to EUR if currency is set to 'eur'
    else if (currency === 'eur') {
      newPrice = price / exchangeRate; // Assume 120 rsd = 1 eur
    }
    setPrice(newPrice);

    // do the same for paidAmount and remaining
    let newPaidAmount = currency === 'rsd' ? paidAmount : paidAmount;
    let newRemaining = currency === 'rsd' ? remaining : remaining;

    setPaidAmount(newPaidAmount);
    setRemaining(newRemaining);

    setEvent((prevEvent) => ({
      ...prevEvent,
      price: newPrice,
      paidAmount: newPaidAmount,
      remaining: newRemaining
    }));
  }, [currency]);


  const areFieldsEmpty = () => {
    const fieldValues = Object.values(event).map((value) =>
      Array.isArray(value) ? value.length === 0 : value === ""
    );
    return fieldValues.some((value) => value === true);
  };

  const handleDateChange = (value) => {
    const date = new Date(value);
    const formattedDate = `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}`;
    setDateFromCalendarS(formattedDate);
    setEvent((prevEvent) => ({ ...prevEvent, date: formattedDate }));
  };

  const handleClientChange = (client) => {
    const clientId = client ? client.id : "";
    setEvent((prevEvent) => ({ ...prevEvent, client: clientId }));
  };


  const handleTimeEndChange = (timeEnd) => {
    setEndTime(timeEnd)
    setEvent((prevEvent) => ({ ...prevEvent, timeEnd: timeEnd || "" }));
  };

  const handleTreatmentChange = async (treatment) => {
    setidStaffAndPriceForSelectedTreatment(treatment);
    // setPrice();
    setEvent((prevEvent) => ({ ...prevEvent, treatment: treatment.id }));
    setTreatmentChanged(true);

    if (event.timeStart) {  // if timeStart already exists, recalculate timeEndtell 
      const endTime = calculateEndTime(event.timeStart, treatment.duration);
      setEvent((prevEvent) => ({ ...prevEvent, timeEnd: endTime || "" }));
    }
  };

  const handleTimeStartChange = (timeStart) => {
    
    if (timeStart === null) {

      return null
    }
    const treatmentDuration = idStaffAndPriceForSelectedTreatment?.duration || 0; // Assuming your treatment object has a duration property
    const endTime = calculateEndTime(timeStart, treatmentDuration);
    // Logging timeEnd here
    setEndTime(endTime);

setStartTime(timeStart)
    setEvent((prevEvent) => ({
      ...prevEvent,
      timeStart: timeStart || "",
      timeEnd: endTime || ""   // You might want to set this here or in the treatment change handler
    }));
  };


  //console.log(endTime,'asd0f0adsf0')
  //LOGIC FOR  SET AUTO END TIME THIS TIME PASS TO ENDTIME COMPONENTE 
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return (hours * 60) + minutes;
  };

  const minutesToTime = (minutes) => {
    if (minutes === null) {

      return null
    }
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  };

  const calculateEndTime = (timeStart, duration) => {

    const startTimeInMinutes = timeToMinutes(timeStart);

    const endTimeInMinutes = startTimeInMinutes + duration;
    return minutesToTime(endTimeInMinutes);
  };
  //LOGIC FOR  SET AUTO END TIME THIS TIME PASS TO ENDTIME COMPONENTE 

  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);

    let newPrice = price;

    // convert price to RSD if currency is set to 'rsd'
    if (newCurrency === 'rsd') {
      newPrice = price * exchangeRate; // Assume 1 eur = 120 rsd
    }
    // convert price to EUR if currency is set to 'eur'
    else if (newCurrency === 'eur') {
      newPrice = price / exchangeRate; // Assume 120 rsd = 1 eur
    }

    setPrice(newPrice);

    // Convert the paid amount to the new currency
    let newPaidAmount = paidAmount;

    if (newCurrency === 'rsd') {
      newPaidAmount = paidAmount * exchangeRate; // Convert paid amount to RSD
    } else if (newCurrency === 'eur') {
      newPaidAmount = paidAmount / exchangeRate; // Convert paid amount to EUR
    }

    setPaidAmount(newPaidAmount);

    // Update the remaining field based on the new price and paid amount
    if (currency === 'eur') {

    } else {

    }
    const remainingInSelectedCurrency = newPrice - newPaidAmount;
    setRemaining(Math.max(0, remainingInSelectedCurrency));

    setEvent((prevEvent) => ({
      ...prevEvent,
      price: newPrice,
      paidAmount: newPaidAmount,
      remaining: remainingInSelectedCurrency
    }));
  };


  const handlePaidAmountChange = (event) => {
    const paid = event ? event.target.value : paidAmount;
    setPaidAmount(paid);

    const remainingInSelectedCurrency = price - paid;
    setRemaining(Math.max(0, remainingInSelectedCurrency));

    setEvent((prevEvent) => ({
      ...prevEvent,
      paidAmount: paid,
      remaining: remainingInSelectedCurrency
    }));
  };




  const handleIdStaffAndPriceChange = (newIdStaffAndPrice) => {
    setidStaffAndPriceForSelectedTreatment(newIdStaffAndPrice);
  };

  const handleStaffChange = (staff) => {
    const staffId = staff ? staff.id : "";
    setEvent((prevEvent) => ({
      ...prevEvent,
      staff: staffId,
    }));
  };




  const handlePaidFilled = () => {
    //setDepositPaid(depositPaid);
    if (event.status) {
      const matchedStatus = statusTreatment.find(s => s.id === event.status);
      if (matchedStatus) {
        handleStatusChange(matchedStatus.title, depositPaid);
        setEvent((prevEvent) => ({
          ...prevEvent,
          status: event.status,

        }));

      } else {
        console.log('Matching status not found.');
      }
    } else {
      // Handle the case when no status is selected.
      console.log('No status selected.');
    }
  };
  const handleStatusChange = (status, depositPaid) => {
    if (status === null) {
      return null
    }
    const label = status ? status.label : "";
    let paid = depositPaid;  // Use customPaidValue if provided
    let paidDeposite = depositPaid
    setSelectedLabel(label);

    if (label === 'PLACEN') {
      if (currency === 'eur') {
        paid = price;
      } else if (currency === 'rsd') {
        paid = price; // Convert price from EUR to RSD
      }
    } else if (label === 'DEPOZIT') {

      // paid = currency === 'eur' ? 50 : 50 * 120;
      if ((currency === 'eur' && price > preferences[0].reservationLevelOne) || price > preferences[0].reservationLevelOne * exchangeRate) {
        paidDeposite = currency === 'eur' ? preferences[0].paidReservationLevelTwo : preferences[0].paidReservationLevelTwo * exchangeRate;
        paid = 0;
      } else {
        paidDeposite = currency === 'eur' ? preferences[0].paidReservation : preferences[0].paidReservation * exchangeRate;
        paid = 0
      }
      setEvent((prevEvent) => ({
        ...prevEvent,
        status: status.id,
        paidAmount: paid,
        remaining: remainingInSelectedCurrency,
        price: price
      }));
    } else if (label === 'ZAKAZAN') {
      if ((currency === 'eur' && price > preferences[0].reservationLevelOne) || price > preferences[0].reservationLevelOne * exchangeRate) {
        paidDeposite = currency === 'eur' ? preferences[0].paidReservationLevelTwo : preferences[0].paidReservationLevelTwo * exchangeRate;
        paid = 0;
      } else {
        paidDeposite = currency === 'eur' ? preferences[0].paidReservation : preferences[0].paidReservation * exchangeRate;
        paid = 0
      }
    } else if (label === 'ISTEKAO') {
      // For the "ISTEKAO" status, keep the currently paid amount
      paid = event.paidAmount;
    }
    setDepositPaid(paidDeposite)
    setPaidAmount(paid);

    const paidInEur = currency === 'rsd' ? paid : paid;
    const remainingInEur = price - paidInEur;
    const remainingInSelectedCurrency = currency === 'rsd' ? remainingInEur : remainingInEur;

    setRemaining(Math.max(0, remainingInSelectedCurrency));

    setEvent((prevEvent) => ({
      ...prevEvent,
      status: status.id,
      paidAmount: paid,
      remaining: remainingInSelectedCurrency,
      price: price
    }));
  };

  const handleCloseQuit = () => {
    //insertEvent();
    setDateFromCalendarS(null)
    setIdOfInsertedClient(null)
    //setRemaining(0)
    setPaidAmount(0)
    setDepositPaid(0)
    setPrice(0)
    //  resetDateFromCalendar();
    // setEndTime("")
    setEvent({
      date: "",
      client: "",
      treatment: "",
      timeStart: "",
      timeEnd: "",
      status: "",
      staff: "",
      paidAmount: 0,
      remaining: 0,
      price: 0,
    });
    setOpen(false);
    onClose && onClose();

  };
  return (
    <div>
      {isSidebarOpen ? (
        <Button variant="outlined" onClick={handleClickOpen} sx={{ width: "200px" }}>
          Zakažite tretman
        </Button>
      ) : (
        <Button variant="outlined" onClick={handleClickOpen} sx={{
          width: "50px", border: 'none', marginRight: 0, ':hover': {
            backgroundColor: 'transparent',  // set this to your button's normal background color
            border: 'none',      // set this to your button's normal border color
          }
        }}>
          <AddCardIcon sx={{ color: "rgb(96,96,96)", }} />
        </Button>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseSave}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">Zakažite tretman klijenta</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <Box sx={{ pt: 0, display: 'flex', flexDirection: 'column' }}>
              <Typography>Selektuj klijenta</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <ComboClient users={users} onChange={handleClientChange} idOfInsertedClient={idOfInsertedClient} />
                <IconButton onClick={handleClickOpenDialogCreateNewClient} sx={{ ml: 1 }}>
                  <AddCircleOutlineIcon sx={{ fontSize: '35px' }} />
                </IconButton>
              </Box>

            </Box>

            <Box sx={{ pt: 2 }}>
              <Typography>Selektuj tretman</Typography>
              <ComboTreatment onChange={handleTreatmentChange} />
            </Box>
            <Box sx={{ pt: 2 }}>
              <Typography>Selektuj datum</Typography>
              <DataEvent onDateChange={handleDateChange} dateFromCalendarS={dateFromCalendarS} parentDate={parentDate} isOpen={isOpen} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: 1, pr: 2 }}>
                <Box sx={{ pt: 2 }}>
                  <Typography>Pocetak tretmana</Typography>
                  <TimeStart onClick={handleTimeStartChange}
                   onChange={handleTimeStartChange} startTime={event.timeStart} />
                </Box>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ pt: 2 }}>
                  <Typography>Zavrsetak tretmana</Typography>
                  <TimeEnd onChange={handleTimeEndChange} endTimeValue={endTime} />
                </Box>
              </Box>
            </Box>

            <Typography sx={{ pt: 2 }}>Ko radi tretman?</Typography>
            <ComboStaff
              onChange={handleStaffChange}
              date = {dateFromCalendarS}              
              startTime = {event.timeStart}
              endTime = {endTime}

            />
            <Box>
              <Typography sx={{ pt: 2 }}>Status</Typography>
              <ComboStatus onChange={handleStatusChange} treatmentChanged={treatmentChanged}
                setTreatmentChanged={setTreatmentChanged}
              />
              <Box>
                <Typography sx={{ pt: 2, pb: 1 }}>Cena tretmana je:</Typography>
                <FormControl variant="outlined" style={{ width: '50%', borderRadius: '2px' }}>
                  <OutlinedInput
                    id="outlined-basic"
                    placeholder="Unesi cenu"
                    value={idStaffAndPriceForSelectedTreatment ? price : ' '}
                    style={{ height: "40px" }}
                  />
                </FormControl>


                <Typography sx={{ pt: 2 }}>Selektuj valutu:</Typography>
                <Select
                  value={currency}
                  onChange={handleCurrencyChange}
                  style={{ width: '50%', borderRadius: '2px', height: '40px' }}
                >
                  <MenuItem value={'rsd'} style={{ height: '40px' }}>rsd</MenuItem>
                  <MenuItem value={'eur'} style={{ height: '40px' }}>eur</MenuItem>
                </Select>

                <Typography sx={{ pt: 2, pb: 1 }}>Placeno:</Typography>
                <FormControl variant="outlined" style={{ width: '50%', borderRadius: '2px' }}>
                  <OutlinedInput
                    id="outlined-basic"
                    placeholder="Unesi iznos"
                    type="number"
                    value={paidAmount}
                    onChange={handlePaidAmountChange}
                    style={{ height: "40px" }}
                  />
                </FormControl>
                <Typography>

                </Typography>
                <Typography
                  onClick={handlePaidFilled}
                  style={{ cursor: 'pointer' }}>
                  Depozit : {depositPaid}
                </Typography>
                <Typography sx={{ pt: 2, pb: 1 }}>Preostalo za uplatu:</Typography>
                <FormControl variant="outlined" style={{ width: '50%', borderRadius: '2px' }}>
                  <OutlinedInput
                    id="outlined-basic"
                    placeholder="Preostalo za uplatu"
                    type="number"
                    value={remaining}
                    disabled // make this field non-editable
                    style={{ height: "40px" }}
                  />
                </FormControl>

              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <ClientDialog open={openDialog} handleClose={handleCloseDialog} handleUsersRefetch={refetchUsers} setIdOfInsertedClient={setIdOfInsertedClient} />

        <DialogActions>
          <Button autoFocus onClick={handleCloseQuit}>
            Otkazi
          </Button>
          <Button
            onClick={() => {
              insertEvent();
              handleCloseSave();
              dispatch(fetchClients());
            }}
            autoFocus
            disabled={isButtonDisabled} // Disable the button based on the state
          >
            Dodaj
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};