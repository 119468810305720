// React-related imports
import React, { useState, useEffect } from 'react';

// Redux-related imports
import { useSelector, useDispatch } from 'react-redux';
import { fetchClients,fetchUnpaidDepositTreatments, fetchTreatments, fetchPreferences, fetchUsers, fetchStaffTreatments, fetchStaff,fesecondStateClientTreatment, postClientTreatments, fetchClientTreatmentStatus } from '../../../redux/action';

// Third-party libraries
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// react-bootstrap components
import { Form, Button } from 'react-bootstrap';

// MUI core components
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography, OutlinedInput } from '@mui/material';

// MUI styles
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// MUI Lab
import { Autocomplete } from '@mui/material';

// Component imports
import updateCalendarforDialogUpdate from '././updateCalendarforDialogUpdate';
import { ToastContainer, toast } from 'react-toastify';

  const UpdateDialogScheduler = ({ open, event, onClose, onUpdate, toggleDataUpdated  }) => {
    // console.log(event,'eventeeewwweeeww33');
    const BASE_URL = 'https://api.adaktarbooking.com';
    const [filteredTreatmentList, setFilteredTreatmentList] = useState([]);
      //DATA FROM API FROM REDUX 
      const clients = useSelector(state => state.menu.clients);  // access the data from the state
      const treatments = useSelector(state => state.menu.treatments);  // access the data from the state
      const staffTreatments = useSelector(state => state.menu.staffTreatments);  // access the data from the state
      const staffAll = useSelector(state => state.menu.staff);  // access the data from the state
      const clientTreatments = useSelector(state => state.menu.clientTreatments);  // access the data from the state
      const users = useSelector(state => state.menu.users);  
      const clientTreatmentStatus = useSelector(state => state.menu.clientTreatmentStatus);  
      const preferenceS = useSelector(state => state.menu.preferences);
      const dispatch = useDispatch();
      const [exchangeRate, setExchangeRate] = useState(null);
      // const [preferences, setPreferences] = useState(null);

    //initialization
    const [client, setClient] = useState('');
    const [date, setDate] = useState(event?.date || null);
    const [timeStart, setTimeStart] = useState('');
    const [timeEnd, setTimeEnd] = useState('');
    const [status, setStatus] = useState('');
    const [price, setprice] = useState(0);
    const [depozit, setDepozit] = useState('');
    const [paid, setpaid] = useState(0);
    const [tretman, setTretman] = useState('');
    const [staff, setStaff] = useState('');
    //for combobox  data from apies 
    const [clientsList, setClientsList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [treatmentList, setTreatmentList] = useState([]);
    const [staffList, setStaffList] = useState([]);
    const [staffTreatmentList, setStaffTreatmentList] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState(event?.clientId || '');
    const [selectedStatusId, setSelectedStatusId] = useState('');
    const [selectedStaffId, setSelectedStaffId] = useState('');
    const [selectedTreatmentId, setSelectedTreatmentId] = useState('');
    const [preferences, setPreferences] = useState();
    const [description, setDescription] = useState(event?.description || ''); 

    const [depozitePaid, setDepozitePaid] = useState(0);
    const [isPlacen, setIsPlacen] = useState(false);
    const [currency, setCurrency] = useState('EUR'); // default is EUR now

    const handleSelectChange = (event) => {
      setSelectedStaffId(event.target.value); // Ovde setujemo izabrani ID
      // Ovde možete dodati dodatnu logiku ako je potrebno
    };
    const currencies = [
      { label: 'RSD', value: 'RSD' },
      { label: 'EUR', value: 'EUR' },
      // Add other currencies as needed
    ];

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));


    const token = localStorage.getItem('accessToken');

    const getTimeSlots = () => {
      const slots = [];
      for (let i = 7; i <= 22; i++) { 
        for (let j = 0; j < 60; j+=30) {  // Change this line to increment by 30
          if (i === 22 && j > 0) continue; // Skip any minute values for 22:00
          let hh = i < 10 ? `0${i}` : `${i}`;
          let mm = j < 10 ? `0${j}` : `${j}`;
          slots.push(`${hh}:${mm}`);
        }
      }
      return slots;
    }
    

  useEffect(() => {
    const selectedStatusName = statusList.find(status => status.id === selectedStatusId)?.title || '';
    if (selectedStatusName === 'DEPOZIT' || selectedStatusName === 'ISTEKAO') {
      setpaid(0); // Use eventPaid if available, otherwise default to 0
    }
}, [selectedStatusId, statusList]);

    useEffect(() => {
      const dateParts = event?.date?.split(".") || [];
    if(dateParts !== null ){
      
    }
    // Check if we have all necessary date parts
    if (dateParts.length === 3) {
        // Reorder to a format Javascript's Date can parse, "YYYY-MM-DD"
        // Swap month and day, also ensure the month and day are zero padded to form MM and DD.
        const reformattedDate = `${dateParts[2]}-${dateParts[0].padStart(2, '0')}-${dateParts[1].padStart(2, '0')}`;
        const parsedDate = new Date(reformattedDate);
        setDate(isNaN(parsedDate.getTime()) ? new Date() : parsedDate);
    } 

      setClient(event?.client || '');
     // setDate(new Date(event?.date || ''));
      setTimeStart(event?.startTime || '');
      setTimeEnd(event?.endTime || '');
      setStatus(event?.status || '');
      setprice(event?.price || '');
      setDepozit(event?.depozit || '');
      setpaid(event?.paid || 0);
      setSelectedTreatmentId(event?.treatmentId || '');
      setDescription(event?.description || '');
    
      //for clients
      setClientsList(clients);
      const clientObj = clients.find(client => client.id === event?.clientId);
      setSelectedClientId(clientObj?.id || '');
    
    //for status
      setStatusList(clientTreatmentStatus);
      const statusObj = clientTreatmentStatus.find(status => status.id === event?.status);//.status i change into clientTreatmentStatusId
      setSelectedStatusId(statusObj?.id || '');

      //for treatments
      setTreatmentList(treatments);
      const treatmentObj = treatments.find(treatment => treatment.id === event?.treatmentId);
      setTretman(treatmentObj?.title || '');
          
      setStaffList(staffAll);
      const staffObj = staffAll.find(staff => staff.id === event?.staffId);
      setSelectedStaffId(staffObj?.id || '');
    
      setStaffTreatmentList(staffTreatments);

      setPreferences(preferenceS);

      //API TAKE PREFERENCE AND SET PREFERENCE 
    if(preferenceS !== null ){
      if (preferenceS && preferenceS.length > 0) {
        setExchangeRate(preferenceS[0].exchangeRate);
      }
    }
      
  }, [event, clients, clientTreatmentStatus, preferenceS, treatments, staff, staffTreatments]);  // Remember to add 'clients', 'clientTreatmentStatus', 'treatments', 'staff', and 'staffTreatments' to the dependency array of useEffect

 // Updated handleChange function
const handleChange = (setter) => (event) => {
  const selectedId = event.target.value;

  // If the change is for the 'paid' input, update directly
  if (setter === setpaid) {
    setpaid(selectedId);
    return;
  }

  const foundTreatment  = treatments.find(treatment => treatment.id === selectedId);
  const token = localStorage.getItem('accessToken');
  const config = {
    headers: {
      "accept": "application/json",
      "Authorization": `Bearer ${token}`,
    }
  };

  axios.get(`https://api.adaktarbooking.com/api/v1/treatmentprice/${foundTreatment.treatmentPriceId}`, config)
    .then((response) => {
      let newPrice = response.data.price;
      setprice(newPrice);
    })
    .catch((error) => {
      console.log(error, 'davidimiovo');
    });

  setter(selectedId);
};

// New handler specifically for the 'paid' input
const handlePaidChange = (event) => {
  const newPaidValue = event.target.value;
  setpaid(newPaidValue); // Update the 'paid' state directly
};
useEffect(() => {
  // Filtriraj tretmane na osnovu selektovanog osoblja
  const filteredTreatments = staffTreatmentList
    .filter((item) => item.staffId === selectedStaffId)
    .map((item) => item.treatmentId);
  setFilteredTreatmentList(filteredTreatments);
}, [selectedStaffId, staffTreatmentList]);

    const handleDateChange = (newValue) => {
      setDate(newValue);
    };
    const handleUpdateClick = async () => {
      setCurrency('EUR');
      const staffTreatment = staffTreatmentList.find(st => st.staffId === selectedStaffId && st.treatmentId === selectedTreatmentId);
      const staffTreatmentId = staffTreatment ? staffTreatment.id : '';
      const formattedStartTime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(timeStart.split(':')[0], 10), parseInt(timeStart.split(':')[1], 10)));
      formattedStartTime.setUTCHours(parseInt(timeStart.split(':')[0], 10));
      formattedStartTime.setUTCMinutes(parseInt(timeStart.split(':')[1], 10));
      formattedStartTime.setUTCSeconds(0);
      formattedStartTime.setUTCMilliseconds(0);
      
      const formattedEndTime = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(timeEnd.split(':')[0], 10), parseInt(timeEnd.split(':')[1], 10)));
      formattedEndTime.setUTCHours(parseInt(timeEnd.split(':')[0], 10));
      formattedEndTime.setUTCMinutes(parseInt(timeEnd.split(':')[1], 10));
      formattedEndTime.setUTCSeconds(0);
      formattedEndTime.setUTCMilliseconds(0);
      
      const selectedStatusName = statusList.find(status => status.id === selectedStatusId)?.title || '';

      const reservationDays = preferences[0]?.reservationValidDays || 0; // make sure preferences state is updated
      let bookingTime = new Date(formattedStartTime);
      bookingTime.setDate(bookingTime.getDate() + reservationDays);
    

      let reminderForOneMonth = new Date(formattedStartTime);
      reminderForOneMonth.setMonth(reminderForOneMonth.getMonth() + 1);
    
      let reminderForThreeMonths = new Date(formattedStartTime);
      reminderForThreeMonths.setMonth(reminderForThreeMonths.getMonth() + 3);
    
      let reminderForSixMonths = new Date(formattedStartTime);
      reminderForSixMonths.setMonth(reminderForSixMonths.getMonth() + 6);
    
      let reminderForTwelveMonths = new Date(formattedStartTime);
      reminderForTwelveMonths.setMonth(reminderForTwelveMonths.getMonth() + 11);
    
      // Proverite da li je satus "ZAKAZAN" ili "ISTEKAO" i postavite "paid" na 0 ako jeste
      if (selectedStatusName === 'DEPOZIT' || selectedStatusName === 'ISTEKAO') {
        setpaid(0);
      }
          const updatedEvent = {
          id: event.clientTreatmentId,
          startTime: formattedStartTime.toISOString(),
          endTime: formattedEndTime.toISOString(),
          price: price,
          paid: paid,
          description: '',
          therapy: tretman, 
          controlDays: 0,
          bookingTime: bookingTime.toISOString(),
          sentMailRemainderForTreatment: true,
          sentMailReservationExpired: true,
          discount: 0,
          sendMailAt1m: true,
          sendMailAt3m: true,
          sendMailAt6m: true,
          sendMailAt12m: true,
          reminderForOneMonth: reminderForOneMonth.toISOString(),
          reminderForThreeMonths: reminderForThreeMonths.toISOString(),
          reminderForSixMonths: reminderForSixMonths.toISOString(),
          reminderForTwelveMonths: reminderForTwelveMonths.toISOString(),
          color: 'string',
          cancilTokenROneMonth: 'string',
          cancilTokenRThreeMonths: 'string',
          cancilTokenRSixMonths: 'string',
          cancilTokenRTwelveMonths: 'string',
          clientId: selectedClientId,
          staffTreatmentId: staffTreatmentId, 
          clientTreatmentStatusId: selectedStatusId,
          description: description,
        };
         console.log(updatedEvent,"update treatment sd")
      try {
        const response = await axios.put(`${BASE_URL}/api/v1/clienttreatment/${event.clientTreatmentId}`, updatedEvent, {
//         const response = await axios.put(`https://api.adaktarbooking.com/api/v1/clienttreatment/${event.clientTreatmentId}`, updatedEvent, {
// >>>>>>> 7af677294b7207627449e1e4cd7edc60b4a816e0
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
  
        if (response.status === 200) {
          const date = new Date(event.date);
          console.log(date,'datumpriupdate')
          localStorage.setItem('startTime',date.toString());

          //dispatch(fetchUnpaidDepositTreatments());

         //  onUpdate(updatedEvent);
        dispatch(postClientTreatments());
        dispatch(fesecondStateClientTreatment());

//          toast.success("Uspešno Ste izmeniili !");

//          dispatch(fetchStaff());
//          dispatch(fetchUsers());
//          dispatch(fetchClientTreatmentStatus());
//          dispatch(fetchPreferences());
//          onClose();
//          toggleDataUpdated();  // Toggle the parent's state
//                  }
// =======
        
          

          // dispatch(fetchClients());
          // dispatch(fetchTreatments());
          // dispatch(fetchStaffTreatments());
          // dispatch(fetchStaff());
          // dispatch(fetchUsers());
          // dispatch(fetchClientTreatmentStatus());
          // dispatch(fetchPreferences()); 
          onClose()
          toggleDataUpdated();  // Toggle the parent's state
          toast.success("Uspešno Ste izmeniili !");
        }
      } catch (error) {
        toast.error("Greška prilikom izmene !");
        console.error('Failed to update the event:', error);
      }
    };
    
 
  
    const handlePaidFilled = () => {
      // setDepositPaid(depositPaid);
      // handleStatusChange(0, depositPaid);  // Calling handleStatusChange and passing depositPaid as customPaidValue
      setpaid(depozitePaid) ;
  
};
   
 
    useEffect(() => {
      if (status === "ZAKAZAN" && preferences && price !== null) {
          calculateAndSetDepozit();
      }
  }, [price, preferences, status]);
  
  useEffect(() => {
    
    const selectedStatusName = statusList.find(status => status.id === selectedStatusId)?.title || '';
    if (selectedStatusName === 'ZAKAZAN' && preferences && price !== null) {
        calculateAndSetDepozit();
        setpaid(event.paid || 0);       
          setIsPlacen(false);
    } else if (selectedStatusName === 'PLACEN' && preferences && price !== null){
      setpaid(price);
      setIsPlacen(true);

    }   

}, [selectedStatusId, statusList, preferences, price,selectedTreatmentId]);
 
const calculateAndSetDepozit = () => {
  


  if (!preferences) return;  // if preferences is null or undefined, return early
  
  let reservationLevelInCurrentCurrency;
  
  // Convert reservationLevelOne to the current currency
  if (currency === 'RSD') {
      reservationLevelInCurrentCurrency = preferences[0].reservationLevelOne * exchangeRate;
  } else {
      reservationLevelInCurrentCurrency = preferences[0].reservationLevelOne; // EUR value
  }

  // Determine deposit amount based on the price and the converted reservation level.
  let calculatedDepozit;
  if (price > reservationLevelInCurrentCurrency) {
      calculatedDepozit = currency === 'RSD' ? preferences[0].paidReservationLevelTwo * exchangeRate : preferences[0].paidReservationLevelTwo;
  } else {
      calculatedDepozit = currency === 'RSD' ? preferences[0].paidReservation * exchangeRate : preferences[0].paidReservation;
  }

  setDepozitePaid(calculatedDepozit);
};


const convertCurrency = (value, toCurrency) => {
  if (toCurrency === 'RSD') return value * exchangeRate;
  if (toCurrency === 'EUR') return value / exchangeRate;
  return value; // no conversion if currency is not recognized.
};
const handleChangeCurrency = e => {
  const newCurrency = e.target.value;
  
  // Convert all values
  const convertedPrice = convertCurrency(price, newCurrency);
  const convertedPaid = convertCurrency(paid, newCurrency);
  
  // Set the converted values and the new currency
  setprice(convertedPrice);
  setpaid(convertedPaid);
  setCurrency(newCurrency);

  // Recalculate depozitePaid after setting new price.
  calculateAndSetDepozit();
};

const handleChangee = setter => e => {
  setter(e.target.value);
};


  function handleClose() {
    setCurrency('EUR');
    onClose(); // Assuming onClose closes the dialog.
  }
  
  return (
    <Dialog
    fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
   
      aria-labelledby="responsive-dialog-title"
      maxWidth="md"
      fullWidth
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}
      PaperProps={{ style: { boxShadow: 'none' } }}
    >
      <DialogTitle>Izmenite zakazan tretman</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 0 }}>
          <Typography variant="body1" pb={1} mt={2}>Klijent</Typography>
          <FormControl fullWidth>
            <Select
              id="client"
              value={selectedClientId}
              onChange={(event) => {
                setSelectedClientId(event.target.value);
                setClient(event.target.value);
              }}
              style={{ height: '40px' }}
              placeholder="Client"
            >
              {clientsList.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.name} {client.surname} - {client.phone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1" pb={1} mt={2}>Datum</Typography>
          <DatePicker
            id="date"
            selected={date}
            onChange={handleDateChange}
            className="form-control"
            dateFormat="d.M.yyyy"
          />
        </Box>
        <Box sx={{ flex: 1 }}>
        <Typography variant="body1" pb={1} mt={2}>Vreme pocetka</Typography>
        <Autocomplete
          id="start-time"
          options={getTimeSlots()}
          value={timeStart}
          onChange={(event, newValue) => setTimeStart(newValue)}
          renderInput={(params) => (
            <FormControl fullWidth>
              <Select
                {...params.inputProps}
                value={params.inputProps.value || ''}
                onChange={(event) => {
                  params.inputProps.onChange(event);
                  setTimeStart(event.target.value);
                }}
                style={{ height: '40px' }}
                placeholder="Vreme pocetka"
              >
                {getTimeSlots().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          style={{ width: '100%' }}
          getOptionLabel={(option) => option}
        />
      </Box>

      <Box sx={{ flex: 1, pt: 2 }}>
        <Typography variant="body1" pb={1} mt={2}>Vreme završetka</Typography>
        <Autocomplete
          id="end-time"
          options={getTimeSlots()}
          value={timeEnd}
          onChange={(event, newValue) => setTimeEnd(newValue)}
          renderInput={(params) => (
            <FormControl fullWidth>
              <Select
                {...params.inputProps}
                value={params.inputProps.value || ''}
                onChange={(event) => {
                  params.inputProps.onChange(event);
                  setTimeEnd(event.target.value);
                }}
                style={{ height: '40px' }}
                placeholder="Vreme kraja"
              >
                {getTimeSlots().map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          style={{ width: '100%' }}
          getOptionLabel={(option) => option}
        />
      </Box>



        <Box sx={{ pt: 2, mb: 2 }}>
          <Typography variant="body1" pb={1} mt={2}>Osoblje</Typography>
          <FormControl fullWidth>
            <Select
              id="staff"
              value={selectedStaffId}
              onChange={handleSelectChange}

              style={{ height: '45px' }}
              inputProps={{
                placeholder: 'Pleacholder',
                style: { height: '100%' }
              }}
            >
              {Array.isArray(staffList) &&
                staffList.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>
                    {staff.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ pt: 0 }}>
          <Typography variant="body1" pb={1} mt={2}>Izabrani tretman</Typography>
          <FormControl fullWidth>
            <Select
              labelId="treatment-label"
              id="treatment"
              value={selectedTreatmentId}
              onChange={handleChange(setSelectedTreatmentId)}
              style={{ height: '40px' }}
              inputProps={{
                id: 'treatment',
                placeholder: 'Select Tretman',
              }}
            >
              {Array.isArray(treatmentList) &&
                treatmentList.map((treatment) => {
                  if (filteredTreatmentList.includes(treatment.id)) {
                    return (
                      <MenuItem key={treatment.id} value={treatment.id}>
                        {treatment.title}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1" pb={1} mt={2}>Cena</Typography>
          <FormControl fullWidth>
          <OutlinedInput
            id="price-input"
            value={currency === 'EUR' ? Number(price).toFixed(2) : price}
            onChange={handleChange(setprice)}
            fullWidth
            style={{ height: '40px' }}
            placeholder="Unesi cenu"
            disabled={true}  // This line makes the input non-editable
        />
          </FormControl>
          <Typography variant="body1" pb={1} mt={2}>Valuta</Typography>
       <FormControl fullWidth variant="outlined">
          <Select value={currency} onChange={handleChangeCurrency} fullWidth>

            {currencies.map((curr) => (
                <MenuItem key={curr.value} value={curr.value}>
                    {curr.label}
                </MenuItem>
            ))}
        </Select>
      </FormControl>
        </Box>
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1" pb={1} mt={0}>Status</Typography>
          <FormControl fullWidth>
            <Select
              id="status"
              value={selectedStatusId}
              onChange={(event) => {
                setSelectedStatusId(event.target.value);
                setStatus(event.target.value);
                // Check if the status is "ZAKAZAN" or "ISTEKAO" and set "paid" to 0 if true
                const selectedStatusName = statusList.find(status => status.id === event.target.value)?.title || '';
                if (selectedStatusName === 'DEPOZIT' || selectedStatusName === 'ISTEKAO') {
                  setpaid(0);
                }
              }}
              style={{ height: '40px' }}
              renderValue={(selected) => {
                const selectedStatus = statusList.find(status => status.id === selected);
                return selectedStatus ? selectedStatus.title : '';
              }}
              input={<OutlinedInput placeholder="Status" style={{ height: '40px' }} />}
            >
              {Array.isArray(statusList) &&
                statusList.map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <Typography 
             onClick={handlePaidFilled} 
             style={{ 
              cursor: isPlacen ? 'default' : 'pointer', 
              pointerEvents: isPlacen ? 'none' : 'auto',
              pt: 1 
          }}>
       
          Depozit: {depozitePaid}
      </Typography>


      <Box sx={{ pt: 2 }}>
  <Typography variant="body1" pb={1} mt={2}>Unesi placanje</Typography>
  <FormControl fullWidth>
    <OutlinedInput
      id="paid-input"
      value={currency === 'EUR' && typeof paid === 'number' ? paid.toFixed(2) : paid}
      onChange={handlePaidChange}
      fullWidth
      style={{ height: '40px' }}
      placeholder="preostalo za uplatu"
    />
  </FormControl>
</Box>
        
        <Box sx={{ pt: 2 }}>
          <Typography variant="body1" pb={1} mt={2}>Preostalo za uplatu </Typography>
          <FormControl fullWidth>
            <OutlinedInput
              id="paid-input"
              value={price - paid}
              onChange={handleChange(setpaid)}
              fullWidth
              style={{ height: '40px' }}
              
              //placeholder="Preostalo za uplatu"
            />
          </FormControl>
        </Box>


        <Box sx={{ pt: 0 }}>
        <Typography variant="body1" pb={1} mt={2}>Opis</Typography>
        <FormControl fullWidth>
          <TextField
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            multiline
            rows={4}
            placeholder="Enter description here..."
          />
        </FormControl>
      </Box>


      
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setCurrency('EUR'); onClose();}}>
           Odustani</Button>
        <Button onClick={handleUpdateClick}>Izmeni</Button>
      </DialogActions>
    </Dialog>
  
    );
  };
  
  export default UpdateDialogScheduler;
  
  

