// import * as React from "react";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import dayjs from "dayjs";

// export default function DataEvent(props) {
//   const { onDateChange, date: initialDate } = props;
//   const [date, setDate] = React.useState(initialDate ? dayjs(initialDate) : null);

//   const handleDateChange = (newDate) => {
//     dayjs(newDate).format("MM, DD, YYYY");
//     setDate(newDate);
//     onDateChange(newDate);
//     console.log(date);
//   };

//   const selectedDate = date ? dayjs(date).format(" MM, DD, YYYY") : "";
//   //console.log(selectedDate);
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer components={["DatePicker", "DatePicker", "DatePicker"]}>
//         <DatePicker
//           label="Select date"
//           value={date}
//           onChange={handleDateChange}
//           renderInput={(params) => <input {...params.inputProps} type="date" />}
//         />
//         {date && <div>Selected date: {dayjs(date).format("MM, DD, YYYY")}</div>}
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import isValid from 'date-fns/isValid'

const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
  const formattedDate = dayjs(value).format('DD/MM/YY');
  const placeholder = 'Izaberite datum';

  return (
    <div 
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        width: "100%", 
        height: "40px", 
        borderRadius: "5px", 
        border: "1px solid #ccc",
        padding: "0 10px" 
      }}
    >
      <input
        onClick={onClick}
        value={isValid(new Date(value)) ? formattedDate : placeholder}
        ref={ref}
        style={{ 
          border: 'none', 
          flex: 1,
          outline: 'none' 
        }}
      />
      <CalendarMonthIcon onClick={onClick} style={{ cursor: 'pointer' }} />
    </div>
  );
});

export default function CustomDatePicker({ isOpen,parentDate,onDateChange, date: initialDate, dateFromCalendarS }) {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (isOpen && dateFromCalendarS) {
      const formattedDate = dayjs(dateFromCalendarS, "MM/DD/YYYY").toDate();
      setSelectedDate(formattedDate);
    } else if (!isOpen) {
      setSelectedDate(null);
    } else if (initialDate) {
      setSelectedDate(dayjs(initialDate).toDate());
    }
  }, [initialDate, dateFromCalendarS, isOpen]);


  const handleChange = (date) => {
    setSelectedDate(date);
    onDateChange(dayjs(date).format("MM/DD/YYYY"));
  };

  return (
    <div style={{width: '100%'}}>
      <DatePicker 
        selected={selectedDate} 
        onChange={handleChange} 
        customInput={<CustomInput />}
      />
      {/* {selectedDate && <div>Selected date: {dayjs(selectedDate).format("MM/DD/YYYY")}</div>} */}
    </div>
  );
}