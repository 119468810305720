import React from "react";
import { useNavigate } from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";
import { fetchClients } from '../../redux/action';

import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
//import for radio group
import UseRadioGroup from "../../components/Rbutton";

//import datapicker
import BasicDateTimePicker from "../../components/DataPicker";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

//Buttons
import UploadButtons from "../../components/UploadButton"; //uploadButton
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";

import ClientTreatment from "./ClientTreatment"
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';

import TreatmentDataBtn from "./ButtonTreatmentAndBasicData/TreatmentDataBtn"
import BasicDataBtn from "./ButtonTreatmentAndBasicData/BasicDataBtn"
import { toast } from 'react-toastify';
import ResponsiveDateTimePickers from '../client/DatapickerForClient'

dayjs.extend(utc);
dayjs.extend(timezone);

const PreviewClient = () => {
  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [isEventUpdated, setEventUpdated] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken'); // get the token from local storage
  const BASE_URL = 'https://api.adaktarbooking.com';

  //this part is fow view
  const [showOsnovniPodaci, setShowOsnovniPodaci] = useState(true);
  const [showTretmani, setShowTretmani] = useState(false);

  const handleOsnovniPodaciClick = () => {
    setShowOsnovniPodaci(true);
    setShowTretmani(false);
  };

  const handleTretmaniClick = () => {
    setShowOsnovniPodaci(false);
    setShowTretmani(true);
  };


  //--------------------------------------
  const { id } = useParams(); //id from url
  const [clients, setClients] = useState({ name: "" });

  //take client with id from server  and set state with clients
  const headers = {
    "accept": "application/json",
    "Authorization": `Bearer ${token}`,

  }
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [newsOnEmail, setNewsOnEmail] = React.useState("");


  React.useEffect(() => {

    axios
      .get(`${BASE_URL}/api/v1/client/${id}`, { headers })
      .then((response) => {

        setClients(response.data);
      
        const dateObject = dayjs(response.data.dateOfBirth);
        console.log(dateObject);
        setDateOfBirth(dateObject);
        setNewsOnEmail(response.data.newsOnEmail.toString()); // Add this line. Convert boolean to string.

      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error', error.response.status);
          console.error('Error detail', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
      });
  }, []);


  //change clients state set my filed editable
  const handleNameChange = (event) => {
    setClients({ ...clients, name: event.target.value });
  };
  const handleNewsOnEmailChange = (event) => {
    setNewsOnEmail(event.target.value);
  };
  const handleSurnameChange = (event) => {
    setClients({ ...clients, surname: event.target.value });
  };
  const handlePhoneChange = (event) => {
    setClients({ ...clients, phone: event.target.value });
  };
  const handleAddressChange = (event) => {
    setClients({ ...clients, address: event.target.value });
  };

  const handleDrugAllergiesChange = (event) => {
    setClients({ ...clients, drugAllergies: event.target.value });
  };

  const handleDescriptionChange = (event) => {
    setClients({ ...clients, description: event.target.value });
  };

  const handleDateChange = (date) => {
    setDateOfBirth(dayjs(date));
  };
  const handleEmailChange = (event) => {
    setClients({ ...clients, email: event.target.value });
  };

  // const handleNewsOnEmailChange = (event) => {
  //   setNewsOnEmail(event.target.value);
  // };
  //onclick containedButton update client in database

  const updateClient = () => {
    const format =  dateOfBirth.utc().tz("Europe/Moscow")
    console.log(format)
    const adjustedDateOfBirth = format.format().split('T')[0]; //this code is take time for belgrade meaby is can 
    //happend some err  if will be some another country 
  
    console.log(adjustedDateOfBirth)

    const clientData = {
      ...clients,
      // dateOfBirth: dateOfBirth && dateOfBirth.isValid() ? dateOfBirth.toISOString() : null,
      dateOfBirth: adjustedDateOfBirth,

      newsOnEmail: newsOnEmail === "true"

    };

    axios
      .put(`${BASE_URL}/api/v1/client/${id}`, clientData, { headers })
      .then((response) => {
        setRefreshData(!refreshData); // Add this line here

        setClients(response.data); //update the state with the response
        if (response.data.dateOfBirth) {
          const dateObject = dayjs(response.data.dateOfBirth);
          setDateOfBirth(dateObject);
        }
        if (response.data.newsOnEmail) {
          setNewsOnEmail(response.data.newsOnEmail.toString());
        }
        // console.log('Updated client successfully', response.data);
        dispatch(fetchClients());

        toast.success(">>>Uspesno ste izmenili !");

      })
      .catch((error) => {
        toast.error("Nije uspesno izmenjeno !");

        console.log(error);

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {
            console.error('Bad Request - Invalid or missing data', error.response.data);
          } else if (error.response.status === 401) {
            console.error('Unauthorized - Invalid token', error.response.data);
          } else {
            console.error(`Unknown server error - ${error.response.status}`, error.response.data);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
      });
  };
  //--



  const navigateONIndex = () => {
    navigate("/index");
    //console.log("cao");
  };
  const navOnCliShearch = () => {
    navigate("/client/search");
  };
  return (
    <>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            ml: isMenuOpen ? 33 : 8,
            pt: 8,
            pl: 2,
            pr: 2,

            "@media (max-width: 600px)": {
              ml: 0,
            },
          }}
        >
          <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
            <Box>
              <Typography sx={{ fontSize: "25px" }}> Klijenti</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <IconButtons onClick={navigateONIndex} />

                <Typography sx={{ fontSize: "15px" }}>
                  - Klijenti - promena
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            sx={{
              bgcolor: "white",
              width: "100%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item md={12}>

              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' }
              }}>
                <Typography variant="h6" sx={{ p: 2, marginLeft: 2 }}>
                  Pregled klijenta - {clients.name}
                </Typography>
                <Box sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  '& > :not(style)': { m: 1 }, // Add margin to children elements
                }}>
                  <BasicDataBtn onClick={handleOsnovniPodaciClick} active={showOsnovniPodaci} />
                  <TreatmentDataBtn onClick={handleTretmaniClick} active={showTretmani} />
                </Box>
              </Box>
              <Divider sx={{}} />
            </Grid>

            {showOsnovniPodaci && (
              <>
                <Grid container sx={{}}>
                  <Grid item xs={12} sm={6}>
                    <Box p={2}>
                      {/* <UploadButtons /> */}
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Ime
                      </Typography>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <OutlinedInput
                          id="outlined-basic"
                          placeholder="Unesi ime"
                          value={clients.name}
                          onChange={handleNameChange}
                          size="small"
                          style={{ height: "40px" }}
                          startAdornment={
                            <InputAdornment position="start">
                              <PersonIcon fontSize="small" />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <Typography sx={{ paddingBottom: "10px", mt: 2 }}>
                        Prezime
                      </Typography>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <OutlinedInput
                          id="outlined-basic"
                          placeholder="Unesi Prezime"
                          value={clients.surname}
                          onChange={handleSurnameChange}
                          size="small"
                          style={{ height: "40px" }}
                          startAdornment={
                            <InputAdornment position="start">
                              <PersonIcon fontSize="small" />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        Datum rodjenja
                      </Typography>
                      {
                      dateOfBirth?.$y
                       ?
                        (<ResponsiveDateTimePickers
                          value={dateOfBirth}
                          onDateChange={handleDateChange} />) :
                          (<ResponsiveDateTimePickers
                            onDateChange={handleDateChange} />)}

                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box p={2}>
                      <Typography sx={{ paddingBottom: "10px" }}>
                        Broj telefona
                      </Typography>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <OutlinedInput
                          id="outlined-basic"
                          placeholder="Unesi broj telefona"
                          value={clients.phone}
                          onChange={handlePhoneChange}
                          size="small"
                          style={{ height: "40px" }}
                          startAdornment={
                            <InputAdornment position="start">
                              <PhoneIphoneIcon fontSize="small" />
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      <Typography sx={{ paddingBottom: "10px", mt: 2 }}>
                        Adresa
                      </Typography>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <OutlinedInput
                          id="outlined-basic"
                          placeholder="Unesi adresa"
                          value={clients.address}
                          onChange={handleAddressChange}
                          size="small"
                          style={{ height: "40px" }}
                          startAdornment={
                            <InputAdornment position="start">
                              <LocationOnIcon fontSize="small" />
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      <Typography sx={{ paddingBottom: "10px", mt: 2 }}>
                        Email
                      </Typography>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <OutlinedInput
                          id="outlined-basic"
                          type="email"
                          placeholder="Unesi email"
                          value={clients.email}
                          onChange={handleEmailChange}
                          autoComplete="email"
                          size="small"
                          style={{ height: "40px" }}
                          startAdornment={
                            <InputAdornment position="start">
                              <EmailIcon fontSize="small" />
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      <Typography sx={{ paddingBottom: "10px", mt: 2 }}>
                        Da li zeli da prima novosti na email?
                      </Typography>
                      <UseRadioGroup value={newsOnEmail} onChange={handleNewsOnEmailChange} />

                      <Typography sx={{ paddingBottom: "10px", mt: 2 }}>
                        Alergija na lekove
                      </Typography>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <OutlinedInput
                          id="outlined-basic"
                          placeholder="Unesi podatke o alergijama na lekove"
                          value={clients.drugAllergies}
                          onChange={handleDrugAllergiesChange}
                          size="small"
                          style={{ height: "40px" }}
                        />
                      </FormControl>

                      <Typography sx={{ paddingBottom: "10px", mt: 2 }}>
                        Opis
                      </Typography>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <OutlinedInput
                          id="outlined-basic"
                          placeholder="Unesi opis klijenta"
                          value={clients.description}
                          onChange={handleDescriptionChange}
                          size="small"
                          multiline
                          style={{ height: "40px" }}
                        />
                      </FormControl>


                    </Box>
                  </Grid>
                </Grid>
                <Divider />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="right"
                  height={100}
                  sx={{
                    gap: 2,
                    mr: 5,
                    "@media (max-width: 347px)": {
                      justifyContent: "center",
                      mr: 0,
                    },
                  }}
                >
                  <BasicContained onClick={updateClient} />
                  <BasicOutlined onClick={navOnCliShearch} />
                </Box>
              </>
            )}
            {showTretmani && (
              <Grid container sx={{}}>
                <ClientTreatment />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>

    </>
  );
};

export default PreviewClient;
