import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Dialog, useTheme, useMediaQuery } from "@mui/material";

const DataPickerForCalendarShowe = ({
  isVisible,
  datePickerDate,
  handleDateChange,
  setDatePickerVisible,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
    //  fullScreen={fullScreen}
      open={isVisible}
      onClose={() => setDatePickerVisible(false)}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
          borderRadius: '5px',
          padding: '10px',
          '& .react-datepicker': {
            border: 'none',
          },
          '& .react-datepicker__header': {
            backgroundColor: 'primary',
            borderRadius: '5px 5px 0 0',
          },
          '& .react-datepicker__current-month': {
            fontSize: '1rem',
          },
          '& .react-datepicker__day': {
            outline: 'none',
            '&:hover': {
              backgroundColor: '#eee',
            },
          },
          '& .react-datepicker__day--selected': {
            backgroundColor: '#0a6',
            color: 'white',
          },
        }}
      >
        <DatePicker
          selected={datePickerDate}
          onChange={handleDateChange}
          inline
          autoFocus
        />
      </Box>
    </Dialog>
  );
};

export default DataPickerForCalendarShowe;
