import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function PrevButton(props) {
  const { onClick } = props;
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Stack direction="row" spacing={1}>
      <IconButton onClick={handleClick} aria-label="delete">
        <ArrowBackIosNewIcon />
      </IconButton>
    </Stack>
  );
}
