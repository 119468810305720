// import * as React from "react";
// import dayjs from "dayjs";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
// import { TextField } from "@mui/material";
// export default function DataPickerPrice ({tsPrice, setTsPrice}) {

//   const handleDateChange = (date) => {
//     setTsPrice(date);
//   };
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer
//         components={[
//           "DatePicker",
//           "MobileDatePicker",
//           "DesktopDatePicker",
//           "StaticDatePicker"
//         ]}
//       >
//         <DemoItem >
       

// <DatePicker
//       defaultValue={dayjs("2022-04-17")}
//       label="Date picker"
//       value={tsPrice}
//       onChange={handleDateChange}
//       renderInput={(params) => <TextField {...params} />}
//       slotProps={{ textField: { size: 'small' } }}
//     />
//         </DemoItem>
        
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
  const formattedDate = dayjs(value).format('DD/MM/YY');
  const placeholder = 'Izaberite datum';

  return (
    <div 
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        width: "100%", 
        height: "50px", 
        borderRadius: "5px", 
        border: "1px solid #ccc",
        padding: "0 10px" 
      }}
    >
      <input
        onClick={onClick}
        value={value ? formattedDate : placeholder}
        ref={ref}
        style={{ 
          border: 'none', 
          flex: 1,
          outline: 'none' 
        }}
      />
      <CalendarMonthIcon onClick={onClick} style={{ cursor: 'pointer' }} />
    </div>
  );
});

export default function DataPickerPrice({ tsPrice, setTsPrice }) {
  const [selectedDate, setSelectedDate] = useState(tsPrice ? new Date(tsPrice) : null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setTsPrice(date);
  };

  return (
    <DemoContainer
      components={[
        "DatePicker",
        "MobileDatePicker",
        "DesktopDatePicker",
        "StaticDatePicker",
      ]}
    >
      <DemoItem>
        <DatePicker 
          selected={selectedDate} 
          onChange={handleDateChange} 
          customInput={<CustomInput />}
          defaultValue={new Date("2022-04-17")}
        />
      </DemoItem>
    </DemoContainer>
  );
}
