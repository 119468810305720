import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DialogAddPrice from "./Dialog/DialogAddPrice"
//import for radio group
import UseRadioGroup from "../../components/Rbutton";

//import datapicker
import BasicDateTimePicker from "../../components/DataPicker";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

//combobutton
import ComboBoxBoje from "../../components/ComboBoxBoje";

//button
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";
import jwt_decode from 'jwt-decode';

//for color picker 
import { ColorPicker } from "@mui/lab";
import { AdapterDateFns } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { SketchPicker } from "react-color";
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';

import { setDataInserted } from '../../redux/action';  // import the action

import {  fetchTreatments } from '../../redux/action';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Tnew = () => {
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [staffList, setStaffList] = useState([]);

    // Add state variables for each input field
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [repetitionPeriod, setRepetitionPeriod] = useState("");
    const [duration, setDuration] = useState("");
    const [selectedColor, setSelectedColor] = useState("#000000");
    const [treatmentPriceData, setTreatmentPriceData] = useState(null);
    const [priceId, setPriceId] = useState(null);
  //  const [isDataInserted, setDataInserted] = useState(false);
  const BASE_URL = 'https://api.adaktarbooking.com';

    // console.log(treatmentPriceData,"cena iz perent ")
    useEffect(() => {
      // Fetch all staffs when the component mounts
      const fetchStaffs = async () => {
        try {
          const token = localStorage.getItem('accessToken');
          const tenant = 'root';
    
          const response = await axios.get(`${BASE_URL}/api/v1/staff/allstaff`, {
            headers: {
              "accept": "application/json",
              "Authorization": `Bearer ${token}`,
              "tenant": tenant,
              "Content-Type": "application/json",
            },
          });
    
          if (response.status === 200) {
            setStaffList(response.data);

          } else {
            console.log("Failed to fetch staffs");
          }
    
        } catch (error) {
          console.error(error);
        }
      }
    
      fetchStaffs();
    }, []);  // Empty dependency array to make it run only on mount
    const assignTreatmentToAllStaffs = async (treatmentId) => {
      try {
        const token = localStorage.getItem('accessToken');
        const tenant = 'root';
    
        for (let i = 0; i < staffList.length; i++) {
          const staffId = staffList[i].id;  // Assuming each staff has an 'id' field
    
          const staffTreatmentData = {
            staffId,
            treatmentId,
          };
    
          const response = await axios.post(`${BASE_URL}/api/v1/stafftreatment/insert`, staffTreatmentData, {
            headers: {
              "accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
              "tenant": tenant,
            },
          });
    
          if (response.status !== 200) {
            console.log(`Failed to assign treatment to staff with id: ${staffId}`);
          }
        }
    
    
      } catch (error) {
        console.error(error);
      }
    }
    
    
    

    const resetForm = () => {
      setTitle("");
      setDescription("");
      setRepetitionPeriod("");
      setDuration("");
      setSelectedColor("#000000");
    };
    
    const handleSubmit = async () => {
   
      try {
        const token = localStorage.getItem('accessToken');
        const tenant = 'root';
        const decodedToken = jwt_decode(token);
    
        // First API call to insert treatmentPriceData
        const response1 = await axios.post(`${BASE_URL}/api/v1/treatmentprice/insert`, treatmentPriceData, {
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "tenant": tenant,
          },
        });
    
        if (response1.status === 200) {
          toast.success("Cena je uspešno dodat!");

          setPriceId(response1.data);  // assuming the id is in the response data
    
          const treatmentData = {
            title,
            description,
            repetitionPeriod: Number(repetitionPeriod),
            duration: Number(duration),
            color: selectedColor,
            treatmentPriceId: response1.data, // Use the id directly here
          };
    
         const response2 = await axios.post(`${BASE_URL}/api/v1/treatment/insert`, treatmentData, {
        headers: {
          "accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "tenant": tenant,
        },
      });

      if (response2.status === 200) {
       
          toast.success("Tretman je uspešno dodat!");
 
        resetForm();
        
        navigate("/treatment/search");
        dispatch(setDataInserted(true));  // dispatch the action here
        dispatch(fetchTreatments());

        // Assign the treatment to all staffs
        assignTreatmentToAllStaffs(response2.data);  // Assuming the treatment id is in the response data

      } else {
        console.log("Failed to add treatment");
      }

    } else {
      console.log("Failed to add TreatmentPriceData");
    }

  } catch (error) {
    console.error(error);
  }
};
    
 

  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
  };
  const navOnCliShearch = () => {
    navigate("/treatment/search");
  };

  return (
    <>
      
 
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,

              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> Tretman</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButtons onClick={navigateONIndex} />

                  <Typography sx={{ fontSize: "15px" }}>
                    - Tretman - Unos
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: '16px',

              }}
            >
              <Grid item md={12}>
                <Typography variant="h6" sx={{ p: 2 }}>
                  Unos tretmana
                </Typography>
                <Divider sx={{}} />
              </Grid>
              <Grid container sx={{}}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                  <OutlinedInput
                    size="small"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <InsertDriveFileIcon fontSize="small" />
                      </InputAdornment>
                    }
                    placeholder="Unesi naziv"
                    style={{ height: "40px" }}
                    inputProps={{
                      style: {
                        padding: "10px",
                      },
                    }}
                    label="Naziv"
                    labelPlacement="start"
                  />
                </FormControl>
                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
              Period ponavljanja
            </Typography>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <OutlinedInput
                id="repetitionPeriod"
                value={repetitionPeriod}
                onChange={(e) => setRepetitionPeriod(Number(e.target.value))}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <LoopIcon fontSize="small" />
                  </InputAdornment>
                }
                placeholder="Period ponavljanja"
                style={{ height: "40px" }}
                inputProps={{ type: "number", step: 1 }}
              />
            </FormControl>

            <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
              Trajanje (minuta)
            </Typography>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <OutlinedInput
                id="duration"
                value={duration}
                onChange={(e) => setDuration(Number(e.target.value))}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <AccessTimeIcon fontSize="small" />
                  </InputAdornment>
                }
                placeholder="Trajanje (minuta)"
                style={{ height: "40px" }}
                inputProps={{ type: "number", step: 0.5 }}
              />
            </FormControl>

            <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
              Opis
            </Typography>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <OutlinedInput
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                size="small"
                multiline
                placeholder="Opis"
                style={{ height: "40px" }}
              />
            </FormControl>
                     
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                

                  <DialogAddPrice 
                      onConfirm={(dataFromChild) => {
                        setTreatmentPriceData(dataFromChild);
                        console.log(dataFromChild,"data from child");
                      }}
                    />

                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Odaberite boju
                    </Typography>
                    <SketchPicker
                    color={selectedColor}
                    onChangeComplete={(newColor) => setSelectedColor(newColor.hex)}
                    />
                    
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="right"
                height={100}
                sx={{
                  gap: 2,
                  mr: 5,
                  "@media (max-width: 347px)": {
                    justifyContent: "center",
                    mr: 0,
                  },
                }}
              >
                <BasicContained onClick={handleSubmit} />
                <BasicOutlined onClick={navOnCliShearch} />
              </Box>
            </Grid>
          </Box>
        </Box> 
    
    </>
   
  );
};

export default Tnew;
