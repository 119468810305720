import React from "react";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { mainNavbarItems } from "./consts/navbaritems";
import { useParams, useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Cnew from "../pages/client/new";
import {  Menu, MenuItem } from '@mui/material';
import DataTableUsers from "../components/Datatables/users/DataUsers";
import DataTableC from "../components/Datatables/clients/DataClient";
import Csearch from "../pages/client/search";

import Collapse from "@mui/material/Collapse";

import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import jwt_decode from "jwt-decode";
import { makeStyles } from '@material-ui/core/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//redux
import { useSelector, useDispatch } from "react-redux";
import { toggleMenu } from "../redux/action";
const drawerWidth = 265;
// SET COLOR START
const gradientGoldBrown = 'linear-gradient(to right bottom, #a47a1e,#d3a84c,#ffec94,#e6be69,#ffd87c, #b58f3e ,#956d13 )';
const borderBottomColor = 'gray'; // define the constant here
//a47a1e d3a84c ffec94 e6be69  ffd87c b58f3e 956d13
// SET COLOR END

//SET FOR  DROPDOWN LOGOUT CHANGE PASSWORD 
const useStyles = makeStyles({
  redText: {
    color: 'black',
  },
  colorExpandLessIcon: {
    color: 'black',
  },
  colorExpandMoreIcon: {
    color: 'black',
  },
});
//----------END---------------------

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)} + 0px)`,
  [theme.breakpoints.up(1023)]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [theme.breakpoints.up(1023)]: {
    width: `calc(100% - 65px)`,
  },
  zIndex: theme.zIndex.drawer - 1,
  width: `calc(100% - 0px)`, //set lenght appbar
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,

  }),
  background: "white",//'linear-gradient(to right,#2980b9, #2c3e50)',// Replace 'your_color_here' with your desired color
  borderBottom: `2px solid ${borderBottomColor}`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  }),
  // "&::after": {
  //   content: '""',
  //   position: 'absolute',
  //   bottom: 0,
  //   left: 0,
  //   width: '100%',
  //   height: '2px',
  //   backgroundImage: gradientGoldBrown, // Apply
  // },
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  background: "white ",//'linear-gradient(to bottom,#2980b9, #2c3e50)', // Add your preferred gradient here
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      background:"white",// 'linear-gradient(to bottom, #2c3e50, #1565c0)', // And also here
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      background: "white",//'linear-gradient(to bottom,#2980b9, #2c3e50)', // And here as well
    },
  }),
}));



export default function Navbar(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen); //this state has same value like open and on another page I use this value to set margine and move conntent this is from redux
  const basicRole = useSelector((state) => state.menu.basicRole); 
  const dispatch = useDispatch();
  const [openL, setOpenL] = React.useState({});
  const [open, setOpen] = React.useState(isMenuOpen);
  const classes = useStyles();

//LOGIC FOR DROP DOWN MENU LOGOUT AND CHANGE PASSWORD

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLogoutChange = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('basicRole');

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiryTime');
    props.setIsLoggedIn(false);
  };
  //this is for take data from token 
  const token = localStorage.getItem('accessToken');
  const decodedToken = jwt_decode(token);
  const fullName = decodedToken.fullName;

  
//LOGIC FOR DROP DOWN MENU LOGOUT AND CHANGE PASSWORD END

  
useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth < 1024);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
    dispatch(toggleMenu());
  };

  // this code set all items to open without auto close
  // open subit
  // const handleClick = (id) => {
  //   setOpenL((prevState) => ({
  //     ...prevState,
  //     [id]: !prevState[id]

  //   }));
  // };

  // this code set all items with auto close, jus one item you can open
  const handleClick = (id) => {
    setOpenL((prevState) => {
      if (open === true) {
        //possible open just whan open === true (menubutton)

        const newState = {};
        if (prevState[id] === true) {
          newState[id] = false;
        } else {
          newState[id] = true;
        }
        //newState[id] = true;
        return newState;
      } else {
        const newState = {};
        if (prevState[id] === true) {
          newState[id] = false;
        } else {
          newState[id] = false;
        }
        //newState[id] = true;
        return newState;
      }
    });
  };

  //when mainmenu close all item close
  React.useEffect(() => {
    if (open === false) {
      setOpenL((prevState) => {
        const newState = {};
        Object.keys(prevState).forEach((key) => {
          newState[key] = false;
        });
        return newState;
      });
    }
  }, [open]);

  return (
    <>{open && isMobile && (
        <div 
          style={{ 
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999 // Ensure this is lower than the drawer's z-index
          }} 
        />
      )}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} sx={{}}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <IconButton
      aria-label="open drawer"
      onClick={handleDrawerOpen}
      edge="start"
      sx={{
        color: "black",
      }}
    >
      <MenuIcon />
    </IconButton>
    <Box sx={{ margin: 2 }}>
          <Typography sx={{ color: "black" }} aria-haspopup="true" aria-controls="simple-menu" onClick={handleLogoutChange}>
    {fullName} 
  </Typography>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
          <MenuItem onClick={() => { handleClose(); navigate('/user/changepassword'); }}>Change password</MenuItem>
        </Menu>
      </Box>
    </Toolbar>

        </AppBar>
        
        <Drawer 
          variant="permanent" 
          open={open}
          style={{ 
            position: isMobile ? 'absolute' : 'initial', 
            zIndex: isMobile ? 1000 : 'initial', 
            backgroundColor: isMobile ? 'white' : 'initial',
          }} 
        >
          {/* <DrawerHeader >
         
        <ListItem disablePadding>
            <ListItemButton  onClick={() => navigate('/#')}>
              <ListItemIcon>
              <Typography variant="body1">AC</Typography>
              </ListItemIcon>
              <ListItemText primary="ADAKTAR DOO" />
            </ListItemButton>
          </ListItem>
        
        
           <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> 
         
        </DrawerHeader>
        
        <Divider /> */}

          <List>
            {mainNavbarItems.map((item, index) => {
              if (
                item.id === 0 ||
                item.id === 2 ||
                item.id === 3 ||
                item.id === 4 ||
                item.id === 5 ||
                item.id === 6 
              ) {
                if (item.id === 0) {
                  return (
                    <>
                      <ListItem
                        key={item.id}
                        disablePadding
                        sx={{ display: "block", paddingBottom: "7px" }}
                      >
                        <ListItemButton
                          onClick={() => {
                            handleClick(item.id);
                            navigate(item.route);
                          }}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.label}        primaryTypographyProps={{ className: classes.redText }} 
/>
                        </ListItemButton>
                      </ListItem>

                      <Divider />
                    </>
                  );
                } else if (item.id === 2) {
                  return (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        onClick={() => {
                          handleClick(item.id);
                          const routeToNavigate = item.items.find(subItem => subItem.id === 2.2)?.route;
                          if (routeToNavigate) {
                              navigate(routeToNavigate);
                          }
                  
                        }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label}       primaryTypographyProps={{ className: classes.redText }} 
 />
                        {openL[item.id] ? <ExpandLess className={classes.colorExpandLessIcon}  /> : <ExpandMore className={classes.colorExpandMoreIcon} />}
                      </ListItemButton>
                      <Collapse
                        in={openL[item.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 9 }}
                            onClick={() => navigate(item.items[0].route)}
                          >
                            <ListItemText primary={item.items[0].name}       primaryTypographyProps={{ className: classes.redText }} 
/>
                          </ListItemButton>
                        </List>

                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 9 }}
                            onClick={() => navigate(item.items[1].route)}
                          >
                            <ListItemText primary={item.items[1].name}       primaryTypographyProps={{ className: classes.redText }} 
/>
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </ListItem>
                  );
                } else if (item.id === 3) {
                  return (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        onClick={() => {
                          handleClick(item.id);
                          const routeToNavigate = item.items.find(subItem => subItem.id === 3.2)?.route;
                          if (routeToNavigate) {
                              navigate(routeToNavigate);
                          }

                        }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label}
                          primaryTypographyProps={{ className: classes.redText }} 
                          />
                        {openL[item.id] ? <ExpandLess className={classes.colorExpandLessIcon}  /> : <ExpandMore className={classes.colorExpandMoreIcon} />}
                      </ListItemButton>
                      <Collapse
                        in={openL[item.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 9 }}
                            onClick={() => navigate(item.items[0].route)}
                          >
                            <ListItemText primary={item.items[0].name} 
                                  primaryTypographyProps={{ className: classes.redText }} 
                            />
                          </ListItemButton>
                        </List>

                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 9 }}
                            onClick={() => navigate(item.items[1].route)}
                          >
                            <ListItemText primary={item.items[1].name}
                                   primaryTypographyProps={{ className: classes.redText }} 
                            />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </ListItem>
                  );
                } else if (item.id === 4 && !basicRole) {
                  return (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        onClick={() => {
                          handleClick(item.id);
                          const routeToNavigate = item.items.find(subItem => subItem.id === 4.2)?.route;
                              if (routeToNavigate) {
                                  navigate(routeToNavigate);
                              }

                        }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label}       primaryTypographyProps={{ className: classes.redText }} 
/>
                        {openL[item.id] ? <ExpandLess className={classes.colorExpandLessIcon}  /> : <ExpandMore className={classes.colorExpandMoreIcon} />}
                      </ListItemButton>
                      <Collapse
                        in={openL[item.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 9 }}
                            onClick={() => navigate(item.items[0].route)}
                          >
                            <ListItemText primary={item.items[0].name}      primaryTypographyProps={{ className: classes.redText }} 
 />
                          </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 9 }}
                            onClick={() => navigate(item.items[1].route)}
                          >
                            <ListItemText primary={item.items[1].name}      primaryTypographyProps={{ className: classes.redText }} 
 />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </ListItem>
                  );
                } else if (item.id === 5 && !basicRole) {
                  return (
                    <ListItem
                      key={item.id}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        onClick={() => {
                          handleClick(item.id);
                          const routeToNavigate = item.items.find(subItem => subItem.id === 5.2)?.route;
                            if (routeToNavigate) {
                                navigate(routeToNavigate);
                            }

                        }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label}primaryTypographyProps={{ className: classes.redText }} />
                        {openL[item.id] ? <ExpandLess className={classes.colorExpandLessIcon}  /> : <ExpandMore className={classes.colorExpandMoreIcon} />}
                      </ListItemButton>
                      <Collapse
                        in={openL[item.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 9 }}
                            onClick={() => navigate(item.items[0].route)}
                          >
                            <ListItemText primary={item.items[0].name}primaryTypographyProps={{ className: classes.redText }} />
                          </ListItemButton>
                        </List>
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 9 }}
                            onClick={() => navigate(item.items[1].route)}
                          >
                            <ListItemText primary={item.items[1].name}primaryTypographyProps={{ className: classes.redText }} />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </ListItem>
                  );
                } else if (item.id === 6 && !basicRole ) {
                
                    return (
                      <ListItem
                        key={item.id}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          onClick={() => {
                            handleClick(item.id);
                            const routeToNavigate = item.items.find(subItem => subItem.id === 6.2)?.route;
                            if (routeToNavigate) {
                                navigate(routeToNavigate);
                            }
  
                          }} 
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.label}primaryTypographyProps={{ className: classes.redText }} />
                          {openL[item.id] ? <ExpandLess className={classes.colorExpandLessIcon}  /> : <ExpandMore className={classes.colorExpandMoreIcon} />}
                        </ListItemButton>
                        <Collapse
                          in={openL[item.id]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ pl: 9 }}
                              onClick={() => navigate(item.items[0].route)}
                            >
                              <ListItemText primary={item.items[0].name}primaryTypographyProps={{ className: classes.redText }} />
                            </ListItemButton>
                          </List>
                          <List component="div" disablePadding>
                            <ListItemButton
                              sx={{ pl: 9 }}
                              onClick={() => navigate(item.items[1].route)}
                            >
                              <ListItemText primary={item.items[1].name}primaryTypographyProps={{ className: classes.redText }} />
                            </ListItemButton>
                          </List>
                        </Collapse>
                      </ListItem>
                    );
                  
                }
              }
               else if(item.id === 7 && !basicRole ){
                //other elements
                return (
                  <>
                    <ListItem
                    key={item.id}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      onClick={() => {
                        handleClick(item.id);
                        navigate(item.route);
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.label}primaryTypographyProps={{ className: classes.redText }} />
                    </ListItemButton>
                  </ListItem>
                  
                  </>
                
                  
                );
              }else if  (item.id !== 7) {
                //other elements
                return (
                  <>
                    <ListItem
                    key={item.id}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      onClick={() => {
                        handleClick(item.id);
                        navigate(item.route);
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.label}primaryTypographyProps={{ className: classes.redText }} />
                    </ListItemButton>
                  </ListItem>
                  
                  </>
                
                  
                );
              }
            })}
          </List>
        </Drawer>
        
        {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader /> */}
       
       {/* <Csearch style={{ zIndex: 500, marginLeft:20 }} /> */}
 {/* <DataTableC /> */}
  
        
        {/* <DataTableC /> */}

        
        {/* <Box component="main" sx={{ flexGrow: 1, p: 3, mt:20 }}>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="center">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </Box> */}
        
{/* 

        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
          enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
          imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
          Convallis convallis tellus id interdum velit laoreet id donec ultrices.
          Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
          adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
          nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
          leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
          feugiat vivamus at augue. At augue eget arcu dictum varius duis at
          consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
          sapien faucibus et molestie ac.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>
      </Box> */}
      </Box>
    </>
  );
}
