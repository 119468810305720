import React from "react";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";

import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

//import for radio group
import UseRadioGroup from "../../components/Rbutton";

//import datapicker
import BasicDateTimePicker from "../../components/DataPicker";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
//for color picker 
import { ColorPicker } from "@mui/lab";
import { AdapterDateFns } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { SketchPicker } from "react-color";
import axios from "axios";
import dayjs from "dayjs";
//combobox treatment

//button
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";

//import comboboxTreatment
import ComboBoxTreatment from "../../components/ComboBoxTeatment";
//import switch button
import SwitchButton from "../../components/SwitchButton";
// import { PercentIcon } from '@material-ui/icons';

import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';

import {  fetchStaff  } from '../../redux/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Snew = () => {
  const maintitle = "Osoblje";
  const path = "- Osoblje - Unos";
  const subtitle = "Unos novog osoblja";
  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  //sate for date from input
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState(true);
  const [activetreatment , setActiveTreatment] = useState(true);
  const [percentageIncome, setPercentageIncome] = useState(0);
  const [income, setIncome] = useState(0);
  const [staffType, setStaffType] = useState("");
  const [treatments, setTreatments] = useState("");
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [colortreatmen, setcolortreatmen] = useState("");
  const [isVisible, setIsVisible] = React.useState(false);
  const dispatch = useDispatch();
  const BASE_URL = 'https://api.adaktarbooking.com';


   const resetForm = () => {
    setName("");
    setSurname("");
    setDescription("");
    setDateOfBirth(null);
    setPercentageIncome(0);
    setIncome(0);
    setActive(true);
    setStaffType("");
    setPhone("");
    setAddress("");
    setSelectedColor("");
  };
  const insertStaffDB = async () => {

    if (!phone.startsWith('+381')) {
      alert('Broj telefona mora da pocinje sa +381');
      return;
    }
  
    const data = {
      name,
      surname,
      description,
      dateOfBirth: new Date(dateOfBirth).toISOString(),
      percentageIncome: parseInt(percentageIncome),
      income: parseInt(income),
      active,
      staffType,
      phone,
      address,
      color: selectedColor,
    };
    
    try {
      const token = localStorage.getItem('accessToken'); 
      const tenant = 'root'; 
  
      const response = await axios.post(`${BASE_URL}/api/v1/staff/insert`, data, {
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
          "tenant": tenant  
        }
      });
  
      toast.success("Osoblje je uspešno dodato!");
      dispatch(fetchStaff());
      resetForm();

      //console.log("Staff data submitted successfully");

      const staffId = response.data; 

      // Iterate over each treatment and make an API call
      for (let i = 0; i < treatments.length; i++) {
        const treatment = treatments[i];
        //const treatmentid = treatment.id.toString(); // Convert treatment ID to string


        const secondApiData = {
          active: true,
          color: " ",
          treatmentId: treatment.toString(), // Add a null check here
          staffId:  staffId.toString() // Add a null check here
        };   

      
        const secondApiResponse = await axios.post(`${BASE_URL}/api/v1/stafftreatment/insert`, secondApiData, {
          headers: {
            "Content-Type": "application/json",
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
          }
        });
        //dispatch(fetchStaff());

      }

      
    } catch (error) {console.error("Error: Unauthorized");
        toast.error("Greška prilikom dodavanja osoblja!");
    if (error.response && error.response.status === 401) {
        console.error("Error: Unauthorized");
      } else {
        console.error("Error submitting staff data", error);
      }
    }
  };
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
  };
  const navOnCliShearch = () => {
    navigate("/staff/search");
  };
  return (
    <>
      
        <Box sx={{ width: "100%" ,}}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,

              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> {maintitle}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButtons onClick={navigateONIndex} />

                  <Typography sx={{ fontSize: "15px" }}>{path}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: '16px',
              }}
            >
              <Grid item md={12}>
                <Typography variant="h6" sx={{ p: 2 }}>
                  {subtitle}
                </Typography>
                <Divider sx={{}} />
              </Grid>
              <Grid container sx={{}}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    p={2}
                    sx={{
                      "@media (min-width: 900px)": {
                        pt: 7,
                      },
                    }}
                  >
                                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                  Ime
                </Typography>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <OutlinedInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    size="small"
                    id="outlined-basic"
                    placeholder="Unesi ime"
                    style={{ height: "40px" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        padding: "10px",
                      },
                    }}
                  />
                </FormControl>

                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                  Prezime
                </Typography>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <OutlinedInput
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    size="small"
                    id="outlined-basic"
                    placeholder="Unesi prezime"
                    style={{ height: "40px" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        padding: "10px",
                      },
                    }}
                  />
                </FormControl>
                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                  Opis
                </Typography>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <OutlinedInput
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    size="small"
                    id="outlined-basic"
                    placeholder="Unesi opis klijenta"
                    style={{ height: "40px" }}
                    multiline
                  />
                </FormControl>
                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                  Datum rodjenja
                </Typography>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    id="date"
                    label="Birthday"
                    type="date"
                    defaultValue="2023-05-16"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                  />
                </FormControl>

                   
                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                  Telefon
                </Typography>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <OutlinedInput
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    size="small"
                    id="outlined-basic"
                    placeholder="Unesi telefon"
                    style={{ height: "40px" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        padding: "10px",
                      },
                    }}
                  />
                </FormControl>
                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                  Adresa
                </Typography>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <OutlinedInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    size="small"
                    id="outlined-basic"
                    placeholder="Unesi adresu"
                    style={{ height: "40px" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <HomeIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        padding: "10px",
                      },
                    }}
                  />
                </FormControl>
                   </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Typography>Aktivan</Typography>
                      <SwitchButton
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                      />
                    </Box>
                    <Box></Box>
                    <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Zarada
                    </Typography>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <OutlinedInput
                        value={income}
                        onChange={(e) => setIncome(e.target.value)}
                        size="small"
                        id="outlined-basic"
                        placeholder="Unesi procenat zarade"
                        style={{ height: "40px" }}
                        inputProps={{
                          type: "number",
                          step: 0.5,
                          min: 0,
                          style: {
                            padding: "10px",
                          },
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <LoopIcon fontSize="small" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Procenat zarade
                    </Typography>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <OutlinedInput
                        value={percentageIncome}
                        onChange={(e) => setPercentageIncome(e.target.value)}
                        size="small"
                        id="outlined-basic"
                        placeholder="Unesi procenat zarade"
                        style={{ height: "40px" }}
                        inputProps={{ type: "number", step: 0.5, min: 0, style: { padding: "10px" } }}
                        startAdornment={
                          <AttachMoneyIcon position="start">
                            <AttachMoneyIcon fontSize="small" />
                          </AttachMoneyIcon>
                        }
                      />
                    </FormControl>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Tretman koje obavlja zaposleni
                    </Typography>
                    {/* <TextField
                      size="small"
                      id="outlined-basic"
                      label="Unesi Naziv"
                      variant="outlined"
                      style={{ width: "100%" }}
                    /> */}
                   <ComboBoxTreatment
                    value={treatments}
                    onChange={(e) => setTreatments(e.target.value)}
                  />
                {isVisible && <Typography>Aktivan</Typography>}
                  {isVisible && (
                    <SwitchButton
                      checked={active}
                      onChange={(e) => setActiveTreatment(e.target.checked)}
                    />
                  )}


                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                  Uloga osoblja
                </Typography>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <OutlinedInput
                    value={staffType}
                    onChange={(e) => setStaffType(e.target.value)}
                    size="small"
                    id="outlined-basic"
                    placeholder="Unesi ulogu osoblja"
                    style={{ height: "40px" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <GroupIcon fontSize="small" />
                      </InputAdornment>
                    }
                    inputProps={{
                      style: {
                        padding: "10px",
                      },
                    }}
                  />
                </FormControl>

                  
                    <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Odaberite boju
                    </Typography>
                    <SketchPicker
                      color={selectedColor}
                      onChangeComplete={(newColor) => setSelectedColor(newColor.hex)}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="right"
                height={100}
                sx={{
                  gap: 2,
                  mr: 5,
                  "@media (max-width: 347px)": {
                    justifyContent: "center",
                    mr: 0,
                  },
                }}
              >
                <BasicContained onClick={insertStaffDB} />
                <BasicOutlined onClick={navOnCliShearch} />
              </Box>
            </Grid>
          </Box>
        </Box> 
    

      
    </>
  );
};

export default Snew;
