import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function BasicContained(props) {
  const { onClick } = props;
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Stack spacing={2} direction="row">
      <Button variant="contained" onClick={handleClick} sx={{ width: "100px" }}>
        Snimi
      </Button>
    </Stack>
  );
}
