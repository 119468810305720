import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// export default function TimeStart(props) {
//   const { onChange, startTime } = props;

//   const [timeSlots, setTimeSlots] = useState([]);
//   const [selectedTimeStart, setSelectedTimeStart] = useState(startTime);

//   useEffect(() => {
//     const slots = Array.from(new Array(24 * 4)).map(
//       (_, index) =>
//         `${index < 10 ? "0" : ""}${Math.floor(index / 4)}:${
//           (index % 4) * 15 < 10 ? "0" : ""
//         }${(index % 4) * 15}`
//     );
//     setTimeSlots(slots);
//   }, []);

//   const handleTimeStartChange = (event, value) => {
//     setSelectedTimeStart(value);
//     onChange(value);
//   };

//   return (
//     <Autocomplete
//       id="disabled-options-demo"
//       options={timeSlots}
//       sx={{ width: "100%" }}
//       onChange={handleTimeStartChange}
//       value={selectedTimeStart}
//       renderInput={(params) => <TextField {...params} label="Time" />}
//     />
//   );
// }
 // useEffect(() => {
  //   // const slots = Array.from(new Array(15 * 2)).map((_, index) => {
  //   //   const hour = Math.floor(index / 2) + 8; // start from 08:00
  //   //   const minute = (index % 2) * 30; // every 30 minutes
  //   const slots = Array.from(new Array(15 * 4)).map((_, index) => {
  //     const hour = Math.floor(index / 4) + 8; // starting from 08:00
  //     const minute = (index % 4) * 15; // intervals of 15 minute

  //     const formattedHour = hour.toString().padStart(2, "0");
  //     const formattedMinute = minute.toString().padStart(2, "0");
  //     return `${formattedHour}:${formattedMinute}`;
  //   });
  //   setTimeSlots(slots);
  // }, []);
export default function TimeStart({startTime, ...props }) {
  const { onChange } = props;
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeStart, setSelectedTimeStart] = useState (startTime);

 
  useEffect(() => {
    const slots = [];
    for (let hour = 7; hour <= 22; hour++) {  // Starting from 7 now
      for (let minute = 0; minute < 60; minute += 30) {
        if (hour === 22 && minute > 0) continue; // Skip any minute values for 22:00
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        slots.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    setTimeSlots(slots);
}, []);

 

  // const handleTimeStartChange = (event, value) => {
  //   setSelectedTimeStart(value);
  //   onChange(value);
  // };
  useEffect(() => {
      setSelectedTimeStart(startTime);  
  }, [startTime]);

  const handleTimeStartChange = (event, value) => {
    if (value === null) {
      setSelectedTimeStart(null);
      onChange(null);
    } else {
      setSelectedTimeStart(value);
      onChange(value);
    }
  };
  return (
    <Autocomplete
      id="disabled-options-demo"
      options={timeSlots}
      sx={{ width: "100%" }}
      onChange={handleTimeStartChange}
      onClick={handleTimeStartChange}
      value={selectedTimeStart}
      renderInput={(params) => 
        <TextField 
          {...params} 
          placeholder="Time"
          variant="outlined"
          InputProps={{
            ...params.InputProps, 
            style: { height: '40px' }
          }}
        />
    }
  />
  );
}
