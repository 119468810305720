import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function DropDownDay(props) {
  const { options, selectedDay, onDayonChange,isSidebarOpen } = props;

  const [inputValue, setInputValue] = React.useState("");

  return (
    <div>
      <Autocomplete
        size="small"
        value={selectedDay}
        onChange={(event, newValue) => {
          onDayonChange(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options}
        sx={{ width: isSidebarOpen ? "200px" : "150px", pl: isSidebarOpen ? "0px" : "20px" }} // width changes based on the isSidebarOpen prop
        renderInput={(params) => <TextField {...params} label="izaberi " />}
      />
    </div>
  );
}
