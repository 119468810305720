import PeopleIcon from '@mui/icons-material/People';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BadgeIcon from '@mui/icons-material/Badge';

// Define customColor once and use it in all your icons
const customColor = { color: 'gray' };

export const mainNavbarItems = [
    {
        id: 0,
    icon: <span style={{ color: 'gray' }}>AC</span>,
        label: 'ADAKTAR DOO',
        route: '/index',
    },
    {
        id: 1,
        icon: <CalendarMonthIcon style={customColor} />,
        label: 'Zakazivanje',
        route: '/scheduling',
    },
    {
        id: 2,
        icon: <PeopleIcon style={customColor} />,
        label: 'Klijenti',
        route: 'client',
        items: [
            { id: 2.1, name: "Novi", route: '/client/new' },
            { id: 2.2, name: "Pretrage", route: '/client/search' }
        ]
    },
    {
        id: 3,
        icon: <ColorLensIcon style={customColor} />,
        label: 'Tretmani',
        route: 'treatment',
        items: [
            { id: 3.1, name: "Novi", route: '/treatment/new' },
            { id: 3.2, name: "Pretrage", route: '/treatment/search' }
        ]
    },
    {
        id: 4,
        icon: <BadgeIcon style={customColor} />,
        label: 'Osoblje',
        route: '',
        items: [
            { id: 4.1, name: "Novi", route: '/staff/new' },
            { id: 4.2, name: "Pretrage", route: '/staff/search' }
        ]
    },
    {
        id: 5,
        icon: <PersonIcon style={customColor} />,
        label: 'Korisnici',
        route: 'user',
        items: [
            { id: 5.1, name: "Novi", route: '/user/new' },
            { id: 5.2, name: "Pretrage", route: '/user/search' }
        ]
    },
    {
        id: 6,
        icon: <SummarizeIcon style={customColor} />,
        label: 'Izvestaji',
        route: '/report',
        items: [
            { id: 6.1, name: "Ukupna zarada", route: '/report/new' },
            { id: 6.2, name: "Ukupno tretmana", route: '/report/search' }
        ]
    },
    {
        id: 7,
        icon: <SettingsIcon style={customColor} />,
        label: 'Podešavanja',
        route: '/setting',
    },
]
