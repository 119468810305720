import React from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";

import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { SketchPicker } from "react-color";

//import for radio group
import UseRadioGroup from "../../components/Rbutton";

//import datapicker
import BasicDateTimePicker from "../../components/DataPicker";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import axios from "axios";
import { useParams } from "react-router-dom";
//combobutton
import ComboBoxBoje from "../../components/ComboBoxBoje";

//button
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";

import IconButtons from "../../components/IconButton";
//component
import TreatmentPrice from "./TreatmentPrice"
import DialogAddPrice from "./Dialog/DialogAddPrice"
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import Button from "react-bootstrap/Button";
import PriceBtn from "./BtnPrice/PriceBtn"
import BasicDataBtn from "../client/ButtonTreatmentAndBasicData/BasicDataBtn"

import {  fetchTreatments } from '../../redux/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PreviewTreatmen = () => {
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const [selectedColor, setSelectedColor] = useState("");
  //---- for corecting and call data from base
  const { id } = useParams(); //id from url
  const [treatment, setTreatment] = useState({ title: "",description:"", repetitionPeriod:0,duration:0, color:"",});
  const [treatmentPriceID, settreatmentPriceID] = useState("");//idofprice
  const [priceUpdateData, setpriceUpdateData] = useState("");
  const [showOsnovniPodaci, setShowOsnovniPodaci] = useState(true);
  const [price, setprice] = useState(false);
   const dispatch = useDispatch();
   const BASE_URL = 'https://api.adaktarbooking.com';
   const basicRole = useSelector((state) => state.menu.basicRole); 

  //-----------------------
  const token = localStorage.getItem('accessToken'); // get the token from local storage
  //take client with id from server  and set state with clients
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
   
   React.useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${BASE_URL}/api/v1/treatment/${id}`, { headers })
        .then((response) => {
          setTreatment(response.data); // set initial treatment values
          setSelectedColor(response.data.color); // Set the selected color from treatment.color
          settreatmentPriceID(response.data.treatmentPrice.id);
        })
       .catch((error) => {
         if (error.response) {
           // The request was made and the server responded with a status code
           // that falls out of the range of 2xx
           console.error('Error', error.response.status);
           console.error('Error detail', error.response.data);
         } else if (error.request) {
           // The request was made but no response was received
           console.error('No response received', error.request);
         } else {
           // Something happened in setting up the request that triggered an Error
           console.error('Error', error.message);
         }
       });
      }
       if (showOsnovniPodaci || !treatment.title) {
        fetchData();
      }
    
   }, [showOsnovniPodaci,id]);
  //change treatment state
  const handleNameChange = (event) => {
    setTreatment({ ...treatment, title: event.target.value });
  };
  const handleDescriptionChange = (event) => {
    setTreatment({ ...treatment, description: event.target.value });
  };
  const handleRepetationPeriodChange = (event) => {
    setTreatment({ ...treatment, repetitionPeriod: event.target.value });
  };
  const handleDurationChange = (event) => {
    setTreatment({ ...treatment,duration: event.target.value });
  };
  const handleColorChange = (event) => {
    setTreatment({ ...treatment, color: event.target.value });
  };

  //onclick containedButton update client in database
  const Btupdate = () => {
   
    const url = `${BASE_URL}/api/v1/treatment/${id}`;
  
    const payload = {
      id: id, // Assuming the treatment ID is available in the id variable
      title: treatment.title,
      description: treatment.description,
      repetitionPeriod: treatment.repetitionPeriod,
      duration: treatment.duration,
      color: selectedColor, // Use the selected color from the state
      treatmentPriceId: treatmentPriceID, // Use the id directly here

    };
  
    axios
      .put(url, payload, { headers })
      .then((response) => {
        dispatch(fetchTreatments());
    if (response.status === 200) {
                toast.success("Tretman je uspešno izmenjen!");
            } else {
                toast.error("Izmena tretmana nije uspela!");
            }
        setTreatment(response.data); // update the treatment state with the response data
  
        // Update price data after the treatment data is successfully updated
        axios
          .put(`${BASE_URL}/api/v1/treatmentprice/${treatmentPriceID}`, priceUpdateData, { headers })
          .then((response) => {
            if (response.status === 200) {
              toast.success("Uspesno ste izmenili cenu !");
          } else {
              toast.error("Izmena cene nije uspela!");
          }
           // console.log(response.data, "Updated Treatment Price Data");
            // handle your response here

         

          })
        
          .catch((error) => {
          
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error('Error', error.response.status);
              console.error('Error detail', error.response.data);
            } else if (error.request) {
              // The request was made but no response was received
              console.error('No response received', error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message);
            }
          });
  
      })
      .catch((error) => {
       
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };
  
  
  //----------------------------------------------end


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
  };
  const navOnCliShearch = () => {
    navigate("/treatment/search");
  };

  //sets for price


  const handleMainData = () => {
    setShowOsnovniPodaci(true);
    setprice(false);
  };
  const handlePriceClick = () => {
    setShowOsnovniPodaci(false);
    setprice(true);
  };

  return (
    <>
      
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,

              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> Tretmani</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButtons onClick={navigateONIndex} />

                  <Typography sx={{ fontSize: "15px" }}>
                    - Tretmani - Pregled
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: '16px',

              }}
            >
              <Grid item md={12}>  
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                flex: 1, 
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' }
              }}>
                <Typography variant="h6" sx={{ p: 2, marginLeft: 2 }}>
                  Tretmana - {treatment.title}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: { xs: 'column', md: 'row' },
                  '& > :not(style)': { m: 1 }, // Add margin to children elements
                  }}>
                  <BasicDataBtn onClick={handleMainData} active={showOsnovniPodaci} />
                  <PriceBtn onClick={handlePriceClick} active={price} />
               </Box>
            </Box>
            <Divider sx={{}} />
            </Grid>

                        
        

              {showOsnovniPodaci && (
                <>
              <Grid container sx={{}}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                  <Box sx={{mt:2}}>

                  <Typography sx={{ paddingBottom: "10px" }}>
                    Naziv
                  </Typography>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-basic"
                      placeholder="Unesi Naziv"
                      value={treatment.title}
                      onChange={handleNameChange}
                      size="small"
                      style={{ height: "40px" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <InsertDriveFileIcon fontSize="small" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  </Box>
                  <Box sx={{mt:2}}>
                  <Typography sx={{ paddingBottom: "10px" }}>
                    Period ponavljanja
                  </Typography>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-basic"
                      type="number"
                      step="1"
                      value={treatment.repetitionPeriod}
                      onChange={handleRepetationPeriodChange}
                      size="small"
                      style={{ height: "40px" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <LoopIcon fontSize="small" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  </Box>
                  <Box sx={{mt:2}}>
                  <Typography sx={{ paddingBottom: "10px" }}>
                    Trajanje (minuta)
                  </Typography>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-basic"
                      type="number"
                      step="0.5"
                      placeholder="Unesi Naziv"
                      value={treatment.duration}
                      onChange={handleDurationChange}
                      size="small"
                      style={{ height: "40px" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <AccessTimeIcon fontSize="small" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>

                  </Box>
                  <Box sx={{mt:2}}>

                    <Typography sx={{ paddingBottom: "10px" }}>
                    Opis
                  </Typography>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-basic"
                      placeholder="Unesi opis klijenta"
                      value={treatment.description}
                      onChange={handleDescriptionChange}
                      size="small"
                      multiline
                      style={{ height: "40px" }}
                    />
                  </FormControl>
                  </Box>

                    <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Boja
                    </Typography>
                    <SketchPicker
                      color={selectedColor}
                      onChangeComplete={(newColor) => setSelectedColor(newColor.hex)}
                    />

                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                    <Box sx={{mt:7}}>
                      <DialogAddPrice  treatmentPriceId={treatmentPriceID}  
                       onConfirm={(dataFromChild) => {
                       setpriceUpdateData(dataFromChild)

                      }} /> 
                    </Box>
                 

                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="right"
                height={100}
                sx={{
                  gap: 2,
                  mr: 5,
                  "@media (max-width: 347px)": {
                    justifyContent: "center",
                    mr: 0,
                  },
                }}
              >
                <BasicContained onClick={Btupdate} />
                <BasicOutlined onClick={navOnCliShearch} />
              </Box>
                </>
              )}
                {price && (
                    <Grid container sx={{}}>
                  <TreatmentPrice treatmentPriceId={treatmentPriceID}  /> 
                   </Grid>
                  )}
            </Grid>
          </Box>
        </Box> 
    
       
            
      
    </>
  );
};

export default PreviewTreatmen;
