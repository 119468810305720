import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function DropDownUser(props) {
  const { options, selectedOption, onOptionChange } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [inputValue, setInputValue] = React.useState("");

  // console.log(selectedOption);
  // console.log(options);

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Autocomplete
            size="small"
            value={selectedOption}
            onChange={(event, newValue) => {
              onOptionChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            sx={{
              width: isSmallScreen ? '200px' : '200px',
              pl: "20px"
            }}
            renderInput={(params) => <TextField {...params} label="Izaberi " />}
          />
        </Grid>
      </Grid>
    </div>
  );
}
