import React from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";

import { useState,useEffect } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import EuroIcon from "@mui/icons-material/Euro";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import TimelapseIcon from "@mui/icons-material/Timelapse";
//combobutton
import ComboBoxBoje from "../../components/ComboBoxBoje";
import CAdminAgent from "../../components/ComboAdminAgent";
//button
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Settings = () => {
  const maintitle = "Podešavanja";
  const path = "- Podešavanja - Izmena";
  const subtitle = "Izmena podešavanja";
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const BASE_URL = 'https://api.adaktarbooking.com';

  const headers = {
    "Content-Type": "application/json",
    "accept": "application/json",
    "Authorization": `Bearer ${token}`,
  };

  const initialPreferenceState = {
    name: "",
    address: "",
    phone: "",
    exchangeRate: 0,
    reservationValidDays: 0,
    paidReservation: 0,
    reservationLevelOne: 0,
    paidReservationLevelTwo: 0,
  };

  const [preference, setPreference] = useState(initialPreferenceState);
  const [originalPreference, setOriginalPreference] = useState(initialPreferenceState);
  const [isFormModified, setIsFormModified] = useState(false);
  const [open, setOpen] = useState();
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    getPreference();
  }, []);

  const getPreference = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/v1/preferences/allpreferences`, { headers });
      if (res.data && res.data.length > 0) {
        setPreference(res.data[0]);
        setOriginalPreference(res.data[0]);
      }
    } catch (err) {
      console.log("Error during retrieval", err);
    }
  };

  const updatePreference = async () => {
    try {
      const res = await axios.put(`${BASE_URL}/api/v1/preferences/${preference.id}`, preference, { headers });
      setPreference(res.data);
      toast.success("Uspesno Ste izmenili podešavanja!");

    } catch (err) {
      toast.error("Greska prilikom izmene !");

      console.log("Error during update", err);
    }
  };

  const handleChange = (e) => {
    setPreference({
      ...preference,
      [e.target.name]: e.target.value,
    });
    setIsFormModified(true);
  };

  const navigateToIndex = () => {
    setPreference(originalPreference);
    setIsFormModified(false);
    navigate("/index");
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    setPreference(originalPreference); // Reset preference state with original data
    setIsFormModified(false); // Reset isFormModified to false
    navigate("/index");
    };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            ml: isMenuOpen ? 33 : 8,
            pt: 8,
            pl: 2,
            pr: 2,

            "@media (max-width: 600px)": {
              ml: 0,
            },
          }}
        >
          <Grid item xs={12} sx={{ mt: 2, mb: 1, pl: 1, pt: 1 }}>
            <Box>
              <Typography sx={{ fontSize: "25px" }}> {maintitle}</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <IconButtons onClick={navigateONIndex} />

                <Typography sx={{ fontSize: "15px" }}>{path}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            sx={{
              bgcolor: "white",
              width: "100%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: '16px',

            }}
          >
            <Grid item md={12} pt="5px">
              <Typography variant="h6" sx={{ p: 2 }}>
                {subtitle}
              </Typography>
            </Grid>
            <Divider sx={{}} />
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                    Naziv
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    name="name"
                    value={preference.name}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                    Kurs
                  </Typography>
                  <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{ type: "number", step: 0.1 }}
                  name="exchangeRate"
                  value={preference.exchangeRate}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EuroIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                    Broj telefona
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    name="phone"
                    value={preference.phone} // Use the updated value from preference state
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                  />


                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                    Adresa
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    name="address"
                    value={preference.address}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FmdGoodIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                    Depozit za tretmane koji kostaju manje od: {preference.reservationLevelOne}
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    inputProps={{ type: "number", step: 1 }}
                    name="paidReservation"
                    value={preference.paidReservation}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EuroIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                  Unesite vrednost u odnosnu na koju odredjujete depozit 
                </Typography>
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{ type: "number", step: 1 }}
                  name="reservationLevelOne"
                  value={preference.reservationLevelOne}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EuroIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />

                <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                  Depozit za tretmane koji kostaju preko:{preference.reservationLevelOne}
                </Typography>
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{ type: "number", step: 1 }}
                  name="paidReservationLevelTwo"
                  value={preference.paidReservationLevelTwo}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EuroIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />

                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                    Rezervacija važi (dana)
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    inputProps={{ type: "number", step: 1 }}
                    name="reservationValidDays"
                    value={preference.reservationValidDays}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TimelapseIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="right"
              height={100}
              sx={{
                gap: 2,
                mr: 5,
                "@media (max-width: 347px)": {
                  justifyContent: "center",
                  mr: 0,
                },
              }}
            >
            <BasicContained onClick={() => { updatePreference(); getPreference(); }}  disabled={!isFormModified} />
              <BasicOutlined onClick={navigateONIndex} />
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Settings;
