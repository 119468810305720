import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// export default function TimeEnd(props) {
//   const { onChange, endTime } = props;

//   const [timeSlots, setTimeSlots] = useState([]);
//   const [selectedTimeEnd, setSelectedTimeEnd] = useState(endTime);

//   useEffect(() => {
//     const slots = Array.from(new Array(24 * 4)).map(
//       (_, index) =>
//         `${index < 10 ? "0" : ""}${Math.floor(index / 4)}:${
//           (index % 4) * 15 < 10 ? "0" : ""
//         }${(index % 4) * 15}`
//     );
//     setTimeSlots(slots);
//   }, []);

//   const handleTimeEndChange = (event, value) => {
//     setSelectedTimeEnd(value);
//     onChange(value);
//   };

//   return (
//     <Autocomplete
//       id="disabled-options-demo"
//       options={timeSlots}
//       sx={{ width: "100%" }}
//       onChange={handleTimeEndChange}
//       value={selectedTimeEnd}
//       renderInput={(params) => <TextField {...params} label="Time" />}
//     />
//   );
// }
export default function TimeEnd(props) {
  const { onChange, endTimeValue } = props;
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeEnd, setSelectedTimeEnd] = useState(null); // Initialize with null

  useEffect(() => {
    const slots = [];
    for (let hour = 7; hour <= 22; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        if (hour === 22 && minute > 0) continue;
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        slots.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    setTimeSlots(slots);

    // Check if endTimeValue exists in the slots and set it
    if (endTimeValue && slots.includes(endTimeValue)) {
      setSelectedTimeEnd(endTimeValue);
    }
  }, [endTimeValue]); // Added endTimeValue in the dependency array
  
  const handleTimeEndChange = (event, value) => {
    setSelectedTimeEnd(value);
    onChange(value);
  };

  return (
    <Autocomplete
id="disabled-options-demo"
options={timeSlots}
sx={{ width: "100%" }}
onChange={handleTimeEndChange}
value={selectedTimeEnd}
renderInput={(params) => 
  <TextField 
    {...params} 
    variant="outlined" 
    InputProps={{
      ...params.InputProps, 
      style: { height: '40px' }
    }} 
  />
}
/>
  );
}
