import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Box,  Typography } from "@mui/material";
import { useState } from "react";
import { Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import IconButtons from "../../components/IconButton";
import ResponsiveDateTimePickers from "../../components/DataPicker";
import ButtonReport from "../../components/ButtonReport";
import axios from "axios";
import dayjs from "dayjs";
import utc from 'dayjs-plugin-utc';
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



dayjs.extend(utc);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    flex: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    width: '15%',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
  },
  cellTretman: {
    width: '30%',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
  },
  cellReduced: {
    width: '15%',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  cellText: {
    fontSize: 12,
  },
});
const Rttreatment = () => {
  const maintitle = "Izveštaj";
  const path = "- Izveštaj - Ukupno tretmana za trazeni period ";
  //data for treatment
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [totalTreatment, setTotalTreatment] = useState([]);




  const [selectedStartDateForDay, setSelectedStartDateForDay] = useState(null);
  const [selectedEndDateForDay, setSelectedEndDateForDay] = useState(null);
  const [allScheduledTreatments, setAllScheduledTreatments] = useState([]);

  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);


  const [formattedStartDate, setFormattedStartDate] = useState('');
const [formattedEndDate, setFormattedEndDate] = useState('');

  const navigate = useNavigate();


  function FormateBooking(bookingTime) {
    const date = new Date(bookingTime);
  
    // Extract the day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
    // console.log( `${day}/${month}/${year}`,'bookingtime222')

    // Format the date as dd/mm/yyyy
    return `${day}/${month}/${year}`;
  }
  // console.log(dataForPDF, "kkl");
  function setTimeToLocal(date, hours, minutes, seconds, milliseconds) {
    let newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes || 0);
    newDate.setSeconds(seconds || 0);
    newDate.setMilliseconds(milliseconds || 0);
    return newDate;
  }
  


  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleStartDateChangeForDay = (date) => {
    setSelectedStartDateForDay(date);
  };

  const handleEndDateChangeForDay = (date) => {
    setSelectedEndDateForDay(date);
  };

  const navigateONIndex = () => {
    navigate("/index");
  };


  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1; // getMonth() returns 0-11
    const year = d.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
}


  // THIS PART IS FOR  SUMM OF TREATMENT   1 PART ON PAGE  START --------
  useEffect(() => {
    if (totalTreatment.length > 0) { // Checks if totalTreatment is not empty
      generatePDFAlltreatment(); // Calls the function to generate the PDF
    }
  }, [totalTreatment]); // This will only re-run if totalTreatment changes
  
  const handleSubmit = async () => {
    const adjustedStartDate = setTimeToLocal(selectedStartDate, 7, 0, 0, 0);

    // Assuming selectedEndDate is the date object for the end date
    // Adjust the end date to 23:00:00 local time
    const adjustedEndDate = setTimeToLocal(selectedEndDate, 23, 0, 0, 0);
    
    //DATA FOR TOP OF PDF
    const formatteStartdDates = formatDateToDDMMYYYY(adjustedStartDate);
    const formattedEndDated = formatDateToDDMMYYYY(adjustedEndDate);

  setFormattedStartDate(formatteStartdDates);
    setFormattedEndDate(formattedEndDated);
    //DATA FOR TOP OF PDF
    // Format the adjusted dates to ISO strings
    const formattedStartDate = adjustedStartDate.toISOString();
    const formattedEndDate = adjustedEndDate.toISOString();
        const token = localStorage.getItem('accessToken');
    // console.log(formattedStartDate,
    //   formattedEndDate,'datass2ss122')
    const url = 'https://api.adaktarbooking.com/api/v1/clienttreatment/numberoftreatments'; 
    const bodyData = {
      "startTime":formattedStartDate,
      "endTime": formattedEndDate
    };
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
         
        },
        body: JSON.stringify(bodyData) // Convert the JavaScript object to a JSON string
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json(); // Assuming the server responds with JSON-formatted data
      //setTotalTreatment(data); // I assume this is a state setter

      // Here you might want to transform your data before setting it, depending on the expected structure:
      const transformedData = data.map(item => ({
        bookingTime: FormateBooking(item.startTime), // Assuming formatDate is a function for formatting the date
        clientName: item.clientName,
        clientTreatmentStatus: item.clientTreatmentStatus,
        endTime: formatTime(item.endTime), // Convert ISO string to HH:MM
        paid: item.paid,
        price: item.price,
        staffName: item.staffName,
        startTime: formatTime(item.startTime), // Convert ISO string to HH:MM
        treatmentName: item.treatmentName,
  
      }));
  
      setTotalTreatment(transformedData); // Assuming you have a state setter for this
    //  generatePDFAlltreatment(); // Call the PDF generation function
  
    } catch (error) {
      if (error.message.includes('401')) {
        navigate('/login');
      }
  
      console.error('There was an error with the fetch operation:', error);
    }
  };
  




  const generatePDFAlltreatment = () => {
    // Ensure that updatedDataTreatment is set with the API response in the handleSubmit function
    // It might look like: setUpdatedDataTreatment(dataFromAPI);
    const win = window.open('', '_blank');
    win.document.write('<html><head><title>PDF Viewer</title></head><body style="margin: 0;"><div id="root"></div></body></html>');
    win.document.close();
  
    ReactDOM.render(
      <PDFViewer width="100%" height="100%">
        <Document>
          <Page size="A4" style={styles.page}>
          <Text style={{ fontSize: 16, textAlign: 'center' }}> {/* Adjust style as needed */}
                Period: {formattedStartDate} - {formattedEndDate}
                

              </Text>
            <View style={styles.table}>
              <View style={styles.row}>
                {/* Update the headers to match the fields */}
                {['Rezervisano', 'Klijent ', 'Tretman', 'Status', 'Pocetak', 'Zavrsetak', 'Osoblje', 'Cena', 'Placeno'].map((header) => (
                  <View style={styles.cell} key={header}>
                    <Text style={styles.headerText}>{header}</Text>
                  </View>
                ))}
              </View>
  
              {/* Map over the updatedDataTreatment array to create rows for each item */}
              {totalTreatment.map((item, rowIndex) => (
                <View style={styles.row} key={rowIndex}>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.bookingTime}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.clientName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.treatmentName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.clientTreatmentStatus}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.startTime}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.endTime}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.staffName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.price}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.paid ? 'Yes' : 'No'}</Text>
                  </View>
                </View>
              ))}
              {/* You can add a summary row if needed */}
            </View>
          </Page>
        </Document>
      </PDFViewer>,
      win.document.getElementById('root')
    );
  };
  
  
  useEffect(() => {
    if (allScheduledTreatments.length > 0) { // Checks if totalTreatment is not empty
      generatePDFallScheduledTreatments(); // Calls the function to generate the PDF
    }
  }, [allScheduledTreatments]); // This will only re-run if totalTreatment changes
  const printPdfForDay = async () => {
   

    const adjustedStartDate = setTimeToLocal(selectedStartDateForDay, 7, 0, 0, 0);

    // Assuming selectedEndDate is the date object for the end date
    // Adjust the end date to 23:00:00 local time
    const adjustedEndDate = setTimeToLocal(selectedEndDateForDay, 23, 0, 0, 0);
    

    //DATA FOR  TOP OF PDF 
    const formatteStartdDates = formatDateToDDMMYYYY(adjustedStartDate);
    const formattedEndDated = formatDateToDDMMYYYY(adjustedEndDate);

    setFormattedStartDate(formatteStartdDates);
    setFormattedEndDate(formattedEndDated);
        //DATA FOR  TOP OF PDF 

    // Format the adjusted dates to ISO strings
    const formattedStartDate = adjustedStartDate.toISOString();
    const formattedEndDate = adjustedEndDate.toISOString();

   

    const url = 'https://api.adaktarbooking.com/api/v1/clienttreatment/allscheduledtreatments'; 
    const bodyData = {
      "startTime":formattedStartDate,
      "endTime": formattedEndDate
    };
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,
         
        },
        body: JSON.stringify(bodyData) // Convert the JavaScript object to a JSON string
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json(); // Assuming the server responds with JSON-formatted data
      //setTotalTreatment(data); // I assume this is a state setter

      // Here you might want to transform your data before setting it, depending on the expected structure:
      const transformedData = data.map(item => ({
        id: item.id,
      startTime: formatTime(item.startTime), // Assuming formatTime is a function you've created
      endTime: formatTime(item.endTime),     // for formatting the time
      price: item.price,
      paid: item.paid === 1 ? 'Yes' : 'No', // Assuming paid is a boolean-like field (1 for yes, 0 for no)
      description: item.description,
      therapy: item.therapy,
      controlDays: item.controlDays,
      bookingTime: FormateBooking(item.startTime), // Assuming formatDate is a function for formatting the date
      discount: item.discount,
      clientId: item.clientId,
      clientTreatmentStatusId: item.clientTreatmentStatusId,
      staffTreatmentId: item.staffTreatmentId,
      clientName: item.clientName,
      staffName: item.staffName,
      treatmentName: item.treatmentName,
      clientTreatmentStatus: item.clientTreatmentStatus,
      }));
  
      setAllScheduledTreatments(transformedData); // Assuming you have a state setter for this
    //  generatePDFAlltreatment(); // Call the PDF generation function
  
    } catch (error) {
      if (error.message.includes('401')) {
        navigate('/login');
      }
  
      console.error('There was an error with the fetch operation:', error);
    }

  };



  
  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  
  // Similarly, you may want to format dates:
  // const formatDate = (isoString) => {
  //   const date = new Date(isoString);
  //   const day = date.getDate().toString().padStart(2, '0');
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   const year = date.getFullYear();
  //   return `${day}/${month}/${year}`;
  // };
  

  // THIS PART IS FOR  SUMM OF TREATMENT   1 PART ON PAGE  END --------calculateTotalTreatment
  ///THIS CODE IS FOR CREATE PDF FOR ALL TREATMENT TODAY START -------------



  const generatePDFallScheduledTreatments = () => {
   
    const win = window.open('', '_blank');
    win.document.write('<html><head><title>PDF Viewer</title></head><body style="margin: 0;"><div id="root"></div></body></html>');
    win.document.close();
  
    ReactDOM.render(
      <PDFViewer width="100%" height="100%">
        <Document>
          <Page size="A4" style={styles.page}>
          <Text style={{ fontSize: 16, textAlign: 'center' }}> {/* Adjust style as needed */}
                Period: {formattedStartDate} - {formattedEndDate}
              </Text>
            <View style={styles.table}>
              <View style={styles.row}>
                {/* Define the headers based on your fields */}
                {/* <View style={styles.cellReduced}>
                  <Text style={styles.headerText}>Client ID</Text>
                </View> */}
                <View style={styles.cellTretman}>
                  <Text style={styles.headerText}>Tretman</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.headerText}>Osoblje</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.headerText}>Pocetak</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.headerText}>Zavrsetak</Text>
                </View>
                <View style={styles.cellReduced}>
                  <Text style={styles.headerText}>rezervisano</Text>
                </View>
                {/* ... Add other headers as needed ... */}
              </View>
  
              {/* Map through your data to create rows */}
              {allScheduledTreatments.map((item, rowIndex) => (
                <View style={styles.row} key={rowIndex}>
                  {/* <View style={styles.cellReduced}>
                    <Text style={styles.cellText}>{item.clientId}</Text>
                  </View> */}
                  <View style={styles.cellTretman}>
                    <Text style={styles.cellText}>{item.treatmentName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.staffName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.startTime}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.endTime}</Text>
                  </View>
                  <View style={styles.cellReduced}>
                    <Text style={styles.cellText}>{item.bookingTime}</Text>
                  </View>
                  {/* ... Add other cells as needed ... */}
                </View>
              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>,
      win.document.getElementById('root')
    );
  };

 

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ ml: isMenuOpen ? 33 : 8, pt: 8, pl: 2, pr: 2, "@media (max-width: 600px)": { ml: 0 } }}>
          <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
            <Box>
              <Typography sx={{ fontSize: "25px" }}> {maintitle}</Typography>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                <IconButtons onClick={navigateONIndex} />
                <Typography sx={{ fontSize: "15px" }}>{path}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid sx={{
            bgcolor: "white", width: "100%", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", borderRadius: '16px',
          }}>
            <Grid item md={12} pt="5px">
              <Typography variant="h6" sx={{ p: 2 }}>Ukupno tretmana</Typography>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>Datum od</Typography>
                  <ResponsiveDateTimePickers
                    selectedDate={selectedStartDate}
                    handleDateChange={handleStartDateChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>Datum do</Typography>
                  <ResponsiveDateTimePickers
                    size="small"
                    selectedDate={selectedEndDate}
                    handleDateChange={handleEndDateChange}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="right"
              height={100}
              sx={{ gap: 2, mr: 5, "@media (max-width: 347px)": { justifyContent: "center", mr: 0 } }}
            >
              <ButtonReport onClick={handleSubmit} />
            </Box>
          </Grid>

          <Grid sx={{
            bgcolor: "white", width: "100%", mt: 10, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", borderRadius: '16px',
          }}>
            <Grid item md={12} pt="5px">
              <Typography variant="h6" sx={{ p: 2 }}>Selektuj datum za prikaz zakazanih tretmana </Typography>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>Datum od</Typography>
                  <ResponsiveDateTimePickers
                    selectedDate={selectedStartDateForDay}
                    handleDateChange={handleStartDateChangeForDay}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>Datum do</Typography>
                  <ResponsiveDateTimePickers
                    size="small"
                    selectedDate={selectedEndDateForDay}
                    handleDateChange={handleEndDateChangeForDay}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="right"
              height={100}
              sx={{ gap: 2, mr: 5, "@media (max-width: 347px)": { justifyContent: "center", mr: 0 } }}
            >
              <ButtonReport onClick={printPdfForDay} />

            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Rttreatment;
