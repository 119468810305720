import React from "react";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";

import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

//redux
import { useSelector, useDispatch } from "react-redux";
//icon
import PersonIcon from "@mui/icons-material/Person";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

//combobutton
import ComboBoxBoje from "../../components/ComboBoxBoje";
import CAdminAgent from "../../components/ComboAdminAgent";
//button
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';

import { Dialog } from '@material-ui/core';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Unew = () => {
  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const [isConfirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
    // Add state for form fields
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedRole, setSelectedRole] = useState("");
    //for validate
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const BASE_URL = 'https://api.adaktarbooking.com';

   // Add this code inside your component

   const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setUserName("");
    setPassword("");
    setConfirmPassword("");
    setPhoneNumber("");
    setSelectedRole("");
  };
  
      // Add a function to handle form submission and make the API call
      // Move the userData variable declaration outside of the function
const userData = {
  firstName,
  lastName,
  email,
  userName,
  password,
  confirmPassword,
  phoneNumber,
  selectedRole: selectedRole.value,
};

const insertUserDB = async () => {
  if (
    firstName === '' ||
    lastName === '' ||
    email === '' ||
    userName === '' ||
    password === '' ||
    confirmPassword === '' ||
    phoneNumber === ''
  ) {
    alert('Molimo Vas da popunite sva polja.');
    setPopupOpen(true);
    return;
  }

  if (!validateEmail(email)) {
    setPopupMessage('Molimo Vas unesite ispravan e-mail.');
    setPopupOpen(true);
    return;
  }

  if (!validatePhoneNumber(phoneNumber)) {
    setPopupMessage('Broj telefona mora da poljinje sa +381.');
    setPopupOpen(true);
    return;
  }

  if (!validatePassword(password)) {
    setPopupMessage('Invalid password. Password must contain at least 8 characters, one uppercase letter, one number, and one special character.');
    setPopupOpen(true);
    return;
  }
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(`${BASE_URL}/api/users`, userData, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {   
      toast.success("Klijent je uspešno dodat!");
     // console.log("User added successfully");
      resetForm();
    }else{
      toast.error("Greska prilikom dodavanja klijenta !");

    }
  } catch (error) {
    toast.error("Greska prilikom dodavanja korisnika !");

    console.error("Error:", error);
    if (error.response) {

      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  }
};

    
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
  };
  const navOnCliShearch = () => {
    navigate("/user/search");
  };
  //for validate
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const isValidEmail = regex.test(email);

    // if (!isValidEmail) {
    //   alert('Molimo Vas unesite ispravan e-mail.');
    // }
    return regex.test(email);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\+381/; // Regex to match "+381" at the start of the phone number
    const isValidPhoneNumber = regex.test(phoneNumber);
    if (!isValidPhoneNumber) {
      setPopupMessage('Invalid phone number.');
      setPopupOpen(true);
    }
    return isValidPhoneNumber;
  };
  
  const validatePassword = (password) => {
    const regex = /^(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
    const isValidPassword = regex.test(password);
    if (!isValidPassword) {
      setPopupMessage('Invalid password. Password must contain at least 8 characters, one uppercase letter, one number, and one special character.');
      setPopupOpen(true);
    }
    return isValidPassword;
  };
  

  
  return (
    <>
    
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,

              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> Korisnik</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButtons onClick={navigateONIndex} />

                  <Typography sx={{ fontSize: "15px" }}>
                    - Korisnik - Novi
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: '16px',

              }}
            >
              <Grid item md={12}>
                <Typography variant="h6" sx={{ p: 2 }}>
                  Unos novog korisnika
                </Typography>
                <Divider sx={{}} />
              </Grid>
              <Grid container sx={{}}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Ime
                    </Typography>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-basic"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      size="small"
                      startAdornment={
                        <InputAdornment position="start">
                          <PersonIcon fontSize="small" />
                        </InputAdornment>
                      }
                      placeholder="Unesi ime korisnika"
                      style={{ height: "40px" }}
                      inputProps={{
                        style: {
                          padding: "10px",
                        },
                      }}
                    />
                  </FormControl>

                  <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Prezime</Typography>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <OutlinedInput
                    id="outlined-basic"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    size="small"
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon fontSize="small" />
                      </InputAdornment>
                    }
                    placeholder="Unesi prezime korisnika"
                    style={{ height: '40px' }}
                    inputProps={{
                      style: {
                        padding: '10px',
                      },
                    }}
                  />
                </FormControl>

                <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>E-mail</Typography>
<FormControl variant="outlined" style={{ width: '100%' }}>
  <OutlinedInput
    id="outlined-basic"
    value={email}
    onChange={(e) => {
      const { value } = e.target;
      setEmail(value);
      setEmailError(validateEmail(value) ? '' : 'Invalid email');
    }}
    size="small"
    startAdornment={
      <InputAdornment position="start">
        <EmailIcon fontSize="small" />
      </InputAdornment>
    }
    placeholder="Unesi e-mail korisnika"
    style={{ height: '40px' }}
    inputProps={{
      style: {
        padding: '10px',
      },
    }}
    error={!!emailError}
    validate
  />
</FormControl>
{emailError && <Typography variant="caption" color="error">{emailError}</Typography>}
<Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Broj telefona</Typography>
<FormControl variant="outlined" style={{ width: '100%' }}>
  <OutlinedInput
    id="outlined-basic"
    value={phoneNumber}
    onChange={(e) => {
      const { value } = e.target;
      setPhoneNumber(value);

      if (!value.startsWith('+381')) {
        setPhoneNumberError('Phone number should start with +381');
      } else {
        setPhoneNumberError(validatePhoneNumber(value) ? '' : 'Invalid phone number');
      }
    }}
    size="small"
    startAdornment={
      <InputAdornment position="start">
        <PhoneIcon fontSize="small" />
      </InputAdornment>
    }
    placeholder="Unesi broj telefona korisnika"
    style={{ height: '40px' }}
    inputProps={{
      style: {
        padding: '10px',
      },
    }}
    error={!!phoneNumberError}
    validate
  />
</FormControl>
{phoneNumberError && <Typography variant="caption" color="error">{phoneNumberError}</Typography>}


                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>

                  <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Korisničko ime</Typography>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                  <OutlinedInput
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    size="small"
                    id="outlined-basic"
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon fontSize="small" />
                      </InputAdornment>
                    }
                    placeholder="Unesi korisničko ime"
                    style={{ height: '40px' }}
                    inputProps={{
                      style: {
                        padding: '10px',
                      },
                    }}
                  
                  />
                </FormControl>

                <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Password</Typography>
<FormControl variant="outlined" style={{ width: '100%' }}>
  <OutlinedInput
    id="outlined-basic"
    value={password}
    onChange={(e) => {
      const { value } = e.target;
      setPassword(value);

      if (!validatePassword(value)) {
        setPasswordError(
          'Password should contain at least one special character, one uppercase character, and one number'
        );
      } else {
        setPasswordError('');
      }
    }}
    size="small"
    startAdornment={
      <InputAdornment position="start">
        <LockIcon fontSize="small" />
      </InputAdornment>
    }
    placeholder="Enter password"
    style={{ height: '40px' }}
    inputProps={{
      style: {
        padding: '10px',
      },
    }}
    error={!!passwordError}
    type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password' type based on showPassword state
    validate
    endAdornment={
      <InputAdornment position="end">
      <span
        role="button"
        style={{ cursor: 'pointer' }}
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </span>
    </InputAdornment>
    }
  />
</FormControl>
{passwordError && <Typography variant="caption" color="error">{passwordError}</Typography>}



                <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Potvrdi šifru</Typography>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <OutlinedInput
                    type={showConfirmPassword ? 'text' : 'password'}
                    error={!isConfirmPasswordValid}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfirmPasswordValid(e.target.value === password);
                    }}
                    size="small"
                    id="outlined-basic"
                    startAdornment={
                      <InputAdornment position="start">
                        <LockIcon fontSize="small" />
                      </InputAdornment>
                    }
                    placeholder="Potvrdi šifru korisnika"
                    style={{ height: '40px' }}
                    inputProps={{
                      style: {
                        padding: '10px',
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <span
                          role="button"
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </span>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                    {/* <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Uloga
                    </Typography> */}

                    {/* <CAdminAgent
                     value={selectedRole}
                     onChange={(selectedRoleId) => setSelectedRole(selectedRoleId)}
                     /> */}
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="right"
                height={100}
                sx={{
                  gap: 2,
                  mr: 5,
                  "@media (max-width: 347px)": {
                    justifyContent: "center",
                    mr: 0,
                  },
                }}
              >
               <BasicContained 
                disabled={!isConfirmPasswordValid} 
                onClick={isConfirmPasswordValid ? insertUserDB : null} 
            />
            

                <BasicOutlined  onClick={navOnCliShearch} />

                {isPopupOpen && (
            <div className="popup-overlay">
              <div className="popup-dialog">
                <div className="popup-content">
                {/* {popupMessage && alert(popupMessage) && setPopupMessage('')} */}
                  <div onClick={() => setPopupOpen(false)}>Close</div>
                </div>
              </div>
            </div>
          )}


              </Box>
            </Grid>
          </Box>
        </Box> 
    
    </>
  );
};

export default Unew;
