import React from 'react';
import { IconButton, Box, Stack } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';

export default function ButtonWIchShowDateOfCalendar(props) {
  const { onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Stack spacing={2} direction="row">
      <IconButton 
        onClick={handleClick} 
        sx={{ 
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
        //  width: { xs: '50%', sm: '40%', md: '30%', lg: '20%', xl: '15%' }, 
        //  height: { xs: '50px', sm: '60px', md: '70px', lg: '80px', xl: '90px' }
        }}
      >
        <Box 
          component="span" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' 
          }}
        >
          <EventNoteIcon color="action" />
        </Box>
      </IconButton>
    </Stack>
  );
}
