import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux"; //redux
import { fetchStaff } from '../../../../redux/action';
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function ComboStaff(props) {
  const { onChange, staff, idStaffAndPrice, onIdStaffAndPriceChange, date, startTime, endTime } = props;
  // console.log(date, startTime, endTime,"fromadsfasf staff")//ovde se nalazi id tretmana idprice od tretmana i naziv  

  const allStaff = useSelector(state => state.menu.staff);
  // const targetStaff = allStaff.find(staff => staff.name === "Proba");

  // console.log(allStaff,targetStaff,'asdf0008888');

  const staffTreatments = useSelector(state => state.menu.staffTreatments);
  const clientTreatments = useSelector(state => state.menu.clientTreatments);

  const [stafff, setstafff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [overlappingTreatments, setOverlappingTreatments] = useState([]);
  const [staffIds, setStaffIds] = useState([]); //containe id of all staff wich have same id of treatment likeidStaffAndPrice.ID 
  const [removeStaffFromList, setRemoveStaffFromList] = useState([]); //containe id of all staff wich have same id of treatment likeidStaffAndPrice.ID 
  const [staffUpdate, setStaffUpdate] = useState(false); // 
  const dispatch = useDispatch();

  const [selectedStaff, setSelectedStaff] = useState(
    staff
      ? {
        name: staff.split(" - ")[0].trim(),
        id: parseInt(staff.split(" - ")[1]),
      }
      : null
  );



  useEffect(() => {
    // Derive stafff from allStaff
    const staffData = allStaff.filter(item => item.active).map((staffMember) => ({
      name: `${staffMember.name} ${staffMember.surname}`,
      id: staffMember.id,
      active: staffMember.active,
    }));

    let currentStaffIds = [];

    console.log(startTime);

    if (date && startTime && endTime && date !== '0' && startTime !== '0' && endTime !== '0') {
      
      const startDateTime = new Date(formatDateTime(date, startTime));
      const endDateTime = new Date(formatDateTime(date, endTime));

      // Calculate overlaps as before

      const overlaps = clientTreatments.filter(treatment => {
        const treatmentStart = new Date(treatment.startTime);
        const treatmentEnd = new Date(treatment.endTime);

        return (
          (treatmentStart >= startDateTime && treatmentStart < endDateTime) ||
          (treatmentEnd > startDateTime && treatmentEnd <= endDateTime) ||
          (treatmentStart <= startDateTime && treatmentEnd >= endDateTime)
        );
      });


      const staffTreatmentIds = overlaps.map(overlap => overlap.staffTreatmentId);
      const relevantStaffTreatments = staffTreatments.filter(staffTreatment => staffTreatmentIds.includes(staffTreatment.id)
      );
      const extractedStaffIds = relevantStaffTreatments.map(staffTreatment => staffTreatment.staffId);


      // Here you compute removeStaffFromList
      setRemoveStaffFromList(extractedStaffIds);
      // console.log(extractedStaffIds, 'asdfasf000asdf')
      currentStaffIds = staffData.map(staff => staff.id);
      console.log("extractedStaffIds",extractedStaffIds)

      if (extractedStaffIds.length > 0) {
        currentStaffIds = currentStaffIds.filter(id => !extractedStaffIds.includes(id));
      }

    }
    else {
      currentStaffIds = staffData.map(staff => staff.id)
    }

    const uniqueStaffIds = [...new Set(currentStaffIds)];
    const filteredStaffData = staffData.filter(staff => currentStaffIds.includes(staff.id));

    // Update states
    setstafff(filteredStaffData);
    setStaffIds(uniqueStaffIds);

  }, [clientTreatments, allStaff, endTime, date]);

  //  console.log(filteredStaff,'00asdf00033')
  //console.log(removeStaffFromList,"asdf0008888")

  //   useEffect(() => {
  //     // Process staff data
  //     const staffData = allStaff.map((staffMember) => ({
  //         name: `${staffMember.name} ${staffMember.surname}`,
  //         id: staffMember.id,
  //     }));
  //     setstafff(staffData);
  // if(removeStaffFromList !== []){
  // Filter and process staffTreatments data
  //     const filteredStaffTreatments = staffTreatments.filter(staffTreatment => staffTreatment.treatmentId === idStaffAndPrice.id);
  //     if (filteredStaffTreatments.length > 0) {
  //       const staffIdsFromFiltered = filteredStaffTreatments.map(staffTreatment => staffTreatment.staffId);

  //       // Filter out the staffIds that are present in removeStaffFromList
  //       const finalStaffIds = staffIdsFromFiltered.filter(staffId => removeStaffFromList.includes(staffId));
  // console.log(finalStaffIds,stafff,'asdf0008888')
  //       setStaffIds(finalStaffIds);
  //     }


  // }

  // }, [idStaffAndPrice, stafff, staffTreatments, removeStaffFromList, allStaff]);
  // useEffect(() => {
  //   // Process staff data
  //   const staffData = allStaff.map((staffMember) => ({
  //       name: `${staffMember.name} ${staffMember.surname}`,
  //       id: staffMember.id,
  //   }));
  //   setstafff(staffData);

  //   if (removeStaffFromList.length > 0) {
  //       // Find all staff IDs that match with IDs in removeStaffFromList
  //       const matchedStaffIds = staffData
  //           .filter(staff => removeStaffFromList.includes(staff.id))
  //           .map(staff => staff.id);
  // console.log(matchedStaffIds,'asdf0008888')
  //       // Update the state with matched staff IDs
  //       setStaffIds(matchedStaffIds);
  //   }else{

  //      const allStaffIds = staffData.map(staff => staff.id);
  //         setStaffIds(allStaffIds);
  //         console.log('nema batoo ');
  //   }

  // }, [idStaffAndPrice, stafff, staffTreatments, removeStaffFromList, allStaff]);


  useEffect(() => {
    const uniqueStaffIds = [...new Set(staffIds)];

    const filteredStaffData = staffIds

    if (removeStaffFromList.length > 0) {


      const activeData = filteredStaffData.filter(item => item.active);


      setFilteredStaff(activeData);

    } else {

      const data = filteredStaff.filter(staff => filteredStaffData.includes(staff.id));

      const activeData = data.filter(item => item.active);


      setFilteredStaff(activeData);

    }


  }, [stafff, staffIds]);


  const formatDateTime = (date, time) => {
    const [month, day, year] = date.split("/");
    return `${year}-${month}-${day}T${time}:00`;
  };


  const handleStaffChange = (event, value) => {
    setSelectedStaff(value);
    onChange(value);
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={stafff}
      sx={{ width: "100%" }}
      onChange={handleStaffChange}
      value={selectedStaff}
      getOptionLabel={(option) => `${option.name}`}
      renderInput={(params) => <TextField {...params} variant="outlined" InputProps={{ ...params.InputProps, style: { height: '40px' } }} placeholder="Staff" />}
    />
  );
}
