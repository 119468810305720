// rootReducer.js

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import appReducer from '../redux/reducers';
//import dataInsertReducer from '../redux/dataInsertReducer'; // import your new reducer

const rootReducer = combineReducers({
    menu: appReducer,
   // dataInsert: dataInsertReducer, // add the new reducer

});

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)  // applying the middleware
);
export default store;
