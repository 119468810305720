

// import React, { useState } from 'react';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import dayjs from 'dayjs';
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
//   const formattedDate = dayjs(value).format('DD/MM/YY');
// const placeholderText="dd / mm / yyyy"

//   return (
//     <div 
//       style={{ 
//         display: 'flex', 
//         alignItems: 'center', 
//         width: "100%", 
//         height: "50px", // Your desired height
//         borderRadius: "5px", 
//         border: "1px solid #ccc",
//         padding: "0 10px" 
//       }}
//     >
//       <input
//         onClick={onClick}

//         value={value ? formattedDate : placeholderText}
//         ref={ref}
//         style={{ 
//           border: 'none', 
//           flex: 1,
//           outline: 'none' 
//         }}
//       />
//       <CalendarMonthIcon onClick={onClick} style={{ cursor: 'pointer' }} />
//     </div>
//   );
// });

// export default function ResponsiveDateTimePickers({ selectedDate, handleDateChange }) {
  
//   const [date, setDate] = useState(selectedDate ? dayjs(selectedDate).toDate() : null);

//   const handleChange = (date) => {
//     setDate(date);
//     handleDateChange(date);
//   };

//   return (
//     <DemoContainer
//       components={[
//         "DatePicker",
//         "MobileDatePicker",
//         "DesktopDatePicker",
//         "StaticDatePicker",
//       ]}
//     >
//       <DemoItem>
//         <DatePicker 
//           selected={date} 
//           onChange={handleChange} 
//           customInput={<CustomInput />}
//           showYearDropdown
//           scrollaableMonthYearDropdown
//           dateFormat = 'dd/mm/yyyy'
//         />
//       </DemoItem>
//     </DemoContainer>
//   );
// }
import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
  const formattedDate = dayjs(value).format('DD/MM/YY');
  const placeholder = 'Izaberite datum';

  return (
    <div 
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        width: "100%", 
        height: "50px", // Your desired height
        borderRadius: "5px", 
        border: "1px solid #ccc",
        padding: "0 10px" 
      }}
    >
      <input
        onClick={onClick}
        value={value ? formattedDate : placeholder}
        ref={ref}
        style={{ 
          border: 'none', 
          flex: 1,
          outline: 'none' 
        }}
      />
      <CalendarMonthIcon onClick={onClick} style={{ cursor: 'pointer' }} />
    </div>
  );
});

export default function ResponsiveDateTimePickers({ selectedDate, handleDateChange }) {
  const [date, setDate] = useState(selectedDate ? dayjs(selectedDate).toDate() : null);

  const handleChange = (date) => {
    setDate(date);
    handleDateChange(date);
  };

  return (
    <DemoContainer
      components={[
        "DatePicker",
        "MobileDatePicker",
        "DesktopDatePicker",
        "StaticDatePicker",
      ]}
    >
      <DemoItem>
        <DatePicker 
          selected={date} 
          onChange={handleChange} 
          customInput={<CustomInput />}
        />
      </DemoItem>
    </DemoContainer>
  );
}
