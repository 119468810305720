import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// export default function ResponsiveDateTimePickers() {
//   const [startDate, setStartDate] = useState(new Date());

//   return (
//     <>
//       <DatePicker
//         selected={startDate}
//         onChange={(date) => setStartDate(date)}
//         dateFormat="dd / MM / yyyy"
//         allowTextInput={true}
//         placeholderText="dd / mm / yyyy"
//       />
//     </>
//   );
// }
// import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';





  

  export default function ResponsiveDateTimePickers({ onDateChange, value, handleDateChange }) {
    const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (value && value.$D !== undefined && value.$M !== undefined && value.$y !== undefined) {
        const day = value.$D;
        const month = value.$M;
        const year = value.$y;
        const initialDate = new Date(year, month, day);
        setDate(initialDate);
    }
}, [value]);

  
  
    const handleInputChange = (e) => {
      const { value } = e.target;
      const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/;
      if (regex.test(value)) {
        const [day, month, year] = value.split('.');  
        const newDate = new Date(year, month - 1, day);
        setDate(newDate);

        if (onDateChange) onDateChange(newDate);
        if (handleDateChange) handleDateChange(newDate);

      }
    };
  
    return (
      <div style={{ 
        // border: '1px solid lightgray',  
        // borderRadius: '5px' ,
        width: '100% !important', 
      }}>
      <DatePicker
      selected={date}
      onChange={(newDate) => {
          setDate(newDate);
          if (onDateChange) onDateChange(newDate);
        }}
        dateFormat='dd.MM.yyyy'  
        customInput={
        <input
          type='text'
          value={date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : undefined}
          onChange={value ? undefined : handleInputChange}  // Conditional attachment of the handler
          placeholder='dd/mm/yyyy'
          style={{
            width: '100% !important',  

            color: date ? 'black' : 'gray',
            padding: '10px',
            borderRadius: '5px',
            border: 'solid 1px',
          }}

        />
          }
          style={{ width: '100% !important' }}  // Added !important

        />
      </div>
  
  );
}





// export default function ResponsiveDateTimePickers({ onDateChange, value }) {
  

//   const [date, setDate] = useState( new Date() );


//     console.log(formattedDate,'asdf0adsf0sdfdate')

// const handleInputChange = (e) => {
//   const { value } = e.target;
//   const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/;
//   if (regex.test(value)) {
//     const [day, month, year] = value.split('/');
//     const newDate = new Date(year, month - 1, day);
//     setDate(newDate);
//     if (onDateChange) onDateChange(newDate);  // Add this line

//   }
// };

// return (
//   <div>
//     <DatePicker
//       selected={date}
//       onChange={(newDate) => {
//           setDate(newDate);
//           if (onDateChange) onDateChange(newDate);  // Add this line
//         }}
//               dateFormat='dd/MM/yyyy'
//       customInput={
//         <input
//           type='text'
//           value={date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : undefined}
//           onChange={handleInputChange}
//           placeholder='dd/mm/yyyy'
//           style={{
//             color: date ? 'black' : 'gray',
//             padding: '10px',
//             width: '100%',
//             borderRadius: '5px',
//             border: '1px solid lightgray'
//           }}
//         />
//       }
//     />
//   </div>
// );
// }
