import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';

export default function ComboClient(props) {
  const { onChange, client } = props;
  const [users, setUsers] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const allClients = useSelector(state => state.menu.clients); // Access the data from the state
  useEffect(() => {

    if (allClients && allClients.length) { // Check if data exists and is non-empty
      let usersData = allClients.map((user) => ({
        id: user.id,
        name: user.name,
        surname: user.surname,
        phone: user.phone,
      }));

      // If there is a search query, filter the usersData based on the query  
      setUsers(usersData);
    }
  }, [allClients]);

  useEffect(() => {
    if (props.users && props.users.length) { // Check if data exists and is non-empty
      let usersData = props.users.map((user) => ({
        id: user.id,
        name: user.name,
        surname: user.surname,
        phone: user.phone,
      }));

      // If there is a search query, filter the usersData based on the query  
      setUsers(usersData);
    }
  }, [props.users]);

  useEffect(() => {
    if (client) {
      setSelectedClient(
        users.find((user) => `${user.name} - ${user.phone}` === client)
      );
    } else {
      setSelectedClient(null);
    }
  }, [client, users]);


  //USEEFFECT FOR AUTO SELECT CLIENT WICH IS INSERTED 
  useEffect(() => {
    if (props.idOfInsertedClient) {
      const newSelectedClient = users.find(user => user.id === props.idOfInsertedClient);
      setSelectedClient(newSelectedClient);
      onChange(newSelectedClient);
    }
  }, [props.idOfInsertedClient, users]);

  const handleUserChange = (event, value) => {
    setSelectedClient(value);
    onChange(value);
  };


  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={users}
      sx={{ width: "100%" }}
      // value={selectedClient}
      value={selectedClient}
      getOptionLabel={(option) => `${option.name} ${option.surname} - ${option.phone}`}

      // renderInput={(params) => (
      //   <TextField 
      //     {...params} 
      //     label="User"
      //     sx={{
      //       ...params.sx,
      //       height: "45px", 
      //       '& .MuiInputBase-root': {
      //         height: '100%',
      //       }
      //     }}
      //   />
      // )}

      onChange={handleUserChange}

      filterOptions={(options, params) => {
        const words = params.inputValue.split(' ').map(word => word.toLowerCase());

        return options.filter(option =>
          words.every(
            word =>
              option.name.toLowerCase().includes(word) ||
              option.surname.toLowerCase().includes(word) ||
              option.phone.includes(word)
          )
        );
      }}

      renderOption={(props, option) => (

        <li {...props} key={`${option.name}-${option.surname}-${option.phone}-${option.id}`}>
          {option.name} {option.surname} {option.phone}
        </li>
      )}

      renderInput={(params) => (
        <TextField
          {...params}
          label="User"
          sx={{
            ...params.sx, // Ensure you spread the existing params.sx if any
            height: "45px",
            '& .MuiInputBase-root': {
              height: '100%',
            }
          }}
        />

      )}
    />


  );
}
