import React from "react";

import { DataGrid } from "@mui/x-data-grid";
import axios, { all } from "axios";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { NavLink, useParams } from "react-router-dom";
//Button
import EditButton from "../../components/Datatables/EditButton";
import DelButton from "../../components/Datatables/DelButton"
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear"; //icon for delete search
import TextField from "@mui/material/TextField";
import UpdateDialogScheduler from '../../components/Scheduler/updateDialog/UpdateDialogScheduler ';
import { format } from "date-fns";
import { styled } from "@mui/system";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "react-bootstrap";
import { useMediaQuery } from '@material-ui/core';
import ButtonPdfUnpaid from '.././mainpage/ButtonPdfUnpaid'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux"; //redux
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "rgba(237, 237, 237)",
    fontWeight: 'bold',
  },
  "& .clear-icon": {
    backgroundColor: "lightgray", // Set your desired color for the ClearIcon here
  },
  "& .MuiDataGrid-row": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));
const useNoRowsOverlayStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  img: {
    maxWidth: '2%',
    animation: '$spin 1s linear infinite',
  },
  "@keyframes spin": {
    "0%": {
      transform: 'rotate(0deg)',
    },
    "100%": {
      transform: 'rotate(360deg)',
    },
  },
});

const useStyles = makeStyles({
  wrapCellContent: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: 160,
  },

});
//import component 
// import DialogAddPrice from "./Dialog/DialogAddPrice"
export default function UnpaidDeposits() {
  const [openD, setOpenD] = useState(false);
  const [toBeDeletedId, setToBeDeletedId] = useState(null);
  const [selectedId, setSelectedId] = React.useState(null);

  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);
  const { id } = useParams(); //id from url
  const [treatmentStatuses, setTreatmentStatuses] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [statusMap, setStatusMap] = useState({});
  const [depositStatus, setDepositStatus] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const BASE_URL = 'https://api.adaktarbooking.com'
  const clientTreatmentData = useSelector(state => state.menu.clientTreatments);
  const staffTreatments = useSelector(state => state.menu.staffTreatments);
  const clientTreatmentStatus = useSelector(state => state.menu.clientTreatmentStatus);
  const unpaidDepositTreatments = useSelector((state) => state.menu.unpaidDepositTreatments);


  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery('(min-width:600px)');
  const isDesktop = useMediaQuery('(min-width:1351px)');
  const classes = useStyles();
  const token = localStorage.getItem('accessToken'); // get the token from local storage
  const tenant = 'root'; // Define your tenant
  //console.log(token,"tokenis")
  //function for deleting row whan click on deletebutton
  const calculateRemainingDays = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 4); // Add 4 days
    const now = new Date();
    const remaining = Math.floor((date.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    return remaining >= 0 ? remaining : "ISTEKAO";
  };

  function getRemainingTime(createdDate) {
    // Set the expiry date to 4 days from the creation date
    let expiryDate = new Date(createdDate);
    expiryDate.setDate(createdDate.getDate() + 4);

    // Get the current date and time
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    let difference = expiryDate.getTime() - currentDate.getTime();

    // Calculate the difference in hours and minutes
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    difference -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(difference / (1000 * 60 * 60));
    difference -= hours * (1000 * 60 * 60);

    const mins = Math.floor(difference / (1000 * 60));

    // Determine if time has expired (if days, hours, and minutes are negative)
    const hasExpired = (days < 0 || hours < 0 || mins < 0);

    // Return time remaining, adding '-' if time has expired
    return `${hasExpired ? '-' : ''}${Math.abs(days)}d ${Math.abs(hours)}h ${Math.abs(mins)}m`;
  }
  const handleOpen = (id) => {
    setOpenD(true); // Open the dialog
    setSelectedId(id);  // Set the selected id

  };
  const handleCloseD = () => {
    setOpenD(false);
  };


  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // set orientation to landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(16); // smanjena veličina fonta

    const title = "Neplaceni Depoziti ";
    const headers = [["CLIENT", "PHONE", "REZERVACIJA", "DATUM I VREME TRETMANA", "PLACENO", "TRETMAN", "OSOBLJE", "EXPIRE"]];

    const data = filteredRows.map(elt => [
      elt.client,
      elt.phone,
      format(new Date(elt.createdOn), "M.dd.yyyy"), // display only date for "REZERVACIJA"
      `${format(new Date(elt.startTime), "M.dd.yyyy HH:mm")} / ${format(new Date(elt.endTime), "HH:mm")}`,
      elt.paid,
      elt.treatment,
      elt.employee,
      getRemainingTime(new Date(elt.createdOn))
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        cellWidth: 'wrap',
        rowHeight: 40 // Set height for rows
      },
      columnStyles: {
        0: { cellWidth: 80 }, // Prilagođene širine za svaku kolonu
        1: { cellWidth: 90 },
        2: { cellWidth: 80 },
        3: { cellWidth: 120 },
        4: { cellWidth: 60 },
        5: { cellWidth: 70 },
        6: { cellWidth: 60 },
        7: { cellWidth: 80 },
      }
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);

    var blob = doc.output('blob');
    var url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }
  // useEffect(() => {
  //   // Fetch the status data
  //   axios
  //     .get(`${BASE_URL}/api/v1/clienttreatmentstatus/allstatus`, {
  //       headers: {
  //         "accept": "application/json",
  //         "Authorization": `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       const statusData = clientTreatmentStatus;
  //       console.log(clientTreatmentStatus, 'responssss22eeeee')
  //       console.log(response, 'respons2sss22eeeee')
  //       // Create a map of status IDs to status names
  //       const statusMap = {};
  //       let depozitId = null;

  //       statusData.forEach((status) => {
  //         statusMap[status.id] = status.title;

  //         // Check if the title is "DEPOZIT" and if so, store its ID
  //         if (status.title === "DEPOZIT") {
  //           depozitId = status.id;
  //         }
  //       });

  //       // Update the state
  //       setStatusMap(statusMap);
  //       if (depozitId) {
  //         setDepositStatus(depozitId);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching status data", error);
  //     });
  // }, []);

  const getColumnStatusName = (statusId) => {
    return statusMap[statusId] || "";
  };

  function handleDelete(id) {
    handleCloseD();
    axios
      .delete(`${BASE_URL}/api/v1/clienttreatment/${id}`, {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
        }
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Uspesno ste obrisali !");

          const updatedRows = rows.filter((row) => row.id !== id);
          setRows(updatedRows);
          setFilteredRows(updatedRows);
        } else {
          console.error('Failed to delete treatment');
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error("Niste obrisali!");

          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error', error.response.data);
          switch (error.response.status) {
            case 400:
              console.error("Error 400: Bad Request");
              break;
            case 402:
              console.error("Error 402: Payment Required");
              break;
            default:
              console.error(`Unknown error occurred: ${error.response.status}`);
              break;
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
      });
  }


  const toggleDataUpdated = () => {
    setDataUpdated(prevState => !prevState);
  };


  //columns in table
  const columns = [
    //  { field: "id", headerName: "ID", flex: 1 },
    {
      field: "client", headerName: "Ime i prezime ", width: isDesktop ? undefined : 100, flex: isDesktop ? 1.5 : undefined,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.value}
        </div>
      ),

      headerAlign: "center", align: "center"
    },



    {
      field: "phone", headerName: "Telefon", width: isDesktop ? undefined : 150, flex: isDesktop ? 2 : undefined,
      headerAlign: "center", align: "center"
    },
    {
      field: "bookingTime", headerName: "Rezervacija", width: isDesktop ? undefined : 100, flex: isDesktop ? 1.2 : undefined,
      headerAlign: "center", align: "center",
      valueGetter: (params) => {
        if (params.row.createdOn && !isNaN(Date.parse(params.row.createdOn))) {
          const creationDate = new Date(params.row.createdOn);
          const formattedDate = format(creationDate, "dd.MM.yyyy");
          return formattedDate;
        }
        return "";
      },
    },
    {
      field: "dateAndTime",
      headerName: "Datum i vreme tretmana",
      headerAlign: "center", align: "center",
      width: isDesktop ? undefined : 240, flex: isDesktop ? 2.7 : undefined,


      valueGetter: (params) => {
        const startTime = params.row.startTime || "";
        const formattedStartTime = format(new Date(startTime), "dd.MM.yyyy HH:mm");
        return formattedStartTime;
      },
    },
    // { field: "price", headerName: "Cena", flex: 1 },
    {
      field: "paid", headerName: "Placeno", width: isDesktop ? undefined : 100, flex: isDesktop ? 1 : undefined,
      headerAlign: "center", align: "center",
    },
    //  { field: "discount", headerName: "Popust", flex: 1 },
    {
      field: "treatmentName", headerName: "Tretman", width: isDesktop ? undefined : 100, flex: isDesktop ? 1.5 : undefined,
      headerAlign: "center", align: "center",
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.value}
        </div>
      ),
    },

    {
      field: "staffName", headerName: "Osoblje", width: isDesktop ? undefined : 100, flex: isDesktop ? 1 : undefined,
      headerAlign: "center", align: "center",
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.value}
        </div>
      ),
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    // },
    // { field: "description", headerName: "Opis", flex: 1 },
    {
      field: "expire",
      headerName: "Rezervacija istice za",
      width: isDesktop ? undefined : 200, flex: isDesktop ? 2 : undefined,
      headerAlign: "center", align: "center",
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.value}
        </div>
      ),
      valueGetter: (params) => {
        if (params.row.createdOn && !isNaN(Date.parse(params.row.createdOn))) {
          const creationDate = new Date(params.row.createdOn);
          return getRemainingTime(creationDate);
        }
        return "";
      },
    },
    {
      field: "action",
      headerName: "Akcije",
      width: isDesktop ? undefined : 100, flex: isDesktop ? 1 : undefined,
      headerAlign: "center", align: "center",
      renderCell: (params) => {
        return (
          <div>
            <Box sx={{ display: "flex" }}>
              <EditButton

                onClick={() => {
                  // console.log(params.row, 'ofaj')
                  const staffId = params.row.staffId;
                  const treatmentId = params.row.treatmentId;
                  const clientTreatmentId = params.row.id;
                  const startTimeEnd = params.row["start/end"] || ""; // Use an empty string as default if the property is undefined
                  const [startTime, endTime] = startTimeEnd.split(" / "); // Perform the split operation
                  // console.log(params.row, "o0sd9f")
                  setSelectedRow({
                    clientId: params.row.clientId,
                    clientTreatmentId: clientTreatmentId,
                    clientTreatmentStatusId: params.row.clientTreatmentStatusId,
                    date: format(new Date(params.row.startTime), "M.dd.yyyy"),
                    startTime: format(new Date(params.row.startTime), "HH:mm"),
                    endTime: format(new Date(params.row.endTime), "HH:mm"),
                    paid: params.row.paid,
                    price: params.row.price,
                    staffId: staffId,
                    staffTreatmentId: params.row.staffTreatmentId,
                    status: params.row.clientTreatmentStatusId,
                    treatment: params.row.treatment,
                    treatmentColor: params.row.treatmentColor,
                    treatmentId: treatmentId,
                    client: params.row.employee,
                  });

                  setOpenUpdateDialog(true);
                }}
              />


              <div>
                <DelButton onClick={() => handleOpen(params.row.id)} />
                <Dialog
                  open={openD}
                  onClose={handleCloseD}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Potvrdi Brisanje "}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Da li ste sigurni da želite da obrišete ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseD} color="primary">
                      Ne
                    </Button>
                    <Button onClick={() => handleDelete(selectedId)} color="primary" autoFocus>
                      Da
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>

              {/* <DelButton onClick={() => handleDelete(params.row.id)} /> */}
            </Box>
          </div>
        );
      },
    },
  ];
  const handleEdit = (rowData) => {
    setSelectedRow(rowData);
    setOpenUpdateDialog(true);
  };



  useEffect(() => {
    if (unpaidDepositTreatments) {

      const updatedRows = unpaidDepositTreatments.map((item) => {
        return {
          // Fill in the properties from item, example:
          id: item.id,
          staffId: item.staffId,
          staffName: item.staffName,
          treatmentId: item.treatmentId,
          employee: item.staffName, // Example property name
          treatment: item.treatmentName, // Example property name
          client: item.clientName, // Example property name
          status: item.clientTreatmentStatusName, // Example property name
          clientId: item.clientId,
          startTime: item.startTime,
          endTime: item.endTime,
          phone: item.clientPhone, // Example property name
          createdOn: item.bookingTime,
          paid: item.paid,
          treatmentName: item.treatmentName,
          price: item.price,
          clientTreatmentStatusId: item.clientTreatmentStatusId,
          // ... other fields as necessary
        };
      });

      const successfulRows = updatedRows.filter(row => row !== null);
      setRows(successfulRows);
    }
  }, [unpaidDepositTreatments]); // Dependency array



  // /clientTreatmentData, token


  useEffect(() => {
    if (search === "") {
      setFilteredRows(rows.filter(row => row.clientTreatmentStatusId == "08db6163-73d3-4fed-802b-25bbf9fa961f"));
    } else {
      const searchTerms = search.toLowerCase().split(' '); // Split the search string into words
      const filteredData = rows.filter((row) =>
        row.clientTreatmentStatusId == "08db6163-73d3-4fed-802b-25bbf9fa961f" && // Add this line to exclude certain IDs
        searchTerms.every(term =>
          (row.client && row.client.toLowerCase().includes(term)) ||
          // (row.surname && row.surname.toLowerCase().includes(term)) ||
          (row.phone && row.phone.toLowerCase().includes(term))
          // (row.name && row.surname && (row.name.toLowerCase() + ' ' + row.surname.toLowerCase()).includes(term))
        )
      );
      setFilteredRows(filteredData);
    }
  }, [search, rows]);



  function DeleteSearch() {
    setSearch("");
  }
  const [open, setOpen] = useState(false);


  const handleButtonClick = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  const CustomNoRowsOverlay = () => {
    const classes = useNoRowsOverlayStyles();
    return (
      <div className={classes.root}>
        <img
          src="/images/adaktar.png"
          alt="Loading"
          className={classes.img}
        />

      </div>
    );
  };


  return (
    <div style={{ height: 600, width: "100%", marginBottom: "120px" }}>


      <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "0px", paddingRight: "20px", paddingTop: "10px" }}>
        {/* <DialogAddPrice open={open} handleClose={handleClose}  /> */}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "50px" }}>
        <TextField
          fullWidth
          size="Normal"
          id="standard-basic"
          label="search"
          variant="standard"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: "50%" }} // Set the desired width here

        />
        {search !== "" && (
          <IconButton aria-label="delete" onClick={DeleteSearch}>
            <ClearIcon />
          </IconButton>
        )}
        <ButtonPdfUnpaid
          onClick={exportPDF}
          style={{
            height: '40px',
            width: '150px',
            marginLeft: matches ? '20px' : '10px', // changes margin based on screen width
          }}
        >
          Export to PDF
        </ButtonPdfUnpaid>

      </Box>

      <StyledDataGrid
        // localeText={{ noRowsLabel: 'Učitava se....' }}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}

        sx={{
          height: 500, // set height in pixels
          border: "none",
          borderRadius: "8px",
          marginTop: "16px",
          paddingX: "40px",
          backgroundColor: "white",
          // marginLeft:"1.5%",
          width: '100%', // Set width to 100% here
          overflowX: 'auto',
        }}
        rows={filteredRows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onRowClick={(params) => {
        }}
      />
      <UpdateDialogScheduler
        open={openUpdateDialog}
        event={selectedRow}
        onClose={() => { setOpenUpdateDialog(false); }}

        toggleDataUpdated={toggleDataUpdated}
      // include onUpdate and fullScreen props if required
      />


    </div>
  );
}
