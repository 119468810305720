import React from "react";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";

import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

//combobutton
import ComboBoxBoje from "../../components/ComboBoxBoje";
import CAdminAgent from "../../components/ComboAdminAgent";
//button
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";
import jwt_decode from "jwt-decode";

const Changepassword = () => {
  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  console.log(isMenuOpen);
  const BASE_URL = 'https://api.adaktarbooking.com';

    // Add state for form fields
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedRole, setSelectedRole] = useState("");

   const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setUserName("");
    setPassword("");
    setConfirmPassword("");
    setPhoneNumber("");
    setSelectedRole("");
  };
  
      // Add a function to handle form submission and make the API call
    const insertUserDB = async () => {
      const userData = {
        firstName,
        lastName,
        email,
        userName,
        password,
        confirmPassword,
        phoneNumber,
       role: selectedRole, // Add the selected role to the userData object

      };
  
      try {
        const token = localStorage.getItem('accessToken'); // get the token from local storage
        const tenant = 'root'; // Define your tenant
  
        const response = await fetch("/api/users", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "tenant": tenant  // Add the tenant to the header
            },
          body: JSON.stringify(userData),
        });
  
        if (response.ok) {
          console.log("User added successfully");
          // Handle success
          resetForm(); // Call the resetForm function here
        } else {
          console.log("Failed to add user");
          // Handle error
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle error
      }
    };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
  };
  const navOnCliShearch = () => {
    navigate("/user/search");
  };

  // get uset wich is logined
  const token = localStorage.getItem('accessToken');
  const decodedToken = jwt_decode(token);
  const fullName = decodedToken.fullName;

  return (
    <>
    
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,

              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> Korisnik</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButtons onClick={navigateONIndex} />

                  <Typography sx={{ fontSize: "15px" }}>
                    - Korisnik - promena sifre
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item md={12}>
                <Typography variant="h6" sx={{ p: 2 }}>
                  Promena šifre - {fullName}
                </Typography>
                <Divider sx={{}} />
              </Grid>
              <Grid container sx={{}}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                    <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Trenutna šifra
                    </Typography>
                    <TextField
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        size="small"
        id="outlined-basic"
        label="Unesi ime korisnika"
        variant="outlined"
        style={{ width: '100%' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
                

                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>

                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Nova šifra
                    </Typography>
                    <TextField
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                      size="small"
                      id="outlined-basic"
                      label="Unesi korisničko ime"
                      variant="outlined"
                      style={{ width: "100%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  

                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="right"
                height={100}
                sx={{
                  gap: 2,
                  mr: 5,
                  "@media (max-width: 347px)": {
                    justifyContent: "center",
                    mr: 0,
                  },
                }}
              >
                <BasicContained />
                <BasicOutlined onClick={insertUserDB}  />
              </Box>
            </Grid>
          </Box>
        </Box> 
    
    </>
  );
};

export default Changepassword;
