import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Scheduling from "./pages/scheduling/scheduling";
import Cnew from "./pages/client/new";
import Csearch from "./pages/client/search";
import Tnew from "./pages/treatment/new";
import Tsearch from "./pages/treatment/search";
import Snew from "./pages/staff/new";
import Ssearch from "./pages/staff/search";
import Unew from "./pages/users/new";
import Usearch from "./pages/users/search";
import Rtearnings from "./pages/report/totalEarnings.js";
import Rttreatment from "./pages/report/totalTreatment";
import Settings from "./pages/settings/settings";
import PreviewClient from "./pages/client/previewC";
import PreviewTreatmen from "./pages/treatment/previewT";
import EditStaf from "./pages/staff/EditStaf";
import EditUser from "./pages/users/EditUser";
import { Backdrop } from "@mui/material";
import Login from "./pages/login/login";
import Changepassword from "./pages/users/changePassword"
import { useSelector, useDispatch } from "react-redux"; //redux
import MainPage from "./pages/mainpage/MainPage";
import { setBasicRole, fetchUnpaidDepositTreatments, fetchClients, fetchTreatments, fetchPreferences, fetchUsers, fetchStaffTreatments, fetchStaff, postClientTreatments, fetchClientTreatmentStatus } from '.././src/redux/action';
import jwtDecode from 'jwt-decode';
import { Navigate } from 'react-router-dom';

import axios from "axios";
import { useNavigate } from 'react-router-dom'; // import useNavigate


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // initialize useNavigate
  const [fetchData, setFetchData] = useState(false);
  const [isPageRefreshed, setIsPageRefreshed] = useState(false);

  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const clients = useSelector(state => state.menu.clients);  // access the data from the state
  const treatments = useSelector(state => state.menu.treatments);  // access the data from the state
  const staffTreatments = useSelector(state => state.menu.staffTreatments);  // access the data from the state
  const staff = useSelector(state => state.menu.staff);  // access the data from the state
  const clientTreatments = useSelector(state => state.menu.clientTreatments);  // access the data from the state
  const users = useSelector(state => state.menu.users);
  const clientTreatmentStatus = useSelector(state => state.menu.clientTreatmentStatus);
  const preferences = useSelector(state => state.menu.preferences);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('accessToken'));
  const logoutTimerRef = useRef();

  function ProtectedRoute({ children }) {
    const isLoggedIn = !!localStorage.getItem('accessToken'); // Provera da li je korisnik ulogovan

    if (!isLoggedIn) {
      // Ako nije ulogovan, preusmeriti na stranicu za prijavu
      return <Navigate to="/login" />;
    }

    return children; // Ako je ulogovan, prikazati traženu komponentu
  }

  const handleLogin = () => {
    setIsLoggedIn(true);
  };


  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(fetchClients());
      dispatch(fetchTreatments());
      dispatch(fetchStaffTreatments());
      dispatch(fetchStaff());
      dispatch(postClientTreatments());
      dispatch(fetchUsers());
      dispatch(fetchClientTreatmentStatus());
      dispatch(fetchPreferences());
      dispatch(fetchUnpaidDepositTreatments());

    }
  }, [isLoggedIn, dispatch]);
  const handleAutoLogout = () => {
    // Logic to handle logout
    setIsLoggedIn(false);
    localStorage.removeItem('accessToken');
    // Redirect to login page or some other action
    navigate('/login');

  };


  // useEffect(() => {
  //   const token = localStorage.getItem('accessToken');

  //   if (token) {
  //     try {

  //       setIsLoggedIn(true);


  //       logoutTimerRef.current = setTimeout(handleAutoLogout, 59.9 * 60 * 1000);

  //     } catch (error) {
  //       console.error("Error decoding the token:", error.message);
  //       // Handle the error, perhaps clear the token or redirect to login
  //       localStorage.removeItem('accessToken');
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     // Cleanup: Clear the timer when the component is unmounted
  //     clearTimeout(logoutTimerRef.current);
  //   };
  // }, []);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     dispatch(postClientTreatments());
  //   }, 10000); // na primer, svakih 10 sekundi

  //   return () => clearInterval(intervalId);
  // }, [dispatch]);

  // User activity record
  const isUserActiveRef = useRef(false);
  const lastMouseMoveTimeRef = useRef(Date.now());

  const BASE_URL = 'https://api.adaktarbooking.com';

  const handleMouseClick = () => {
    isUserActiveRef.current = true;
    lastMouseMoveTimeRef.current = Date.now();
  };

  const handleInactivityCheck = () => {
    const inactiveTime = Date.now() - lastMouseMoveTimeRef.current;
    if (inactiveTime >= 59.8 * 60 * 1000) {
      handleAutoLogout();
    }
  };

  let inactivityTimer;

  const startInactivityTimer = () => {
    inactivityTimer = setInterval(handleInactivityCheck, 1000); // Check every second
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken')
    const token = localStorage.getItem('accessToken')
    if ((refreshToken) && (token && isValidToken(token))) {
      try {
        // Make an API call to refresh the access token using the refresh token
        const response = await fetch(`${BASE_URL}/api/tokens/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'tenant': 'root',
            // Include the refresh token in the request body or headers as needed by your backend
            // Example: 'Authorization': `Bearer ${refreshToken}`
          },
          body: JSON.stringify({
            "token": token,
            "refreshToken": refreshToken
          }),
        });

        if (response.ok) {
          // If the refresh token is valid and the API call succeeds, update the access token
          const data = await response.json();
          const accessToken = data.token; // Assuming your server returns a new access token
          const refreshToken = data.refreshToken; // Assuming your server returns a new access token
          // Update the access token in localStorage or wherever you store it
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
        } else {
          // If the API call fails (e.g., due to an expired refresh token), handle the error
          setIsLoggedIn(false);
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('accessToken');
          navigate('/login');
        }
      }
      catch (error) {

        console.error('Error refreshing token:', error);
        setIsLoggedIn(false);
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        navigate('/login');
      }
    } else {
      setIsLoggedIn(false);
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      navigate('/login');
    }
  };

  useEffect(() => {
    // Event listener for mouse movement
    document.addEventListener('click', handleMouseClick);

    // Start the inactivity timer initially
    startInactivityTimer();

    // Cleanup
    return () => {
      document.removeEventListener('click', handleMouseClick);
      clearInterval(inactivityTimer); // Clear the inactivity timer on cleanup
    };
  }, []);


  useEffect(() => {
    // Fetch the value from localStorage
    const basicRoleFromStorage = localStorage.getItem('basicRolee');
    const basicRole = basicRoleFromStorage ? JSON.parse(basicRoleFromStorage) : null;

    // Dispatch an action to update the Redux state
    dispatch(setBasicRole(basicRole));
  }, [dispatch]);

  // APSOLUTNI PROMASAJ !!!!!

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate('/index');
  //   } else {
  //     navigate('/login');
  //   }
  // }, [isLoggedIn]);

  // APSOLUTNI PROMASAJ !!!!!

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token && isValidToken(token)) {
      setIsLoggedIn(true);
      logoutTimerRef.current = setTimeout(refreshToken, 59.9 * 60 * 1000);

    } else {
      setIsLoggedIn(false);
      localStorage.removeItem('accessToken');
      navigate('/login');
    }
  }, []);

  function isValidToken(token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.exp > Date.now() / 1000;
    } catch (error) {
      return false; // Token nije validan ili je došlo do greške pri dekodiranju
    }
  }


  if (!isLoggedIn) {

    return <Login onLogin={handleLogin} />;
  }
  return (
    <div className="App">
      <ToastContainer />

      isLoggedIn && <Navbar setIsLoggedIn={setIsLoggedIn} />

      <Backdrop
        open={isMenuOpen}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "@media (min-width: 600px)": {
            backgroundColor: "transparent",
          },
        }}
      />
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />

        <Route path="/index" element={<ProtectedRoute><MainPage /></ProtectedRoute>} />
        <Route path="/scheduling" element={<ProtectedRoute><Scheduling isLoggedIn={isLoggedIn} /></ProtectedRoute>} />

        <Route path="/client/new" element={<ProtectedRoute><Cnew /></ProtectedRoute>} />
        <Route path="/client/search" element={<ProtectedRoute><Csearch /></ProtectedRoute>} />
        <Route path="/client/preview/:id" element={<ProtectedRoute><PreviewClient /></ProtectedRoute>} />

        <Route path="/treatment/new" element={<ProtectedRoute><Tnew /></ProtectedRoute>} />
        <Route path="/treatment/search" element={<ProtectedRoute><Tsearch /></ProtectedRoute>} />
        <Route path="/treatment/preview/:id" element={<ProtectedRoute><PreviewTreatmen /></ProtectedRoute>} />

        <Route path="/staff/new" element={<ProtectedRoute><Snew /></ProtectedRoute>} />
        <Route path="/staff/search" element={<ProtectedRoute><Ssearch /></ProtectedRoute>} />
        <Route path="/staff/edit/:id" element={<ProtectedRoute><EditStaf /></ProtectedRoute>} />

        <Route path="/user/new" element={<ProtectedRoute><Unew /></ProtectedRoute>} />
        <Route path="/user/search" element={<ProtectedRoute><Usearch /></ProtectedRoute>} />
        <Route path="/user/edit/:id" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
        <Route path="/user/changepassword" element={<ProtectedRoute><Changepassword /></ProtectedRoute>} />

        <Route path="/report/new" element={<ProtectedRoute><Rtearnings /></ProtectedRoute>} />
        <Route path="/report/search" element={<ProtectedRoute><Rttreatment /></ProtectedRoute>} />
        <Route path="/setting" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
        <Route path="/*" element={<ProtectedRoute><MainPage /></ProtectedRoute>} />
      </Routes>
    </div>
  );
}

export default App;
