import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//Button
import EditButton from "../../components/Datatables/EditButton";
import DelButton from "../../components/Datatables/DelButton"
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear"; //icon for delete search
import TextField from "@mui/material/TextField";
import UpdateDialogScheduler from '../../components/Scheduler/updateDialog/UpdateDialogScheduler ';
import { format } from "date-fns";
import { styled } from "@mui/system";


import { useMediaQuery } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapCellContent: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: 160,
  },

});


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "rgba(237, 237, 237)",
  },
  "& .clear-icon": {
    backgroundColor: "lightgray", // Set your desired color for the ClearIcon here
  },
  "& .MuiDataGrid-row": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));

//import component 
// import DialogAddPrice from "./Dialog/DialogAddPrice"
export default function TreatmentPrice() {


  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);
  const { id } = useParams(); //id from url
  const [treatmentStatuses, setTreatmentStatuses] = React.useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [statusMap, setStatusMap] = useState({});
  const isDesktop = useMediaQuery('(min-width:1300px)');
  const BASE_URL = 'https://api.adaktarbooking.com';
  const [dataUpdated, setDataUpdated] = useState(false);

  const basicRole = useSelector((state) => state.menu.basicRole);
  const classes = useStyles();

  const token = localStorage.getItem('accessToken'); // get the token from local storage
  const tenant = 'root'; // Define your tenant
  //console.log(token,"tokenis")
  //function for deleting row whan click on deletebutton

  useEffect(() => {
    // Fetch the status data
    axios
      .get(`${BASE_URL}/api/v1/clienttreatmentstatus/allstatus`, {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((response) => {
        const statusData = response.data;
        // Create a map of status IDs to status names
        const statusMap = {};
        statusData.forEach((status) => {
          statusMap[status.id] = status.id;
        });
        setStatusMap(statusMap);
      })
      .catch((error) => {
        console.error("Error fetching status data", error);
      });
  }, []);


  const getColumnStatusName = (statusId) => {
    return statusMap[statusId] || "";
  };
  function handleDelete(id) {
    axios
      .delete(`${BASE_URL}/api/v1/clienttreatment/${id}`, {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const updatedRows = rows.filter((row) => row.id !== id);
          setRows(updatedRows);
          setFilteredRows(updatedRows);
        } else {
          console.error('Failed to delete treatment');
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Server responded with an error', error.response.data);
          switch (error.response.status) {
            case 400:
              console.error("Error 400: Bad Request");
              break;
            case 402:
              console.error("Error 402: Payment Required");
              break;
            default:
              console.error(`Unknown error occurred: ${error.response.status}`);
              break;
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
      });
  }



  const toggleDataUpdated = () => {
    setDataUpdated(prev => !prev); // Toggle the current value
  };

  //columns in table
  const columns = [
    //  { field: "id", headerName: "ID", flex: 1 },
    // {
    //   field: "clientName",
    //   headerName: "Client Name",
    //   width: isDesktop ? undefined : 150,
    //   flex: isDesktop ? 1.5 : undefined,
    //   // Add your value getter or renderCell function here
    // },
    // {
    //   field: "username  ",
    //   headerName: "username",
    //   width: isDesktop ? undefined : 150,
    //   flex: isDesktop ? 1.5 : undefined,
    //   // Add your value getter or renderCell function here
    // },
    {
      field: "reservation",
      headerName: "Rezervisano",
      width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
      valueGetter: (params) => {
        if (params.row.startTime && !isNaN(Date.parse(params.row.startTime))) {
          const reservationDate = new Date(params.row.startTime);
          const formattedDate = format(reservationDate, "dd.M.yyyy");
          return formattedDate;
        }
        return "";
      },
    }, {
      field: "start/end",
      headerName: "Pocetak/Kraj",
      width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.value}
        </div>
      ),
      valueGetter: (params) => {
        const startTime = params.row.startTime || "";
        const endTime = params.row.endTime || "";
        const formattedStartTime = format(new Date(startTime), "HH:mm");
        const formattedEndTime = format(new Date(endTime), "HH:mm");
        return `${formattedStartTime} / ${formattedEndTime}`;



      },

    },
    { field: "price", headerName: "Cena", width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined, },
    { field: "paid", headerName: "Placeno", width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined, },
    { field: "discount", headerName: "Popust", width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined, },
    { field: "employee", headerName: "Zaposleni", width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined, },
    {
      field: "treatment", headerName: "Tretman", width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
    }, { field: "description", headerName: "Opis", width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined, },
    {
      field: 'creirao',
      headerName: 'Kreirao',
      width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
      valueGetter: (params) => params.row.createdBy || 'N/A', // Assuming 'createdBy' is a string
    },

    // New column for 'Created On'
    {
      field: 'createdOn',
      headerName: 'Kreirano',
      width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,

      valueGetter: (params) => {
        if (!params.row.createdOn) return 'N/A';
        const date = new Date(params.row.createdOn);
        return format(date, 'dd.MM.yyyy'); // Format the date to 'dd.mm.yyyy'
      },
    },

    // New column for 'Last Modified By'
    {
      field: 'lastModifiedBy',
      headerName: 'Izmenio',
      width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,

      valueGetter: (params) => params.row.lastModifiedBy || 'N/A',
    },

    // New column for 'Last Modified On'
    {
      field: 'lastModifiedOn',
      headerName: 'Izmenjeno',
      width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
      valueGetter: (params) => {
        if (!params.row.lastModifiedOn) return 'N/A';
        const date = new Date(params.row.lastModifiedOn);
        return format(date, 'dd.MM.yyyy'); // Format the date to 'dd.mm.yyyy'
      },
    },
    {
      field: "action",
      headerName: "Akcije",
      width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
      renderCell: (params) => {
        return (
          <div>
            <Box sx={{ display: "flex" }}>
              <EditButton

                onClick={() => {
                  // console.log(params.row, 'ofaj')
                  const staffId = params.row.staffId;
                  const treatmentId = params.row.treatmentId;
                  const clientTreatmentId = params.row.id;
                  const startTimeEnd = params.row["start/end"] || ""; // Use an empty string as default if the property is undefined
                  const [startTime, endTime] = startTimeEnd.split(" / "); // Perform the split operation
                  // console.log(params.row, "o0sd9f")
                  setSelectedRow({
                    clientId: params.row.clientId,
                    clientTreatmentId: clientTreatmentId,
                    clientTreatmentStatusId: params.row.clientTreatmentStatusId,
                    date: format(new Date(params.row.startTime), "dd.M.yyyy"),
                    startTime: format(new Date(params.row.startTime), "HH:mm"),
                    endTime: format(new Date(params.row.endTime), "HH:mm"),
                    paid: params.row.paid,
                    price: params.row.price,
                    staffId: staffId,
                    staffTreatmentId: params.row.staffTreatmentId,
                    status: params.row.clientTreatmentStatusId,
                    treatment: params.row.treatment,
                    treatmentColor: params.row.treatmentColor,
                    treatmentId: treatmentId,
                    client: params.row.employee,
                    description: params.row.description,


                  });

                  setOpenUpdateDialog(true);
                }}
              />



              <DelButton onClick={() => handleDelete(params.row.id)} />
            </Box>
          </div>
        );
      },
    },
  ];
  const hiddenFields = ['creirao', 'createdOn', 'lastModifiedBy', 'lastModifiedOn'];

  const visibleColumns = basicRole
    ? columns.filter(column => !hiddenFields.includes(column.field))
    : columns;

  const handleEdit = (rowData) => {
    setSelectedRow(rowData);
    setOpenUpdateDialog(true);
  };

  //API TAKE DATA FROM DB TREATMENTS PRICE 
  useEffect(() => {
    axios.get(`${BASE_URL}/api/v1/clienttreatment/gettreatments/${id}`, {
      headers: {
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          const updatedRows = await Promise.all(response.data.map(async (item) => {
            let staffName = "Unknown", treatmentName = "Unknown", staffId, treatmentId;
            const startTime = item.startTime || ""; // Use an empty string as default if startTime is undefined
            const endTime = item.endTime || ""; // Use an empty string as default if endTime is undefined
            let createdBy, createdOn, lastModifiedBy, lastModifiedOn;

            try {
              const staffTreatmentInfoResponse = await axios.get(`${BASE_URL}/api/v1/stafftreatment/info/${item.staffTreatmentId}`, {
                headers: {
                  "accept": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
              });
              staffId = staffTreatmentInfoResponse.data.staffId;
              treatmentId = staffTreatmentInfoResponse.data.treatmentId;

              const staffResponse = await axios.get(`${BASE_URL}/api/v1/staff/${staffId}`, {
                headers: {
                  "accept": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
              });

              const treatmentResponse = await axios.get(`${BASE_URL}/api/v1/treatment/${treatmentId}`, {
                headers: {
                  "accept": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
              });

              treatmentName = treatmentResponse.data.title;

              staffName = staffResponse.data.name;
              if (!basicRole) {
                if (treatmentResponse.data.treatmentPrice.createdBy) {

                  const createdByResponse = await axios.get(`${BASE_URL}/api/users/${treatmentResponse.data.treatmentPrice.createdBy}`, {
                    headers: {
                      "accept": "application/json",
                      "Authorization": `Bearer ${token}`,
                    },
                  });
                  createdBy = createdByResponse.data.userName;
                }


                if (treatmentResponse.data.treatmentPrice.lastModifiedBy) {
                  const lastModifiedByResponse = await axios.get(`${BASE_URL}/api/users/${treatmentResponse.data.treatmentPrice.lastModifiedBy}`, {
                    headers: {
                      "accept": "application/json",
                      "Authorization": `Bearer ${token}`,
                    },
                  });
                  lastModifiedBy = lastModifiedByResponse.data.userName;
                }

                createdOn = treatmentResponse.data.treatmentPrice.createdOn;
                lastModifiedOn = treatmentResponse.data.treatmentPrice.lastModifiedOn;
                // createdBy = treatmentResponse.data.treatmentPrice.createdBy;
                // lastModifiedBy = treatmentResponse.data.treatmentPrice.lastModifiedBy;

              }




              // Fetch the status title
              const statusResponse = await axios.get(`${BASE_URL}/api/v1/clienttreatmentstatus/${item.clientTreatmentStatusId}`, {
                headers: {
                  "accept": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
              });

              const statusTitle = statusResponse.data.title;

              return {
                ...item,
                id: item.id,
                staffId: staffId,
                treatmentId: treatmentId,
                employee: staffName,
                treatment: treatmentName,
                status: statusTitle,
                startTime: startTime,
                endTime: endTime,
                createdBy: createdBy,
                createdOn: createdOn,
                lastModifiedBy: lastModifiedBy,
                lastModifiedOn: lastModifiedOn,

              };
            } catch (error) {
              console.error('Error fetching staff or treatment info', error);
            }
          }));

          setRows(updatedRows);
          setFilteredRows(updatedRows);
        } else {
          console.error('Failed to fetch treatments');
        }
      })
      .catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              console.error("Error 400: Bad Request", error.response.data);
              break;
            case 402:
              console.error("Error 402: Payment Required", error.response.data);
              break;
            default:
              console.error(`Unknown error occurred: ${error.response.status}`, error.response.data);
              break;
          }
        } else if (error.request) {
          console.error('The request was made but no response was received', error.request);
        } else {
          console.error('Error', error.message);
        }
      });
  }, [token, id, dataUpdated]);


  //function for search
  //function for search
  useEffect(() => {
    if (search === "") {
      setFilteredRows(rows);
    } else {
      const filteredData = rows.filter((row) =>
        row.reservation?.toLowerCase().includes(search.toLowerCase()) ||
        row.employee?.name?.toLowerCase().includes(search.toLowerCase()) ||
        row.treatment?.toLowerCase().includes(search.toLowerCase()) ||
        row.status?.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredRows(filteredData);
    }
  }, [search, rows]);


  function DeleteSearch() {
    setSearch("");
  }
  const [open, setOpen] = useState(false);


  const handleButtonClick = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div style={{ height: 600, width: "100%", marginBottom: "120px" }}>


      <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "0px", paddingRight: "20px", paddingTop: "10px" }}>
        {/* <DialogAddPrice open={open} handleClose={handleClose}  /> */}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "50px" }}>
        <TextField
          fullWidth
          size="Normal"
          id="standard-basic"
          label="search"
          variant="standard"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: "50%" }}

        />
        {search !== "" && (
          <IconButton aria-label="delete" onClick={DeleteSearch}>
            <ClearIcon />
          </IconButton>
        )}
      </Box>

      <StyledDataGrid
        sx={{
          height: 500,
          border: "none",
          borderRadius: "8px",
          marginTop: "16px",
          paddingX: "40px",
          backgroundColor: "white",
          // marginLeft:"1.5%",
          width: '100%',
          // overflowX: 'auto',
        }}
        rows={filteredRows}
        columns={visibleColumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onRowClick={(params) => {
        }}
      />
      <UpdateDialogScheduler
        open={openUpdateDialog}
        event={selectedRow}
        onClose={() => { setOpenUpdateDialog(false); }}
        toggleDataUpdated={toggleDataUpdated}
      />


    </div>
  );
}
