import * as React from "react";
import Switch from "@mui/material/Switch";

export default function SwitchButton({ checked, onChange }) {
  const [isChecked, setIsChecked] = React.useState(checked);

  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <Switch
      checked={isChecked}
      onChange={handleChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}
