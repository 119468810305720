import { SET_UNPAID_DEPOSIT_TREATMENTS, ADD_NEW_TREATMENTS, SET_BASIC_ROLE, SET_USER_EMAIL, SET_CLIENT_TREATMENTS_SECOND, TOGGLE_MENU, SET_USERS, SET_PREFERENCES, SET_DATA_INSERTED, SET_CLIENTS, SET_TREATMENTS, SET_STAFF_TREATMENTS, SET_STAFF, SET_CLIENT_TREATMENTS, SET_CLIENT_TREATMENT_STATUS } from '../redux/action';


const initialState = {
  isMenuOpen: false,
  isDataInserted: false,
  clients: [],
  treatments: [],
  staffTreatments: [],
  staff: [],
  clientTreatments: [],
  clientTreatmentSecond: [],
  users: [],
  clientTreatmentStatus: [],
  preferences: [],
  basicRole: JSON.parse(localStorage.getItem("basicRoless")),


};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen
      };
    case SET_DATA_INSERTED:
      return {
        ...state,
        isDataInserted: action.payload
      };
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.payload
      };
    case SET_TREATMENTS:
      return {
        ...state,
        treatments: action.payload
      };
    case SET_STAFF_TREATMENTS:
      return {
        ...state,
        staffTreatments: action.payload
      };
    case SET_STAFF:
      return {
        ...state,
        staff: action.payload
      };
    case SET_CLIENT_TREATMENTS:
      return {
        ...state,
        clientTreatments: action.payload
      };
    case SET_CLIENT_TREATMENTS_SECOND:
      return {
        ...state,
        clientTreatmentSecond: action.payload
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_CLIENT_TREATMENT_STATUS:
      return {
        ...state,
        clientTreatmentStatus: action.payload,
      };
    case SET_PREFERENCES:
      return {
        ...state,
        preferences: action.payload,
      };
    case ADD_NEW_TREATMENTS:
      return {
        ...state,
        clientTreatments: [...state.clientTreatments, ...action.payload]
      };

    case SET_BASIC_ROLE:
      return {
        ...state,
        basicRole: action.payload,

      };
      case SET_UNPAID_DEPOSIT_TREATMENTS:
        return {
          ...state,
          unpaidDepositTreatments: action.payload
        };
  

    default:
      return state;
  }
};

export default appReducer;
