import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function ComboBoxBoje() {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      size="small"
      options={color}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Izaberite boju" />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const color = [
  { label: "Braon" },
  { label: "Breskva" },
  { label: "Crvena" },
  { label: "Ljubičasta" },
  { label: "Narandžasta" },
  { label: "Neboplava" },
  { label: "Plava" },
  { label: "Plava" },
  { label: "Plava mint" },
  { label: "Purpurna" },
  { label: "Roze svetlo" },
  { label: "Roze tamno" },
  { label: "Siva" },
  { label: "Teget" },
  { label: "Trula višnja" },
  { label: "Zlatna" },
  { label: "Zelena svetlo" },
  { label: "Žuta" },
];
