import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function BasicOutlined(props) {
  const { onClick } = props;
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Stack spacing={2} direction="row">
     <Button 
        variant="outlined" 
        onClick={handleClick} 
        sx={{ 
          width: "100px",
          backgroundImage: 'linear-gradient(to right, #110604, black)', // Add your gradient here
        color: "black", // Set the text color to black
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          border: "1px solid",
          borderColor: 'white', // adjust the border color as per your needs
          boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)', // add your preferred shadow here
          ':hover': {
            backgroundImage: 'linear-gradient(to right, black, black)',
            borderColor:"blue" // Set your hover gradient here
          },
        }}
      >
        Otkaži
      </Button>
    </Stack>
  );
}
{/* <Button 
variant="outlined" 
onClick={handleClick} 
sx={{ 
  width: "100px",
  backgroundImage: 'linear-gradient(to right, #110604, black)', // Add your gradient here
  color: "transparent",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  border: "1px solid",
  borderColor: 'white', // adjust the border color as per your needs
}}
> */}