import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import axios from "axios";
import dayjs from "dayjs";
import utc from 'dayjs-plugin-utc';
import ReactDOM from 'react-dom';
import ResponsiveDateTimePickers from "../../components/DataPicker";
import ButtonReport from "../../components/ButtonReport";
import IconButtons from "../../components/IconButton";
import ComboBoxBoje from "../../components/ComboBoxBoje";
import CAdminAgent from "../../components/ComboAdminAgent";
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import PersonIcon from "@mui/icons-material/Person";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockIcon from "@mui/icons-material/Lock";
import { Theme } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

dayjs.extend(utc);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    flex: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    width: '15%',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
  },
  cellTretman: {
    width: '30%',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
  },
  cellReduced: {
    width: '15%',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  cellText: {
    fontSize: 12,
  },
});


const Rtearnings = () => {
  const maintitle = "Izveštaj";
  const path = "- Izveštaj - Ukupna zarada ";
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [treatmentCount, setTreatmentCount] = useState(0);

  const [selectedStartDateForDay, setSelectedStartDateForDay] = useState(null);
  const [selectedEndDateForDay, setSelectedEndDateForDay] = useState(null);
  const [totalEarnings, SetTotalEarnings] = useState([]);


  const [selectedStartDateForDaySecond, setSelectedStartDateForDaySecond] = useState(null);
  const [selectedEndDateForDaySecond, setSelectedEndDateForDaySecond] = useState(null);
  const [totalEarningsSecond, SetTotalEarningsSecond] = useState([]);



  const [formattedStartDate, setFormattedStartDate] = useState('');
const [formattedEndDate, setFormattedEndDate] = useState('');

  const [dataForPDF, setDataForPDF] = useState([]);
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [updatedData, setUpdatedData] = useState([]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false); // Dodatno stanje za praćenje generisanja PDF-a
  const navigate = useNavigate();
  const BASE_URL = 'https://api.adaktarbooking.com';

  const data = {
    startTime: '',
    endTime: '',
  };

  const combineDateAndTime = (date, isEndDate = false) => {
    const currentDate = new Date();
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    const formattedTime = dayjs(currentDate).format('THH:mm:ss.SSS[Z]');
    return dayjs.utc(`${formattedDate}${formattedTime}`).local().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  };

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // Similarly, you may want to format dates:
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  function setTimeToLocal(date, hours, minutes, seconds, milliseconds) {
    let newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes || 0);
    newDate.setSeconds(seconds || 0);
    newDate.setMilliseconds(milliseconds || 0);
    return newDate;
  }
  

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleStartDateChangeForDay = (date) => {
    setSelectedStartDateForDay(date);
  };

  const handleEndDateChangeForDay = (date) => {
    setSelectedEndDateForDay(date);
  };


  const handleStartDateChangeForDaySecond = (date) => {
    setSelectedStartDateForDaySecond(date);
  };

  const handleEndDateChangeForDaySecond = (date) => {
    setSelectedEndDateForDaySecond(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
  };

  const handleApiResponse = (response) => {
    setDataForPDF(response.data);
  };

  const generatePDF = () => {

  };

  const calculateTotalPrice = () => {


    let totalPrice = 0;
    for (const item of updatedData) {
      totalPrice += item.price;
    }
    return totalPrice;
  };
  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1; // getMonth() returns 0-11
    const year = d.getFullYear();

    // Pad day and month with leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
}
function FormateBooking(bookingTime) {
  const date = new Date(bookingTime);

  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();
  // console.log( `${day}/${month}/${year}`,'bookingtime222')

  // Format the date as dd/mm/yyyy
  return `${day}/${month}/${year}`;
}

  useEffect(() => {
    if (totalEarnings.length > 0) { // Checks if totalEarnings is not empty
      generatePDFAlltreatment(); // Calls the function to generate the PDF
    }
  }, [totalEarnings]); // This will only re-run if totalEarnings changes

  const printPdfForDay = async () => {
    
   const adjustedStartDate = setTimeToLocal(selectedStartDateForDay, 7, 0, 0, 0);

// Assuming selectedEndDate is the date object for the end date
// Adjust the end date to 23:00:00 local time
    const adjustedEndDate = setTimeToLocal(selectedEndDateForDay, 23, 0, 0, 0);

    //THIS IS FOR TOP DATE ON PDF
    const formatteStartdDates = formatDateToDDMMYYYY(adjustedStartDate);
    const formattedEndDated = formatDateToDDMMYYYY(adjustedEndDate);

        setFormattedStartDate(formatteStartdDates);
    setFormattedEndDate(formattedEndDated);
//END HERE TOP DATE
    // Format the adjusted dates to ISO strings
    const formattedStartDate = adjustedStartDate.toISOString();
    const formattedEndDate = adjustedEndDate.toISOString();



    const token = localStorage.getItem('accessToken');
    // console.log(formattedStartDate,
    //   formattedEndDate, 'datass2ss122')

    const url = 'https://api.adaktarbooking.com/api/v1/clienttreatment/totalearningsontreatments';
    const bodyData = {
      "startTime": moment(formattedStartDate),
      "endTime": moment(formattedEndDate),
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,

        },
        body: JSON.stringify(bodyData) // Convert the JavaScript object to a JSON string
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // Assuming the server responds with JSON-formatted data
      //SetTotalEarnings(data); // I assume this is a state setter

      // Here you might want to transform your data before setting it, depending on the expected structure:
      const transformedData = data.map(item => ({
        bookingDate: FormateBooking(item.startTime), // Convert ISO string to dd/mm/yyyy
        clientName: item.clientName,
        clientTreatmentStatus: item.clientTreatmentStatus,
        endTime: formatTime(item.endTime), // Convert ISO string to HH:MM
        paid: item.paid ? 'Yes' : 'No', // Assuming paid is now a boolean
        price: item.price,
        staffName: item.staffName,
        startTime: formatTime(item.startTime), // Convert ISO string to HH:MM
        treatmentName: item.treatmentName,

        // Additional fields from your new API structure
        id: item.id,
        description: item.description,
        therapy: item.therapy,
        controlDays: item.controlDays,
        discount: item.discount,
        clientId: item.clientId,
        clientTreatmentStatusId: item.clientTreatmentStatusId,
        staffTreatmentId: item.staffTreatmentId,


      }));

      SetTotalEarnings(transformedData); // Assuming you have a state setter for this
      //  generatePDFAlltreatment(); // Call the PDF generation function

    } catch (error) {
      console.error('There was an error with the fetch operation:', error);
    }
  };

  const totalPrice = totalEarnings.reduce((sum, item) => sum + parseFloat(item.price), 0);

  const generatePDFAlltreatment = () => {
    const win = window.open('', '_blank');
    win.document.write('<html><head><title>PDF Viewer</title></head><body style="margin: 0;"><div id="root"></div></body></html>');
    win.document.close();

    ReactDOM.render(
      <PDFViewer width="100%" height="100%">
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
              <Text style={{ fontSize: 16, textAlign: 'center' }}> {/* Adjust style as needed */}
                Period: {formattedStartDate} - {formattedEndDate}
              </Text>
            <View style={styles.table}>
              
              <View style={styles.row}>
                {/* Update the headers to match the fields */}
                {['Rezervacija', ' klijent', 'Tretman', 'Status ', 'Poceo', 'Zavrsio', 'Osoblje', 'Cena', 'Placeno'].map((header) => (
                  <View style={styles.cell} key={header}>
                    <Text style={styles.headerText}>{header}</Text>
                    
                  </View>
                ))}
              </View>


              {/* Map over the totalEarnings array to create rows for each item */}
              {totalEarnings.map((item, rowIndex) => (
                <View style={styles.row} key={rowIndex}>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.bookingDate}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.clientName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.treatmentName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.clientTreatmentStatus}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.startTime}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.endTime}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.staffName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.price}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.paid ? 'Da' : 'Ne'}</Text>
                  </View>
                </View>
                
              ))}
              
               <View style={styles.row}>
              <View style={styles.cell}>
                <Text style={styles.headerText}>Ukupno:</Text>
              </View>
              <View style={{ ...styles.cell, ...styles.totalPriceCell }}>
                <Text style={styles.cellText}>{totalPrice.toFixed(2)}</Text>
              </View>
              {/* Empty cells to fill the space */}
              <View style={styles.cell} />
              <View style={styles.cell} />
              <View style={styles.cell} />
              <View style={styles.cell} />
              <View style={styles.cell} />
              <View style={styles.cell} />
              <View style={styles.cell} />
            </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>,
      win.document.getElementById('root')
    );
  };

  //LOGIC FOR SECOND REPORT 

  useEffect(() => {
    if (totalEarningsSecond.length > 0) { // Checks if totalEarnings is not empty
      generatePDFAlltreatmentSecond(); // Calls the function to generate the PDF
    }
  }, [totalEarningsSecond]); // This will only re-run if totalEarnings changes

  const printPdfForDaySecond = async () => {
   const adjustedStartDate = setTimeToLocal(selectedStartDateForDaySecond, 7, 0, 0, 0);

// Assuming selectedEndDate is the date object for the end date
// Adjust the end date to 23:00:00 local time
    const adjustedEndDate = setTimeToLocal(selectedEndDateForDaySecond, 23, 0, 0, 0);


    //DATA FOR TOP OF PDF 
    const formattedEndDates = formatDateToDDMMYYYY(adjustedStartDate);
    const formattedEndDated = formatDateToDDMMYYYY(adjustedEndDate);

    setFormattedStartDate(formattedEndDates);
    setFormattedEndDate(formattedEndDated);
    //DATA FOR TOP OF PDF 

    // Format the adjusted dates to ISO strings
    const formattedStartDate = adjustedStartDate.toISOString();
    const formattedEndDate = adjustedEndDate.toISOString();



    const token = localStorage.getItem('accessToken');
    // console.log(formattedStartDate,
    //   formattedEndDate, 'datass2ss122')
    const url = 'https://api.adaktarbooking.com/api/v1/clienttreatment/allupdatedtreatments';
    const bodyData = {
      "startTime":moment(formattedStartDate) ,
      "endTime":  moment(formattedEndDate),
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${token}`,

        },
        body: JSON.stringify(bodyData) // Convert the JavaScript object to a JSON string
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json(); // Assuming the server responds with JSON-formatted data
      //SetTotalEarnings(data); // I assume this is a state setter

      // Here you might want to transform your data before setting it, depending on the expected structure:
      const transformedData = data.map(item => ({
        startTime: formatTime(item.startTime), // Convert ISO string to HH:MM
        endTime: formatTime(item.endTime), // Convert ISO string to HH:MM
        price: item.price,
        paid: item.paid === 1 ? 'Yes' : 'No', // Assuming paid is 1 for true and 0 for false
        description: item.description,
        bookingDate: FormateBooking(item.startTime), // Convert ISO string to dd/mm/yyyy
        clientName: item.clientName,
        staffName: item.staffName,
        treatmentName: item.treatmentName,
        clientTreatmentStatus: item.clientTreatmentStatus,

        createdBy: item.createdBy,
        createdOn: formatDate(item.createdOn),
        lastModifiedBy: item.lastModifiedBy,
        lastModifiedOn: formatDate(item.lastModifiedOn),
      }));

      SetTotalEarningsSecond(transformedData); // Assuming you have a state setter for this
      //  generatePDFAlltreatment(); // Call the PDF generation function

    } catch (error) {
      console.error('There was an error with the fetch operation:', error);
    }
  };


  const generatePDFAlltreatmentSecond = () => {
    const win = window.open('', '_blank');
    win.document.write('<html><head><title>PDF Viewer</title></head><body style="margin: 0;"><div id="root"></div></body></html>');
    win.document.close();

    ReactDOM.render(
      <PDFViewer width="100%" height="100%">
        <Document>
          <Page size="A4" orientation="landscape" style={styles.page}>
          <Text style={{ fontSize: 16, textAlign: 'center' }}> {/* Adjust style as needed */}
                Period: {formattedStartDate} - {formattedEndDate}
              </Text>

            <View style={styles.table}>
              <View style={styles.row}>
                {/* Update the headers to match the fields */}
                {['Rezervacija',
                  'Klijent',
                  'Tretmani',
                  'status',
                  'Pocetak',
                  'Zavrsetak',
                  'osoblje',
                  'Cena',
                  'Placeno', 'kreirao', 'datum', 'Izmenio', 'Datum'].map((header) => (
                    <View style={styles.cell} key={header}>
                      <Text style={styles.headerText}>{header}</Text>
                    </View>
                  ))}
              </View>


              {/* Map over the totalEarnings array to create rows for each item */}
              {totalEarningsSecond.map((item, rowIndex) => (
                <View style={styles.row} key={rowIndex}>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.bookingDate}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.clientName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.treatmentName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.clientTreatmentStatus}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.startTime}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.endTime}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.staffName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.price}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.paid}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.createdBy}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.createdOn}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.lastModifiedBy}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.lastModifiedOn}</Text>
                  </View>

                </View>
              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>,
      win.document.getElementById('root')
    );

  };



  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ ml: isMenuOpen ? 33 : 8, pt: 8, pl: 2, pr: 2, "@media (max-width: 600px)": { ml: 0 } }}>
          <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
            <Box>
              <Typography sx={{ fontSize: "25px" }}> {maintitle}</Typography>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                <IconButtons onClick={navigateONIndex} />
                <Typography sx={{ fontSize: "15px" }}>{path}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid sx={{
            bgcolor: "white", width: "100%", mt: 10, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", borderRadius: '16px',
          }}>
            <Grid item md={12} pt="5px">
              <Typography variant="h6" sx={{ p: 2 }}>Ukupna zarada </Typography>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>Datum od</Typography>
                  <ResponsiveDateTimePickers
                    selectedDate={selectedStartDateForDay}
                    handleDateChange={handleStartDateChangeForDay}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>Datum do</Typography>
                  <ResponsiveDateTimePickers
                    size="small"
                    selectedDate={selectedEndDateForDay}
                    handleDateChange={handleEndDateChangeForDay}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="right"
              height={100}
              sx={{ gap: 2, mr: 5, "@media (max-width: 347px)": { justifyContent: "center", mr: 0 } }}
            >
              <ButtonReport onClick={printPdfForDay} />
            </Box>
          </Grid>
          {/* second report  */}
          <Grid sx={{
            bgcolor: "white", width: "100%", mt: 10, boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", borderRadius: '16px',
          }}>
            <Grid item md={12} pt="5px">
              <Typography variant="h6" sx={{ p: 2 }}>Ko je izvrsio izmene </Typography>
            </Grid>
            <Divider />
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>Datum od</Typography>
                  <ResponsiveDateTimePickers
                    selectedDate={selectedStartDateForDay}
                    handleDateChange={handleStartDateChangeForDaySecond}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box p={2}>
                  <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>Datum do</Typography>
                  <ResponsiveDateTimePickers
                    size="small"
                    selectedDate={selectedEndDateForDay}
                    handleDateChange={handleEndDateChangeForDaySecond}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="right"
              height={100}
              sx={{ gap: 2, mr: 5, "@media (max-width: 347px)": { justifyContent: "center", mr: 0 } }}
            >
              <ButtonReport onClick={printPdfForDaySecond} />
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Rtearnings;
