import React from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";

import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

//import for radio group
import UseRadioGroup from "../../components/Rbutton";

//import datapicker
import BasicDateTimePicker from "../../components/DataPicker";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

//Buttons
import UploadButtons from "../../components/UploadButton"; //uploadButton
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";

//import commponent
import DataTableStaff from "../../components/Datatables/staff/DataStaff";

const Ssearch = () => {
  const maintitle = "Osoblje";
  const path = "- Osoblje - Pretraga";
  const subtitle = "Osoblje";

  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
  };
  const navOnCliShearch = () => {
    navigate("/client/search");
  };
  return (
    <>
     
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,

              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1   }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> {maintitle}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButtons onClick={navigateONIndex} />

                  <Typography sx={{ fontSize: "15px" }}>{path}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: '16px',

              }}
            >
              <Grid item md={12} sx={{borderRadius: 2, }}>
                <Typography variant="h6" sx={{ pt: 3, pl: 3 }}>
                  {subtitle}
                </Typography>
                {/* <Divider sx={{}} /> */}
              </Grid>
              <Grid container sx={{}}>
                <Grid item xs={12} sm={12}>
                  <Box p={2}>
                    <DataTableStaff />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="right"
                    height={50}
                  ></Box>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                    <Box p={2}></Box>
                  </Grid> */}
              </Grid>
              <Divider />
            </Grid>
          </Box>
        </Box>
    
    </>
  );
};

export default Ssearch;
