import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Route,Routes } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './redux/root.reducer'
import store from './redux/root.reducer'

//const store = createStore (rootReducer);


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
         <App/>
      </Provider>
    
    </BrowserRouter> 
  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals();
