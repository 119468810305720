import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useState, useContext  } from "react";
import { useParams } from "react-router-dom";
import Chip from '@material-ui/core/Chip'; // import Chip
import axios from "axios";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDataInserted } from '../../redux/action';  // import the action
import { Button } from "@mui/material";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ComboBoxEditTreatment({ treatmentStaff, onChange, onSelectedOptionsChange }) {

  const [treatments, setTreatments] = useState([]); // Create a state variable for treatments
const [selectedTreatments, setSelectedTreatments] = useState([]);
const [selectedTreatmentOptions, setSelectedTreatmentOptions] = useState([]);
const [isNewOptionAdded, setIsNewOptionAdded] = useState(false);
const dispatch = useDispatch();

const isDataInserted = useSelector((state) => state.menu.isDataInserted); // get the value from Redux store
const BASE_URL = 'https://api.adaktarbooking.com';



  const { id } = useParams();  // Extract staff ID from URL parameters
  const [open, setOpen] = useState(false);  // Add a state variable for open state
  const handleDropdownClose = (event, reason) => {
    if (reason === 'toggleInput') {
      setOpen(false);
    }
  };
  
// useEffect(() => {
  
//   const assignNewTreatmentToAllStaff = async () => {
//     if (!isDataInserted) {
//       return;
//     }
  
//     const token = localStorage.getItem("accessToken");
  
//     // Get all staff
//     const staffResponse = await fetch("${BASE_URL}/api/v1/staff/allstaff", {
//       method: "GET",
//       headers: {
//         accept: "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     });
  
//     const staffData = await staffResponse.json();
  
//     // Get the id of the new treatment
//     const newTreatment = treatments.find((treatment) => !selectedTreatments.includes(treatment.id));
//     if (!newTreatment) {
//       console.error("No new treatment found");
//       return;
//     }
  
//     // Assign the new treatment to each staff
//     for (const staff of staffData) {
//       await axios.post(`${BASE_URL}/api/v1/stafftreatment/insert`, 
//         {
//           active: true, 
//           color: "string", 
//           treatmentId: newTreatment.id, 
//           staffId: staff.id
//         },
//         {
//           headers: {
//             "accept": "application/json",
//             "Authorization": `Bearer ${token}`,
//           },
//         }
//       );
//     }

//     // Once we're done with the process, set isDataInserted to false
//     dispatch(setDataInserted(false));
//   };
  
//   assignNewTreatmentToAllStaff();
// }, [isDataInserted]);


useEffect(() => {
  const fetchStaffDetails = async () => {
    const token = localStorage.getItem("accessToken");
    const apiUrlStaff = `${BASE_URL}/api/v1/staff/${id}`;
    const response = await fetch(apiUrlStaff, {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    //console.log(data,"combo alllllll")

    const staffTreatmentIds = data.staffTreatments.map(
      (treatment) => treatment.treatmentId
    );
    setSelectedTreatments(staffTreatmentIds);
    
  };

  fetchStaffDetails();
}, [id]);

useEffect(() => {
  const apiUrlTreatments =
    `${BASE_URL}/api/v1/treatment/alltretmans`;
  const token = localStorage.getItem("accessToken");

  fetch(apiUrlTreatments, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      const treatmentData = data.map((item) => {
        const isSelected = selectedTreatments.includes(item.id);
        return { id: item.id, title: item.title, selected: isSelected };
      });

      setTreatments(treatmentData);
    })
    .catch((error) => console.error("Error fetching treatments:", error));
}, [selectedTreatments]);

const handleTreatmentChange = async (event, value) => {
  const selectedTreatmentIds = value ? value.map((treatment) => treatment.id) : [];

  const token = localStorage.getItem("accessToken");

  // Find newly selected treatments
  const newlySelectedTreatments = value.filter(treatment => !selectedTreatments.includes(treatment.id));
  
  // Find deselected treatments
  const deselectedTreatments = selectedTreatments.filter(st => !selectedTreatmentIds.includes(st));
  
  // Insert newly selected treatments
  for (const treatment of newlySelectedTreatments) {
    await axios.post(`${BASE_URL}/api/v1/stafftreatment/insert`, 
      {
        active: true, 
        color: "string", 
        treatmentId: treatment.id, 
        staffId: id
      },
      {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    );
  }
  
  // Delete deselected treatments

  for (const treatmentId of deselectedTreatments) {
    const apiUrlStaff = `${BASE_URL}/api/v1/staff/${id}`;
    const staffDetailsResponse = await fetch(apiUrlStaff, {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const staffDetails = await staffDetailsResponse.json();
    
    // Get the staffTreatment id that corresponds to the deselected treatment id
    const staffTreatmentId = staffDetails.staffTreatments.find(treatment => treatment.treatmentId === treatmentId).id;
    
    await axios.delete(`${BASE_URL}/api/v1/stafftreatment/${staffTreatmentId}`, {
      headers: {
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });
}

  
  const updatedTreatments = treatments.map(treatment => {
    if (selectedTreatmentIds.includes(treatment.id)) {
      return { 
        ...treatment, 
        selected: true 
      };
    } else {
      return { 
        ...treatment, 
        selected: false 
      };
    }
  });

  const updatedSelectedTreatmentOptions = updatedTreatments.filter(treatment => treatment.selected);
  setSelectedTreatmentOptions(updatedSelectedTreatmentOptions);

  setTreatments(updatedTreatments);
  setSelectedTreatments(selectedTreatmentIds);
  const customEvent = {
    target: { value: selectedTreatmentIds },
  };
  onChange(customEvent);
};

const selectAllTreatments = async () => {
  const token = localStorage.getItem("accessToken");

  // Insert all treatments
  for (const treatment of treatments) {
    if (!treatment.selected) {
      await axios.post(`${BASE_URL}/api/v1/stafftreatment/insert`,
        {
          active: true, 
          color: "string", 
          treatmentId: treatment.id, 
          staffId: id
        },
        {
          headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );
    }
  }

  setSelectedTreatments(treatments.map((treatment) => treatment.id));
  const updatedTreatments = treatments.map(treatment => ({...treatment, selected: true}));

  setTreatments(updatedTreatments);
  setSelectedTreatmentOptions(updatedTreatments);
  const customEvent = {
    target: { value: treatments.map((treatment) => treatment.id) },
  };
  onChange(customEvent);
};


//console.log(selectedTreatmentOptions,"ovde ");


useEffect(() => {
  // Call the function passed as a prop
  onSelectedOptionsChange(selectedTreatmentOptions);
}, [selectedTreatmentOptions]);


return (
  
  <div>
  <Autocomplete
    multiple // Adding 'multiple' prop for multiple selection
    blurOnSelect="mouse"
    open={open}
    onClose={handleDropdownClose}
    onOpen={() => setOpen(true)}

    id="checkboxes-tags-demo"
    size="small"
    options={treatments}
    onChange={handleTreatmentChange}
    value={treatments.filter((option) => option.selected)} // Filter the treatments array to get the selected options
    getOptionLabel={(option) => option.title}
    renderOption={(props, option) => (
      <li {...props}>
        <Checkbox style={{ marginRight: 8 }} checked={option.selected} />
        {option.title}
      </li>
    )}
    style={{ width: " 100%" }}
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip variant="outlined" label={option.title} {...getTagProps({ index })} />
      ))
    }
    renderInput={(params) => <TextField {...params} placeholder="Izaberite tretman" />}
  />
  <Button sx={{mt:2}} variant="contained" color="primary" onClick={selectAllTreatments}>
    selektuj sve 
  </Button>
</div>

);
}

// // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// const treatment = [
//   { title: "Obrve 200" },
//   { title: "Obrve 200" },
//   { title: "Obrve 200" },
//   { title: "Obrve 250" },
//   { title: "Obrve 300" },
//   { title: "Obrve 350" },
//   { title: "Obrve 400" },
//   { title: "Obrve 450" },
//   { title: "Obrve 500" },
//   { title: "Obrve 550" },
//   { title: "Obrve 600" },
//   { title: "Obrve 650" },
//   { title: "Obrve 700" },
//   { title: "Obrve 750" },
//   { title: "Obrve 800" },
//   { title: "Obrve 850" },
//   { title: "Obrve 900" },
//   { title: "Obrve 950" },
//   { title: "Obrve 1000" },
//   { title: "Diamond lips 600e" },
//   { title: "Dokvalifikacija 1 dan" },
//   { title: "Osvezavanje obrva 100" },
//   { title: "Osvezavanje obrva 150" },
//   { title: "Osvezavanje obrva 200" },
//   { title: "Ajlajner 400" },
//   { title: "Ajlajner 600e" },
//   { title: "Korekcija 50" },
//   { title: "Laser 100" },
//   { title: "Laser 50" },
//   { title: "Konsultacije 10e" },
//   { title: "Konsultacije 20e" },
//   { title: "PRP 100" },
//   { title: "Mezoterapija 50" },
//   { title: "Mezoniti" },
//   { title: "Hijaluron" },
//   { title: "PRP 200" },
//   { title: "Korekcija 100" },
//   { title: "Mezoterapija 60" },
//   { title: "Mezoterapija 70" },
//   { title: "Hijaluron 2" },
//   { title: "Hijaluron 3" },
//   { title: "Hijaluron 4" },
//   { title: "PRP 150" },
//   { title: "Osvezavanje 175e" },
//   { title: "Ind obuka 1" },
//   { title: "Ind obuka 2" },
//   { title: "Ind obuka 3" },
//   { title: "Kontrola Master" },
//   { title: "Grup/obuka 1" },
//   { title: "Grup/obuka 2" },
//   { title: "Grup/obuka 3" },
//   { title: "Usavrsavanje 1 dan" },
//   { title: "Removel" },
//   { title: "Osvezavanje obrva 250" },
//   { title: "Korekcija usana 100" },
//   { title: "Dokvalifikacija usne 1 dan" },
//   { title: "Korekcija Ajlajnera 100e" },
//   { title: "Dokvalifikacija ajlajner 1 dan" },
//   { title: "Osvezavanje usana" },
//   { title: "Cupanje obrva (1000 din)" },
//   { title: "Kontrola Adam" },
//   { title: "Laser kod Adama (50)" },
//   { title: "Laser kod Adama (100)" },
//   { title: "Osvezavanje usana 400e" },
//   { title: "Farbanje obrva" },
//   { title: "Kontrola dr. Marko" },
//   { title: "Master class" },
//   { title: "Edukacija YUGOTOUR" },
//   { title: "Master class YUGO TOUR" },
//   { title: "Bazni Yugo tour" },
//   { title: "laser 200" },
//   { title: "Brow lift" },
//   { title: "Lash lift" },
//   { title: "Obrve-osvezavanje 300e" },
//   { title: "Korekcija Pp 100e" },
//   { title: "Laser 100e novi" },
//   { title: "Obrve - osvezavanje 1000e" },
//   { title: "Osvezavanje 550e" },
//   { title: "Obrve 1100e" },
//   { title: "Obrve 1200e" },
//   { title: "Obrve 1300e" },
//   { title: "Obrve 1400e" },
//   { title: "Obrve 1500e" },
//   { title: "Obrve 1600e" },
//   { title: "Korekcija 200e" },
//   { title: "Osvezavanje 550e" },
//   { title: "Osvezavanje 600e" },
//   { title: "Osvezavanje 650e" },
//   { title: "Osvezavanje 700e" },
//   { title: "Osvezavanje 750e" },
//   { title: "Osvezavanje 1000e" },
//   { title: "Diamond lips 1000e" },
//   { title: "Ajlajner 1000e" },
//   { title: "Korekcija ajlajnera 200e" },
// ];
