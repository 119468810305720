import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ComboBoxTreatment(props) {
  const [treatments, setTreatments] = useState([]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);

  useEffect(() => {
    const apiUrl = 'https://api.adaktarbooking.com/api/v1/treatment/alltretmans';
    const token = localStorage.getItem('accessToken'); 

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'accept': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const treatmentData = data.map((item) => ({ id: item.id, title: item.title }));
        setTreatments(treatmentData);
        setSelectedTreatments(treatmentData);
      })
      .catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }, []);

  const handleTreatmentChange = (event, values) => {
    const selectedTreatmentIds = values.map(value => value.id);
    const customEvent = {
      target: { value: selectedTreatmentIds },
    }; 
    props.onChange(customEvent);
    setSelectedTreatments(values);
  };

  const selectAll = () => {
    setSelectedTreatments(treatments);
    const customEvent = {
      target: { value: treatments.map(treatment => treatment.id) },
    }; 
    props.onChange(customEvent);
  };

  const deselectAll = () => {
    setSelectedTreatments([]);
    const customEvent = {
      target: { value: [] },
    }; 
    props.onChange(customEvent);
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        size="small"
        options={treatments}
        value={selectedTreatments}
        disableCloseOnSelect
        onChange={handleTreatmentChange}
        getOptionLabel={(option) => option.title}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )}
        style={{ width: " 100%" }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Izaberite tretman" />
        )}
      /> 
 <Box sx={{ paddingTop: "10px" }}>
    <Button 
      variant="contained" 
      color="primary" 
      onClick={selectAll} 
      sx={{ marginRight: "2px", width: "200px", height: "40px" }}
    >
      Potvrdi  
    </Button>
    <Button 
      variant="contained" 
      color="secondary" 
      onClick={deselectAll} 
      sx={{ marginRight: "2px", width: "200px", height: "40px" }}
    >
      Otkaži 
    </Button>
  </Box>
    </div>
  );
}


// // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// const treatment = [
//   { title: "Obrve 200" },
//   { title: "Obrve 200" },
//   { title: "Obrve 200" },
//   { title: "Obrve 250" },
//   { title: "Obrve 300" },
//   { title: "Obrve 350" },
//   { title: "Obrve 400" },
//   { title: "Obrve 450" },
//   { title: "Obrve 500" },
//   { title: "Obrve 550" },
//   { title: "Obrve 600" },
//   { title: "Obrve 650" },
//   { title: "Obrve 700" },
//   { title: "Obrve 750" },
//   { title: "Obrve 800" },
//   { title: "Obrve 850" },
//   { title: "Obrve 900" },
//   { title: "Obrve 950" },
//   { title: "Obrve 1000" },
//   { title: "Diamond lips 600e" },
//   { title: "Dokvalifikacija 1 dan" },
//   { title: "Osvezavanje obrva 100" },
//   { title: "Osvezavanje obrva 150" },
//   { title: "Osvezavanje obrva 200" },
//   { title: "Ajlajner 400" },
//   { title: "Ajlajner 600e" },
//   { title: "Korekcija 50" },
//   { title: "Laser 100" },
//   { title: "Laser 50" },
//   { title: "Konsultacije 10e" },
//   { title: "Konsultacije 20e" },
//   { title: "PRP 100" },
//   { title: "Mezoterapija 50" },
//   { title: "Mezoniti" },
//   { title: "Hijaluron" },
//   { title: "PRP 200" },
//   { title: "Korekcija 100" },
//   { title: "Mezoterapija 60" },
//   { title: "Mezoterapija 70" },
//   { title: "Hijaluron 2" },
//   { title: "Hijaluron 3" },
//   { title: "Hijaluron 4" },
//   { title: "PRP 150" },
//   { title: "Osvezavanje 175e" },
//   { title: "Ind obuka 1" },
//   { title: "Ind obuka 2" },
//   { title: "Ind obuka 3" },
//   { title: "Kontrola Master" },
//   { title: "Grup/obuka 1" },
//   { title: "Grup/obuka 2" },
//   { title: "Grup/obuka 3" },
//   { title: "Usavrsavanje 1 dan" },
//   { title: "Removel" },
//   { title: "Osvezavanje obrva 250" },
//   { title: "Korekcija usana 100" },
//   { title: "Dokvalifikacija usne 1 dan" },
//   { title: "Korekcija Ajlajnera 100e" },
//   { title: "Dokvalifikacija ajlajner 1 dan" },
//   { title: "Osvezavanje usana" },
//   { title: "Cupanje obrva (1000 din)" },
//   { title: "Kontrola Adam" },
//   { title: "Laser kod Adama (50)" },
//   { title: "Laser kod Adama (100)" },
//   { title: "Osvezavanje usana 400e" },
//   { title: "Farbanje obrva" },
//   { title: "Kontrola dr. Marko" },
//   { title: "Master class" },
//   { title: "Edukacija YUGOTOUR" },
//   { title: "Master class YUGO TOUR" },
//   { title: "Bazni Yugo tour" },
//   { title: "laser 200" },
//   { title: "Brow lift" },
//   { title: "Lash lift" },
//   { title: "Obrve-osvezavanje 300e" },
//   { title: "Korekcija Pp 100e" },
//   { title: "Laser 100e novi" },
//   { title: "Obrve - osvezavanje 1000e" },
//   { title: "Osvezavanje 550e" },
//   { title: "Obrve 1100e" },
//   { title: "Obrve 1200e" },
//   { title: "Obrve 1300e" },
//   { title: "Obrve 1400e" },
//   { title: "Obrve 1500e" },
//   { title: "Obrve 1600e" },
//   { title: "Korekcija 200e" },
//   { title: "Osvezavanje 550e" },
//   { title: "Osvezavanje 600e" },
//   { title: "Osvezavanje 650e" },
//   { title: "Osvezavanje 700e" },
//   { title: "Osvezavanje 750e" },
//   { title: "Osvezavanje 1000e" },
//   { title: "Diamond lips 1000e" },
//   { title: "Ajlajner 1000e" },
//   { title: "Korekcija ajlajnera 200e" },
// ];
