import * as React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme, useMediaQuery } from '@mui/material';

export default function DelButton(props) {
  const { onClick, size } = props;  // Add the size here
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const smallSizePx = 12; 
  const largeSizePx = 18; 
  const iconSize = isSmallScreen ? smallSizePx : largeSizePx;
  const smallSizePd = 4; 
  const largeSizePd = 3; 
  const iconpadding = isSmallScreen ? smallSizePd : largeSizePd;
    

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
    <IconButton
      aria-label="delete"
      size={size}
      onClick={handleClick}
      style={{ color: "red", padding: `${iconpadding}px` }}
      >
      <DeleteIcon style={{ fontSize: iconSize }} />
    </IconButton>
  </Stack>

  );
}
