import * as React from "react";


import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { NavLink, useParams } from "react-router-dom";
//Button
import EditButton from "../EditButton";
import DelButton from "../DelButton";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear"; //icon for delete search
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux"; //redux

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "rgba(237, 237, 237)",
  },
  "& .clear-icon": {
    backgroundColor: "lightgray",
  },
  "& .MuiDataGrid-row": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  "& .MuiDataGrid-cell": {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
}));


const useStyles = makeStyles({
  wrapCellContent: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: 160,
  },
  
});

export default function DataTableStaff() {
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);
  const isDesktop = useMediaQuery('(min-width:1000px)');
  const classes = useStyles();
  const staff = useSelector(state => state.menu.staff);  // access the data from the state
  const navigate = useNavigate();
  const BASE_URL = 'https://api.adaktarbooking.com';
  const basicRole = useSelector((state) => state.menu.basicRole); 

  const token = localStorage.getItem('accessToken'); 
  //function for deleting row whan click on deletebutton
  function handleDelete(id) {
    axios
        .delete(`${BASE_URL}/api/v1/staff/${id}`, {
          headers: {
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        })
        .then(() => {
            const updatedRows = rows.filter((row) => row.id !== id);
            setRows(updatedRows);
            setFilteredRows(updatedRows);
        })
        .catch((error) => {
            console.log(error);
        });
}

const navigateOnCreateCard = () => {
  navigate("/staff/new"); // Replace '/desired-route' with your actual route.
}
  //columns in table
  const columns = [
    { field: "name", headerName: "Ime", width: 300, headerAlign: "center", align: "center", width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined, },
    { field: "surname", headerName: "Prezime", width: 300, headerAlign: "center", align: "center", width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined, },
    { field: "description", headerName: "Opis", width: 300, headerAlign: "center", align: "center", width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
       renderCell: (params) => (
      <div className={classes.wrapCellContent}>
        {params.value}
      </div>
    ),},
    { field: "percentageIncome", headerName: "Procenat zarade", width:200, headerAlign: "center", align: "center", width: isDesktop ? undefined : 150, flex: isDesktop ? 2 : undefined, },
    { field: "active", headerName: "Aktivan", width: 300, headerAlign: "center", align: "center", width: isDesktop ? undefined : 100, flex: isDesktop ? 1 : undefined, },
    {
      field: "action",
      headerName: "Akcije",
      width: 300,
      headerAlign: "center",
      align: "center", width: isDesktop ? undefined : 100, flex: isDesktop ? 1 : undefined,
      renderCell: (params) => (
        <div>
          <Box sx={{ display: "flex" }}>
            <NavLink to={`/staff/edit/${params.row.id}`}>
              <EditButton />
            </NavLink>
            {!basicRole && (
            <DelButton onClick={() => handleDelete(params.row.id)} />
            )}
          </Box>
        </div>
      ),
    },
  ];

  //function get data and put data in state


  
                   

  // React.useEffect(() => {
  //   axios.get("${BASE_URL}/api/v1/staff/allstaff", {

  //     headers: {
  //       "accept": "application/json",
  //       "Authorization": `Bearer ${token}`,
  //     },
  //   })
  //   .then((response) => {
  //     setRows(response.data);
  //     setFilteredRows(response.data);
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching data: ", error);
  //   });
  // }, []);

  React.useEffect(() => {
    setRows(staff);
    setFilteredRows(staff);
  }, [staff]);


  //function for search
  useEffect(() => {
    if (search === "") {
      setFilteredRows(rows);
    } else {
      const filteredData = rows.filter((row) =>
        row.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredRows(filteredData);
    }
  }, [search, rows]);

  function DeleteSearch() {
    setSearch("");
  }

  return (

    <div style={{ height:  '50%', width: "100%" , marginBottom:"50px"}}>
<Box sx={{ display: "flex", justifyContent: "center", marginBottom: "50px" }}>
        <TextField
          fullWidth
          size="Normal"
          id="standard-basic"
          label="Search"
          variant="standard"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: "50%" }} // Set the desired width here

        />

        {search !== "" && (
          <IconButton aria-label="delete" onClick={DeleteSearch}>
            <ClearIcon />
          </IconButton>
        )}
                    <IconButton onClick={navigateOnCreateCard} sx={{ ml: 1 }}>
        <AddCircleOutlineIcon sx={{ fontSize: '35px' }} />
    </IconButton>
      </Box>

      <StyledDataGrid
    sx={{
      height: 500, // set height in pixels
      border: "none",
      borderRadius: "8px",
      marginTop: "16px",
      paddingX:"40px",
      backgroundColor:"white",
     // marginLeft:"1.5%",
      width: '100%', // Set width to 100% here
     // overflowX: 'auto',
    }}
    rows={filteredRows}
    columns={columns}
    pageSize={5}
    rowsPerPageOptions={[5]}
  />
    </div>
  );
}
