import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { NavLink, useParams } from "react-router-dom";
//Button
import EditButton from "../EditButton";
import DelButton from "../DelButton";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear"; //icon for delete search
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux"; //redux

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { useNavigate } from 'react-router-dom';


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "rgba(237, 237, 237)",
  },
  "& .clear-icon": {
    backgroundColor: "lightgray", // Set your desired color for the ClearIcon here
  },
  "& .MuiDataGrid-row": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));


const useStyles = makeStyles({
  wrapCellContent: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: 160,
  },
  
});
;

export default function DataTableUsers() {
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [userRoles, setUserRoles] = useState({});
  const isDesktop = useMediaQuery('(min-width:850px)');
  const classes = useStyles()
  const users = useSelector(state => state.menu.users);  
  const navigate = useNavigate();
  const BASE_URL = 'https://api.adaktarbooking.com';

  const fetchUserRoles = async (id) => {
    const token = localStorage.getItem('accessToken'); // Retrieve the token from localStorage
    try {
        const response = await axios.get(`${BASE_URL}/api/users/${id}/roles`, {

            headers: {
                "Content-Type": "application/json",
                "accept": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });

        if (response.data) {
            
            // Filter roles that have enabled set to true and then map to get their names
            let roleNames = response.data
                .filter(role => role.enabled)
                .map((role) => role.roleName);

            setUserRoles(prevRoles => ({ ...prevRoles, [id]: roleNames.join(', ') }));
        }
    } catch (error) {
        console.error(`Error fetching roles for user ${id}:`, error);
    }
};


  //function for deleting row whan click on deletebutton
  function handleDelete(id) {
    axios
      .delete(`https://jsonplaceholder.typicode.com/users/${id}`)
      .then(() => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
        setFilteredRows(updatedRows);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const navigateOnCreateCard = () => {
    navigate("/user/new"); // Replace '/desired-route' with your actual route.
  }

  //columns in table 
  const columns = [// { field: "id", headerName: "ID", flex: 1, hide: true },
    { field: "username", headerName: "Korisničko ime",  headerAlign: "center", align: "center",width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
     renderCell: (params) => (
      <div className={classes.wrapCellContent}>
        {params.value}
      </div>
    ),},
    { field: "name", headerName: "Naziv",  headerAlign: "center", align: "center" ,width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,},
    { field: "level", headerName: "Uloga",  headerAlign: "center", align: "center", width: isDesktop ? undefined : 200, flex: isDesktop ? 1.5 : undefined, 
    renderCell: (params) => {
      // Set your desired limit for text length
      const maxLength = 50; 
  
      if (params.value.length > maxLength) {
        return (
          <div className={classes.wrapCellContent}>
            {params.value}
          </div>
        )
      } else {
        return params.value;
      }
    },valueGetter: (params) => userRoles[params.id] || 'Loading...' },
    {
      field: "action",
      headerName: "Akcije",
      
      headerAlign: "center",
      align: "center",width: isDesktop ? undefined : 150, flex: isDesktop ? 1.5 : undefined,
      renderCell: (params) => {
        return (
          <div>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <NavLink to={`/user/edit/${params.row.id}`}>
                <EditButton />
              </NavLink>
              <DelButton onClick={() => handleDelete(params.row.id)} />
            </Box>
          </div>
        );
      },
    },
  ];

  //function get data and put data in state
  
  // React.useEffect(() => {
  //   const token = localStorage.getItem('accessToken'); // Retrieve the token from localStorage

  //   axios.get("${BASE_URL}/api/users", {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "accept": "application/json",
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   }).then((response) => {
  //     const mappedData = response.data.map(user => {
  //       fetchUserRoles(user.id);
  //       return {
  //         id: user.id,
  //         username: user.userName,
  //         name: `${user.firstName} ${user.lastName}`,
  //         level: userRoles[user.id] || 'Loading...',
  //         email: user.email,
  //         phoneNumber: user.phoneNumber,
  //         imageUrl: user.imageUrl,
  //         isActive: user.isActive,
  //         emailConfirmed: user.emailConfirmed,
  //       };
  //     });
  //     setRows(mappedData);
  //     setFilteredRows(mappedData);
  //   }).catch((error) => {
  //     console.error("Error fetching users:", error);
  //   });
  // }, []);


  React.useEffect(() => {
    const mappedData = users.map(user => {
      fetchUserRoles(user.id);
      return {
        id: user.id,
        username: user.userName,
        name: `${user.firstName} ${user.lastName}`,
        level: userRoles[user.id] || 'Loading...',
        email: user.email,
        phoneNumber: user.phoneNumber,
        imageUrl: user.imageUrl,
        isActive: user.isActive,
        emailConfirmed: user.emailConfirmed,
      };
    });
    setRows(mappedData);
    setFilteredRows(mappedData);
  }, [users]);
  //function for search
  useEffect(() => {
    if (search === "") {
      setFilteredRows(rows);
    } else {
      const filteredData = rows.filter((row) =>
        row.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredRows(filteredData);
    }
  }, [search, rows]);

  function DeleteSearch() {
    setSearch("");
  }

  return (
    <div style={{ height: '65vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div style={{ height: '90%', width: '100%', marginBottom: "50px" }}>
    <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "50px" }}>
          <TextField
            fullWidth
            size="Normal"
            id="standard-basic"
            label="search"
            variant="standard"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: "50%" }} // Set the desired width here

          />
            

            {search !== "" && (
              <IconButton aria-label="delete" onClick={DeleteSearch}>
                <ClearIcon />
              </IconButton>
            )}
            <IconButton onClick={navigateOnCreateCard} sx={{ ml: 1 }}>
        <AddCircleOutlineIcon sx={{ fontSize: '35px' }} />
    </IconButton>

          </Box>

          <StyledDataGrid
            sx={{
              border: "none",
          "& .MuiDataGrid-row": {
               borderBottom: "none",
             },
         borderRadius: "8px",
         marginTop: "16px",
         backgroundColor: "white",
         paddingX: "40px",

            }}
            rows={filteredRows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
    </div>
    </div>
  );
}
