import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_DATA_INSERTED = 'SET_DATA_INSERTED';
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_TREATMENTS = 'SET_TREATMENTS';
export const SET_STAFF_TREATMENTS = 'SET_STAFF_TREATMENTS';
export const SET_STAFF = 'SET_STAFF';
export const SET_CLIENT_TREATMENTS = 'SET_CLIENT_TREATMENTS';
export const SET_CLIENT_TREATMENTS_SECOND = 'SET_CLIENT_TREATMENTS_SECOND';
export const SET_USERS = 'SET_USERS';
export const SET_CLIENT_TREATMENT_STATUS = 'SET_CLIENT_TREATMENT_STATUS';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const ADD_MISSING_TREATMENTS = 'ADD_MISSING_TREATMENTS';
export const ADD_NEW_TREATMENTS = 'ADD_NEW_TREATMENTS';

export const SET_BASIC_ROLE = 'SET_BASIC_ROLE';
export const SET_UNPAID_DEPOSIT_TREATMENTS = 'SET_UNPAID_DEPOSIT_TREATMENTS';

const BASE_URL = 'https://api.adaktarbooking.com';


// Action Creators
export const toggleMenu = () => {
  return {
    type: TOGGLE_MENU
  };
};


export function setDataInserted(dataInserted) {
  return { type: SET_DATA_INSERTED, payload: dataInserted };
}

export function setClients(clients) {
  return { type: SET_CLIENTS, payload: clients };
}
export function setTreatments(treatments) {
  return { type: 'SET_TREATMENTS', payload: treatments };
}

export function setStaffTreatments(staffTreatments) {
  return { type: 'SET_STAFF_TREATMENTS', payload: staffTreatments };
}

export function setStaff(staff) {
  return { type: 'SET_STAFF', payload: staff };
}

export function setClientTreatments(clientTreatments) {
  return { type: 'SET_CLIENT_TREATMENTS', payload: clientTreatments };
}
export function setClientTreatmentsSecond(clientTreatmentSecond) {
  return { type: 'SET_CLIENT_TREATMENTS_SECOND', payload: clientTreatmentSecond };
}

export function setUsers(users) {
  return { type: SET_USERS, payload: users };
}
export function setClientTreatmentStatus(clientTreatmentStatus) {
  return { type: SET_CLIENT_TREATMENT_STATUS, payload: clientTreatmentStatus };
}

export function setPreferences(preferences) {
  return { type: SET_PREFERENCES, payload: preferences };
}
export function setUnpaidDepositTreatments(unpaidDepositTreatments) {
  return { type: SET_UNPAID_DEPOSIT_TREATMENTS, payload: unpaidDepositTreatments };
}


export const addNewTreatments = (newTreatments) => ({
  type: ADD_NEW_TREATMENTS,
  payload: newTreatments
});


export function setBasicRole(role) {
  return { type: SET_BASIC_ROLE, payload: role };
}

//TAKE DATA FROM APIES  AND PUT IN GLOBAL STATE 
const tenant = 'root';
const token = localStorage.getItem('accessToken');

export const fetchClients = (navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('accessToken');

    const response = await axios.get(`${BASE_URL}/api/v1/client/allclients`, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant
      }
    });

    dispatch(setClients(response.data));
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }


    // if (error.response && error.response.status === 401) {
    //   navigate('/login'); // Use navigate to redirect to login

    // } else {
    //   console.error(error);
    // }

  }
};

// Get all treatments
export const fetchTreatments = (navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('accessToken');

    const response = await axios.get(`${BASE_URL}/api/v1/treatment/alltretmans`, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant
      }
    });
    dispatch(setTreatments(response.data)); // You need to create this action similar to setClients
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }

  }
};

// Get all Staff Treatments
export const fetchStaffTreatments = (navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('accessToken');

    const response = await axios.get(`${BASE_URL}/api/v1/stafftreatment`, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant
      }
    });
    dispatch(setStaffTreatments(response.data)); // You need to create this action similar to setClients
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }

    // if (error.response && error.response.status === 401) {
    //   navigate('/login'); // Use navigate to redirect to login

    // } else {
    // }
  }
};

// Get all staff
export const fetchStaff = (navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('accessToken');

    const response = await axios.get(`${BASE_URL}/api/v1/staff/allstaff`, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant
      }
    });
    dispatch(setStaff(response.data)); // You need to create this action similar to setClients
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }

    // if (error.response && error.response.status === 401) {
    //   navigate('/login'); // Use navigate to redirect to login

    // } else {
    // }
  }
};

// Post client treatments

export const postClientTreatments = (navigate) => async (dispatch) => {
  const token = localStorage.getItem('accessToken');

  // Get current date for endTime
  const currentDate = new Date();

  // Add 3 months to the current date for endTime
  const startTimeDate = new Date(currentDate);
  startTimeDate.setDate(startTimeDate.getDate() - 30);
  const startFormatted = formatDateTime(startTimeDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

  // Calculate endTime (30 days after current date)
  const endTimeDate = new Date(currentDate);
  endTimeDate.setDate(endTimeDate.getDate() + 30);
  const endFormatted = formatDateTime(endTimeDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

  try {
    const response = await axios.post(`${BASE_URL}/api/v1/clienttreatment/calendar`,
      { startTime: startFormatted, endTime: endFormatted },
      {
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
          // "tenant": tenant  // 'tenant' variable was not provided in the code, make sure you have it defined
        }
      });
    dispatch(setClientTreatments(response.data)); // Assuming you have setClientTreatments action already defined
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }

    // if (error.response && error.response.status === 401) {
    //   navigate('/login'); // Use navigate to redirect to login

    // } else {
    // }
  }
};
///FOR UPDATE CLIENT TREATMENT
export const fesecondStateClientTreatment = (startDate, navigate) => async (dispatch) => {
  const token = localStorage.getItem('accessToken');



  const currentDate = new Date(startDate);

  // Add 3 months to the current date for endTime
  const startTimeDate = new Date(currentDate);
  startTimeDate.setDate(startTimeDate.getDate() - 30);
  const startFormatted = formatDateTime(startTimeDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

  // Calculate endTime (30 days after current date)
  const endTimeDate = new Date(currentDate);
  endTimeDate.setDate(endTimeDate.getDate() + 30);
  const endFormatted = formatDateTime(endTimeDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

  try {
    const response = await axios.post(`${BASE_URL}/api/v1/clienttreatment/calendar`,
      { startTime: startFormatted, endTime: endFormatted },
      {
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
          // "tenant": tenant  // 'tenant' variable was not provided in the code, make sure you have it defined
        }
      });
    dispatch(setClientTreatmentsSecond(response.data)); // Assuming you have setClientTreatments action already defined
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }

    // if (error.response && error.response.status === 401) {
    //   navigate('/login'); // Use navigate to redirect to login

    // } else {
    //   console.error(error);
    // }
  }
};
//THIS IS FOR FINDING DIFFERENCE IN CLIENT TREATMENT AND UPDATE FIRST CLIENT TREATMENT FROM API ...



// Helper function to format Date object into required string format
const formatDateTime = (dateObj) => {
  const year = dateObj.getUTCFullYear();
  let month = dateObj.getUTCMonth() + 1;
  let day = dateObj.getUTCDate();
  let hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();
  let seconds = dateObj.getUTCSeconds();

  // Add leading zeroes if necessary
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
};



export const fetchUsers = (navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('accessToken');

    const response = await axios.get(`${BASE_URL}/api/users`, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant
      }
    });

    dispatch(setUsers(response.data));
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }

    // if (error.response && error.response.status === 401) {
    //   navigate('/login'); // Use navigate to redirect to login

    // } else {
    //   console.error(error);
    // }
  }
};

export const fetchClientTreatmentStatus = (navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('accessToken');

    const response = await axios.get(`${BASE_URL}/api/v1/clienttreatmentstatus/allstatus`, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant
      }
    });
    dispatch(setClientTreatmentStatus(response.data));
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }

    // if (error.response && error.response.status === 401) {
    //   navigate('/login'); // Use navigate to redirect to login

    // } else {
    //   console.error(error);
    // }
  }
};



export const fetchPreferences = (navigate) => async (dispatch) => {
  try {
    const token = localStorage.getItem('accessToken');
    const tenant = 'root';

    const response = await axios.get(`${BASE_URL}/api/v1/preferences/allpreferences`, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant
      }
    });

    dispatch(setPreferences(response.data));
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }

    // if (error.response && error.response.status === 401) {
    //   navigate('/login'); // Use navigate to redirect to login

    // } else {
    //   console.error(error);
    // }
  }
};
export const fetchUnpaidDepositTreatments = (requestBody, navigate) => async (dispatch) => {
  const url = `https://api.adaktarbooking.com/api/v1/clienttreatment/unpaiddeposittreatments`;

  const token = localStorage.getItem('accessToken');
  const tenant = 'root';
  const headers = {
    "Content-Type": "application/json",
    "accept": "application/json",
    "Authorization": `Bearer ${token}`,
    "tenant": tenant
  };
  try {
    const response = await axios.post(url, {}, { headers }); // Empty body {}
    dispatch(setUnpaidDepositTreatments(response.data));
  } catch (error) {
    if (token !== null) {
      console.error(error);
      navigate('/login');
    }
  }
};

