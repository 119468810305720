import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"; //redux

import {setBasicRole,  fetchUsers, fetchClients, fetchTreatments, fetchStaffTreatments, fetchStaff ,postClientTreatments } from '../../redux/action'

  
function Login({ onLogin }) {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
const BASE_URL = 'https://api.adaktarbooking.com';
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
   
    

    useEffect(() => {
      document.body.style.overflow = 'hidden'; // Disable scrolling on mount
      window.scrollTo(0, 0); // Scroll to the top of the page
      return () => {
        document.body.style.overflow = ''; // Enable scrolling on unmount
      };
    }, []);
    

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(`${BASE_URL}/api/tokens`, {
          email: email,
          password: password,
        }, {
          headers: {
            "accept": "application/json",
            "tenant": 'root', 
            "Content-Type": "application/json",
          },
        });
  
        if (response.data.token) {
          localStorage.setItem('accessToken', response.data.token);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('refreshTokenExpiryTime', response.data.refreshTokenExpiryTime);

          navigate("/index");
          onLogin();
  
          if (response.status === 200) {
            dispatch(fetchClients());
            dispatch(fetchTreatments());
            dispatch(fetchStaffTreatments());
            dispatch(fetchStaff());
             dispatch(fetchUsers());
            dispatch(postClientTreatments());
            const token = response.data.token;

            const detailsOfCurrentLoggedUser = await axios.get(`${BASE_URL}/api/personal/profile`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "accept": "application/json",
               "Content-Type": "application/json",
              }
            });
            if(detailsOfCurrentLoggedUser.status=== 200 ){

              const roles = await axios.get(`${BASE_URL}/api/users/${detailsOfCurrentLoggedUser.data.id}/roles`, {
            headers: {
              "Content-Type": "application/json",
              "accept": "application/json",
              "Authorization": `Bearer ${token}`,
            },
          });

          const basicRoleEnabled = roles.data.some(role => role.roleName === 'Basic' && role.enabled === true);
          const adminRoleNotEnabled = roles.data.some(role => role.roleName === 'Admin' && role.enabled === false);
          
          const hasBasicRole = basicRoleEnabled && adminRoleNotEnabled;
          
          // Proverite da li objekti postoje i da li su uslovi zadovoljeni
          if (hasBasicRole) {
              dispatch(setBasicRole(true)); // Setujemo na true jer su oba uslova zadovoljena
              localStorage.setItem('basicRolee', JSON.stringify(true));
          } else {
              dispatch(setBasicRole(false)); // Setujemo na false jer uslovi nisu zadovoljeni
              localStorage.setItem('basicRolee', JSON.stringify(false));
          }
                  
      
      
            }

          }
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        alert('Invalid email or password');
      }
    };
  
   
    

  

      const gradientGoldBrown = 'linear-gradient(to right bottom, #a47a1e,#d3a84c,#ffec94,#e6be69,#ffd87c, #b58f3e ,#956d13 )';

      
     
  return (
 
                <Box
                sx={{
                    minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(/images/LoginBackground.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              >
              <Box sx={{ marginTop: '0px' }}>
              <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255)',
                padding: '24px',
                borderRadius: '0',
                width: '100%',
                maxWidth: '400px',
                margin: 'auto',
                border: '1px solid #f0c14b',
                boxShadow: '-10px 10px 10px -10px rgba(240, 193, 75, 0.5)',
                marginBottom: '0px',

              }}
              >
                <Container>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px' }}>
                      <img src="/images/adaktar.png" alt="Logo" style={{ maxWidth: '25%' }} />
                  </Box>
                  <Typography variant="h4" gutterBottom sx={{
                      textAlign: 'center',
                      backgroundImage: gradientGoldBrown,
                      backgroundClip: 'text',
                      color: 'transparent',
                      mb:6,
                      mt:4,
                    }}>
                    Logovanje
                  </Typography>

                  <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                  <TextField
                    sx={{
                      marginBottom: '16px',
                    //  marginBottom: '20px',
                    }}
                    fullWidth
                    label="E-pošta"
                    type="email"
                    variant="standard"
                    size="small"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{ sx: {  color: '#91653C',
                    '&:before': {
                      borderBottomColor: '#ffec94',
                    },
                    '&:after': {
                      borderBottomColor: '#ffec94',
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                      borderBottomColor: 'brown',
                    }, } }}
                    InputLabelProps={{
                      sx: {
                        color: 'black',
                        backgroundImage: gradientGoldBrown,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        width: '120px', 
                      },
                    }}
                  />
                    <TextField
                      sx={{ marginBottom: '24px' }}
                      fullWidth
                      label="Šifra"
                      type={showPassword ? 'text' : 'password'}
                      variant="standard"
                      size="small"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"  // Dodato ovde
                      InputProps={{ 
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: {  
                          color: '#91653C',
                          '&:before': {
                            borderBottomColor: '#ffec94',
                          },
                          '&:after': {
                            borderBottomColor: '#ffec94',
                          },
                          '&:hover:not($disabled):not($focused):not($error):before': {
                            borderBottomColor: 'blue',
                          }, 
                        }
                      }}
                      InputLabelProps={{
                        sx: {
                          color: 'black',
                          backgroundImage: gradientGoldBrown,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        },
                      }}
                    />
                      <Button
                      variant="contained"
                      size="large"
                      sx={{ 
                      
                        backgroundColor: 'white',
                      color:"#b58f3e",
                        borderRadius: '25px',
                        textTransform: 'none',
                        width: '100%',
                        mt:4,
                        border: '1px solid #ffec94',
                        '&:hover': {
                          background: gradientGoldBrown,
                          color:"white", },
                       
                      
                      }}
                      type="submit"
                    >
                    
                        Prijavi se
                      
                    </Button>
                  </form>
               </Container>
      </Box>
    </Box>

    </Box>
  
  );

}

export default Login;
