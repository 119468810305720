import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
export default function ButtonReport(props) {
  const { onClick, isSidebarOpen } = props;
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Stack spacing={2} direction="row">
      <Button onClick={handleClick} variant="outlined" sx={{ width: isSidebarOpen ? "200px" : "50px",  }}
>PDF</Button>
    </Stack>
  );
}
