import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import HouseIcon from "@mui/icons-material/House";

export default function IconButtons(props) {
  const { onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Stack direction="row" spacing={1}>
      <IconButton aria-label="delete" onClick={handleClick}>
        <HouseIcon />
      </IconButton>
    </Stack>
  );
}
