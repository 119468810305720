import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { useRef } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { red } from "@material-ui/core/colors";
import { Button } from "@mui/material";

import "./styles.css";

function Scheduler() {
  const theme = useTheme();
  const [currentEvents, setCurrentEvents] = useState([]);
  const calendarRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const users = [
    { id: 1, name: "John" },
    { id: 2, name: "Jane" },
    { id: 3, name: "Bob" },
  ];

  const handleDateClick = (selected) => {
    const title = prompt("Please enter a new title for your event");
    const calendarApi = selected.view.calendar;
    calendarApi.unselect();

    if (title) {
      const start = selected.startStr;
      const end = calendarApi.add(start, 2, "day").toISOString();
      calendarApi.addEvent({
        id: `${selected.dateStr}-${title}`,
        title,
        start,
        end,
        allDay: false,
        userId: selectedUser.id, // dodajemo userId u događaj
      });
    }
  };

  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${selected.event.title}'`
      )
    ) {
      selected.event.remove();
    }
  };
  //-------------------------
  const customHeaderToolbar = {
    left: "prev,next today",
    center: "title",
    right: "dayGridMonth,timeGridWeek,timeGridDay,timeGridDay3",
  };
  const customHeaderToolbar1 = {
    left: "prev,next today",
    center: "title",
    right: "dayGridMonth,timeGridWeek,timeGridDay,timeGridDay3",
  };

  function renderHeaderView() {
    return (
      <div style={{ backgroundColor: "blue", height: "50px" }}>
        Moj kalendar
      </div>
    );
  }
  //-------------------------
  const filteredEvents = currentEvents.filter((event) => {
    if (!selectedUser) {
      return true;
    }
    return event.extendedProps.userId === selectedUser.id;
  });
  const customSlotContent = (slotInfo) => {
    return (
      <>
        <div className="custom-slot">
          {slotInfo.date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
        <div className="custom-slot-content">
          {slotInfo.view.type === "timeGridWeek" ? (
            <div>Week View</div>
          ) : (
            <div>Day View</div>
          )}
          <div>Custom content goes here!</div>
        </div>
      </>
    );
  };
  return (
    <>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between">
          <Box flex="1 1 100%" ml="15px">
            <Box>
              <Typography variant="h6">Select user:</Typography>
              <select
                value={selectedUser ? selectedUser.id : ""}
                onChange={(event) => {
                  const selectedId = parseInt(event.target.value);
                  const user = users.find((u) => u.id === selectedId);
                  setSelectedUser(user);
                }}
              >
                <option value="">Select user</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </Box>
            <FullCalendar
              height="75vh"
              initialView="timeGridDay"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={customHeaderToolbar}
              allDaySlot={false}
              slotDuration="00:15:00" //set row on 15 min
              slotLabelInterval="01:00" //show on one hour
              timeZone="Europe/Belgrade"
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                hour12: false,
              }}
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              select={handleDateClick}
              eventClick={handleEventClick}
              eventsSet={(events) => setCurrentEvents(events)}
              initialEvents={[
                {
                  id: "12315",
                  title: "All-day event",
                  date: "2022-09-14",
                },
                {
                  id: "5123",
                  title: "Timed event",
                  date: "2022-09-28",
                },
              ]}
              views={{
                timeGridDay3: {
                  type: "timeGrid",
                  duration: { days: 3 },
                  buttonText: "3 Days",
                },
              }}
            ></FullCalendar>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Scheduler;
