import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import axios from "axios";

import dayjs from 'dayjs';

import { fetchClients } from '../../redux/action';

import Grid from "@mui/material/Grid";

import { Box, Paper, Typography } from "@mui/material";

import { Divider, InputAdornment, IconButton } from "@material-ui/core";

import PersonIcon from "@mui/icons-material/Person";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

import IconButtons from "../../components/IconButton";
import ResponsiveDateTimePickers from "../../components/DataPicker";
// UseRadioGroup from "../../components/Rbutton";
import UseRadioGroup from '../../components/Rbutton'

import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';

import { ToastContainer, toast } from 'react-toastify';
import DatapickerForClient from '../client/DatapickerForClient'

export default function ClientDialog({ setIdOfInsertedClient, open, handleClose, handleUsersRefetch  }) {
    const [openClientDialog, setOpenClientDialog] = useState();
    const BASE_URL = 'https://api.adaktarbooking.com';

    const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    //create state variables for each TextField:
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [newsOnEmail, setNewsOnEmail] = useState(true);
  const [drugAllergies, setDrugAllergies] = useState("");
 // const [idOfinsertedClient, setIdOfinsertedClient] = useState("");




  const resetForm = () => {
    setName("");
    setSurname("");
    setDateOfBirth(null);
    setSelectedDate(null);
    setPhone("");
    setEmail("");
    setAddress("");
    setImage("");
    setDescription("");
    setNewsOnEmail(true);
    setDrugAllergies("");
   // setSelectedColor("#000000"); // Reset the color to the initial value
  
  };
  
  
  
  
  //function for inserd cliend in db  
  const InsertClientDB = async () => {
    let currentTime = dayjs().format('HH:mm:ss.SSSZ');
   //
    let combinedDateTime = dayjs(dateOfBirth).format('YYYY-MM-DD') + "T" + currentTime;
  
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!email) {
    //   alert('Molimo Vas unesite ispravan email.');
    //   return;
    // } else if (!emailRegex.test(email)) {
    //   alert('Molimo Vas unesite ispravan email.');
    //   return;
    // }  

    if (!phone.trim()) {
      alert('Molimo Vas unesite broj telefona.');
      return;
    }    if (!name.trim()) {
      alert('Molimo Vas unesite ime.');
      return;
    }
  
    if (!surname.trim()) {
      alert('Molimo Vas unesite prezime.');
      return;
    }
    const data = {
      name,
      surname,
      dateOfBirth: selectedDate ? selectedDate.toISOString() : null,
      phone,
      email,
      address,
      image,
      description,
      newsOnEmail,
      drugAllergies,
    };
    try {
      const token = localStorage.getItem('accessToken'); 
      const tenant = 'root'; 
  
      const response = await axios.post(`${BASE_URL}/api/v1/client/insert`, data, {
        headers: {
          "Content-Type": "application/json",
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
          "tenant": tenant  
        }
      });
  
      if (response.status === 200) {   
        toast.success("Klijent je uspešno dodat!"); 
        resetForm();
        // dispatch(fetchClients());
        handleUsersRefetch()
        setIdOfInsertedClient(response.data);  // Assume the ID is located at response.data.id
        handleClose(); 

      }
     
    } catch (error) {
      toast.error("Klijent nije dodat!");

        alert('Pogresno Ste uneli podatke')
      if (error.response && error.response.status === 404) {
        console.error("Error submitting client data: 404 not found");
      } else {
        console.error("Error submitting client data", error);
        toast.error("Klijent nije dodat!");

      }
    }
  };
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
    const navigateONIndex = () => {
      navigate("/index");
    };
    const navOnCliShearch = () => {
      navigate("/client/search");
    };
    
    const handleNewsOnEmailChange = (event) => {
      setNewsOnEmail(event.target.value === "true");
    };
    
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setImage("");
      }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Unos novog klijenta</DialogTitle>
        <DialogContent>
            {/* All your form fields go here just like they are in the Cnew component */}

            <Grid container sx={{}}>
<Grid item xs={12} sm={6}>
  <Box p={2}>
  {/* <UploadButtons onChange={handleImageChange} value={image} /> */}
  <Typography sx={{ paddingBottom: "13px" }}>
    Ime
  </Typography>
  <FormControl variant="outlined" style={{ width: "100%" }}>
    <OutlinedInput
      id="outlined-basic"
      placeholder="Unesi ime"
      value={name}
      onChange={(e) => setName(e.target.value)}
      size="small"
      style={{ height: "40px" }}
      startAdornment={
        <InputAdornment position="start">
          <PersonIcon fontSize="small" />
        </InputAdornment>
      }
    />
  </FormControl>
  
  <Typography sx={{ paddingBottom: "13px",mt:2 }}>
    Prezime
  </Typography>
  <FormControl variant="outlined" style={{ width: "100%" }}>
    <OutlinedInput
      id="outlined-basic"
      placeholder="Unesi Prezime"
      value={surname}
      onChange={(e) => setSurname(e.target.value)}
      size="small"
      style={{ height: "40px" }}
      startAdornment={
        <InputAdornment position="start">
          <PersonIcon fontSize="small" />
        </InputAdornment>
      }
    />
  </FormControl>
    <Typography sx={{ mt: 2 }}>
      Datum rodjenja
    </Typography>
    {/* <ResponsiveDateTimePickers selectedDate={selectedDate} handleDateChange={handleDateChange} /> */}
      <DatapickerForClient onDateChange={handleDateChange} />
   </Box>

  
</Grid>
<Grid item xs={12} sm={6}>
  <Box p={2}>
  <Typography sx={{ paddingBottom: "13px" }}>
      Broj telefona
    </Typography>
    <FormControl variant="outlined" style={{ width: "100%" }}>
      <OutlinedInput
        id="outlined-basic"
        placeholder="Unesi broj telefona"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        size="small"
        style={{ height: "40px" }}
        startAdornment={
          <InputAdornment position="start">
            <PhoneIphoneIcon fontSize="small" />
          </InputAdornment>
        }
      />
    </FormControl>
    {/* <p style={{ fontSize: "12px", color: "gray" }}>pocinje sa (+381)</p> */}
    <Typography sx={{ paddingBottom: "13px", mt: 2 }}>
      Adresa
    </Typography>
    <FormControl variant="outlined" style={{ width: "100%" }}>
      <OutlinedInput
        id="outlined-basic"
        placeholder="Unesi adresa"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        size="small"
        style={{ height: "40px" }}
        startAdornment={
          <InputAdornment position="start">
            <LocationOnIcon fontSize="small" />
          </InputAdornment>
        }
      />
    </FormControl>
    <Typography sx={{ paddingBottom: "13px", mt: 2 }}>
      Email
    </Typography>
    <FormControl variant="outlined" style={{ width: "100%" }}>
      <OutlinedInput
        id="outlined-basic"
        type="email"
        placeholder="Unesi email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="email"
        size="small"
        style={{ height: "40px" }}
        startAdornment={
          <InputAdornment position="start">
            <EmailIcon fontSize="small" />
          </InputAdornment>
        }
      />
    </FormControl>
    <p style={{ fontSize: "12px", color: "gray" }}>mora da ima @gmail.com</p>
    <Typography sx={{ fontSize: "17px", mb: 1, mt: 2 }}>
      Da li zeli da prima novosti na email?
    </Typography>
    <UseRadioGroup
      value={newsOnEmail ? "true" : "false"}
      onChange={handleNewsOnEmailChange}
    />

  <Typography sx={{ paddingBottom: "13px" , mt: 2 }}>
    Alergija na lekove
  </Typography>
  <FormControl variant="outlined" style={{ width: "100%" }}>
    <OutlinedInput
      id="outlined-basic"
      placeholder="Unesi podatke o alergijama na lekove"
      value={drugAllergies}
      onChange={(e) => setDrugAllergies(e.target.value)}
      size="small"
      style={{ height: "40px" }}
    />
  </FormControl>
  <Typography sx={{ paddingBottom: "13px" , mt: 2 }}>
    Opis
  </Typography>
  <FormControl variant="outlined" style={{ width: "100%" }}>
    <OutlinedInput
      id="outlined-basic"
      placeholder="Unesi opis klijenta"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      size="small"
      multiline
      style={{ height: "40px" }}
    />
  </FormControl>
    {/* <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
      Odaberite boju
    </Typography>
    <SketchPicker
      color={selectedColor}
      onChangeComplete={(newColor) => setSelectedColor(newColor.hex)}
    /> */}
  </Box>
</Grid>
</Grid>            {/* ... Other form components */}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Otkaži
            </Button>
            <Button onClick={InsertClientDB} color="primary">
                Sačuvaj
            </Button>




        </DialogActions>
    </Dialog>

    );
}