import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
export default function ComboBoxStatus(props) {
  const {setTreatmentChanged , treatmentChanged ,onChange, status: initialStatus } = props;
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null); //have wich status is selected

  const [selectedStatus, setSelectedStatus] = useState(
    initialStatus
      ? statusOptions.find((option) => option.label === initialStatus)
      : null
  );


  //THIS API IS JUST FOR ONCE INSERT STATUS 
  // useEffect(() => {
  //   const token = localStorage.getItem('accessToken');
  //   const config = {
  //     headers: {
  //       "accept": "application/json",
  //       "Authorization": `Bearer ${token}`,
  //     }
  //   };
  //   statusOptions.forEach(status => {
  //     const body = {
  //       title: status.label,
  //       color: " "
  //     };
  //     axios.post('https://api.adaktarbooking.com/api/v1/clienttreatmentstatus/insert', body, config)
  //       .then((response) => {
  //         console.log('Inserted status:', response.data);
  //       })
  //       .catch((error) => {
  //         console.error('There was an error!', error);
  //       });
  //   });
  // }, []);
  useEffect(() => {
    const fetchStatusOptions = async () => {
      const token = localStorage.getItem('accessToken'); 
  
      try {
        const response = await axios.get("https://api.adaktarbooking.com/api/v1/clienttreatmentstatus/allstatus",
          {
            headers: {
              'Authorization': `Bearer ${token}`, 
              'Content-Type': 'application/json'
            }
          }
        );
  
        const mappedStatuses = response.data.map((status) => ({
          id: status.id, 
          label: status.title, 
          color: status.color
        }));
  
        setStatusOptions(mappedStatuses);
  
        // Set default selected value to "Depozit"
        const defaultStatus = mappedStatuses.find(status => status.label === "Depozit");
        if (defaultStatus) {
          setSelectedStatus(defaultStatus);
        }
  
      } catch (error) {
        // Handle error condition
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Response error: ", error.response.data);
          console.error("Response error: ", error.response.status);
          console.error("Response error: ", error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Request error: ", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Other error: ", error.message);
        }
        console.error("Error config: ", error.config);
      }
    };
  
    fetchStatusOptions();
  }, []);


  useEffect(() => {
    if (treatmentChanged) {
      const depositStatus = statusOptions.find(option => option.label === "DEPOZIT");
      if (depositStatus) {
        setSelectedStatus(depositStatus);
        setSelectedOptionStatus(depositStatus.id);
        onChange(depositStatus);  // notify the parent component of the change
      }
  
      // Reset treatmentChanged to false
      setTreatmentChanged(false);
    }
  }, [treatmentChanged,setTreatmentChanged , statusOptions]);
  

  useEffect(() => {
    setSelectedStatus(
        initialStatus
            ? statusOptions.find((option) => option.label === initialStatus)
            : null
    );
}, [statusOptions, initialStatus]);
  
  // useEffect(() => {
  //   console.log("Display Status: ", selectedOptionStatus);
  // }, [selectedOptionStatus]);

  return (
  //   <Autocomplete
  //   disablePortal
  //   id="combo-box-demo"
  //   options={statusOptions}
  //   sx={{ width: "100%" }}
  //   onChange={(_, value) => {
  //     console.log("Selected value: ", value); // Add this line
  //     setSelectedStatus(value);
  //     onChange(value); // This is calling the handleStatusChange from the parent
  //         setSelectedOptionStatus(value);
  //   }}
  //   value={selectedStatus}
  //   renderInput={(params) => <TextField {...params} label="status" />}
  // />
  <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={statusOptions}
  sx={{ width: "100%" }}
  onChange={(_, value) => {
    setSelectedStatus(value);
    onChange(value); 
    setSelectedOptionStatus(value ? value.id : null);
  }}
  
  value={selectedStatus}
  renderInput={(params) => 
    <TextField 
      {...params} 
      variant="outlined" 
      InputProps={{
        ...params.InputProps, 
        style: { height: '40px' }
      }} 
      placeholder="Status"
    />
  }
/>

  );
}

// const statusOptions = [
//   { label: 'DEPOZIT' },
//   { label: 'ZAKAZAN' },
//   { label: 'PLACEN' },
//   { label: 'ISTEKAO' },
// ];