import React from "react";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";

import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

//import for radio group
import UseRadioGroup from "../../components/Rbutton";

//import datapicker
import ResponsiveDateTimePickers from "../../components/DataPicker";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

//Buttons
import UploadButtons from "../../components/UploadButton"; //uploadButton
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";
import { styled } from "@mui/material/styles";
import MyScheduler from "../../components/Scheduler/Scheduler";
import Calendar from "../../components/Scheduler/CustomCalender";
const Scheduling = () => {
  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
  };
  const navOnCliShearch = () => {
    navigate("/client/search");
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            ml: isMenuOpen ? 33 : 8,
            pt: 8,
            pl: 2,
            pr: 2,

            "@media (max-width: 600px)": {
              ml: 0,
            },
          }}
        >
          <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
            <Box>
              <Typography sx={{ fontSize: "25px" }}> Kalendar</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <IconButtons onClick={navigateONIndex} />

                <Typography sx={{ fontSize: "15px" }}></Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            sx={{
              bgcolor: "white",
              width: "100%",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box p={2}>
              {/* <MyScheduler /> */}
              <Calendar />
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Scheduling;
