import React from "react";
import { useNavigate } from "react-router-dom";


import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";

import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

//import for radio group
import UseRadioGroup from "../../components/Rbutton";

//import datapicker
import ResponsiveDateTimePickers from "../../components/DataPicker";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

//Buttons
import UploadButtons from "../../components/UploadButton"; //uploadButton
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";

//for color picker 
import { ColorPicker } from "@mui/lab";
import { AdapterDateFns } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { SketchPicker } from "react-color";
import axios from "axios";
import dayjs from 'dayjs';

import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';


import { fetchClients } from '../../redux/action';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatapickerForClient from '../client/DatapickerForClient'
const Cnew = () => {
  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const BASE_URL = 'https://api.adaktarbooking.com';


  //create state variables for each TextField:
const [name, setName] = useState("");
const [surname, setSurname] = useState("");
const [dateOfBirth, setDateOfBirth] = useState(null);
const [phone, setPhone] = useState("");
const [email, setEmail] = useState("");
const [address, setAddress] = useState("");
const [image, setImage] = useState("");
const [description, setDescription] = useState("");
const [newsOnEmail, setNewsOnEmail] = useState(true);
const [drugAllergies, setDrugAllergies] = useState("");
//const [selectedColor, setSelectedColor] = useState("#000000");



const resetForm = () => {
  setName("");
  setSurname("");
  setDateOfBirth(null);
  setSelectedDate(null);
  setPhone("");
  setEmail("");
  setAddress("");
  setImage("");
  setDescription("");
  setNewsOnEmail(true);
  setDrugAllergies("");
 // setSelectedColor("#000000"); // Reset the color to the initial value

};




//function for inserd cliend in db  
const InsertClientDB = async () => {
  let currentTime = dayjs().format('HH:mm:ss.SSSZ');
  let combinedDateTime = dayjs(dateOfBirth).format('YYYY-MM-DD') + "T" + currentTime;

  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // if (!email) {
  //   alert('Molimo Vas unesite ispravan email.');
  //   return;
  // } else if (!emailRegex.test(email)) {
  //   alert('Please enter a valid email address.');
  //   return;
  // }  
  // const phoneRegex = /^\0\d*$/; // Adjust this regex according to your needs
  // if (!phone) {
  //   alert('Broj telefona mora da pocinje sa 0');
  //   return;
  // } else if (!phoneRegex.test(phone)) {
  //   alert('Phone number should start with 0');
  //   return;
  // }

  const data = {
    name,
    surname,
    dateOfBirth: selectedDate ? selectedDate.toISOString() : null,
    phone,
    email,
    address,
    image,
    description,
    newsOnEmail,
    drugAllergies,
  };
  try {
    const token = localStorage.getItem('accessToken'); 
    const tenant = 'root'; 

    const response = await axios.post(`${BASE_URL}/api/v1/client/insert`, data, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
        "tenant": tenant  
      }
    });

   
    if (response.status === 200) {   
         toast.success("Klijent je uspešno dodat!");

      resetForm();
      dispatch(fetchClients());
    } else {
    
    }
  } catch (error) { 
     toast.error("Greska prilikom dodavanja klijenta !");
    if (error.response && error.response.status === 404) {
      console.error("Error submitting client data: 404 not found");
    } else {
      console.error("Error submitting client data", error);
    }
  }
};

const handleDateChange = (date) => {
  setSelectedDate(date);
};
  const navigateONIndex = () => {
    navigate("/index");
  };
  const navOnCliShearch = () => {
    navigate("/client/search");
  };
  
  const handleNewsOnEmailChange = (event) => {
    setNewsOnEmail(event.target.value === "true");
  };
  
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage("");
    }
  };
  return (
    <>
      
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,
  
              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> Klijenti</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButtons onClick={navigateONIndex} />

                  <Typography sx={{ fontSize: "15px" }}>
                    - Klijenti - Novi
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: '16px',

              }}
            >
              <Grid item md={12}>
                <Typography variant="h6" sx={{ p: 2 }}>
                  Unos novog klijenta
                </Typography>
                <Divider sx={{}} />
              </Grid>
              <Grid container sx={{}}>
                <Grid item xs={12} sm={6}>
                <Box p={2} style={{ width: '100% !important' }}>
                  {/* <UploadButtons onChange={handleImageChange} value={image} /> */}
                  <Typography sx={{ paddingBottom: "13px" }}>
                    Ime
                  </Typography>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-basic"
                      placeholder="Unesi ime"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      size="small"
                      style={{ height: "40px" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <PersonIcon fontSize="small" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  
                  <Typography sx={{ paddingBottom: "13px",mt:2 }}>
                    Prezime
                  </Typography>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-basic"
                      placeholder="Unesi Prezime"
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                      size="small"
                      style={{ height: "40px" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <PersonIcon fontSize="small" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                    <Typography sx={{ mt: 2 }}>
                      Datum rodjenja
                    </Typography>
                    {/* <ResponsiveDateTimePickers selectedDate={selectedDate} handleDateChange={handleDateChange} /> */}
                    <DatapickerForClient selectedDate={selectedDate} onDateChange={handleDateChange} />

                   </Box>

                  
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box p={2}>
                  <Typography sx={{ paddingBottom: "13px" }}>
                      Broj telefona
                    </Typography>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <OutlinedInput
                        id="outlined-basic"
                        placeholder="Unesi broj telefona"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="number"
                        size="small"
                        style={{ height: "40px" }}
                        startAdornment={
                          <InputAdornment position="start">
                            <PhoneIphoneIcon fontSize="small" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Typography sx={{ paddingBottom: "13px", mt: 2 }}>
                      Adresa
                    </Typography>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <OutlinedInput
                        id="outlined-basic"
                        placeholder="Unesi adresa"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        size="small"
                        style={{ height: "40px" }}
                        startAdornment={
                          <InputAdornment position="start">
                            <LocationOnIcon fontSize="small" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Typography sx={{ paddingBottom: "13px", mt: 2 }}>
                      Email
                    </Typography>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <OutlinedInput
                        id="outlined-basic"
                        type="email"
                        placeholder="Unesi email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                        size="small"
                        style={{ height: "40px" }}
                        startAdornment={
                          <InputAdornment position="start">
                            <EmailIcon fontSize="small" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    <Typography sx={{ fontSize: "17px", mb: 1, mt: 2 }}>
                      Da li zeli da prima novosti na email?
                    </Typography>
                    <UseRadioGroup
                      value={newsOnEmail ? "true" : "false"}
                      onChange={handleNewsOnEmailChange}
                    />

                  <Typography sx={{ paddingBottom: "13px" , mt: 2 }}>
                    Alergija na lekove
                  </Typography>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-basic"
                      placeholder="Unesi podatke o alergijama na lekove"
                      value={drugAllergies}
                      onChange={(e) => setDrugAllergies(e.target.value)}
                      size="small"
                      style={{ height: "40px" }}
                    />
                  </FormControl>
                  <Typography sx={{ paddingBottom: "13px" , mt: 2 }}>
                    Opis
                  </Typography>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <OutlinedInput
                      id="outlined-basic"
                      placeholder="Unesi opis klijenta"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      size="small"
                      multiline
                      style={{ height: "40px" }}
                    />
                  </FormControl>
                    {/* <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Odaberite boju
                    </Typography>
                    <SketchPicker
                      color={selectedColor}
                      onChangeComplete={(newColor) => setSelectedColor(newColor.hex)}
                    /> */}
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="right"
                height={100}
                sx={{
                  gap: 2,
                  mr: 5,
                  "@media (max-width: 347px)": {
                    justifyContent: "center",
                    mr: 0,
                  },
                }}
              >
                <BasicContained onClick={InsertClientDB} />
                <BasicOutlined onClick={navOnCliShearch} />
              </Box>
            </Grid>
          </Box>
        </Box>
    
    </>
  );
};

export default Cnew;
