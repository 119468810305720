import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function ButtonInstandDivForCalendar(props) {
  const { onClick, style, children } = props;

  // Extract backgroundColor from the style prop
  const bgColor = style?.backgroundColor || 'lightred'; // Default to 'transparent' if not provided
  const getFontSize = () => {
    if (window.innerWidth < 420) {
      return '0.6em';
    } else if (window.innerWidth >= 420 && window.innerWidth <= 528) {
      return '0.4em';
    } else if (window.innerWidth < 890) {
      return '0.6em';
    } else if (window.innerWidth < 1075){
      return '0.5em';
    } else {
      return '0.7em';
    }
  };
  
// 450 600

  return (
    <Stack spacing={2} direction="row">
      <Button 
        variant="contained" 
        onClick={onClick} 
        style={style} 
        sx={{ 
          
          width: "100%",
          padding: 0,  // remove padding
          borderRadius: 0,  // remove rounded corners
          border: 0,  // remove border
          backgroundColor: bgColor,  // set background color
        }}>
        <span style={{fontSize: getFontSize(), color: 'black'}}> {/* Call the function here */}
          {children}  
        </span>
      </Button>
    </Stack>
  );
}
