import React from "react";

import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from '@mui/icons-material/Email';//combobutton
import ComboBoxBoje from "../../components/ComboBoxBoje";
import CAdminAgent from "../../components/ComboAdminAgent";
import PhoneIcon from '@mui/icons-material/Phone';
//button
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditUser = () => {
  const maintitle = "Korisnik";
  const path = "- Korisnik - Izmena";
  const subtitle = "Izmena korisnika";
  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();
  const BASE_URL = 'https://api.adaktarbooking.com';

  //---- for corecting and call data from base
  const token = localStorage.getItem('accessToken'); // get the token from local storage
  const { id } = useParams(); //id from url
  const [user, setUser] = useState({
    name: "",
    prezime: "",
    email: "",
    phoneNumber: "",
  });
  
  //-------------------------------------------------------
  //take client with id from server  and set state with treatment
  React.useEffect(() => {
    axios
      .get(`${BASE_URL}/api/users/${id}`, {
        headers: {
          accept: "application/json",
          "Authorization": `Bearer ${token}`,

        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data);
        } else if (response.status === 400) {
          console.log("Bad request. Invalid user ID."); // Set the error message for 400 response code
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  //change treatment state
 
  //onclick containedButton update client in database
  const Btupdate = () => {
    //console.log(treatment.name);
    axios
      .put("https://jsonplaceholder.typicode.com/users/1", user)
      .then((response) => {

        if (response.status === 200) {
          toast.success("Korisnik je uspešno izmenjen!");
      } else {
          toast.error("Izmena korisnik nije uspela!");
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };
    // Change handlers for fields

  const handleNameChange = (event) => {
    setUser({ ...user, name: event.target.value });
  };
  const handlePrezimeChange = (event) => {
    setUser({ ...user, prezime: event.target.value });
  };

  const handleEmailChange = (event) => {
    setUser({ ...user, email: event.target.value });
  };

  const handlePhoneNumberChange = (event) => {
    setUser({ ...user, brojTelefona: event.target.value });
  };

  //----------------------------------------------end-----------------------

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
  };
  const navOnCliShearch = () => {
    navigate("/user/search");
  };
  return (
    <>
    
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,

              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> {maintitle}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButtons onClick={navigateONIndex} />

                  <Typography sx={{ fontSize: "15px" }}>{path}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item md={12}>
                <Typography variant="h6" sx={{ p: 2 }}>
                  {subtitle} - {user.userName}
                </Typography>
                <Divider sx={{}} />
              </Grid>
              <Grid container sx={{}}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                  <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Ime</Typography>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <OutlinedInput
                id="outlined-basic"
                value={user.firstName}
                onChange={handleNameChange}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon fontSize="small" />
                  </InputAdornment>
                }
                placeholder="Unesi ime korisnika"
                style={{ height: '40px' }}
                inputProps={{
                  style: {
                    padding: '10px',
                  },
                }}
              />
            </FormControl>
            <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Prezime</Typography>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <OutlinedInput
                id="outlined-basic"
                value={user.lastName}
                onChange={handlePrezimeChange}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon fontSize="small" />
                  </InputAdornment>
                }
                placeholder="Unesi prezime korisnika"
                style={{ height: '40px' }}
                inputProps={{
                  style: {
                    padding: '10px',
                  },
                }}
              />
            </FormControl>
            <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Email</Typography>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <OutlinedInput
                id="outlined-basic"
                value={user.email}
                onChange={handleEmailChange}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <EmailIcon fontSize="small" />
                  </InputAdornment>
                }
                placeholder="Unesi email korisnika"
                style={{ height: '40px' }}
                inputProps={{
                  style: {
                    padding: '10px',
                  },
                }}
              />
            </FormControl>
            <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Broj telefona</Typography>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <OutlinedInput
                id="outlined-basic"
                value={user.phoneNumber}
                onChange={handlePhoneNumberChange}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <PhoneIcon fontSize="small" />
                  </InputAdornment>
                }
                placeholder="Unesi broj telefona korisnika"
                style={{ height: '40px' }}
                inputProps={{
                  style: {
                    padding: '10px',
                  },
                }}
              />
            </FormControl>
                  
                
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                  <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Korisničko ime</Typography>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <OutlinedInput
                id="outlined-basic"
                value={user.userName}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon fontSize="small" />
                  </InputAdornment>
                }
                placeholder="Korisnicko ime"
                style={{ height: '40px' }}
                inputProps={{
                  style: {
                    padding: '10px',
                  },
                }}
              />
           
            </FormControl>

            <Typography sx={{ fontSize: '17px', mb: 2, mt: 2 }}>Šifra</Typography>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <OutlinedInput
                id="outlined-basic"
                type="password"
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon fontSize="small" />
                  </InputAdornment>
                }
                placeholder="sifra"
                style={{ height: '40px' }}
                inputProps={{
                  style: {
                    padding: '10px',
                  },
                }}
              />
            </FormControl>
                    {/* <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Uloga
                    </Typography>

                    <CAdminAgent /> */}
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="right"
                height={100}
                sx={{
                  gap: 2,
                  mr: 5,
                  "@media (max-width: 347px)": {
                    justifyContent: "center",
                    mr: 0,
                  },
                }}
              >
                <BasicContained onClick={Btupdate} />
                <BasicOutlined onClick={navOnCliShearch} />
              </Box>
            </Grid>
          </Box>
        </Box> 
    
    </>
  );
};

export default EditUser;
