import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";

import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

//import for radio group
import UseRadioGroup from "../../components/Rbutton";

//import datapicker
import BasicDateTimePicker from "../../components/DataPicker";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

//combobox treatment

//button
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";

//import comboboxTreatment
import ComboBoxEditTreatment from "../staff/ComboBoxEditTreatment";
//import switch button
import SwitchButton from "../../components/SwitchButton";
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import {  fetchStaff  } from '../../redux/action';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Snew = () => {
  const maintitle = "Osoblje";
  const path = "-Osoblje - Izmena";
  const subtitle = "Izmena";
  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  //---- for corecting and call data from base
  const { id } = useParams(); //id from url
  const [staff, setStaff] = useState({
    name: "",
    prezime: "",
    email: "",
    phoneNumber: "",
  }); 
  const [ treatmentStaff, setStaffTreatments] = useState([]);
  const [ updatetreatmentStaff, setupdatetreatmentStaff] = useState([]);
  const [ staffTreatmentHaveBindingID, setstaffTreatmentHaveBindingID] = useState([]);

const BASE_URL = 'https://api.adaktarbooking.com';

  //-----------------------
  //take client with id from server  and set state with treatment
  React.useEffect(() => {
    const token = localStorage.getItem("accessToken"); // Get the token from local storage
    axios
      .get(`${BASE_URL}/api/v1/staff/${id}`, {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setStaff(response.data);
        } else if (response.status === 400) {
          console.log("Bad request. Invalid staff ID.");
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error occurred while fetching staff data.");
      });
  }, [id]);

  //change treatment state
 // Change handlers for fields
 const handleTreatmentStaffChange = (event) => {
  const updatedTreatmentStaff = event.target.value;
  setStaffTreatments(updatedTreatmentStaff);
};
const handleSelectedTreatmentOptionsChange = (options) => {
  setupdatetreatmentStaff(options)
};

 const handleNameChange = (event) => {
  setStaff({ ...staff, name: event.target.value });
};

const handleSurNameChange = (event) => {
  setStaff({ ...staff, surname: event.target.value });
};

const handleEmailChange = (event) => {
  setStaff({ ...staff, email: event.target.value });
};

const handlePhoneNumberChange = (event) => {
  setStaff({ ...staff, brojTelefona: event.target.value });
};
const handleDescriptionChange = (event) => {
  setStaff({ ...staff, description: event.target.value });
};
const handleIncomeChange = (event) => {
  setStaff({ ...staff, income: event.target.value });
};

const handlePercentageIncomeChange = (event) => {
  setStaff({ ...staff, percentageIncome: event.target.value });
};
// API WICH TAKE CONECTED ID BETWENE STAFF AND TREATMENT START HERE 
// React.useEffect(() => {
//   const token = localStorage.getItem("accessToken"); // Get the token from local storage
//   axios
//     .get(`${BASE_URL}/api/v1/stafftreatment`, {
//       headers: {
//         "accept": "application/json",
//         "Authorization": `Bearer ${token}`,
//       },
//     })
//     .then((response) => {
//       if (response.status === 200) {
//        // console.log(response,"iz edita caooomacko") 
//         const filteredData = response.data.filter(obj => obj.staffId === id);
//         console.log(filteredData, "Filtered data"); 
//         setstaffTreatmentHaveBindingID(filteredData)
    
//       } else if (response.status === 400) {
//         console.log("Bad request. Invalid staff ID.");
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       console.log("Error occurred while fetching staff treatments data.");
//     });
// }, []);

// API WICH TAKE CONECTED ID BETWENE STAFF AND TREATMENT END HERE
  //onclick containedButton update client in database
  const Btupdate = () => {
    const token = localStorage.getItem("accessToken"); // Get the token from local storage
    const updateStaff = { ...staff,};
    axios
      .put(`${BASE_URL}/api/v1/staff/${id}`, updateStaff, {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {   
          toast.success("Klijent je uspešno izmenjen!");
 
       // console.log("Client data submitted successfully");
        }else toast.error("Izmena klijenta nije uspela!");

       
        dispatch(fetchStaff());

      }
        )
      .catch((error) => {
        console.log(error);
      });
  };
  

  // API TAKE ALL TREATMENTS FOR  ID STAFF   
  React.useEffect(() => {
    const token = localStorage.getItem("accessToken"); // Get the token from local storage
    axios
      .get(`${BASE_URL}/api/v1/stafftreatment/${id}`, {
        headers: {
          "accept": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((response) => {
      //  console.log(response.data.treatment,"response treatment for id ")
      //   setStaffTreatments(response)
        if (response.status === 200) {
          // Assuming that response.data is the treatments data
          setStaffTreatments(response.data.treatment);
        } else if (response.status === 400) {
          console.log("Bad request. Invalid staff ID.");
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("Error occurred while fetching staff treatments data.");
      });
  }, [id]);
  
  // API TAKE ALL TREATMENTS FOR  ID STAFF  END
  //----------------------------------------------end

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const navigateONIndex = () => {
    navigate("/index");
   
  };
  // const navOn = () => {
  //   navigate("/treatment/search");
   
  // };
  const navOnCliShearch = () => {
    navigate("/staff/search");
   
  };
  return (
    <>
   
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,

              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> {maintitle}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <IconButtons onClick={navigateONIndex} />

                  <Typography sx={{ fontSize: "15px" }}>{path}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item md={12}>
                <Typography variant="h6" sx={{ p: 2 }}>
                  {subtitle}
                </Typography>
                <Divider sx={{}} />
              </Grid>
              <Grid container sx={{}}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    p={2}
                    sx={{
                      "@media (min-width: 900px)": {
                        pt: 7,
                      },
                    }}
                  >
        <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
          Ime
        </Typography>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <OutlinedInput
            size="small"
            id="outlined-basic"
            label="Unesi ime"
            value={staff.name}
            onChange={handleNameChange}
            style={{ height: "40px" }}
            startAdornment={
              <InputAdornment position="start">
                <PersonIcon fontSize="small" />
              </InputAdornment>
            }
          />
        </FormControl>
        <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
          Prezime
        </Typography>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <OutlinedInput
            size="small"
            id="outlined-basic"
            label="Unesi prezime"
            value={staff.surname}
            onChange={handleSurNameChange}
            style={{ height: "40px" }}
            startAdornment={
              <InputAdornment position="start">
                <PersonIcon fontSize="small" />
              </InputAdornment>
            }
          />
        </FormControl>
        <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
          Opis
        </Typography>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <OutlinedInput
            size="small"
            id="outlined-basic"
            label="Unesi opis klijenta"
            value={staff.description}
            onChange={handleDescriptionChange}
            style={{ height: "40px" }}
            multiline
          />
        </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box p={2}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Typography>Aktivan</Typography>
                      <SwitchButton checked={staff.active} onChange={(value) => setStaff({ ...staff, active: value })} />
                    </Box>
                    <Box></Box>
                    <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
          Zarada
        </Typography>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <OutlinedInput
            size="small"
            id="outlined-basic"
            label="Zarada"
            type="number"
            value={staff.income}
            onChange={handleIncomeChange}
            style={{ height: "40px" }}
            inputProps={{ min: 0 }}
          />
        </FormControl>
        <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
          Procenat zarade
        </Typography>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <OutlinedInput
            size="small"
            id="outlined-basic"
            label="Procenat zarade"
            type="number"
            value={staff.percentageIncome}
            onChange={handlePercentageIncomeChange}
            style={{ height: "40px" }}
            inputProps={{ min: 0, step: 0.5 }}
            startAdornment={
              <InputAdornment position="start">
                {/* Add appropriate icon component */}
              </InputAdornment>
            }
          />
        </FormControl>
                    <Typography sx={{ fontSize: "17px", mb: 2, mt: 2 }}>
                      Tretman koje obavlja zaposleni
                    </Typography>
                    {/* <TextField
                    size="small"
                    id="outlined-basic"
                    label="Unesi Naziv"
                    variant="outlined"
                    style={{ width: "100%" }}
                  /> */}
                  {/* <ComboBoxTreatment treatmentStaff={treatmentStaff} onChange={handleTreatmentStaffChange} /> */}
                  <ComboBoxEditTreatment treatmentStaff={treatmentStaff} onChange={handleTreatmentStaffChange} onSelectedOptionsChange={handleSelectedTreatmentOptionsChange} />
                  
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="right"
                height={100}
                sx={{
                  gap: 2,
                  mr: 5,
                  "@media (max-width: 347px)": {
                    justifyContent: "center",
                    mr: 0,
                  },
                }}
              >
                <BasicContained onClick={Btupdate} />
                <BasicOutlined onClick={navOnCliShearch} />
              </Box>
            </Grid>
          </Box>
        </Box>
   
      
    </>
  );
};

export default Snew;
