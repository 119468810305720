// import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./Calendar.css";
// import { Box } from "@mui/material";
// import { Button } from "react-bootstrap";
// import moment from "moment";
// import axios from "axios";
// //commponent
// import DropDownUser from "./dropdown/DownUser";
// import DropDownDay from "./dropdown/DownSetDay";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import PrevButton from "./SchedulerButtons/PrevButton";
// import TodayButton from "./SchedulerButtons/TodayButton";
// import NextButton from "./SchedulerButtons/NextButton";
// import ResponsiveDialog from "./dialog/Dialog";
// import Avatar from "@mui/material/Avatar";
// import Stack from "@mui/material/Stack";
// import { deepOrange, deepPurple } from "@mui/material/colors";
// //for editing dialog 
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import { Divider } from "@material-ui/core";
// import ComboClient from "./dialog/combo/ComboforClient";
// import { Typography } from "@mui/material";
// import ComboTreatment from "./dialog/combo/ComboTreatments";
// import DataEvent from "./dialog/DataEvent";
// import ComboBoxStatus from "./dialog/combo/ComboboxStatus";
// import ComboStaff from "./dialog/combo/ComboStaff";
// import TimeStart from "./dialog/TimeStart";
// import TimeEnd from "./dialog/TimeEnd";
// import ComboStatus from "./dialog/combo/ComboStatus";
// import TextField from '@mui/material/TextField';
// import Backdrop from '@mui/material/Backdrop';
// import IconButton from '@mui/material/IconButton';
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import UpdateDialogScheduler from '././updateDialog/UpdateDialogScheduler ';
// import { Select, MenuItem } from '@mui/material';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import ButtonInstandDivForCalendar from ".././Scheduler/ButtonInstandDivForCalendar"
// import { MobileDatePicker } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import ButtonReport from "./../ButtonReport"
// import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
// import ReactDOM from 'react-dom';
// import dayjs from "dayjs";
// import DataPickerForCalendarShowe from "../Scheduler/DataPickerForCalendarShowe"
// import ButtonWIchShowDateOfCalendar from "../Scheduler/ButtonWIchShowDateOfCalendar"
// import {  Drawer } from '@mui/material';
// import { useSelector, useDispatch } from "react-redux"; //redux
// import { fetchClients, fetchTreatments, fetchPreferences, fetchUsers, fetchStaffTreatments, fetchStaff ,postClientTreatments,fetchClientTreatmentStatus } from '../../redux/action';

// React related imports
import React, { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';

// Redux related imports
import { useSelector, useDispatch } from "react-redux"; //redux
import { addNewTreatments, fesecondStateClientTreatment, fetchClients, fetchTreatments, fetchPreferences, fetchUsers, fetchStaffTreatments, fetchStaff, postClientTreatments, fetchClientTreatmentStatus } from '../../redux/action';

// Bootstrap and CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./Calendar.css";
import "react-datepicker/dist/react-datepicker.css";

// Third-party libraries
import moment from "moment";
import axios from "axios";

// MUI core components
import { Box, Button, IconButton, Select, MenuItem, Stack, Avatar, Drawer } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import { useMediaQuery, useTheme } from "@mui/material";

// MUI icons
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { parse, isWithinInterval, subMonths, addMonths } from 'date-fns';


// React-PDF
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Component imports
import DropDownUser from "./dropdown/DownUser";
import DropDownDay from "./dropdown/DownSetDay";
import PrevButton from "./SchedulerButtons/PrevButton";
import TodayButton from "./SchedulerButtons/TodayButton";
import NextButton from "./SchedulerButtons/NextButton";
import ResponsiveDialog from "./dialog/Dialog";
import UpdateDialogScheduler from '././updateDialog/UpdateDialogScheduler ';
import ButtonInstandDivForCalendar from ".././Scheduler/ButtonInstandDivForCalendar"
import ButtonReport from "./../ButtonReport"
import DataPickerForCalendarShowe from "../Scheduler/DataPickerForCalendarShowe"
import ButtonWIchShowDateOfCalendar from "../Scheduler/ButtonWIchShowDateOfCalendar"
import { toast } from 'react-toastify';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    flex: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    width: '15%',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
  },
  cellTretman: {
    width: '30%',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
  },
  cellReduced: {
    width: '15%',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 2,
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  cellText: {
    fontSize: 12,
  },
});


//import Button from '@material-ui/core/Button';
//-----------------------THIS WE USE FOR SET NEXT PREV AND TODAY WEEK
function getWeekNumber(d) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
}

function getWeekStartDate(weekNumber, year) {
  // Jan 1 of 'year'
  var d = new Date(year, 0, 1),
    offset = d.getTimezoneOffset();
  // ISO: week 1 is the one with the year's first Thursday
  // If Jan 1 is Friday, Saturday, or Sunday, we need to go to next Monday
  if (d.getDay() > 1) {
    d.setDate(d.getDate() + (8 - d.getDay()));
  } else if (d.getDay() === 0) {
    d.setDate(d.getDate() + 1);
  }
  // Move to week 1
  d.setDate(d.getDate() - ((d.getDay() + 6) % 7));
  // Adjust to week requested
  d.setDate(d.getDate() + 7 * (weekNumber - 1));
  return new Date(d.getTime() + offset * 60000);
}

function getWeekRange(weekNumber, year) {
  const startDate = getWeekStartDate(weekNumber, year);
  const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000); // Dodaje 6 dana
  return [startDate, endDate];
}

//---------------------------------END---THIS WE USE FOR SET NEXT PREV AND TODAY WEEK--------------------
function Calendar() {

  const dispatch = useDispatch();



  const BASE_URL = 'https://api.adaktarbooking.com';
  const token = localStorage.getItem('accessToken');

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
    const year = date.getFullYear();

    return `${day} / ${month} / ${year}`;
  };
  const formatDateTime = (dateObj) => {
    const year = dateObj.getUTCFullYear();
    let month = dateObj.getUTCMonth() + 1;
    let day = dateObj.getUTCDate();
    let hours = dateObj.getUTCHours();
    let minutes = dateObj.getUTCMinutes();
    let seconds = dateObj.getUTCSeconds();

    // Add leading zeroes if necessary
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
  };


  const staffTreatments = useSelector(state => state.menu.staffTreatments);  // access the data from the state
  const allstaff = useSelector(state => state.menu.staff);  // access the data from the state
  // const clientTreatments = useSelector(state => state.menu.clientTreatments);  // access the data from the state 
  const initialClientTreatments = useSelector(state => state.menu.clientTreatments);
  const [clientTreatments, setClientTreatments] = useState(initialClientTreatments);

  //  const [weekNumber, setWeekNumber] = useState(getWeekNumber(new Date())[1]);
  //  const [year, setYear] = useState(new Date().getFullYear());
  const getInitialStartDate = () => {
    // Check if there's a value in localStorage
    const storedStartDate = localStorage.getItem('startTime');
    // If there is, use that, otherwise calculate the new start date
    return storedStartDate ? new Date(storedStartDate) : getWeekRange(weekNumber, year)[0];
  };
  //  const [startDate, setStartDate] = useState(getWeekRange(weekNumber, year)[0]);
  const [weekNumber, setWeekNumber] = useState(getWeekNumber(new Date())[1]);
  const [year, setYear] = useState(new Date().getFullYear());
  // const startDate = useRef(getWeekRange(weekNumber, year)[0]); // Inicijalna vrednost je 0

  const [startDate, setStartDate] = useState(getWeekRange(weekNumber, year)[0]);



  //console.log(startDate,'sadfwfafws')


  // Ref za praćenje prethodnih vrednosti



  //  const keepOnDate = (newDate) => {
  //   setWeekNumber(getWeekNumber(newDate)[1]);
  //   setYear(newDate.getFullYear());
  //   setStartDate(getWeekRange(getWeekNumber(newDate)[1], newDate.getFullYear())[0]);
  // };

  //datum koji je izabran u datapicker
  const dayfromdatapicker = parse(formatDate(startDate), 'dd / MM / yyyy', new Date());

  //danasnji datum za uzimamo za kreiranje range
  const todaydate = new Date();
  //kreiranje renga
  const startDateRange = {

    start: subMonths(todaydate, 1),
    end: addMonths(todaydate, 1)
  };
  //proveravamo da li je datum iz data pickera da li je u rangu 
  const isStartDateWithinRange = isWithinInterval(dayfromdatapicker, startDateRange);

  // const clientTreatments = useSelector(state => {
  //   if (isStartDateWithinRange) {
  //       return state.menu.clientTreatments;
  //   } else {
  //     fetchClientTreatmentByDate(startDate)



  //   }
  // });


  useEffect(() => {
    if (isStartDateWithinRange) {
      setClientTreatments(initialClientTreatments)
    } else {
      fetchClientTreatmeantDateOutOfRange(startDate)
        .then(data => {
          setClientTreatments(data);
        })
        .catch(error => console.error(error));

    }
  }, [startDate]);




  // // Pokretanje funkcije svakih 10 sekundi
  // setInterval(fetchAndHandleData, 20000);

  const fetchClientTreatmeantDateOutOfRange = async (startDate) => {
    // Pretvaranje startDate u Date objekat
    const currentDate = new Date(startDate);

    // Izračunavanje startTime i endTime
    const startTimeDate = new Date(currentDate);
    startTimeDate.setDate(startTimeDate.getDate() - 30);
    const startFormatted = formatDateTime(startTimeDate);

    const endTimeDate = new Date(currentDate);
    endTimeDate.setDate(endTimeDate.getDate() + 30);
    const endFormatted = formatDateTime(endTimeDate);

    try {
      const response = await axios.post(`${BASE_URL}/api/v1/clienttreatment/calendar`,
        { startTime: startFormatted, endTime: endFormatted },
        {
          headers: {
            "Content-Type": "application/json",
            "accept": "application/json",
            "Authorization": `Bearer ${token}`,
            // Pretpostavka je da je "tenant" već definisan
          }
        });
      console.log(response.data)
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Logika za rukovanje neautorizovanim pristupom
        console.error('Authentication error:', error);
        return null;
      } else {
        if (token !== null) {
          console.error(error);

        }
        return null;
      }
    }
  };


  //dispatch(fesecondStateClientTreatment()); // Pretpostavljam da imate akciju koja se ovako zove

  const secondStateClientTreatment = useSelector(state => state.menu.clientTreatmentSecond);

  const clientsFromApi = useSelector(state => state.menu.clients);
  const treatmentsFromApi = useSelector(state => state.menu.treatments);
  const clientTreatmentStatus = useSelector(state => state.menu.clientTreatmentStatus);

  const [eventData, setEventData] = useState(null); // Create a state for event 
  const [statusISTEKAO, setStatusISTEKAO] = useState(null); // Create a state for event 

  const [clientTreatment, setClientTreatment] = useState([]); //has all  scheduled treatments 
  const [matchingStaffTreatment, setMatchingStaffTreatment] = useState(null);

  const [mergedData, setMergedData] = useState([]);
  //const [staffTreatmentsData, setStaffTreatmentsData] = useState([]);

  const [isFetchingAdditionalData, setIsFetchingAdditionalData] = useState(false);

  const [matchingStaffTreatmentLoaded, setMatchingStaffTreatmentLoaded] = useState(false);
  const [clientTreatmentLoaded, setClientTreatmentLoaded] = useState(false);
  const [matchstaffTreatmentClienMargedataLoaded, setMatchstaffTreatmentClienMargedataLoaded] = useState(false);


  const [margeDataloaded, setMargeDataloaded] = useState(false);

  const [optionStaff, setOptionStaff] = useState([]);
  //for settind dropdown option 
  //for settind dropdown option 
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [staffDropDown, setstaffDropDown] = useState([]);
  const [selectedStaffId, setSelectedStaffId] = useState(null);

  const handleSelectChange = (event) => {
    // we get the id from the selected option value
    const selectedId = event.target.value;
    // set the selected id to the state
    setSelectedStaffId(selectedId === "all" ? null : selectedId);
    setSelectedOption(selectedId);

  };
  //FOR PRINT PDF TREATMENT FOR TODAY 
  const [selectedStartDateForDay, setSelectedStartDateForDay] = useState(null);
  const [selectedEndDateForDay, setSelectedEndDateForDay] = useState(null);
  const [updatedData, setUpdatedData] = useState([]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [dataForPDF, setDataForPDF] = useState([]);
  const [dateFromCalendar, setDateFromCalendar] = useState(null);

  const priorityIds = [
    "08dba41f-a6ce-41b4-8c94-f30f131cf929",
    "08dba41f-a70b-44a7-82fa-86b5eb36e240",
    "08dba41f-a731-4e04-8a17-9c3e91ca0369",
    "08dba41f-a751-4954-8b85-c0208477bb05",
    "08dba41f-a6df-4f62-8945-ce8c582909ff",
    "08dba41f-a705-4296-8813-ab9d4baaf618",
    "08dba41f-a74b-45ba-8304-7dd87467815b",
    "08dba41f-a71d-4947-878d-3a0f227d25a9",
  ];


  //for settind dropdown option END
  //ALL APIS START FROM HERE ------------------------------


  //  this use effect set state statusIstekao with id  wich is istekao from api and kompare in code with anather data 
  useEffect(() => {
    const istekaoObject = clientTreatmentStatus.find(
      (item) => item.title === 'ISTEKAO'
    );

    if (istekaoObject) {
      setStatusISTEKAO(istekaoObject.id)
      //  const paidPositive = clientTreatments.filter(treatment => treatment.clientTreatmentStatusId === istekaoObject.id);

    }
  }, [clientTreatmentStatus]);
  //API WICH TAKE ALL DATA FROM CLIENT TREATMENT START
  // if(  statusISTEKAO !== 0){
  //   console.log(statusISTEKAO,'0asdf00adsf0a0f0088')
  //   const paidPositive = clientTreatments.filter(treatment => treatment.clientTreatmentId === statusISTEKAO);
  //   console.log(paidPositive,'0asdf00adsf0a0f0088')

  // }



  useEffect(() => {

    const matchingStaffResponse = staffTreatments;

    const clientTreatmentResponse = clientTreatments;

    setMatchingStaffTreatment(matchingStaffResponse);
    setMatchingStaffTreatmentLoaded(true);

    setClientTreatment(clientTreatmentResponse);
    setClientTreatmentLoaded(true);



  }, []);




  useEffect(() => {
    if (clientTreatmentLoaded && matchingStaffTreatmentLoaded) {
      const merged = clientTreatment.map((client) => {
        const matchingStaff = matchingStaffTreatment.find(
          (staff) => staff.id === client.staffTreatmentId
        );

        const mergedObject = matchingStaff
          ? { ...client, ...matchingStaff }
          : client;

        const mergedObjectWithID = {
          ...mergedObject,
          clientTreatmentID: client.id,
        };

        return mergedObjectWithID;
      });
      setMergedData(merged);
      setMargeDataloaded(true);
    }
  }, [clientTreatment, matchingStaffTreatment, clientTreatmentLoaded, matchingStaffTreatmentLoaded]);

  useEffect(() => {
    if (margeDataloaded === true && matchingStaffTreatmentLoaded === true && clientTreatmentLoaded === true) {
      // if (false) {

      const fetchAdditionalData = async () => {
        let mergedDataCopy = [...mergedData];
        for (let i = 0; i < mergedDataCopy.length; i++) {
          if (!mergedDataCopy[i].clientData && !mergedDataCopy[i].treatmentData) {
            try {
              // Find the client in the clientsFromApi state
              const clientData = clientsFromApi.find(client => client.id === mergedDataCopy[i].clientId);

              // If the client is found, add the client data to the mergedDataCopy
              if (clientData) {
                mergedDataCopy[i].clientData = clientData;
              }

              // Find the treatment in the treatmentsFromApi state
              const treatmentData = treatmentsFromApi.find(treatment => treatment.id === mergedDataCopy[i].treatmentId);

              // If the treatment is found, add the treatment data to the mergedDataCopy
              if (treatmentData) {
                mergedDataCopy[i].treatmentData = treatmentData;
              }

            } catch (error) {
              console.error("An error occurred while fetching data:", error);
            }
          }
        }
        return mergedDataCopy;
      };

      const fetchAdditionalDataa = async () => {
        let mergedDataCopy = [...mergedData];

        for (let i = 0; i < mergedDataCopy.length; i++) {
          if (!mergedDataCopy[i].clientData && !mergedDataCopy[i].treatmentData) {
            try {
              // Find the client in the clientsFromApi state
              const clientData = clientsFromApi.find(client => client.id === mergedDataCopy[i].clientId);

              // If the client is found, add the client data to the mergedDataCopy
              if (clientData) {
                mergedDataCopy[i].clientData = clientData;
              }

              // Find the treatment in the treatmentsFromApi state
              const treatmentData = treatmentsFromApi.find(treatment => treatment.id === mergedDataCopy[i].treatmentId);

              // If the treatment is found, add the treatment data to the mergedDataCopy
              if (treatmentData) {
                mergedDataCopy[i].treatmentData = treatmentData;
              }

            } catch (error) {
              console.error("An error occurred while fetching data:", error);
            }

          }
        }

        return mergedDataCopy;
      };

      if (matchingStaffTreatment && clientTreatment && matchingStaffTreatment.length > 0 && clientTreatment.length > 0) {
        fetchAdditionalData().then(newData => {
          if (JSON.stringify(newData) !== JSON.stringify(mergedData)) {
            setMergedData(newData);
          }

          // Call the second function after the first one has completed
          fetchAdditionalDataa().then(newDataa => {
            if (JSON.stringify(newDataa) !== JSON.stringify(mergedData)) {
              setMergedData(newDataa);
            }
          });
        });
      }
    }
  }, [matchingStaffTreatment, clientTreatment, token, mergedData]); // Removed mergedData from the dependencies

  useEffect(() => {

    const activeStaff = allstaff.filter(staff => staff.active === true);


    const usersData = activeStaff.map((user) => ({
      name: user.name,
      id: user.id,
      color: user.color,
    }));

    setOptionStaff(usersData);
    setstaffDropDown(usersData);

  }, [allstaff]);


  useEffect(() => {
    const responseClient = clientTreatments
    const responseStaff = staffTreatments
    // replace clientTreatment and matchingStaffTreatment with the response from the API
    setClientTreatment(responseClient);
    setMatchingStaffTreatment(responseStaff);
  }, [eventData, clientTreatments, staffTreatments]);



  //-------------------------------KEEP UPDATE DATA ON EVERY ONE MINUTE---------------------------
  //HERE IS API AND LOGIC WICH  ADD SOME MORE DATA IN MARGED STATE END 
  //UPDATE PART START

  //UPDATE PART START



  const toggleDataUpdatedFunction = (updated) => {
    // console.log("Data was toggled:", updated);
  };

  const handleUpdate = (updatedEvent) => {
    setEventData(updatedEvent); // Update state with new event data
    // dispatch(fetchClients());
    // dispatch(fetchTreatments());
    // dispatch(fetchStaffTreatments());
    // dispatch(fetchStaff());
    // dispatch(postClientTreatments());
    // dispatch(fetchUsers());
    // dispatch(fetchClientTreatmentStatus());
    // dispatch(fetchPreferences()); 
    // additional processing if needed
    // Call the function to update the scheduler data after the event is updated
  };
  const handleInsert = (newEvent) => {
    setEventData(newEvent);
    dispatch(postClientTreatments());


  };

  useEffect(() => {

    const fetchData = async () => {
      const currentDate = new Date(); // Get today's date
      currentDate.setMonth(currentDate.getMonth() + 3); // Add three months

      // Format the date
      const year = currentDate.getUTCFullYear();
      let month = currentDate.getUTCMonth() + 1;
      let day = currentDate.getUTCDate();
      let hours = currentDate.getUTCHours();
      let minutes = currentDate.getUTCMinutes();
      let seconds = currentDate.getUTCSeconds();

      // Add leading zeroes if necessary
      month = month < 10 ? `0${month}` : month;
      day = day < 10 ? `0${day}` : day;
      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;


      try {
        const responseClient = clientTreatments
        const responseStaff = staffTreatments
        // replace clientTreatment and matchingStaffTreatment with the response from the API
        setClientTreatment(responseClient);
        setMatchingStaffTreatment(responseStaff);


      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    fetchData();
  }, [eventData, clientTreatments, staffTreatments]);

  useEffect(() => {
    // Create a function to fetch and handle data


    const fetchAndHandleData = async () => {
      //console.log('pozvalo se na 20se')

      const token = localStorage.getItem('accessToken');

      if (!token) {
        return
      }

      const currentDate = new Date();

      // Add 3 months to the current date for endTime
      const startTimeDate = new Date(currentDate);
      startTimeDate.setDate(startTimeDate.getDate() - 30);
      const startFormatted = formatDateTime(startTimeDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

      // Calculate endTime (30 days after current date)
      const endTimeDate = new Date(currentDate);
      endTimeDate.setDate(endTimeDate.getDate() + 30);
      const endFormatted = formatDateTime(endTimeDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");


      try {
        const response = await axios.post(`${BASE_URL}/api/v1/clienttreatment/calendar`,
          { startTime: startFormatted, endTime: endFormatted },
          {
            headers: {
              "Content-Type": "application/json",
              "accept": "application/json",
              "Authorization": `Bearer ${token}`,
              // "tenant": tenant  // Dodajte ovu liniju ako imate definisanu 'tenant' promenljivu
            }
          });

        const existingIds = new Set(clientTreatments.map(item => item.id)); // Pretpostavka da svaki objekat ima 'id'
        const newItems = response.data.filter(item =>
          !existingIds.has(item.id) && item.clientTreatmentStatusName !== "ISTEKAO"
        );
        if (newItems.length > 0) {
          setClientTreatments([...clientTreatments, ...newItems]);
        }
      } catch (error) {
        if(error.status === 401)
        {

        }
        console.error('Došlo je do greške pri API pozivu', error);
      }
      //   dispatch(fesecondStateClientTreatment(startDate));

      //  const differences = secondStateClientTreatment.filter(itemInSecondState => 
      //   !clientTreatments.some(itemInClient => itemInClient.id === itemInSecondState.id)
      // );
      // if (differences.length > 0) {
      //   setClientTreatments(currentTreatments => [...currentTreatments, ...differences]);
      // }
      setTimeout(fetchAndHandleData, 20000);


    };
    // Set an interval and store its ID
    // const intervalId = setInterval(fetchAndHandleData, 20000);

    // Return a cleanup function to clear the interval
    // return () => clearInterval(intervalId);
    fetchAndHandleData();

  }, []); // Empty dependency array means this effect runs once after the initial render


  //UPDATE PART END

  //ALL APIS END FROM HERE  ---------------------------
  const [selectedDate, setSelectedDate] = useState(new Date());
  //const [currentDate, setCurrentDate] = useState(new Date()); // use for nex prev and today
  // const [objectForKey, setobjectForKey] = useState({}); //setobjectForKey key is a option for every key we create object with selectedData and events
  const [events, setEvents] = useState([]); // save event from chaild compo dialog.js into events
  //----- THIS CODE SET  PREV NEX AND TODAY WEEK

  //const [startDate, endDate] = getWeekRange(weekNumber, year);

  const [endDate, setEndDate] = useState(getWeekRange(weekNumber, year)[1]);

  const handleNextWeek = () => {
    const newStartDate = new Date(
      startDate.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    if (newStartDate.getFullYear() !== year) {
      setYear(newStartDate.getFullYear());
    }
    setStartDate(newStartDate);
  };

  const handlePrevWeek = () => {
    const newStartDate = new Date(
      startDate.getTime() - 7 * 24 * 60 * 60 * 1000
    );
    if (newStartDate.getFullYear() !== year) {
      setYear(newStartDate.getFullYear());
    }
    setStartDate(newStartDate);
  };

  const handleTodayWeek = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startDay = today.getDate() - (dayOfWeek - 1);
    const newStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      startDay
    );
    setYear(today.getFullYear());
    setStartDate(newStartDate);
  };

  //----------------------------END OF CODE FOR NEXT TODAY PREV- WEEK--------------

  //------------------------CODE FOR -- AND NEX3 DAY -----

  const handleNext3days = () => {
    const newStartDate = new Date(
      startDate.getTime() + 3 * 24 * 60 * 60 * 1000
    );
    if (newStartDate.getFullYear() !== year) {
      setYear(newStartDate.getFullYear());
    }
    setStartDate(newStartDate);
  };

  const handlePrev3days = () => {
    const newStartDate = new Date(
      startDate.getTime() - 3 * 24 * 60 * 60 * 1000
    );
    if (newStartDate.getFullYear() !== year) {
      setYear(newStartDate.getFullYear());
    }
    setStartDate(newStartDate);
  };

  const handleToday3days = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startDay = today.getDate() - ((dayOfWeek - 1) % 3); // Change this line to match your desired start day
    const newStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      startDay
    );
    setYear(today.getFullYear());
    setStartDate(newStartDate);
  };

  //------------------------CODE FOR  AND NEX3 DAY -----

  //---------------------------CODE FOR DAY -------

  const handleNextDay = () => {
    const newStartDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
    if (newStartDate.getFullYear() !== year) {
      setYear(newStartDate.getFullYear());
    }
    setStartDate(newStartDate);
  };

  const handlePrevDay = () => {
    const newStartDate = new Date(startDate.getTime() - 24 * 60 * 60 * 1000);
    if (newStartDate.getFullYear() !== year) {
      setYear(newStartDate.getFullYear());
    }
    setStartDate(newStartDate);
  };

  const handleToday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startDay = today.getDate();//- ((dayOfWeek - 1) % );
    const newStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      startDay
    );
    setYear(today.getFullYear());
    const savedDate = localStorage.getItem('startTime');

    if (savedDate) {

      const date = new Date(savedDate);
      setStartDate(date);

      localStorage.removeItem('startTime');

    } else {
      setStartDate(newStartDate);

    }

  };

  //-----------------------END----CODE FOR DAY -------

  //------THIS CODE SET TIME IN TABLE ----------------
  const timeFormat = "HH:mm";
  const interval = 60; // in minutes
  const start = moment("00:00", timeFormat);
  const end = moment("23:59", timeFormat);
  const numIntervals = end.diff(start, "minutes") / interval + 1;

  // Generate the intervals
  const intervals = [];
  for (let i = 0; i < numIntervals; i++) {
    intervals.push(
      start
        .clone()
        .add(i * interval, "minutes")
        .format(timeFormat)
    );
  }

  // Generate the rows
  const rows = [];
  for (let i = 0; i < 24; i++) {
    const cells = intervals.map((interval) => <td key={interval}></td>);
    rows.push(
      <tr key={i}>
        <th scope="row">{start.clone().add(i, "hours").format(timeFormat)}</th>
        <td key={i}>{start.clone().add(i, "hours").format(timeFormat)}</td>
        {cells}
      </tr>
    );
  }
  //------END OF  CODE SET TIME IN TABLE ----------------
  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(selectedDate);
  //--------------------------- TAKE USER SET EVET FOR EVERY USER------------------------
  // --------------------------TAKE USER FROM AXIOS PUT THIS USER IN OPTION AND FOR THIS OPTION WE CREATE OBJECT WICH HAVE EVENTS  ----------------
  //--------------------------THIS EVENT WE TAKE FRO EVENTS BEFORE THAT WE COMPARE EVERY EVENT WITH OPTION IF THEY SAME PUT IN OBJECT EEVNT FROM EVENTS-------

  const onEventSave = (newEvent) => {
    const newEvents = mergedData.map((event) => {
      const startDate = new Date(event.startTime);
      const endDate = new Date(event.endTime);

      const date = `${startDate.getMonth() + 1}.${startDate.getDate()}.${startDate.getFullYear()}`;
      const startTime = startDate.toTimeString().slice(0, 5);
      const endTime = endDate.toTimeString().slice(0, 5);

      const clientName = event.clientData && event.clientData.name;
      const clientSurname = event.clientData && event.clientData.surname;
      const clientID = event.clientData && event.clientData.id;
      const treatmentTitle = event.treatmentData && event.treatmentData.title;
      const color = event.treatmentData && event.treatmentData.color;
      const treatmentId = event.treatmentData && event.treatmentData.id;

      // const updatedEvent = {
      //   id: event.id,
      //   clientName: clientName,
      //   clientSurname: clientSurname,
      //   price: event.price,
      //   paid: event.paid,
      //   treatment: treatmentTitle,
      //   treatmentId: treatmentId,
      //   date: date,
      //   startTime: startTime,
      //   endTime: endTime,
      //   status: event.clientTreatmentStatusId,
      //   treatmentColor: color,
      //   clientTreatmentId: event.clientTreatmentID,
      //   staffID: event.staffId,
      //   clientId: clientID,
      //   staffTreatmentId: event.staffTreatmentId,
      //   clientTreatmentStatusId: event.clientTreatmentStatusId,
      // };

      // Update the mergedData state with the new event
      // setMergedData((prevMergedData) => [...prevMergedData, updatedEvent]);

      return {
        id: event.id,
        clientName: clientName,
        clientSurname: clientSurname,
        price: event.price,
        paid: event.paid,
        treatment: treatmentTitle,
        treatmentId: treatmentId,
        date: date,
        startTime: startTime,
        endTime: endTime,
        status: event.clientTreatmentStatusId,
        treatmentColor: color,
        clientTreatmentId: event.clientTreatmentID,
        staffID: event.staffId,
        clientId: clientID,
        staffTreatmentId: event.staffTreatmentId,
        clientTreatmentStatusId: event.clientTreatmentStatusId,
        // add other properties you want to include here...
      };
    });

    //here we set  to dont show event wich has statu ISTEKAO
    const filteredEvents = newEvents.filter(
      (event) => event.clientTreatmentStatusId !== statusISTEKAO
    );

    setEvents(filteredEvents);
  };




  useEffect(() => {
    onEventSave();
  }, [mergedData]);

  const onEventDeleteAll = () => {
    setEvents([]);
  };

  //--------------------END----------------------------//
  //const opriton part you can add some atribute of my object staff like add id to se or this ${option.id} - ${option.color}
  //  const options = ["All", ...optionStaff.map((option) => `${option.name}`)]; // Add "All" to the options array 
  // const options = ["All", ...optionStaff.map((option) => `${option.name} - ${option.id}`)]; // Add "All" to the options array 
  //kod pre setovanja 
  // const options = ["All", ...optionStaff.map((option) => `${option.id}`)]; // Add "All" to the options array
  //  const [selectedOption, setSelectedOption] = useState(null);
  // setovano sortiranje prvih 5 
  const otherOptions = optionStaff
    .filter(option => !priorityIds.includes(option.id))  // exclude priority IDs
    .map(option => `${option.id}`);

  const options = ["All", ...priorityIds, ...otherOptions];
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (options.length > 0 && !selectedOption) {
      setSelectedOption(options[0]); // Default selected option will be "All"
    }
  }, [options]);

  const wichUserSelected = selectedOption; // in userWichIsSelected we put selected user and in table  we use userWichIsSelected  const to compare with anather walue

  //take selekted user from selectedOption and compare this with optionStaff if thay have same
  const selectedUserr = optionStaff.find(
    (staff) => `${staff.name}  - ${staff.id}` === selectedOption

  );

  //whan take from api picture instand `${staff.name}  - ${staff.id}` put picture
  // for every string or name in options we create object and we assign atribute from event to option string if event.staff same option string
  const optionObjects = options.map((option) => {
    const eventsForOption = events.reduce((acc, event) => {
      if (event.staffID === option) {
        const matchingObject = acc.find((obj) => obj.name === event.staffID);
        if (matchingObject) {
          matchingObject.events.push({
            date: event.date,
            startTime: event.startTime,
            endTime: event.endTime,
            client: `${event.clientName} ${event.clientSurname}`,
            treatment: event.treatment,
            status: event.status,
            treatmentColor: event.treatmentColor,
            paid: event.paid,
            price: event.price,
            clientTreatmentId: event.clientTreatmentId, //id of scheduled  treatment
            staffId: event.staffID,//staff id 
            clientId: event.clientId,
            staffTreatmentId: event.staffTreatmentId,
            clientTreatmentStatusId: event.clientTreatmentStatusId,
            treatmentId: event.treatmentId,

            // Add other fields you want to include here...
          });
        } else {
          acc.push({
            name: event.staffID,
            events: [
              {
                date: event.date,
                startTime: event.startTime,
                endTime: event.endTime,
                client: `${event.clientName} ${event.clientSurname}`,
                paid: event.paid,
                price: event.price,
                treatment: event.treatment,
                status: event.status,
                treatmentColor: event.treatmentColor,

                clientTreatmentId: event.clientTreatmentId, //id of scheduled  treatment
                staffId: event.staffID,//staff id 
                clientId: event.clientId,
                staffTreatmentId: event.staffTreatmentId,
                clientTreatmentStatusId: event.clientTreatmentStatusId,

                treatmentId: event.treatmentId,
                // Add other fields you want to include here...
              },
            ],
          });
        }
      }
      return acc;
    }, []);

    const matchingObject = eventsForOption.find((obj) => obj.name === option);

    return matchingObject || { name: option, events: [] };
  });

  const optionObjectMap = optionObjects.reduce((acc, obj, index) => {
    acc[options[index]] = obj;
    return acc;
  }, {});

  // optionObjects.forEach((obj) => {
  // });

  // console.log(optionObjects, "optionObjectsoptionObjects")
  // console.log(options[1], optionObjectMap[options[1]], "ja sam novii");
  // console.log(events, "eventssssssss")
  //-----------------------------------END END END -----------------------------------------------------------------
  //---------------------------TAKE EVENT FOR SELECTED USER --------------------------- THERE WHAN WE CHANGE SELECTED USER WE SHOW FOR ANATHER USER EVENT, HIS EVENTS-------we have events for selected user
  const [selectedUserEvents, setSelectedUserEvents] = useState([]); //state for events for selected user
  //  console.log(selectedUserEvents, "events");
  useEffect(() => {
    const selectedUserObject = optionObjectMap[wichUserSelected]; //take selected user and put in selectedUserObject
    if (selectedUserObject && selectedUserObject.events) {
      setSelectedUserEvents(selectedUserObject.events); //put events for this object in selectedUserEvents
    } else {
      setSelectedUserEvents([]);
    }
  }, [wichUserSelected, events]);


  //-----------------END----------TAKE EVENT FOR SELECTED USER ------------------
  const handleSelectedOption = (option) => {
    //For DropDownUser Comp
    setSelectedOption(option);
  };

  //-----------------END---------- TAKE USER SET EVET FOR EVERY USER------------------------



  //--------------Sets for DropDownDay comp...--------------------------
  const optionDay = ["Week", "Day3", "Day"];
  const [selectedDay, setSelectedDay] = useState(optionDay[0]);


  useEffect(() => {
    if (selectedDay === 'Day') {
      handleToday(startDate); // This will set startDate to today's date
    }
  }, [selectedDay]);

  const handleSelectedDay = (optionDay) => {
    //For DropDownUser Comp
    setSelectedDay(optionDay);
  };
  let numDays;
  if (selectedDay === "Day3") {
    numDays = 3;
  } else if (selectedDay === "Week") {
    numDays = 7;
  } else if (selectedDay === "Day") {
    numDays = 1;
  }
  //this part of code is for set date for week or 3 days or day. We put this part of code here because of initialization of selected day
  // const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const weekdays = ["Sun", "Mon", "Fri", "Wed", "Thu", "Fri", "Sat"];
  const days = [];
  for (let i = 0; i < numDays; i++) {
    const date = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
    const formattedDate = (date.getMonth() + 1) + '/' // getMonth() starts from 0 for January, hence the +1
      + date.getDate() + '/'
      + date.getFullYear(); // This will give you M-D-YYYY format
    days.push({
      date: date.getDate(), // This gives you the day of the month
      day: weekdays[date.getDay()],
      fullDate: formattedDate, // This is the full date in M-D-YYYY format
    });
  }

  let datePrinted = false;
  //-----------END---Sets for DropDownDay comp...--------------------------
  // Ovde menjamo printedDates u printedEvents
  // const printedEvents = new Set();
  let renderedDays;
  useEffect(() => {
    if (wichUserSelected === "All") {
      setSelectedDay("Day");
    }
  }, [wichUserSelected]);

  const printedEvents = new Set();

  if (wichUserSelected === "All") {
    renderedDays = days.slice(0, 1);
    if (selectedDay !== "Day") {
      setSelectedDay("Day");
    }
  } else if (selectedOption && selectedDay === "Week") {
    renderedDays = days;
  } else if (selectedOption && selectedDay === "Day3") {
    renderedDays = days.slice(0, 3);
  } else if (selectedOption && selectedDay === "Day") {
    renderedDays = days.slice(0, 1);
  } else {
    renderedDays = days; // default case
  }
  //FOR DIALOG EDIT 
  const [dialogOpen, setDialogOpen] = useState(false);
  const [timeTaken, setTimeTaken] = useState("");
  const [dateTaken, setDateTaken] = useState("");
  const [dialogOpenInsert, setDialogOpenInsert] = useState(false);
  //for responsive 
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isDeviceWidthOver1300 = useMediaQuery('(min-width:1300px)');

  const [selectedEvent, setSelectedEvent] = React.useState(null);

  const [isIzmeniDisabled, setChangeDisabled] = useState(true);

  const [isSidebarOpen, setSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };


  // const handleClickOpen = (eventData) => {
  //   console.log(eventData,"aseflkjdif")
  //   setEvent({
  //     date: eventData.date,
  //     client: eventData.client
  //     ,
  //     treatment: eventData.treatment,
  //     timeStart: eventData.startTime,
  //     timeEnd: eventData.endTime,
  //     status: eventData.status,
  //     staff: eventData.staffID,
  //   });
  //   setChangeDisabled(false);
  //   setDialogOpen(true);
  // };
  const handleClickOpen = (eventData) => {
    setSelectedEvent(eventData);
    setChangeDisabled(true);
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);



    setEvent({
      date: "",
      client: "",
      treatment: "",
      timeStart: "",
      timeEnd: "",
      status: "",
      staff: "",
    });

    setChangeDisabled(true);
  };
  // const handleUpdate = (updatedEvent) => {
  //   // Perform the update logic using the updatedEvent data
  //   console.log('Updated Event:', updatedEvent);

  //   const updatedMergedData = mergedData.map((event) => 
  //     event.id === updatedEvent.id ? updatedEvent : event
  //   );
  //   setMergedData(updatedMergedData);
  //   handleClose();
  // };

  const [event, setEvent] = useState({
    date: "",
    client: "",
    treatment: "",
    timeStart: "",
    timeEnd: "",
    status: "",
    staff: "",

  });
  const handlePriceChange = (value) => {
    setEvent((prevEvent) => ({ ...prevEvent, price: value }));
  };

  const handlePaidChange = (value) => {
    setEvent((prevEvent) => ({ ...prevEvent, paid: value }));
  };
  const handleDateChange1 = (newDate) => {
    setWeekNumber(getWeekNumber(newDate)[1]);
    setYear(newDate.getFullYear());
    setStartDate(getWeekRange(getWeekNumber(newDate)[1], newDate.getFullYear())[0]);
  };

  const handleDateChange = (value) => {

    setWeekNumber(getWeekNumber(value)[1]);
    setYear(value.getFullYear());
    setStartDate(getWeekRange(getWeekNumber(value)[1], value.getFullYear())[0]);

    // Extracting only the date from the selected value
    setChangeDisabled(false);
    const date = new Date(value);
    const formattedDate = `${("0" + (date.getMonth() + 1)).slice(-2)}.${(
      "0" + date.getDate()
    ).slice(-2)}.${date.getFullYear()}`;

    // Updating the event state with the formatted date
    setEvent((prevEvent) => ({ ...prevEvent, date: formattedDate }));

    setDatePickerDate(date);
    setDatePickerVisible(false);

    // check if the year is different and update it
    if (date.getFullYear() !== year) {
      setYear(date.getFullYear());
    }

    // update the startDate with the selected date
    setStartDate(date);
  };

  const handleClientChange = (client) => {
    setChangeDisabled(false);
    const clientName = client ? client.name : "";
    const clientPhone = client ? client.phone : "";
    setEvent((prevEvent) => ({
      ...prevEvent,
      client: `${clientName} - ${clientPhone}`,
    }));
  };
  const handleTreatmentChange = (treatment, isDifferent) => {
    setChangeDisabled(false);
    setEvent((prevEvent) => ({ ...prevEvent, treatment }));
  };

  const handleTimeStartChange = (timeStart) => {
    setChangeDisabled(false);
    setEvent((prevEvent) => ({ ...prevEvent, timeStart }));
  };

  const handleTimeEndChange = (timeEnd) => {
    setChangeDisabled(false);
    setEvent((prevEvent) => ({ ...prevEvent, timeEnd }));
  };

  const handleStatusChange = (status) => {
    setChangeDisabled(false);
    setEvent((prevEvent) => ({ ...prevEvent, status: status.label }));
  };
  const handleStaffChange = (staff) => {
    setChangeDisabled(false);
    const staffName = staff ? staff.name : "";
    const staffID = staff ? staff.id : "";
    setEvent((prevEvent) => ({
      ...prevEvent,
      staff: `${staffName} - ${staffID}`,
    }));
    setEvent((prevEvent) => ({
      ...prevEvent,
      staff: `${staffName} - ${staffID}`,
    }));
  };
  const handleIzmeniClick = () => {
    const currentDate = new Date();

    handleClose();
  };

  // code for show nex and before scheduler  and remove table fro first option All
  const [itemsPerPage, setItemsPerPage] = useState(
    window.innerWidth <= 419
      ? 1
      : window.innerWidth <= 605
        ? 2
        : window.innerWidth < 801
          ? 3
          : 6
  );

  const [pageIndex, setPageIndex] = useState(0);
  const filteredOptions = options.filter(option => option !== 'All');
  const slicedOptions = filteredOptions.slice(pageIndex * itemsPerPage, pageIndex * itemsPerPage + itemsPerPage);

  useEffect(() => {
    const handleResize = () => {
      setItemsPerPage(
        window.innerWidth <= 419
          ? 1
          : window.innerWidth <= 732
            ? 2
            : window.innerWidth < 801
              ? 3
              : 6
      );
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const pageSize = window.innerWidth <= 419 ? 1 :
    window.innerWidth <= 732 ? 2 :
      window.innerWidth <= 801 ? 3 : 6;

  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [datePickerDate, setDatePickerDate] = useState(new Date());




  const handlePClick = () => {
    setDatePickerVisible(true);
  };

  //for printig treatment for today start here 
  const handleApiResponse = (response) => {
    setDataForPDF(response.data);
  };
  function combineDateAndTime(date, endOfDay) {
    let hours, minutes, seconds;
    if (endOfDay) {
      hours = 24; // Set end time to 23:00:00
      minutes = 0;
      seconds = 0;
    } else {
      hours = 8; // Set start time to 07:00:00
      minutes = 0;
      seconds = 0;
    }
    let newDate = new Date(date.setHours(hours, minutes, seconds));
    return newDate.toISOString();  // Or format as you need
  }



  function formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    return `${hours}:${minutes}`;
  }

  const printPdfForDay = async () => {
    toast.info("PDF se kreira, molimo vas sačekajte", {
      autoClose: 5000, // 5000ms = 5 seconds
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false
    });
    const parsedStartDate = new Date(startDate);

    // Postavite startno vreme na 07:00 ujutro
    const startOfDay = new Date(parsedStartDate.getFullYear(), parsedStartDate.getMonth(), parsedStartDate.getDate(), 7, 0, 0);

    // Postavite krajnje vreme na 23:00 uveče
    const endOfDay = new Date(parsedStartDate.getFullYear(), parsedStartDate.getMonth(), parsedStartDate.getDate(), 23, 0, 0);

    // Funkcija za formatiranje datuma u ISO format
    function formatToISO(dateTime) {
      return dateTime.toISOString();
    }

    const formattedStartDate = formatToISO(startOfDay);
    const formattedEndDate = formatToISO(endOfDay);

    const requestData = {
      startTime: formattedStartDate,
      endTime: formattedEndDate,
    };

    const token = localStorage.getItem('accessToken');
    const headers = {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/clienttreatment/calendar`,
        requestData,
        { headers }
      );

      const upgradedData = [];
      for (const item of response.data) {
        const updatedItem = { ...item };

        const stafftreatmentResponse = await axios.get(
          `${BASE_URL}/api/v1/stafftreatment/info/${updatedItem.staffTreatmentId}`,
          { headers }
        );
        const stafftreatmentData = stafftreatmentResponse.data;

        const treatmentResponse = await axios.get(
          `${BASE_URL}/api/v1/treatment/${stafftreatmentData.treatmentId}`,
          { headers }
        );
        const treatmentData = treatmentResponse.data;

        const staffResponse = await axios.get(
          `${BASE_URL}/api/v1/staff/${stafftreatmentData.staffId}`,
          { headers }
        );
        const staffData = staffResponse.data;

        const clientResponse = await axios.get(
          `${BASE_URL}/api/v1/client/${updatedItem.clientId}`,
          { headers }
        );
        const clientData = clientResponse.data;

        updatedItem.staffName = staffData.name;
        updatedItem.treatmentName = treatmentData.title;
        updatedItem.clientName = clientData.name;
        upgradedData.push(updatedItem);
      }

      setUpdatedData(upgradedData);
      handleApiResponse(response);
      setIsGeneratingPDF(true); // Označavamo da se generiše PDF
    } catch (error) {
      console.error(error);
    }

  };

  useEffect(() => {
    if (isGeneratingPDF) {
      generatePDFtreatmentFortoday();
      setIsGeneratingPDF(false); // Resetujemo stanje generisanja PDF-a
    }
  }, [isGeneratingPDF]); // Pratimo promene stanja generisanja PDF-a
  const generatePDFtreatmentFortoday = () => {
    // Generiranje PDF-a
    const win = window.open('', '_blank');
    win.document.write('<html><head><title>PDF Viewer</title></head><body style="margin: 0;"><div id="root"></div></body></html>');
    win.document.close();

    ReactDOM.render(
      <PDFViewer width="100%" height="100%">
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.table}>
              <View style={styles.row}>
                <View style={styles.cellReduced}>
                  <Text style={styles.headerText}>Ime klijenta</Text>
                </View>
                <View style={styles.cellTretman}>
                  <Text style={styles.headerText}>Tretman</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.headerText}>Osoblje:</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.headerText}>Pocinje</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.headerText}>Zavrsava</Text>
                </View>
                <View style={styles.cellReduced}>
                  <Text style={styles.headerText}>Datum</Text>
                </View>
              </View>
              {updatedData.map((item, rowIndex) => (
                <View style={styles.row} key={rowIndex}>
                  <View style={styles.cellReduced}>
                    <Text style={styles.cellText}>{item.clientName}</Text>
                  </View>
                  <View style={styles.cellTretman}>
                    <Text style={styles.cellText}>{item.treatmentName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{item.staffName}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{formatTime(new Date(item.startTime))}</Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.cellText}>{formatTime(new Date(item.endTime))}</Text>
                  </View>
                  <View style={styles.cellReduced}>
                    <Text style={styles.cellText}>{formatDate(new Date(item.startTime))}</Text>
                  </View>
                </View>
              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>,
      win.document.getElementById('root')
    );
  };
  //for printig treatment for today END here 
  // const formatDate = (date) => {
  //   const isIOSChrome = /CriOS/i.test(navigator.userAgent);
  //   const [month, day, year] = date.split(isIOSChrome ? '.' : '/');
  //   return isIOSChrome
  //     ? `${month.padStart(2, '0')}.${day.padStart(2, '0')}.${year}`
  //     : `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;
  // };
  //   function isWindowsChrome() {
  //     console.log('@Checking if Windows Chrome...');
  //     console.log('@User agent:', navigator.userAgent);
  //     console.log('@Platform:', navigator.platform);
  //     return /Chrome/.test(navigator.userAgent) &&
  //            /Win/.test(navigator.platform);
  // }
  const resetDateFromCalendar = () => {
    setDateFromCalendar(null);
  }
  const screenWidth = window.innerWidth;
  let timeInterval;
  let widthPercentage;

  if (screenWidth > 1100) {
    timeInterval = 6;
    widthPercentage = '16.66%'; // Assuming you want 1/6 (16.66%) for time interval of 6
  } else if (screenWidth > 800 && screenWidth <= 1100) {
    timeInterval = 4;
    widthPercentage = '25%'; // 1/4 for time interval of 4
  } else {
    timeInterval = 4; // Or set another value for screens smaller than 801px
    widthPercentage = '33.33%'; // Assuming you want 1/3 for smaller screens
  }

  return (
    <>
      {isDeviceWidthOver1300 ? (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: { xs: "center", md: "space-between" },
            gap: 2,
          }}
        >
          {/* <DropDownUser
             selectedOption={selectedOption}
             options={options}
             onOptionChange={handleSelectedOption}
             fullWidth
           /> */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: { xs: "center", md: "space-between" },
              gap: 2,
            }}
          >
            <div style={{ display: isDropdownVisible ? "block" : "none" }}>
              <DropDownUser
                selectedOption={selectedOption}
                options={options}
                onOptionChange={handleSelectedOption}
                fullWidth
              />
            </div>

            <Box width={{ xs: '200px', sm: '200px', md: '200px', lg: '200px', xl: '200px' }}>
              <Select
                onChange={handleSelectChange}
                sx={{
                  width: '100%',  // this will take 100% width of the parent Box
                  height: '40px',
                  padding: '10px',
                  borderRadius: 0,
                  border: '1px solid lightgray',
                }}
                value={wichUserSelected} // assuming this is the selected value for the dropdown
              >
                <MenuItem value="All">All</MenuItem>
                {staffDropDown.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>
                    {staff.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>

          </Box>
          <Box >

            {selectedDay === "Week" && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: { xs: "center", md: "space-between" },
                  gap: 2,
                }}
              >
                <PrevButton onClick={handlePrevWeek} />
                <p style={{ margin: 0, alignSelf: "center" }} onClick={handlePClick}>
                  {formatDate(startDate)} -{" "}
                  {formatDate(new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000))}
                </p>
                {isDatePickerVisible && (
                  <DataPickerForCalendarShowe
                    isVisible={isDatePickerVisible}
                    datePickerDate={datePickerDate}
                    handleDateChange={handleDateChange}
                    setDatePickerVisible={setDatePickerVisible}
                  />
                )}
                <ButtonWIchShowDateOfCalendar onClick={() => setDatePickerVisible(true)}>

                </ButtonWIchShowDateOfCalendar>
                <TodayButton onClick={handleTodayWeek} />
                <NextButton onClick={handleNextWeek} />


              </Box>
            )}
            {selectedDay === "Day3" && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: { xs: "center", md: "space-between" },
                  gap: 2,
                }}
              >
                <PrevButton onClick={handlePrev3days} />
                <p style={{ margin: 0, alignSelf: "center" }} onClick={handlePClick}>
                  {formatDate(startDate)} -{" "}
                  {formatDate(new Date(startDate.getTime() + 2 * 24 * 60 * 60 * 1000))}
                </p>
                {isDatePickerVisible && (
                  <DataPickerForCalendarShowe
                    isVisible={isDatePickerVisible}
                    datePickerDate={datePickerDate}
                    handleDateChange={handleDateChange}
                    setDatePickerVisible={setDatePickerVisible}
                  />
                )}
                <ButtonWIchShowDateOfCalendar onClick={() => setDatePickerVisible(true)}>

                </ButtonWIchShowDateOfCalendar>
                <TodayButton onClick={handleToday3days} />
                <NextButton onClick={handleNext3days} />
              </Box>
            )}
            {selectedDay === "Day" && (

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: { xs: "center", md: "space-between" },
                  gap: 2,
                }}
              >
                <PrevButton onClick={handlePrevDay} />
                <p style={{ margin: 0, alignSelf: "center" }} onClick={handlePClick}>
                  {formatDate(startDate)}
                </p>

                {isDatePickerVisible && (
                  <DataPickerForCalendarShowe
                    isVisible={isDatePickerVisible}
                    datePickerDate={datePickerDate}
                    handleDateChange={handleDateChange}
                    setDatePickerVisible={setDatePickerVisible}
                  />
                )}
                <ButtonWIchShowDateOfCalendar onClick={() => setDatePickerVisible(true)}>

                </ButtonWIchShowDateOfCalendar>

                <TodayButton onClick={handleToday} />
                <NextButton onClick={handleNextDay} />
              </Box>
            )}

          </Box>

          <Box display="flex" alignItems="center">

            <ResponsiveDialog resetDateFromCalendar={resetDateFromCalendar} dateFromCalendar={dateTaken} timefromCalendar={timeTaken} isOpen={dialogOpenInsert} onClose={() => {setDialogOpenInsert(false); setTimeTaken(null)}} onEventSave={onEventSave} handleInsert={handleInsert} />
            < ButtonReport onClick={printPdfForDay} />


            <DropDownDay
              selectedOption={selectedDay}
              options={optionDay}
              onDayonChange={handleSelectedDay}
              fullWidth
            />
          </Box>

        </Box>

      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: { xs: "center", md: "center" },
              gap: 2,
            }}
          >
            <Box >

              {selectedDay === "Week" && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: { xs: "center", md: "space-between" },
                    gap: 2,
                  }}
                >
                  <ResponsiveDialog isSidebarOpen={isSidebarOpen} dateFromCalendar={dateTaken} timefromCalendar={timeTaken} isOpen={dialogOpenInsert} onClose={() => {setDialogOpenInsert(false); setTimeTaken(null)}} onEventSave={onEventSave} handleInsert={handleInsert} />
                  {/* <PrevButton onClick={handlePrevWeek} /> */}
                  <p style={{ margin: 0, alignSelf: "center", fontSize: '13px' }} onClick={handlePClick}>
                    {formatDate(startDate)} -{" "}
                    {formatDate(new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000))}
                  </p>
                  {isDatePickerVisible && (
                    <DataPickerForCalendarShowe
                      isVisible={isDatePickerVisible}
                      datePickerDate={datePickerDate}
                      handleDateChange={handleDateChange}
                      setDatePickerVisible={setDatePickerVisible}
                    />
                  )}
                  <ButtonWIchShowDateOfCalendar onClick={() => setDatePickerVisible(true)}>

                  </ButtonWIchShowDateOfCalendar>
                  {/* <TodayButton onClick={handleTodayWeek} />
                   <NextButton onClick={handleNextWeek} />
                  */}
                  <Button onClick={toggleSidebar} style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <FilterAltIcon sx={{ color: "rgb(192,192,192)" }} />
                  </Button>


                </Box>
              )}
              {selectedDay === "Day3" && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: { xs: "center", md: "space-between" },
                    gap: 2,
                  }}
                >
                  <ResponsiveDialog isSidebarOpen={isSidebarOpen} dateFromCalendar={dateTaken} timefromCalendar={timeTaken} isOpen={dialogOpenInsert} onClose={() => {setDialogOpenInsert(false); setTimeTaken(null)}} onEventSave={onEventSave} handleInsert={handleInsert} />

                  {/* <PrevButton onClick={handlePrev3days} /> */}
                  <p style={{ margin: 0, alignSelf: "center", fontSize: '13px' }} onClick={handlePClick}>
                    {formatDate(startDate)} -{" "}
                    {formatDate(new Date(startDate.getTime() + 2 * 24 * 60 * 60 * 1000))}
                  </p>
                  {isDatePickerVisible && (
                    <DataPickerForCalendarShowe
                      isVisible={isDatePickerVisible}
                      datePickerDate={datePickerDate}
                      handleDateChange={handleDateChange}
                      setDatePickerVisible={setDatePickerVisible}
                    />
                  )}
                  <ButtonWIchShowDateOfCalendar onClick={() => setDatePickerVisible(true)}>

                  </ButtonWIchShowDateOfCalendar>
                  <Button onClick={toggleSidebar} style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <FilterAltIcon sx={{ color: "rgb(192,192,192)" }} />
                  </Button>
                  {/* <TodayButton onClick={handleToday3days} />
                   <NextButton onClick={handleNext3days} /> */}
                </Box>
              )}

              {selectedDay === "Day" && (

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: { xs: "center", md: "space-between" },
                    gap: 2,
                  }}
                >
                  <ResponsiveDialog isSidebarOpen={isSidebarOpen} dateFromCalendar={dateTaken} timefromCalendar={timeTaken} isOpen={dialogOpenInsert} onClose={() => {setDialogOpenInsert(false); setTimeTaken(null)}} onEventSave={onEventSave} handleInsert={handleInsert} />
                  {/* <PrevButton onClick={handlePrevDay} /> */}
                  <p style={{ margin: 0, alignSelf: "center" }} onClick={handlePClick}>
                    {formatDate(startDate)}
                  </p>


                  {isDatePickerVisible && (
                    <DataPickerForCalendarShowe
                      isVisible={isDatePickerVisible}
                      datePickerDate={datePickerDate}
                      handleDateChange={handleDateChange}
                      setDatePickerVisible={setDatePickerVisible}
                    />
                  )}
                  <ButtonWIchShowDateOfCalendar onClick={() => setDatePickerVisible(true)}>

                  </ButtonWIchShowDateOfCalendar>

                  <Button onClick={toggleSidebar} style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <FilterAltIcon sx={{ color: "rgb(192,192,192)" }} />
                  </Button>


                  {/* <TodayButton onClick={handleToday} />
                   <NextButton onClick={handleNextDay} /> */}
                </Box>
              )}

            </Box>
            <Box style={{ display: 'flex' }}>
            </Box>

            {/* DRAWWWERR BAR A------------------START */}

            <Drawer anchor="right" open={isSidebarOpen} onClose={toggleSidebar}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',

                  width: {
                    xs: '250px',  // width on extra-small screens
                    md: '300px',  // width on medium screens
                    lg: '350px',  // width on large screens
                  },
                  padding: 2,

                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: { xs: "center", md: "center" },
                    gap: 2,
                  }}
                >
                  <div style={{ display: isDropdownVisible ? "block" : "none" }}>
                    <DropDownUser
                      selectedOption={selectedOption}
                      options={options}
                      onOptionChange={handleSelectedOption}
                      fullWidth
                    />
                  </div>

                  <Box width={{ xs: '200px', sm: '200px', md: '200px', lg: '200px', xl: '200px' }}>
                    <p>Članovi tima</p>
                    <Select
                      onChange={handleSelectChange}
                      sx={{
                        width: '100%',
                        height: '40px',
                        padding: '10px',
                        borderRadius: 0,
                        border: '1px solid lightgray',
                      }}
                      value={wichUserSelected}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {staffDropDown.map((staff) => (
                        <MenuItem key={staff.id} value={staff.id}>
                          {staff.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <p>Prikaz tretmana</p>
                    <DropDownDay
                      selectedOption={selectedDay}
                      options={optionDay}
                      onDayonChange={handleSelectedDay}
                      fullWidth
                      isSidebarOpen={isSidebarOpen}
                    />
                  </Box>

                  {/* <Box alignItems="center">
            <p>Zakazivanje</p> 
           </Box> */}
                  <Box>
                    <p>Preuzmi PDF</p>
                    < ButtonReport isSidebarOpen={isSidebarOpen} onClick={printPdfForDay} />

                  </Box>
                </Box>
              </Box>
            </Drawer>
            {/* DRAWWWERR BAR A------------------END */}



          </Box>
        </>

      )}



      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginTop: '2rem',
        }}
      >

        {wichUserSelected === "All" &&
          <>
            <IconButton onClick={() => setPageIndex((prev) => Math.max(prev - 1, 0))}>
              <NavigateBeforeIcon color="primary" /> {/* change the color as needed */}
            </IconButton>
            <IconButton
              onClick={() =>
                setPageIndex((prev) => Math.min(prev + 1, Math.floor(options.length / pageSize)))
              }
            >
              <NavigateNextIcon color="primary" /> {/* change the color as needed */}
            </IconButton>
          </>
        }
      </Box>

      {/* --------------------------------- */}
      <Box>

        <>

          {wichUserSelected === "All"
            ? slicedOptions.map((option, index) => {
              let indexx = index - 1;
              if (option === "All") {
                return null;
              }
              // console.log(option, 'aaaaaaa111')
              const printedEvents = new Set();
              const selectedUserEvents = selectedOption === "All" ? optionObjectMap[option].events : selectedUserr.events;
              return (
                <div
                  key={option}
                  style={{
                    marginBottom: "20px",
                    display: "inline-block",
                    paddingLeft: "0px !important",
                    width: window.innerWidth <= 419
                      ? "100%"
                      : window.innerWidth <= 732
                        ? "50%"
                        : window.innerWidth < 801
                          ? "33.33%"
                          : widthPercentage
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <Box className="boxForAvatar"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <Stack direction="row">
                        <Avatar sx={{ bgcolor: deepPurple[500] }}>
                          {optionStaff.find(staff => staff.id === option)?.name.charAt(0)}
                        </Avatar>
                      </Stack>
                    </Box>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <h5>{optionStaff.find(staff => staff.id === option)?.name}</h5>
                      <div style={{
                        height: '15px',
                        width: '15px',
                        borderRadius: '50%',
                        backgroundColor: optionStaff.find(staff => staff.id === option)?.color || '', // Find the color of the staff from optionStaff
                        marginLeft: '10px',
                        marginBottom: '5px',
                      }} />
                    </div>
                  </Box>

                  <table className="table border-1 ">
                    <thead className="inline-block" style={{ height: "0", padding: "0" }}>
                      <tr>
                        {selectedOption && selectedDay === "Day" ? (
                          // Render this table header with time and first day
                          <>
                            <th style={{ height: "0", padding: "0", display: index % 4 === 0 ? 'table-cell' : 'none' }}>

                            </th>
                            {renderedDays.map((day) => (
                              <th style={{ height: "0", padding: "0" }}
                                key={day.day}
                                className={selectedDay === "Day" ? "day1" : ""}

                              >

                              </th>
                            ))}
                          </>
                        ) : (
                          <>
                            <th style={{ height: "0", padding: "0" }}>

                            </th>
                            {renderedDays.map((day) => (
                              <th style={{ height: "0", padding: "0" }}
                                key={day.day}
                                className={selectedDay === "Day" ? "day1" : ""}

                              >

                              </th>
                            ))}
                          </>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {Array.from({ length: 16 }, (_, indexx) => {
                        const hour = 7 + indexx; // This ensures we start at 8 and go to 20

                        return (
                          <tr key={hour}>
                            <th
                              scope="row"
                              style={{
                                padding: 0,
                                display: index % 4 === 0 ? 'table-cell' : 'none',
                              }}
                            >
                              {/* index % 6  ovde se setuje koliko je ce kolona prikazati na stranici  menjanjem br 6  */}
                              {index % timeInterval === 0 ? hour.toString().padStart(2, '0') + ':00' : ''}
                            </th>
                            {renderedDays.map((day, dayIndex) => {
                              const divs = [];
                              let lastMinute = 0;
                              let counter = 0;
                              let currentDate = '';
                              const loopLimit = hour === 22 ? 2 : 2; // Change here for 22:30
                              for (let i = 0; i < loopLimit; i++) {
                                const minute = i * 30;
                                if (minute % 15 === 0) {
                                  const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                                  const date = renderedDays[dayIndex].fullDate; // Get date for current day for set to show just  day like 9 10 ... set .date if you want fulldate set .fullDate
                                  ; // will log 'object' if renderedDays is an array
                                  const matchingEvent = selectedUserEvents.find((event, i) => {
                                    const dateFromEvent = event.date;
                                    const clientTreatmentId = event.clientTreatmentId;
                                    const staffId = event.staffID
                                    const treatmentId = event.treatmentId;
                                    const price = event.price;
                                    const paid = event.clientTreatmentId;
                                    const staffTreatmentId = event.staffTreatmentId;
                                    let month, day, year;
                                    let monthh, dayy, yearr;


                                    if (dateFromEvent.includes("/")) {
                                      [month, day, year] = dateFromEvent.split("/");
                                    } else if (dateFromEvent.includes(".")) {
                                      [month, day, year] = dateFromEvent.split(".");
                                    }

                                    if (date.includes("/")) {
                                      [monthh, dayy, yearr] = date.split("/");
                                    } else if (dateFromEvent.includes(".")) {
                                      [monthh, dayy, yearr] = date.split(".");
                                    } else if (date.includes("-")) {
                                      [yearr, monthh, dayy] = date.split('-');
                                    }
                                    // const formattedDate = `${parseInt(month)}/${parseInt(day)}/${year}`;



                                    // const [monthh, dayy, yearr] = date.split('/');
                                    // const datee = `${parseInt(monthh)}/${parseInt(dayy)}/${yearr}`;

                                    const timeStart = event.startTime;
                                    const [strHour, strMin] = timeStart.split(":");
                                    const strformattedHour = parseInt(strHour);
                                    const strformattedMin = parseInt(strMin);

                                    const timeEnd = event.endTime;
                                    const [endHour, endMin] = timeEnd.split(":");
                                    const endformattedHour = parseInt(endHour);
                                    const endformattedMin = parseInt(endMin);


                                    const matches =
                                      (month === monthh && day === dayy && year === yearr) &&
                                      (strformattedHour < hour || (strformattedHour === hour && strformattedMin <= minute)) &&
                                      (endformattedHour > hour || (endformattedHour === hour && endformattedMin > minute));
                                    //edit from this endformattedMin >= minute into endformattedMin > minute

                                    return matches;
                                  });

                                  divs.push(
                                    <ButtonInstandDivForCalendar
                                      onClick={() => {
                                        const foundEvent = selectedUserEvents.find((event) => {

                                          const dateFromEvent = event.date;
                                          let month, day, year;
                                          let monthh, dayy, yearr;
                                          if (dateFromEvent.includes("/")) {
                                            [month, day, year] = dateFromEvent.split("/");
                                          } else if (dateFromEvent.includes(".")) {
                                            [month, day, year] = dateFromEvent.split(".");
                                          }

                                          if (date.includes("/")) {
                                            [monthh, dayy, yearr] = date.split("/");
                                          } else if (dateFromEvent.includes(".")) {
                                            [monthh, dayy, yearr] = date.split(".");
                                          }

                                          const timeStart = event.startTime;
                                          const [strHour, strMin] = timeStart.split(':');
                                          const strformattedHour = parseInt(strHour);
                                          const strformattedMin = parseInt(strMin);

                                          const timeEnd = event.endTime;
                                          const [endHour, endMin] = timeEnd.split(':');
                                          const endformattedHour = parseInt(endHour);
                                          const endformattedMin = parseInt(endMin);

                                          return (
                                            // (month === monthh && day === dayy && year === yearr) &&
                                            // ((hour === strformattedHour && minute >= strformattedMin) ||
                                            //   (hour > strformattedHour && hour < endformattedHour) ||
                                            //   (hour === endformattedHour && minute < endformattedMin))
                                            (month === monthh && day === dayy && year === yearr) &&
                                            (strformattedHour < hour || (strformattedHour === hour && strformattedMin <= minute)) &&
                                            (endformattedHour > hour || (endformattedHour === hour && endformattedMin > minute))
                                          );
                                        });
                                        if (foundEvent) {
                                          handleClickOpen(foundEvent);
                                        } else {
                                          const timeValue = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                                          setTimeTaken(timeValue);
                                          setDialogOpenInsert(true);
                                          setDateTaken(date)
                                        }
                                      }}
                                      onTouchStart={() => { }}
                                      style={{

                                        backgroundColor: matchingEvent ? matchingEvent.treatmentColor : 'white',
                                        borderBottomWidth: matchingEvent ? 0 : '1px',
                                        borderRightWidth: matchingEvent ? '5px' : 'none',
                                        borderLeftWidth: matchingEvent ? '5px' : 'none',
                                        borderStyle: 'solid',
                                        borderColor: matchingEvent ? 'white' : 'lightgray',
                                      }}
                                      dateTime={`${date}T${time}`}
                                    >

                                      {/* Show text on the event or div */}
                                      {(() => {
                                        const foundEventIndex = selectedUserEvents.findIndex((event) => {
                                          const dateFromEvent = event.date;

                                          let month, day, year;
                                          let monthh, dayy, yearr;
                                          if (dateFromEvent.includes("/")) {
                                            [month, day, year] = dateFromEvent.split("/");
                                          } else if (dateFromEvent.includes(".")) {
                                            [month, day, year] = dateFromEvent.split(".");
                                          }

                                          if (date.includes("/")) {
                                            [monthh, dayy, yearr] = date.split("/");
                                          } else if (dateFromEvent.includes(".")) {
                                            [monthh, dayy, yearr] = date.split(".");
                                          }

                                          const timeStart = event.startTime;
                                          const [strHour, strMin] = timeStart.split(":");
                                          const strformattedHour = parseInt(strHour);
                                          const strformattedMin = parseInt(strMin);

                                          const timeEnd = event.endTime;
                                          const [endHour, endMin] = timeEnd.split(":");
                                          const endformattedHour = parseInt(endHour);
                                          const endformattedMin = parseInt(endMin);

                                          // console.log(hour,strformattedHour,minute,strformattedMin,endformattedHour,endformattedMin,'rokaj333')
                                          return (
                                            // (month === monthh && day === dayy && year === yearr) &&
                                            // ((hour === strformattedHour && minute >= strformattedMin) ||
                                            //   (hour > strformattedHour && hour < endformattedHour) ||
                                            //   (hour === endformattedHour && minute < endformattedMin))
                                            (month === monthh && day === dayy && year === yearr) &&
                                            (strformattedHour < hour || (strformattedHour === hour && strformattedMin <= minute)) &&
                                            (endformattedHour > hour || (endformattedHour === hour && endformattedMin > minute))
                                          );
                                        });


                                        if (foundEventIndex >= 0 && !printedEvents.has(foundEventIndex)) {

                                          const event = selectedUserEvents[foundEventIndex];

                                          printedEvents.add(foundEventIndex);
                                          const dateFromEvent = event.date;
                                          const startTime = event.startTime;
                                          const endTime = event.endTime;
                                          const client = event.client;
                                          const status = event.status;
                                          let treatment = event.treatment;

                                          const [month, day, year] = dateFromEvent.split(".");
                                          // printedDates[dateFromEvent] = true;

                                          return (
                                            <>
                                              {/* {`Date: ${parseInt(month)}/${day}/${year}`}
                                     <br /> */}
                                              {/* {`Start Time: ${startTime} End Time: ${endTime}\n`} */}
                                              {/* <br /> */}
                                              {/* {`Client: ${client}`} */}
                                              {/* <br /> */}
                                              {` ${client}    ${startTime} - ${endTime}    ${treatment}`}
                                              {/* <br /> */}
                                              {/* {`Status: ${status}`} */}
                                              {/* <br /> */}
                                              {/* {`Treatments: ${treatment}`} */}
                                            </>
                                          );
                                        }

                                        return null;
                                      })()}
                                    </ButtonInstandDivForCalendar>
                                  );
                                  lastMinute = minute;
                                }
                              }

                              return (
                                <td
                                  key={day.day}
                                  //onClick={() => console.log(day.fullDate, 'This is the date', 'This is day.day:', day.day)}
                                  style={{

                                    borderBottomColor: 'red',
                                    borderRightWidth: '1px',
                                    borderRightStyle: 'solid',
                                    borderRightColor: 'black',
                                    borderBottomStyle: 'solid',
                                    backgroundColor: selectedUserEvents.some((event) => {

                                      const dateFromEvent = event.date;
                                      const date = renderedDays[dayIndex].fullDate;

                                      // const [month, day, year] = dateFromEvent.split('.');
                                      // const formattedDate = `${parseInt(month)}/${parseInt(day)}/${year}`;

                                      // const [monthh, dayy, yearr] = date.split('/');
                                      // const datee = `${parseInt(monthh)}/${parseInt(dayy)}/${yearr}`;

                                      let month, day, year;
                                      let monthh, dayy, yearr;

                                      if (dateFromEvent.includes("/")) {
                                        [month, day, year] = dateFromEvent.split("/");
                                      } else if (dateFromEvent.includes(".")) {
                                        [month, day, year] = dateFromEvent.split(".");
                                      }

                                      if (date.includes("/")) {
                                        [monthh, dayy, yearr] = date.split("/");
                                      } else if (dateFromEvent.includes(".")) {
                                        [monthh, dayy, yearr] = date.split(".");
                                      }

                                      const timeStart = event.startTime;
                                      const [strHour, strMin] = timeStart.split(':');
                                      const strformattedHour = parseInt(strHour);
                                      const strformattedMin = parseInt(strMin);
                                      // console.log(event.treatmentColor,"kdkas0f0asdf")
                                      const timeEnd = event.endTime;
                                      const [endHour, endMin] = timeEnd.split(':');
                                      const endformattedHour = parseInt(endHour);
                                      const endformattedMin = parseInt(endMin);
                                      return (
                                        (month === monthh && day === dayy && year === yearr) &&
                                        ((hour === strformattedHour && lastMinute >= strformattedMin) ||
                                          (hour > strformattedHour && hour < endformattedHour) ||
                                          (hour === endformattedHour && lastMinute < endformattedMin))


                                      );
                                    })
                                      ? 'lightred'
                                      : event.treatmentColor,

                                    borderBottomWidth: selectedUserEvents.some((event) => {
                                      const dateFromEvent = event.date;
                                      const date = renderedDays[dayIndex].fullDate;

                                      let month, day, year;
                                      let monthh, dayy, yearr;

                                      if (dateFromEvent.includes("/")) {
                                        [month, day, year] = dateFromEvent.split("/");
                                      } else if (dateFromEvent.includes(".")) {
                                        [month, day, year] = dateFromEvent.split(".");
                                      }

                                      if (date.includes("/")) {
                                        [monthh, dayy, yearr] = date.split("/");
                                      } else if (date.includes(".")) {
                                        [monthh, dayy, yearr] = date.split(".");
                                      }

                                      const timeStart = event.startTime;
                                      const [strHour, strMin] = timeStart.split(':');
                                      const strformattedHour = parseInt(strHour);
                                      const strformattedMin = parseInt(strMin);

                                      const timeEnd = event.endTime;
                                      const [endHour, endMin] = timeEnd.split(':');
                                      const endformattedHour = parseInt(endHour);
                                      const endformattedMin = parseInt(endMin);

                                      return (
                                        (month === monthh && day === dayy && year === yearr) &&
                                        ((hour === strformattedHour && lastMinute >= strformattedMin) ||
                                          (hour > strformattedHour && hour < endformattedHour) ||
                                          (hour === endformattedHour && lastMinute < endformattedMin))
                                      );
                                    }) ? '0px' : '1px',




                                  }}
                                >
                                  {divs}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>



                  {/* set open dialog for edit event */}

                  <UpdateDialogScheduler
                    open={dialogOpen}
                    onClose={handleClose}
                    onUpdate={handleUpdate}
                    event={selectedEvent}
                    toggleDataUpdated={toggleDataUpdatedFunction}

                  />





                </div>


              );
            })
            : <>

              {/* //-------------------------------------------------------------------- */}
              <Box className="boxForAvatar"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Stack direction="row">
                  <Avatar sx={{ bgcolor: deepPurple[500] }}>
                    {selectedOption ? optionStaff.find(staff => staff.id === selectedOption)?.name.charAt(0) : ""}
                  </Avatar>
                </Stack>
              </Box>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h5>{selectedOption ? optionStaff.find(staff => staff.id === selectedOption)?.name : ""}</h5>
                {/* set point next to name color of point */}
                {/* Color point next to option */}
                {/* changed1 */}
                <div style={{
                  height: '15px',
                  width: '15px',
                  borderRadius: '50%',
                  backgroundColor: optionStaff.find(staff => staff.id === selectedOption)?.color || '',
                  marginLeft: '10px',
                  marginBottom: '5px',
                }} />
              </div>


              <table className="table border-1">
                <thead className="inline-block">
                  <tr>
                    {selectedOption && selectedDay === "Week" ? (
                      // Render this table header with time and days
                      <>
                        <th>Time</th>
                        {days.map((day) => (
                          <th key={day.day}>
                            {day.day}
                            {day.date}
                          </th>
                        ))}
                      </>
                    ) : selectedOption && selectedDay === "Day3" ? (
                      // Render this table header with time and first 3 days
                      <>
                        <th style={{ minWidth: "50px" }}>Time</th>
                        {days.slice(0, 3).map((day) => (
                          <th
                            key={day.day}
                            className={selectedDay === "Day3" ? "day3" : ""}
                          >
                            {day.day}
                            {day.date}
                          </th>
                        ))}
                      </>
                    ) : selectedOption && selectedDay === "Day" ? (
                      // Render this table header with time and first day
                      <>
                        <th style={{ minWidth: "50px", textAlign: "center" }}>
                          Time
                        </th>
                        {days.slice(0, 1).map((day) => (
                          <th
                            key={day.day}
                            className={selectedDay === "Day" ? "day1" : ""}
                            style={{ textAlign: "center" }}
                          >
                            {day.day}
                            {day.date}
                          </th>
                        ))}
                      </>
                    ) : null}
                  </tr>
                </thead>

                <tbody>



                  {Array.from({ length: 16 }, (_, indexx) => {
                    const hour = 7 + indexx; // This ensures we start at 8 and go to 20

                    return (
                      <tr key={hour}>
                        <th scope="row">{hour.toString().padStart(2, "0")}:00</th>
                        {renderedDays.map((day, dayIndex) => {
                          const divs = [];
                          let lastMinute = 0;
                          let counter = 0;
                          let currentDate = "";

                          for (let i = 0; i < 2; i++) {

                            const minute = i * 30;
                            if (minute % 15 === 0) {
                              // Only show minutes that are multiples of 15
                              const time = `${hour
                                .toString()
                                .padStart(2, "0")}:${minute
                                  .toString()
                                  .padStart(2, "0")}`;
                              const date = renderedDays[dayIndex].fullDate; // Get date for current day for set to show just  day like 9 10 ... set .date if you want fulldate set .fullDate
                              const matchingEvent = selectedUserEvents.find((event, i) => {
                                const dateFromEvent = event.date;
                                const clientTreatmentId = event.clientTreatmentId;
                                const staffId = event.staffID
                                const treatmentId = event.treatmentId;
                                const price = event.price;
                                const paid = event.clientTreatmentId;
                                const staffTreatmentId = event.staffTreatmentId;



                                const [month, day, year] = dateFromEvent.split(".");
                                const formattedDate = `${parseInt(month)}/${parseInt(day)}/${year}`;

                                const timeStart = event.startTime;
                                const [strHour, strMin] = timeStart.split(":");
                                const strformattedHour = parseInt(strHour);
                                const strformattedMin = parseInt(strMin);

                                const timeEnd = event.endTime;
                                const [endHour, endMin] = timeEnd.split(":");
                                const endformattedHour = parseInt(endHour);
                                const endformattedMin = parseInt(endMin);

                                // console.log(`Evenssst ${i}: date ${formattedDate}, start ${strformattedHour}:${strformattedMin}, end ${endformattedHour}:${endformattedMin},color ${event.treatmentColor}`);
                                // console.log(event,"alksdfjlccdkjasfiij")

                                const matches =
                                  renderedDays[dayIndex].fullDate === formattedDate &&
                                  (strformattedHour < hour || (strformattedHour === hour && strformattedMin <= minute)) &&
                                  (endformattedHour > hour || (endformattedHour === hour && endformattedMin > minute));


                                return matches;
                              });
                              divs.push(
                                <ButtonInstandDivForCalendar
                                  onClick={() => {
                                    const foundEvent = selectedUserEvents.find((event) => {

                                      const dateFromEvent = event.date;
                                      let month, day, year;
                                      let monthh, dayy, yearr;
                                      if (dateFromEvent.includes("/")) {
                                        [month, day, year] = dateFromEvent.split("/");
                                      } else if (dateFromEvent.includes(".")) {
                                        [month, day, year] = dateFromEvent.split(".");
                                      }

                                      if (date.includes("/")) {
                                        [monthh, dayy, yearr] = date.split("/");
                                      } else if (dateFromEvent.includes(".")) {
                                        [monthh, dayy, yearr] = date.split(".");
                                      }

                                      const timeStart = event.startTime;
                                      const [strHour, strMin] = timeStart.split(':');
                                      const strformattedHour = parseInt(strHour);
                                      const strformattedMin = parseInt(strMin);

                                      const timeEnd = event.endTime;
                                      const [endHour, endMin] = timeEnd.split(':');
                                      const endformattedHour = parseInt(endHour);
                                      const endformattedMin = parseInt(endMin);

                                      return (
                                        (month === monthh && day === dayy && year === yearr) &&
                                        ((hour === strformattedHour && minute >= strformattedMin) ||
                                          (hour > strformattedHour && hour < endformattedHour) ||
                                          (hour === endformattedHour && minute < endformattedMin))
                                      );
                                    });
                                    if (foundEvent) {
                                      handleClickOpen(foundEvent);
                                    } else {
                                      const timeValue = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                                      setDialogOpenInsert(true);
                                      setTimeTaken(timeValue);
                                      setDateTaken(date)
                                    }
                                  }}


                                  onTouchStart={() => { }}
                                  style={{

                                    backgroundColor: matchingEvent ? matchingEvent.treatmentColor : 'white',
                                    borderBottomWidth: matchingEvent ? 0 : '1px',
                                    borderRightWidth: matchingEvent ? '5px' : 'none',
                                    borderLeftWidth: matchingEvent ? '5px' : 'none',
                                    borderStyle: 'solid',
                                    borderColor: matchingEvent ? 'white' : 'lightgray',
                                  }}
                                  dateTime={`${date}T${time}`}
                                >
                                  {/* show tex on event or div */}
                                  {(() => {
                                    const foundEventIndex = selectedUserEvents.findIndex((event) => {
                                      const dateFromEvent = event.date;

                                      let month, day, year;
                                      let monthh, dayy, yearr;
                                      if (dateFromEvent.includes("/")) {
                                        [month, day, year] = dateFromEvent.split("/");
                                      } else if (dateFromEvent.includes(".")) {
                                        [month, day, year] = dateFromEvent.split(".");
                                      }

                                      if (date.includes("/")) {
                                        [monthh, dayy, yearr] = date.split("/");
                                      } else if (dateFromEvent.includes(".")) {
                                        [monthh, dayy, yearr] = date.split(".");
                                      }

                                      const timeStart = event.startTime;
                                      const [strHour, strMin] = timeStart.split(":");
                                      const strformattedHour = parseInt(strHour);
                                      const strformattedMin = parseInt(strMin);

                                      const timeEnd = event.endTime;
                                      const [endHour, endMin] = timeEnd.split(":");
                                      const endformattedHour = parseInt(endHour);
                                      const endformattedMin = parseInt(endMin);

                                      return (
                                        (month === monthh && day === dayy && year === yearr) &&
                                        ((hour === strformattedHour && minute >= strformattedMin) ||
                                          (hour > strformattedHour && hour < endformattedHour) ||
                                          (hour === endformattedHour && minute < endformattedMin))
                                      );
                                    });


                                    if (foundEventIndex >= 0 && !printedEvents.has(foundEventIndex)) {

                                      const event = selectedUserEvents[foundEventIndex];

                                      printedEvents.add(foundEventIndex);
                                      const dateFromEvent = event.date;
                                      const startTime = event.startTime;
                                      const endTime = event.endTime;
                                      const client = event.client;
                                      const status = event.status;
                                      let treatment = event.treatment;

                                      const [month, day, year] = dateFromEvent.split(".");
                                      // printedDates[dateFromEvent] = true;

                                      return (
                                        <>
                                          {/* {`Date: ${parseInt(month)}/${day}/${year}`}
                                           <br /> */}
                                          {/* {`Start Time: ${startTime} End Time: ${endTime}\n`} */}
                                          {/* <br /> */}
                                          {/* {`Client: ${client}`} */}
                                          {/* <br /> */}
                                          {` ${client}    ${startTime} - ${endTime}    ${treatment}`}
                                          {/* <br /> */}
                                          {/* {`Status: ${status}`} */}
                                          {/* <br /> */}
                                          {/* {`Treatments: ${treatment}`} */}
                                        </>
                                      );
                                    }

                                    return null;
                                  })()}
                                </ButtonInstandDivForCalendar>
                              );
                              lastMinute = minute;
                            }
                          }

                          return (
                            <td
                              key={day.day}
                              //  onClick={() =>
                              //    console.log(
                              //      day.fullDate,
                              //      "ovo je datum bato",
                              //      "ovo je day.day:",
                              //      day.day
                              //    )
                              //  }
                              style={{
                                //: "calc(100% / 7)",
                                borderRight: "1px solid black",
                                ...(selectedUserEvents.some((event) => {
                                  const dateFromEvent = event.date;
                                  const [month, day, year] =
                                    dateFromEvent.split(".");
                                  const formattedDate = `${parseInt(
                                    month
                                  )}/${parseInt(day)}/${year}`;

                                  const timeStart = event.startTime;
                                  const [strHour, strMin] = timeStart.split(":");
                                  const strformattedHour = parseInt(strHour);
                                  const strformattedMin = parseInt(strMin);

                                  const timeEnd = event.endTime;
                                  const [endHour, endMin] = timeEnd.split(":");
                                  const endformattedHour = parseInt(endHour);
                                  const endformattedMin = parseInt(endMin);

                                  return (
                                    renderedDays[dayIndex].fullDate ===
                                    formattedDate &&
                                    ((hour === strformattedHour &&
                                      lastMinute >= strformattedMin) ||
                                      (hour > strformattedHour &&
                                        hour < endformattedHour) ||
                                      (hour === endformattedHour &&
                                        lastMinute <= endformattedMin))
                                  );
                                })
                                  ? {
                                    backgroundColor: "lightred",
                                    borderBottom: "none",
                                    borderRadius: "5px",
                                  }
                                  : {
                                    backgroundColor: "white",
                                    borderBottom: "1px solid red",
                                    borderRight: "1px solid black",
                                  }),
                              }}
                            >
                              {divs}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <UpdateDialogScheduler
                open={dialogOpen}
                onClose={handleClose}
                onUpdate={handleUpdate}
                event={selectedEvent}
              />


            </>

          }


        </>
      </Box>
    </>
  );
}

export default Calendar;