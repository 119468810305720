import React from "react";
import { useNavigate } from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Divider, InputAdornment, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import dayjs from "dayjs";
//import for radio group
import UseRadioGroup from "../../components/Rbutton";

//import datapicker
import BasicDateTimePicker from "../../components/DataPicker";

//redux
import { useSelector, useDispatch } from "react-redux";

//icon
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

//Buttons
import UploadButtons from "../../components/UploadButton"; //uploadButton
import BasicContained from "../../components/ButtonContained";
import BasicOutlined from "../../components/ButtonOutlined";
import IconButtons from "../../components/IconButton";

import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import UnpaidDeposits from "../mainpage/UnpaidDeposits"
import { Bar, Line, Doughnut } from "react-chartjs-2";


import { fetchClients, fetchTreatments, fetchStaffTreatments, fetchStaff ,postClientTreatments } from '../../redux/action';


const MainPage = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState();
  const isMenuOpen = useSelector((state) => state.menu.isMenuOpen);
  const [selectedDate, setSelectedDate] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [isEventUpdated, setEventUpdated] = useState(false);
  const [clients, setClients] = useState({ name: "" });

  const users = useSelector(state => state.menu.users);  
  const userEmail = useSelector(state => state.menu.userEmail);  


  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken'); // get the token from local storage

  //this part is fow view
  const [showOsnovniPodaci, setShowOsnovniPodaci] = useState(true);
  const [showTretmani, setShowTretmani] = useState(false);

  const handleOsnovniPodaciClick = () => {
    setShowOsnovniPodaci(true);
    setShowTretmani(false);
  };

  const handleTretmaniClick = () => {
    setShowOsnovniPodaci(false);
    setShowTretmani(true);
  };
  
  //--------------------------------------
 // const { id } = useParams(); //id from url
//  console.log(id,"id");

  //take client with id from server  and set state with clients
  const headers = {
        "accept": "application/json",
        "Authorization": `Bearer ${token}`,
     
  }
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [newsOnEmail, setNewsOnEmail] = React.useState("");


  // React.useEffect(() => {
  //   axios//ovde pucaaa
  //     .get(`https://api.adaktarbooking.com/api/v1/client/${id}`, { headers })
  //     .then((response) => {
  //       console.log(response,'odsajf0')

  //       setClients(response.data);
  //       const dateObject = dayjs(response.data.dateOfBirth);
  //       setDateOfBirth(dateObject);
  //       setNewsOnEmail(response.data.newsOnEmail.toString()); // Add this line. Convert boolean to string.


  //      console.log(response.data)
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         // The request was made and the server responded with a status code
  //         // that falls out of the range of 2xx
  //         console.error('Error', error.response.status);//ovde puca
  //         console.error('Error detail', error.response.data);//ovde puca
  //       } else if (error.request) {
  //         // The request was made but no response was received
  //         console.error('No response received', error.request);
  //       } else {
  //         // Something happened in setting up the request that triggered an Error
  //         console.error('Error', error.message);
  //       }
  //     });
  // }, [id,refreshData]);
  

  //change clients state set my filed editable
  const handleNameChange = (event) => {
    setClients({ ...clients, name: event.target.value });
  };
  const handleNewsOnEmailChange = (event) => {
    setNewsOnEmail(event.target.value);
  };
  const handleSurnameChange = (event) => {
    setClients({ ...clients, surname: event.target.value });
  };
  const handlePhoneChange = (event) => {
    setClients({ ...clients, phone: event.target.value });
  };
  const handleAddressChange = (event) => {
    setClients({ ...clients, address: event.target.value });
  };
  
const handleDrugAllergiesChange = (event) => {
  setClients({ ...clients, drugAllergies: event.target.value });
};

const handleDescriptionChange = (event) => {
  setClients({ ...clients, description: event.target.value });
};

const handleDateChange = (date) => {
  setDateOfBirth(date);
};
const handleEmailChange = (event) => {
  setClients({ ...clients, email: event.target.value });
};

// const handleNewsOnEmailChange = (event) => {
//   setNewsOnEmail(event.target.value);
// };
  //onclick containedButton update client in database
  // const updateClient = () => {
  //   const clientData = {
  //     ...clients, 
  //     dateOfBirth: dateOfBirth && dateOfBirth.isValid() ? dateOfBirth.toISOString() : null,
  //     newsOnEmail: newsOnEmail === "true"
      
  //       };

  //   axios //ovde puca
  //     .put(`https://api.adaktarbooking.com/api/v1/client/${id}`, clientData, { headers })
  //     .then((response) => {
  //             setRefreshData(!refreshData); // Add this line here

  //       setClients(response.data); //update the state with the response
  //       if(response.data.dateOfBirth) {
  //         const dateObject = dayjs(response.data.dateOfBirth);
  //         setDateOfBirth(dateObject);
  //       }
  //       if(response.data.newsOnEmail) {
  //         setNewsOnEmail(response.data.newsOnEmail.toString()); 
  //       }
  //       console.log('Updated client successfully', response.data);
        
  //     })
  //     .catch((error) => {
  //       console.log(error);
  
  //       if (error.response) {
  //         // The request was made and the server responded with a status code
  //         // that falls out of the range of 2xx
  //         if (error.response.status === 400) {
  //           console.error('Bad Request - Invalid or missing data', error.response.data);
  //         } else if (error.response.status === 401) {
  //           console.error('Unauthorized - Invalid token', error.response.data);
  //         } else {
  //           console.error(`Unknown server error - ${error.response.status}`, error.response.data);
  //         }
  //       } else if (error.request) {
  //         // The request was made but no response was received
  //         console.error('No response received', error.request);
  //       } else {
  //         // Something happened in setting up the request that triggered an Error
  //         console.error('Error', error.message);
  //       }
  //     });
  // };
  //--




//END 
  
  const navigateONIndex = () => {
    navigate("/index");
    //console.log("cao");
  };
  const navOnCliShearch = () => {
    navigate("/client/search");
  };
  

  
  return (
    <>
   
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              ml: isMenuOpen ? 33 : 8,
              pt: 8,
              pl: 2,
              pr: 2,

              "@media (max-width: 600px)": {
                ml: 0,
              },
            }}
          >
            <Grid item xs={12} sx={{ mt: 2, mb: 1, p: 1 }}>
              <Box>
                <Typography sx={{ fontSize: "25px" }}> </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                

                  <Typography sx={{ fontSize: "15px" }}>
                    
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              sx={{
                bgcolor: "white",
                width: "100%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item md={12}>
                
                  <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between' }}>
                  <Typography variant="h6" sx={{ p: 2, marginLeft: 2 }}>
                    Neplaceni depoziti 
                  </Typography>
                  <Box sx={{ display: 'flex' }}>

                 
                
                  
                  </Box>
                </Box>
                <Divider sx={{}} />
              </Grid>

            
             <Grid container sx={{}}>
            <UnpaidDeposits />
            </Grid>
  
            </Grid>
          </Box>
        </Box>
     
    </>
  );
};

export default MainPage;

