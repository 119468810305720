import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from 'axios';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; //redux



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ComboTreatment(props) {

  const [open, setOpen] = useState(false); // New state

 const [value, setValue] = React.useState(null);

  const [treatments, setTreatments] = useState([]);

  const { onChange, treatments: selectedTreatments = [] } = props;
  const treatmentss= useSelector(state => state.menu.treatments); 
  // Convert the selected treatments array to the format used by Autocomplete
  const selectedTreatmentObjects = selectedTreatments.map((treatment) => {
    return { title: treatment, };
  });
 
// console.log(treatments,"treatments ispis")
const handleTreatmentChange = (event, newValue) => {
  setValue(newValue);
  // Check if newValue is not null before trying to access its properties
  onChange(newValue ? {title: newValue.title, id: newValue.id, treatmentPriceId: newValue.treatmentPriceId,duration: newValue.duration } : "");
  setOpen(false); // Close dropdown after selection

};

//TAKE ALL TREATMENT FROM DB START
useEffect(() => {
  const fetchedTreatments = treatmentss.map((item) => {
    return { title: item.title, id: item.id, treatmentPriceId: item.treatmentPriceId, duration: item.duration };
  });
  setTreatments(fetchedTreatments);
}, []);
//TAKE ALL TREATMENT FROM DB END
  return (
    <Autocomplete
    open={open} // Control opening with state
    onOpen={() => setOpen(true)} // Open when user interacts
    onClose={() => setOpen(false)} // Close when user leaves

    id="checkboxes-tags-demo"
    size="small"
    options={treatments}
    disableCloseOnSelect
    onChange={handleTreatmentChange}
    getOptionLabel={(option) => option.title}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option.title}
      </li>
    )}
    style={{ width: " 100%" }}
    renderInput={(params) => 
      <TextField 
        {...params} 
    
        placeholder={value ? "Izaberite tretman" : "Izaberite tretman"} 
        variant="outlined"
        InputProps={{
          ...params.InputProps, 
          style: { height: '40px' }
        }}
      />
    }
    
    
    value={value}
    />
);
}


// const treatments = [
//   { title: "Obrve 200" },
//   { title: "Obrve 200 1" },
//   { title: "Obrve 200 2" },
//   { title: "Obrve 250" },
//   { title: "Obrve 300" },
//   { title: "Obrve 350" },
//   { title: "Obrve 400" },
//   { title: "Obrve 450" },
//   { title: "Obrve 500" },
//   { title: "Obrve 550" },
//   { title: "Obrve 600" },
//   { title: "Obrve 650" },
//   { title: "Obrve 700" },
//   { title: "Obrve 750" },
//   { title: "Obrve 800" },
//   { title: "Obrve 850" },
//   { title: "Obrve 900" },
//   { title: "Obrve 950" },
//   { title: "Obrve 1000" },
//   { title: "Diamond lips 600e" },
//   { title: "Dokvalifikacija 1 dan" },
//   { title: "Osvezavanje obrva 100" },
//   { title: "Osvezavanje obrva 150" },
//   { title: "Osvezavanje obrva 200" },
//   { title: "Ajlajner 400" },
//   { title: "Ajlajner 600e" },
//   { title: "Korekcija 50" },
//   { title: "Laser 100" },
//   { title: "Laser 50" },
//   { title: "Konsultacije 10e" },
//   { title: "Konsultacije 20e" },
//   { title: "PRP 100" },
//   { title: "Mezoterapija 50" },
//   { title: "Mezoniti" },
//   { title: "Hijaluron" },
//   { title: "PRP 200" },
//   { title: "Korekcija 100" },
//   { title: "Mezoterapija 60" },
//   { title: "Mezoterapija 70" },
//   { title: "Hijaluron 2" },
//   { title: "Hijaluron 3" },
//   { title: "Hijaluron 4" },
//   { title: "PRP 150" },
//   { title: "Osvezavanje 175e" },
//   { title: "Ind obuka 1" },
//   { title: "Ind obuka 2" },
//   { title: "Ind obuka 3" },
//   { title: "Kontrola Master" },
//   { title: "Grup/obuka 1" },
//   { title: "Grup/obuka 2" },
//   { title: "Grup/obuka 3" },
//   { title: "Usavrsavanje 1 dan" },
//   { title: "Removel" },
//   { title: "Osvezavanje obrva 250" },
//   { title: "Korekcija usana 100" },
//   { title: "Dokvalifikacija usne 1 dan" },
//   { title: "Korekcija Ajlajnera 100e" },
//   { title: "Dokvalifikacija ajlajner 1 dan" },
//   { title: "Osvezavanje usana" },
//   { title: "Cupanje obrva (1000 din)" },
//   { title: "Kontrola Adam" },
//   { title: "Laser kod Adama (50)" },
//   { title: "Laser kod Adama (100)" },
//   { title: "Osvezavanje usana 400e" },
//   { title: "Farbanje obrva" },
//   { title: "Kontrola dr. Marko" },
//   { title: "Master class" },
//   { title: "Edukacija YUGOTOUR" },
//   { title: "Master class YUGO TOUR" },
//   { title: "Bazni Yugo tour" },
//   { title: "laser 200" },
//   { title: "Brow lift" },
//   { title: "Lash lift" },
//   { title: "Obrve-osvezavanje 300e" },
//   { title: "Korekcija Pp 100e" },
//   { title: "Laser 100e novi" },
//   { title: "Obrve - osvezavanje 1000e" },
//   { title: "Osvezavanje 550e 1" },
//   { title: "Obrve 1100e" },
//   { title: "Obrve 1200e" },
//   { title: "Obrve 1300e" },
//   { title: "Obrve 1400e" },
//   { title: "Obrve 1500e" },
//   { title: "Obrve 1600e" },
//   { title: "Korekcija 200e" },
//   { title: "Osvezavanje 550e" },
//   { title: "Osvezavanje 600e" },
//   { title: "Osvezavanje 650e" },
//   { title: "Osvezavanje 700e" },
//   { title: "Osvezavanje 750e" },
//   { title: "Osvezavanje 1000e" },
//   { title: "Diamond lips 1000e" },
//   { title: "Ajlajner 1000e" },
//   { title: "Korekcija ajlajnera 200e" },
// ];
