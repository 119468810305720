import * as React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme, useMediaQuery } from '@mui/material';


export default function EditButton(props) {
  const { onClick, size } = props;  // Add the size here
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const smallSizePx = 12; // e.g., 24 pixels for small screens
  const largeSizePx = 18; // e.g., 48 pixels for larger screens
  const iconSize = isSmallScreen ? smallSizePx : largeSizePx;
    
  const smallSizePd = 4; 
  const largeSizePd = 3; 
  const iconpadding = isSmallScreen ? smallSizePd : largeSizePd;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
    <IconButton aria-label="edit" onClick={handleClick} style={{ color: "green",  padding: `${iconpadding}px`}}>
      <EditIcon style={{ fontSize: iconSize }} />
    </IconButton>
  </Stack>
  );
}
