import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { NavLink, useParams } from "react-router-dom";
//Button
import EditButton from "../EditButton";
import DelButton from "../DelButton";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear"; //icon for delete search
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { useMediaQuery } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux"; //redux


import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { useNavigate } from 'react-router-dom';




             

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "rgba(237, 237, 237)",
  },
  "& .clear-icon": {
    backgroundColor: "lightgray", // Set your desired color for the ClearIcon here
  },
  "& .MuiDataGrid-row": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));
const useStyles = makeStyles({
  wrapCellContent: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: 160,
  },
  
});
    
export default function DataTableT() {
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);
  const treatments = useSelector(state => state.menu.treatments);  // access the data from the state
  const navigate = useNavigate();
  const basicRole = useSelector((state) => state.menu.basicRole); 

  const isDesktop = useMediaQuery('(min-width:1169px)');
  const classes = useStyles();
  const token = localStorage.getItem('accessToken'); // get the token from local storage
  const tenant = 'root'; // Define your tenant
  //console.log(token,"tokenis")
  //function for deleting row whan click on deletebutton
  const BASE_URL = 'https://api.adaktarbooking.com';

  function handleDelete(id) {
    axios
    .delete(`${BASE_URL}/api/v1/treatment/${id}`, {

      headers: {
        "Authorization": `Bearer ${token}`,
        "tenant": tenant,
      }
    })
    .then(() => {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      setFilteredRows(updatedRows);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  const navigateOnCreateCard = () => {
    navigate("/treatment/new"); // Replace '/desired-route' with your actual route.
  }

  //columns in table
  const columns = [
  //  { field: "id", headerName: "ID", flex: 1, headerAlign: "center", align: "center" },
    { field: "title", headerName: "Naziv", width: isDesktop ? undefined : 200, flex: isDesktop ? 1.5 : undefined, headerAlign: "center", align: "center",
     renderCell: (params) => (
      
      <div className={classes.wrapCellContent}>
        {params.value}
      </div>
    ), },
    { field: "description", headerName: "Opis", width: isDesktop ? undefined : 200, flex: isDesktop ? 1.5 : undefined, headerAlign: "center", align: "center", renderCell: (params) => (
      <div className={classes.wrapCellContent}>
        {params.value}
      </div>
    ),},
    { field: "repetitionPeriod", headerName: "Preporučeno ponavljanje tretmana do (dana)", width: isDesktop ? undefined : 300, flex: isDesktop ? 6 : undefined, headerAlign: "center", align: "center" },
    { field: "duration", headerName: "Trajanje (minuta)", width: isDesktop ? undefined : 200, flex: isDesktop ? 2 : undefined, headerAlign: "center", align: "center" },
    {
      field: "action",
      headerName: "Akcije",
      width: isDesktop ? undefined : 100, flex: isDesktop ? 1.5 : undefined,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <Box sx={{ display: "flex" }}>
            <NavLink to={`/treatment/preview/${params.row.id}`}>
              <EditButton />
            </NavLink>
            
            {!basicRole && (
            <DelButton onClick={() => handleDelete(params.row.id)} />
            )}
          </Box>
        </div>
      ),
    },
  ];


 //function get data from base  and put data in state
// React.useEffect(() => {
//   axios .get("${BASE_URL}/api/v1/treatment/alltretmans", {

  
//      headers: {
//      "accept": "application/json",
//      "Authorization": `Bearer ${token}`,
//      //"tenant": tenant,

//     }
  
   
//   })
//   .then((response) => {
//     setRows(response.data);
//     setFilteredRows(response.data);
//     console.log(response.data,"podaciii")
//     console.log(response,"response from datatreatment");
//   })
//   .catch((error) => {
//     console.log(error,"err from datatreatment");
//   });

// }, []);

React.useEffect(() => {
  setRows(treatments);
  setFilteredRows(treatments);
}, [treatments]);


  //function for search
  useEffect(() => {
    if (search === "") {
      setFilteredRows(rows);
    } else {
      const filteredData = rows.filter((row) =>
        row.title.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredRows(filteredData);
    }
  }, [search, rows]);
  function DeleteSearch() {
    setSearch("");
  }
  return (
    <div style={{ height: '65vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div style={{ height: '90%', width: '100%', marginBottom: "50px" }}>
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "50px" }}>
        <TextField
          fullWidth
          size="Normal"
          id="standard-basic"
          label="search"
          variant="standard"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: "50%" }}
        />
  
        {search !== "" && (
          <IconButton aria-label="delete" onClick={DeleteSearch}>
            <ClearIcon />
          </IconButton>
        )}
               <IconButton onClick={navigateOnCreateCard} sx={{ ml: 1 }}>
        <AddCircleOutlineIcon sx={{ fontSize: '35px' }} />
    </IconButton>
      </Box>
  
      <StyledDataGrid
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
               borderBottom: "none",
             },
         borderRadius: "8px",
         marginTop: "16px",
         backgroundColor: "white",
         paddingX: "40px",
        }}
        rows={filteredRows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </div>
  </div>
  );
  
}
